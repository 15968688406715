import SellerLayout from 'layouts/SellerLayout';
import ProfilePage from '../../Components/Seller/ProfilePage/ProfilePage';

const ProfileSettings = () => {
  return (
    <SellerLayout>
      <ProfilePage />
    </SellerLayout>
  );
};

export default ProfileSettings;

import './productDetail.scss';

const ProductVideo = ({ video }) => {
  return (
    <div className="productImgContainer">
      <div className="productImg">
        <video controls>
          <source src={video?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default ProductVideo;

import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { t } from 'i18next';

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
    padding: '8px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    minWidth: '160px',
    padding: '8px 16px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableInfoRows = ({ heading, info }) => {
  return (
    <StyledTableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <StyledTableCell component="th" scope="row">
        {heading}
      </StyledTableCell>
      <StyledTableCell align="right">{info}</StyledTableCell>
    </StyledTableRow>
  );
};

const ViewProductsTable = ({ product, btnName, index }) => {
  const [expand, setExpand] = useState(false);
  const history = useHistory();

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const product_model = new Date(product?.product_model);
  const product_available_from = new Date(product?.product_available_from);

  const handlePush = () => {
    history.push(`/editProduct/${product?.product_id}`);
  };

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };
  return (
    <div className="mobileTableRowDisplay">
      <div className="briefBox">
        <div className="headingNameContainer" onClick={handlePush}>
          <div className="titleName">
            {index + 1}. {product?.product_name}
          </div>
          <div className="subName">
            {t('filterNames.productType')} : {product?.product_type_name}
          </div>
        </div>

        <div className="buttonContainer">
          <IconButton variant="contained" size="medium" onClick={handleExpand}>
            {expand === false ? (
              <AddCircleIcon
                style={{
                  width: '1.1rem',
                  height: '1.1rem',
                }}
              />
            ) : (
              <RemoveCircleIcon
                style={{
                  width: '1.1rem',
                  height: '1.1rem',
                }}
              />
            )}
          </IconButton>
        </div>
      </div>
      {expand && (
        <div className="infoBox">
          <TableContainer
            sx={{ overflowX: 'hidden' }}
            className="myPolicyOuterTable"
          >
            <Table aria-label="customized table" stickyHeader>
              <TableBody>
                <TableInfoRows
                  heading={t('productListPage.product')}
                  info={product?.product_name}
                />
                <TableInfoRows
                  heading={t('filterNames.productType')}
                  info={product?.product_type_name}
                />
                <TableInfoRows
                  heading={t('productDetailPage.variety')}
                  info={`${product?.product_variety_name}`}
                />
                <TableInfoRows
                  heading={t('productDetailPage.avail')}
                  info={`${product?.product_available_name}`}
                />
                <TableInfoRows
                  heading={t('productDetailPage.availFrom')}
                  info={`${padL(product_available_from.getDate())}-${padL(
                    product_available_from.getMonth() + 1
                  )}-${product_available_from.getFullYear()}`}
                />
                <TableInfoRows
                  heading={t('sellerDashboard.crop')}
                  info={`${
                    month[product_model?.getMonth()]
                      ? `${month[product_model?.getMonth()]} - `
                      : ''
                  } ${product_model?.getFullYear()}`}
                />
                <TableInfoRows
                  heading={t('productDetailPage.availQty')}
                  info={product?.prod_units}
                />
                <TableInfoRows
                  heading={t('productDetailPage.unitPrice')}
                  info={
                    <>
                      <span className="italicSpan">
                        {product?.onsale_price && `₹ ${product?.onsale_price}`}{' '}
                      </span>
                      {product?.price_type == 2 && (
                        <>
                          {product?.prices?.map((price, index) => (
                            <Fragment key={index}>
                              <span className="italicSpan">
                                {price?.minimum} - {price?.maximum}
                                {product?.unit == 'Quintals'
                                  ? 'Q'
                                  : product?.unit == 'Ltrs'
                                  ? 'L'
                                  : product?.unit == 'Bags'
                                  ? 'Bags'
                                  : 'Bales'}{' '}
                                ₹{price?.price && ` ${price?.price}`}
                              </span>
                              <br />
                            </Fragment>
                          ))}
                        </>
                      )}
                    </>
                  }
                />
                <TableInfoRows
                  heading={t('sellerDashboard.quality')}
                  info={product?.product_quality_name}
                />

                <TableInfoRows
                  heading={t('sellerDashboard.action')}
                  info={
                    <button className="mobileGreenBtn" onClick={handlePush}>
                      {btnName == 'edit'
                        ? `${t('productListPage.edit')}`
                        : `${t('productListPage.view')}`}
                    </button>
                  }
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default ViewProductsTable;

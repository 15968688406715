import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import Transaction from 'Components/MyOrders/Transaction';
import useDidMountEffect from 'hooks/useDidMountEffect';
import SellerLayout from 'layouts/SellerLayout';
import { t } from 'i18next';
import { useWindowSize } from '@uidotdev/usehooks';
import SalesTrans from 'Components/MobileTables/SalesTrans/SalesTrans';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const TransactionOrders = () => {
  const size = useWindowSize();
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [salesOrders, setSalesOrders] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    seller_id: user?.seller_id,
    page: 1,
    per_page: 10,
    search: '',
  });

  useEffect(() => {
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/sellertransactions`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  }, []);

  const handleSearch = () => {
    setLoading(true);
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      page: 1,
      per_page: filters?.per_page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/sellertransactions`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  };

  // limiting search calls and search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filters?.search]);

  // handleLoad more
  const handleLoadMore = () => {
    setFilters({ ...filters, page: filters?.page + 1 });
  };

  useDidMountEffect(() => {
    setLoading(true);
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/sellertransactions`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        const oldArray = salesOrders;
        const moreItems = res?.data?.result;
        const newArray = [...oldArray, ...moreItems];
        setSalesOrders(newArray);
        setFullData(res?.data);
        setLoading(false);
      });
  }, [filters?.page]);

  return (
    <SellerLayout>
      <section className="salesOrderPage">
        <Card sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h6" component="div" className="p-3">
            {t('sellerDashboard.salesTransList')}
          </Typography>
        </Card>
        <div className="my-6">
          {size.width <= 769 ? (
            <SalesTrans
              salesOrders={salesOrders}
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              fullData={fullData}
              handleLoadMore={handleLoadMore}
            />
          ) : (
            <Transaction
              salesOrders={salesOrders}
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              fullData={fullData}
              handleLoadMore={handleLoadMore}
            />
          )}
        </div>
      </section>
    </SellerLayout>
  );
};

export default TransactionOrders;

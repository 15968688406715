import './productCard.scss';
import { Link } from 'react-router-dom';

const ProductCard = ({ img, title, desc, product_id }) => {
  return (
    <div className="productCardOuter">
      <Link to={`/product/${product_id}`}>
        <div className="productImg">
          <img src={img} alt="product image" />
          <div className="infoContainer">
            <div className="producTitle">{title}</div>
            {/* <div className="productButton">
            <button className="normalbtn">View More</button>
          </div> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;

import ProductCard from '../../Components/ProductCard/ProductCard';
import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CardSkeleton from '../../Components/ProductCard/CardSkeleton';
import BuyerLayout from 'layouts/BuyerLayout';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const ProductsPage = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const productsD = qs.stringify({
      // language: i18n.language,
    });

    axios
      .post(`${API_URL}/categorys`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProducts(res?.data?.result);
        setLoading(false);
      });
  }, []);

  return (
    <BuyerLayout>
      <div className="wrapper">
        <div className="innerWrapper">
          <Stack spacing={2} mt={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                {t('breadCrumbs.home')}
              </Link>
              <Typography color="text.primary">
                {t('breadCrumbs.productPage')}
              </Typography>
            </Breadcrumbs>
          </Stack>
          <div className="productPage">
            <div className="productItems">
              <div className="productSection">
                <div className="productContainer">
                  <div className="grid grid-cols-1  place-items-center md:grid-cols-4 gap-4">
                    {loading == true ? (
                      <>
                        <CardSkeleton />
                        <CardSkeleton />
                        <CardSkeleton />
                        <CardSkeleton />
                      </>
                    ) : (
                      <>
                        {products?.map((product) => (
                          <ProductCard
                            key={product?.category_id}
                            img={product?.cat_image_thumb}
                            title={product?.category_name}
                            desc={product?.cat_desc}
                            product_id={product?.category_id}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BuyerLayout>
  );
};

export default ProductsPage;

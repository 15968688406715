import { Link } from 'react-router-dom';
import { Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';

import AddressCards from './AddressCards';
import DeliveryAddress from '../Login/DeliveryAddress';
import BuyerLayout from 'layouts/BuyerLayout';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const BuyerAddress = () => {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);
  const [addressDetails, setAddressDetails] = useState([]);
  const [editName, setEditName] = useState('');
  const [addressOpen, setAddressOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [address, setAdress] = useState({
    buyer_id: user?.buyer_id,
    buyer_name: `${user?.first_name} ${user?.last_name}`,
    address_label: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_mobile: user?.buyer_mobile,
    buyer_email: user?.buyer_email,
    buyer_country: '101',
    buyer_state: '',
    buyer_city: '',
    buyer_zip: '',
    shiping_info_id: '',
  });

  const handleAddressClose = () => {
    setAddressOpen(false);
    setAdress({
      buyer_id: user?.buyer_id,
      buyer_name: `${user?.first_name} ${user?.last_name}`,
      address_label: '',
      buyer_short_address: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_mobile: user?.buyer_mobile,
      buyer_email: user?.buyer_email,
      buyer_country: '101',
      buyer_state: '',
      buyer_city: '',
      buyer_zip: '',
      shiping_info_id: '',
    });
  };

  // buyer addrress dialouge
  const handleAddressOpen = () => {
    setEditName('Add');
    setAddressOpen(true);
  };

  useEffect(() => {
    const cartD = qs.stringify({
      buyer_id: user?.buyer_id,
    });

    axios
      .post(`${API_URL}/buyeraddress`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setAddressDetails(res?.data?.result);
        setLoading(false);
      });
  }, []);

  return (
    <BuyerLayout>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <div className="checkoutWrapper">
          <div className="checkoutInnerWrapper">
            <Stack spacing={2} mt={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                  {t('breadCrumbs.home')}
                </Link>
                <Typography color="text.primary">
                  {t('buyerOrderPages.deliveryAddress')}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <section className="buyerAddress">
              <div className="flex items-center justify-between mt-3">
                <h2 className="checkoutTitle">
                  {t('buyerOrderPages.myAddress')}
                </h2>
                <Button
                  variant="contained"
                  size="large"
                  color="success"
                  onClick={handleAddressOpen}
                >
                  {t('buyerOrderPages.addAddress')}
                </Button>
              </div>
              {addressDetails?.length == 0 ? (
                <>
                  <h2 className="noAddress">
                    {t('buyerOrderPages.noAddress')} !
                  </h2>
                  <h3 className="noAddressFound">
                    {t('buyerOrderPages.noAddressFound')}
                  </h3>
                </>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-10">
                  {addressDetails?.map((address) => (
                    <AddressCards
                      key={address?.shiping_info_id}
                      addressDetails={address}
                      setAddressDetails={setAddressDetails}
                    />
                  ))}
                </div>
              )}
            </section>
          </div>
          {addressOpen && (
            <DeliveryAddress
              handleAddressClose={handleAddressClose}
              setAddressDetails={setAddressDetails}
              adressOpen={addressOpen}
              address={address}
              setAdress={setAdress}
              editName={editName}
            />
          )}
        </div>
      </LoadingOverlay>
    </BuyerLayout>
  );
};

export default BuyerAddress;

import './App.scss';
import './_modal.scss';
import './assets/css/argon-dashboard-pro-react.css';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './context/AuthProvider';
import { CartProvider } from './context/CartProvider';
import { OrderProvider } from './context/OrderProvider';
import PublicRoutes from 'routes/PublicRoutes';
import HomePage from 'Pages/Buyer/HomePage';
import ProductsPage from 'Pages/Buyer/ProductsPage';
import CategoriesPage from 'Pages/Buyer/CategoriesPage';
import ProductListPage from 'Pages/Buyer/ProductListPage';
import ProductDetailPage from 'Pages/Buyer/ProductDetailPage';
import GlobalSearch from 'Components/ProductList/GlobalSearch';
import BuyerRoutes from 'routes/BuyerRoutes';
import SuccessPage from 'Pages/Buyer/SuccessPage';
import Failure from 'Components/Success/Failure';
import Checkout from 'Components/Checkout/Checkout';
import BuyerAccount from 'Components/BuyerAccount/BuyerAccount';
import BuyerAddress from 'Components/BuyerAddress/BuyerAddress';
import MyOrders from 'Components/BuyerAccount/MyOrders';
import MyTransOrders from 'Components/BuyerAccount/MyTransOrders';
import BuyerOrderDetails from 'Components/Success/BuyerOrderDetails';

// sellers
import SellerRoutes from 'routes/SellerRoutes';
import ConfigProduct from 'Pages/Seller/ConfigProduct';
import SellerOrderDetails from 'Components/Seller/SellerOrderDetails';
import TransactionOrders from 'Components/ManageOrders/TransactionOrders';
import EditProduct from 'Pages/Seller/EditProduct';
import ProfileSettings from 'Pages/Seller/ProfileSettings';
import SalesOrders from 'Components/ManageOrders/SalesOrders';
import CancelledOrders from 'Components/ManageOrders/CancelledOrders';
import ReturnOrders from 'Components/ManageOrders/ReturnOrders';
import SellerDashboard from 'Components/Seller/SellerDashboard/SellerDashboard';
import ViewProductPage from 'Components/Seller/ViewProduct/ViewProductPage';
import Contact from 'Pages/Buyer/Contact';
import About from 'Pages/Buyer/About';
import PrivacyPolicyPage from 'Pages/Buyer/PrivacyPolicyPage';
import Terms from 'Pages/Buyer/Terms';
import ProductListWithNamePage from 'Pages/Buyer/ProductListWithNamePage';
import { AxiosInterceptor } from 'hooks/axios';
import { ContactProvider } from 'context/ContactProvider';
import ResetBuyerPass from 'Components/Login/ResetBuyerPass';
import ResetSellerPass from 'Components/Login/ResetSellerPass';
import ReturnedOrderDetails from 'Components/Returned/ReturnedOrderDetails';
import CancelOrderDetails from 'Components/Cancel/CancelOrderDetails';
import ProductWithVariety from 'Components/ProductList/ProductWithVariety';
import ProductWithQuality from 'Components/ProductList/ProductWithQuality';
import ProductWithType from 'Components/ProductList/ProductWithType';
import ProductWithAvail from 'Components/ProductList/ProductWithAvail';
import ZeroProductPage from 'Components/Seller/ZeroProduct/ZeroProductPage';
import ZeroProduct from 'Pages/Seller/ZeroProduct';

// for translations
import './i18n';
import { Route, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';

const App = () => {
  return (
    <AuthProvider>
      <AxiosInterceptor>
        <ContactProvider>
          <CartProvider>
            <OrderProvider>
              <Router>
                <ScrollToTop />
                <ToastContainer />
                <Switch>
                  <PublicRoutes exact path="/" comp={HomePage} />
                  <PublicRoutes path="/products" comp={ProductsPage} />
                  <PublicRoutes path="/categories" comp={CategoriesPage} />
                  <PublicRoutes path="/aboutUs" comp={About} />
                  <PublicRoutes path="/contactUs" comp={Contact} />
                  <PublicRoutes path="/terms" comp={Terms} />
                  <PublicRoutes
                    path="/privacyPolicy"
                    comp={PrivacyPolicyPage}
                  />
                  <PublicRoutes
                    path="/product/:productId"
                    comp={ProductListPage}
                  />
                  <PublicRoutes
                    path="/globalsearch/searchkey=/:searchKey"
                    comp={GlobalSearch}
                  />
                  <PublicRoutes
                    path="/productDetail/:productId"
                    comp={ProductDetailPage}
                  />
                  {/* product_name */}
                  <PublicRoutes
                    path="/featureProduct/:id/:product_settings_id"
                    comp={ProductListWithNamePage}
                  />
                  {/* variety */}
                  <PublicRoutes
                    path="/variety/:id/:product_settings_id/:product_variety"
                    comp={ProductWithVariety}
                  />
                  {/* quality */}
                  <PublicRoutes
                    path="/quality/:id/:product_settings_id/:product_quality"
                    comp={ProductWithQuality}
                  />
                  {/* type */}
                  <PublicRoutes
                    path="/type/:id/:product_settings_id/:product_type"
                    comp={ProductWithType}
                  />
                  {/* avail */}
                  <PublicRoutes
                    path="/avail/:id/:product_settings_id/:product_available"
                    comp={ProductWithAvail}
                  />
                  <PublicRoutes
                    path="/buyerUpdatePassword/:keyID"
                    comp={ResetBuyerPass}
                  />
                  <PublicRoutes
                    path="/sellerUpdatePassword/:keyID"
                    comp={ResetSellerPass}
                  />

                  {/* buyer protected routes */}
                  <BuyerRoutes path="/success" comp={SuccessPage} />
                  <BuyerRoutes path="/failure" comp={Failure} />
                  <BuyerRoutes path="/checkout" comp={Checkout} />
                  <BuyerRoutes path="/account" comp={BuyerAccount} />
                  <BuyerRoutes path="/addresses" comp={BuyerAddress} />
                  <BuyerRoutes path="/myOrders" comp={MyOrders} />
                  <BuyerRoutes path="/transactionOrders" comp={MyTransOrders} />
                  <BuyerRoutes
                    path="/orderDetails/:order_id"
                    comp={BuyerOrderDetails}
                  />

                  {/* seller protected routes */}
                  <SellerRoutes path="/sellerHome" comp={SellerDashboard} />
                  <SellerRoutes path="/config product" comp={ConfigProduct} />
                  <SellerRoutes path="/view products" comp={ViewProductPage} />
                  <SellerRoutes
                    path="/zero quantity products"
                    comp={ZeroProductPage}
                  />
                  <SellerRoutes
                    path="/editProduct/:productId"
                    comp={EditProduct}
                  />
                  <SellerRoutes
                    path="/zeroProduct/:productId"
                    comp={ZeroProduct}
                  />
                  <SellerRoutes path="/settings" comp={ProfileSettings} />
                  <SellerRoutes path="/sales orders" comp={SalesOrders} />
                  <SellerRoutes
                    path="/cancelled orders"
                    comp={CancelledOrders}
                  />
                  <SellerRoutes path="/return orders" comp={ReturnOrders} />
                  <SellerRoutes
                    path="/sales transaction"
                    comp={TransactionOrders}
                  />
                  <SellerRoutes
                    path="/sellerOrderDetails/:order_id"
                    comp={SellerOrderDetails}
                  />
                  {/* cancel details */}
                  <SellerRoutes
                    path="/cancelOrderDetails/:order_id"
                    comp={CancelOrderDetails}
                  />
                  {/* returnedDetails */}
                  <SellerRoutes
                    path="/returnedOrderDetails/:order_id"
                    comp={ReturnedOrderDetails}
                  />

                  <Route path="*">
                    <CatchAllRedirect />
                  </Route>
                </Switch>
              </Router>
            </OrderProvider>
          </CartProvider>
        </ContactProvider>
      </AxiosInterceptor>
    </AuthProvider>
  );
};

const CatchAllRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    // Redirect to "/" and replace the current URL in the browser
    history.replace('/');
  }, [history]);

  return null;
};

export default App;

import AboutUs from 'Components/AboutUs/AboutUs';
import BuyerLayout from 'layouts/BuyerLayout';

const About = () => {
  return (
    <BuyerLayout>
      <AboutUs />
    </BuyerLayout>
  );
};

export default About;

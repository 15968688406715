import SellerLayout from 'layouts/SellerLayout';
import ConfigProductPage from '../../Components/Seller/AddProduct/ConfigProductPage';

const ConfigProduct = () => {
  return (
    <SellerLayout>
      <ConfigProductPage />
    </SellerLayout>
  );
};

export default ConfigProduct;

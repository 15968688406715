import BuyerLayout from 'layouts/BuyerLayout';
import ProductListTable from './ProductListTable';
import './productlist.scss';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { useWindowSize } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ProductWithType = () => {
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const { id, product_settings_id, product_type } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const size = useWindowSize();
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState({
    category_id: id,
    product_name: product_settings_id,
    product_variety: '0',
    product_available: '0',
    product_type: product_type,
    product_quality: '0',
  });

  const [filterOptions, setFilterOptions] = useState([]);

  const handleCategoryChange = (e) => {
    history.push(`/product/${e.target.value}`);
    setSelectedFilter({
      ...selectedFilter,
      category_id: e.target.value,
    });
  };

  const handleProductSelect = (e) => {
    history.push(`/featureProduct/${id}/${e.target.value}`);
    setSelectedFilter({
      ...selectedFilter,
      product_name: e.target.value,
    });
  };

  const handleVarietySelect = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_variety: e.target.value,
    });
  };
  const handleSelectedQuality = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_quality: e.target.value,
    });
  };
  const handleSelectedType = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_type: e.target.value,
    });
  };
  const handleAvail = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_available: e.target.value,
    });
  };

  const [expand, setExpand] = useState(true);

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    if (size.width <= 769) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  }, [size.width]);

  useEffect(() => {
    if (product_type) {
      setLoading(true);
      const buyerD = qs.stringify({
        category_id: selectedFilter?.category_id,
        product_settings_id: selectedFilter?.product_name,
        product_variety: selectedFilter?.product_variety,
        product_quality: selectedFilter?.product_quality,
        product_type: selectedFilter?.product_type,
        product_available: selectedFilter?.product_available,
      });

      axios
        .post(`${API_URL}/buyerproductfilter`, buyerD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setFilterOptions(res?.data);
        });

      const productsRes = qs.stringify({
        category_id: selectedFilter?.category_id,
        product_available: selectedFilter?.product_available,
        product_name:
          selectedFilter?.product_name == '0'
            ? ''
            : selectedFilter?.product_name,
        product_type:
          selectedFilter?.product_type == '0'
            ? ''
            : selectedFilter?.product_type,
        product_quality:
          selectedFilter?.product_quality == '0'
            ? ''
            : selectedFilter?.product_quality,
        product_variety:
          selectedFilter?.product_variety == '0'
            ? ''
            : selectedFilter?.product_variety,
      });

      axios
        .post(`${API_URL}/productsbycategory-form`, productsRes, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setProducts(res?.data?.result);
          setCategoryName(res?.data?.category_name);
          setLoading(false);
        });
    }
  }, [product_type]);

  useDidMountEffect(() => {
    setLoading(true);
    const buyerD = qs.stringify({
      category_id: selectedFilter?.category_id,
      product_settings_id: selectedFilter?.product_name,
      product_variety: selectedFilter?.product_variety,
      product_quality: selectedFilter?.product_quality,
      product_type: selectedFilter?.product_type,
      product_available: selectedFilter?.product_available,
    });

    axios
      .post(`${API_URL}/buyerproductfilter`, buyerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setFilterOptions(res?.data);
      });

    const productsD = qs.stringify({
      category_id: selectedFilter?.category_id,
      product_available: selectedFilter?.product_available,
      product_name:
        selectedFilter?.product_name == '0' ? '' : selectedFilter?.product_name,
      product_type:
        selectedFilter?.product_type == '0' ? '' : selectedFilter?.product_type,
      product_quality:
        selectedFilter?.product_quality == '0'
          ? ''
          : selectedFilter?.product_quality,
      product_variety:
        selectedFilter?.product_variety == '0'
          ? ''
          : selectedFilter?.product_variety,
    });

    axios
      .post(`${API_URL}/productsbycategory-form`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProducts(res?.data?.result);
        setLoading(false);
      });
  }, [
    selectedFilter?.category_id,
    selectedFilter?.product_available,
    selectedFilter?.product_name,
    selectedFilter?.product_type,
    selectedFilter?.product_quality,
    selectedFilter?.product_variety,
  ]);

  return (
    <BuyerLayout>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <div className="wrapper">
          <div className="innerWrapper">
            <Stack spacing={2} mt={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                  {t('breadCrumbs.home')}
                </Link>
                <Link underline="hover" color="inherit" to="/products">
                  {t('breadCrumbs.productPage')}
                </Link>
                <Typography color="text.primary">
                  {t('breadCrumbs.productList')}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <section className="productListSection">
              <div className="productListTitle">
                {categoryName} {t('filterNames.products')}
              </div>
              <div className="productListOuter">
                <div className="filterContainer">
                  <div className="filterBox">
                    <div className="filterTitle">
                      <h4>{t('filterNames.filters')}</h4>
                      <div className="expand" onClick={handleExpand}>
                        {expand === true ? (
                          <i className="ri-add-line"></i>
                        ) : (
                          <i className="ri-subtract-line"></i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`filterList ${
                        expand === true ? 'show' : 'hide'
                      }`}
                    >
                      <hr />
                      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4"> */}
                      {/*  Products */}
                      <div className="qna-container">
                        <label className="question" htmlFor="title">
                          {t('filterNames.products')}
                        </label>
                        <div className="answer">
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              value={selectedFilter?.category_id}
                              onChange={handleCategoryChange}
                              displayEmpty
                            >
                              {filterOptions?.product_category?.map(
                                (option) => (
                                  <MenuItem
                                    value={option?.category_id}
                                    key={option?.category_id}
                                  >
                                    {option?.category_name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* Sub Products */}
                      <div className="qna-container">
                        <label className="question" htmlFor="title">
                          {t('filterNames.subProduct')}
                        </label>
                        <div className="answer">
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              value={selectedFilter?.product_name}
                              onChange={handleProductSelect}
                              displayEmpty
                              disabled={
                                filterOptions?.product_info?.length == 0
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="0">
                                {t('filterNames.all')}
                              </MenuItem>
                              {filterOptions?.product_info?.map((option) => (
                                <MenuItem
                                  value={option?.product_settings_id}
                                  key={option?.product_settings_id}
                                >
                                  {option?.product_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* Variety  */}
                      <div className="qna-container">
                        <label className="question" htmlFor="title">
                          {t('filterNames.variety')}
                        </label>
                        <div className="answer">
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              value={selectedFilter?.product_variety}
                              onChange={handleVarietySelect}
                              displayEmpty
                              disabled={
                                filterOptions?.product_variety?.length == 0
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="0">
                                {t('filterNames.all')}
                              </MenuItem>
                              {filterOptions?.product_variety?.map((option) => (
                                <MenuItem
                                  value={option?.product_variety_id}
                                  key={option?.product_variety_id}
                                >
                                  {option?.product_variety_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* qualtiyOptions  */}
                      <div className="qna-container">
                        <label className="question" htmlFor="title">
                          {t('filterNames.quality')}
                        </label>
                        <div className="answer">
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              value={selectedFilter?.product_quality}
                              onChange={handleSelectedQuality}
                              displayEmpty
                              disabled={
                                filterOptions?.product_quality?.length == 0
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="0">
                                {t('filterNames.all')}
                              </MenuItem>
                              {filterOptions?.product_quality?.map((option) => (
                                <MenuItem
                                  value={option?.product_quality_id}
                                  key={option?.product_quality_id}
                                >
                                  {option?.product_quality_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* availbale  */}
                      <div className="qna-container">
                        <label className="question" htmlFor="title">
                          {t('filterNames.avail')}
                        </label>
                        <div className="answer">
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              value={selectedFilter?.product_available}
                              onChange={handleAvail}
                              displayEmpty
                              disabled={
                                filterOptions?.product_available?.length == 0
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="0">
                                {t('filterNames.all')}
                              </MenuItem>
                              {filterOptions?.product_available?.map(
                                (option) => (
                                  <MenuItem
                                    value={option?.product_available_id}
                                    key={option?.product_available_id}
                                  >
                                    {option?.product_available_name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* productTypeOptions  */}
                      <div className="qna-container">
                        <label className="question" htmlFor="title">
                          {t('filterNames.productType')}
                        </label>
                        <div className="answer">
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              value={selectedFilter?.product_type}
                              onChange={handleSelectedType}
                              displayEmpty
                              disabled={
                                filterOptions?.product_type?.length == 0
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="0">
                                {t('filterNames.all')}
                              </MenuItem>
                              {filterOptions?.product_type?.map((option) => (
                                <MenuItem
                                  value={option?.product_type_id}
                                  key={option?.product_type_id}
                                >
                                  {option?.product_type_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                <div className="productListTable">
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer
                      sx={{ maxHeight: '60vh' }}
                      className="myPolicyOuterTable"
                    >
                      <Table
                        aria-label="customized table"
                        stickyHeader
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            {/* <StyledTableCell>S.No</StyledTableCell> */}
                            <StyledTableCell>
                              {t('productListPage.product')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productListPage.image')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productListPage.type')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productListPage.variety')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productListPage.avail')}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t('productListPage.quality')}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t('productListPage.action')}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {products?.length == 0 ? (
                            <StyledTableRow>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                colSpan={8}
                                style={{
                                  textAlign: 'center',
                                  fontSize: '1.25rem',
                                  padding: '2rem 0',
                                }}
                              >
                                {t('noFilterData')}
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            <>
                              {products?.map((product) => (
                                <ProductListTable
                                  product={product}
                                  key={product?.product_id}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            </section>
          </div>
        </div>
      </LoadingOverlay>
    </BuyerLayout>
  );
};

export default ProductWithType;

import BuyerLayout from 'layouts/BuyerLayout';
import CustomerSatisfaction from '../../Components/HomePage/CustomerSatisfaction/CustomerSatisfaction';
import HeroSection from '../../Components/HomePage/HeroSection/HeroSection';
import FeaturedProducts from '../../Components/HomePage/ProductsSection/FeaturedProducts';
import ProductSection from '../../Components/HomePage/ProductsSection/ProductSection';
import ShowCaseSection from '../../Components/HomePage/ShowCaseSection/ShowCaseSection';
import VideoShowCaseSection from '../../Components/HomePage/VideoShowCaseSection/VideoShowCaseSection';

const HomePage = () => {
  return (
    <BuyerLayout>
      {/* <HeroSection /> */}
      <div className="wrapper">
        <div className="innerWrapper">
          <HeroSection />
          <ProductSection />
          {/* <CategoriesSection /> */}
        </div>
      </div>
      <VideoShowCaseSection />
      <div className="wrapper">
        <div className="innerWrapper">
          <FeaturedProducts />
        </div>
      </div>
      {/* <CustomerSatisfaction /> */}
      <ShowCaseSection />
    </BuyerLayout>
  );
};

export default HomePage;

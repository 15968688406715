import ZeroProductPageInner from 'Components/Seller/AddProduct/ZeroProductPageInner';
import SellerLayout from 'layouts/SellerLayout';

const ZeroProduct = () => {
  return (
    <SellerLayout>
      <ZeroProductPageInner />
    </SellerLayout>
  );
};

export default ZeroProduct;

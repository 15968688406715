import ContactUs from 'Components/ContactUs/ContactUs';
import BuyerLayout from 'layouts/BuyerLayout';

const Contact = () => {
  return (
    <BuyerLayout>
      <ContactUs />
    </BuyerLayout>
  );
};

export default Contact;

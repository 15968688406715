// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productSection {
  width: 100%;
  margin-bottom: 4rem;
}
.productSection__title {
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1rem;
}
.productSection .productContainer {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/HomePage/ProductsSection/productionSection.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EAEA,mBAAA;AAFF;AAGE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAGE;EACE,WAAA;AADJ","sourcesContent":["@import '../../../variables.scss';\r\n\r\n.productSection {\r\n  width: 100%;\r\n  // margin: 2rem 0;\r\n  margin-bottom: 4rem;\r\n  &__title {\r\n    font-size: 29px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    margin-bottom: 1rem;\r\n  }\r\n  .productContainer {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Route } from 'react-router-dom';
import { Redirect, Switch, useLocation } from 'react-router-dom';

const PublicRoutes = ({ comp: Component, ...rest }) => {
  const location = useLocation();
  return (
    <>
      <Switch>
        <Route {...rest} render={(props) => <Component {...props} />} />
        {/* Redirect all unmatched routes to the homepage */}
        {location.pathname !== '/' && <Redirect to="/" />}
      </Switch>
    </>
  );
};

export default PublicRoutes;

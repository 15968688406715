import UserDetailTable from './UserDetailTable';
import './productDetail.scss';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import qs from 'qs';
import axios from 'hooks/axios';
import { useContext, useEffect, useState } from 'react';
import Loading from 'react-fullscreen-loading';
import { useWindowSize } from '@uidotdev/usehooks';
// import { ClipLoader } from 'react-spinners';
// import LoadingOverlay from 'react-loading-overlay';

import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Dialog,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ProductInfoTable from './ProductInfoTable';
import DeleteIcon from '@mui/icons-material/Cancel';

import { toastMessageWarning } from '../../utils/toast';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import Login from '../Login/Login';
import BuyerSignUp from '../Login/BuyerSignUp';
import CartContext from '../../context/CartProvider';
import ConfirmModal from './ConfirmModal';
import DeliveryAddress from 'Components/Login/DeliveryAddress';
import SelectAddressModal from 'Components/Login/SelectAddressModal';
import ForgotPass from 'Components/Login/ForgotPass';
import { useTranslation } from 'react-i18next';
import OtpValidator from 'Components/Login/OtpValidator';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ProductDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [productDetails, setProductDetails] = useState([]);
  const [users, setUsers] = useState([]);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const currentUser = localStorage.getItem('userInfo');
  const buyer = JSON.parse(currentUser);
  const storedAddress = JSON.parse(localStorage.getItem('deliveryAddress'));
  const [selectedSeller, setSelectedSeller] = useState([]);
  const { cartDetails, setCartDetails } = useContext(CartContext);
  // const [updatedUsers, setUpdatedUsers] = useState([]);
  // const [zipCode, setZipCode] = useState('');
  const [passOpen, setPassOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [buyerSignOpen, setBuyerSignOpen] = useState(false);
  const size = useWindowSize();
  const userType = localStorage.getItem('currentUser');
  const [oldAddress, setOldAdress] = useState({});
  const [deliveryAddress, setDeliveryAdress] = useState({
    address_label: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_city: '',
    buyer_country: '',
    buyer_email: '',
    buyer_pan: '',
    buyer_id: '',
    buyer_mobile: '',
    buyer_name: '',
    buyer_short_address: '',
    buyer_state: '',
    buyer_zip: '',
    city_name: '',
    country_name: '',
    created_by: '',
    created_on: '',
    first_name: '',
    last_name: '',
    order_id: '',
    seller_id: '',
    shiping_info_id: '',
    state_name: '',
    status: '',
    updated_by: '',
    updated_on: '',
  });
  const [callbackAddress, setCallbackAddress] = useState({
    address_label: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_city: '',
    buyer_country: '',
    buyer_email: '',
    buyer_pan: '',
    buyer_id: '',
    buyer_mobile: '',
    buyer_name: '',
    buyer_short_address: '',
    buyer_state: '',
    buyer_zip: '',
    city_name: '',
    country_name: '',
    created_by: '',
    created_on: '',
    first_name: '',
    last_name: '',
    order_id: '',
    seller_id: '',
    shiping_info_id: '',
    state_name: '',
    status: '',
    updated_by: '',
    updated_on: '',
  });

  const [addressDetails, setAddressDetails] = useState([]);
  const [openDel, setOpenDel] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);

  // del cancel
  const handleCancelModal = () => {
    localStorage.setItem('deliveryAddress', JSON.stringify(oldAddress));
    setOpenDel(false);
    window.location.reload();
  };

  const [addressListOpen, setAddressListOpen] = useState(false);

  // select address modal
  const handleAddressListOpen = () => {
    setAddressListOpen(true);
  };

  const handleAddressListClose = () => {
    setAddressListOpen(false);
  };

  const [address, setAdress] = useState({
    buyer_id: buyer?.buyer_id,
    buyer_name: `${buyer?.first_name} ${buyer?.last_name}`,
    address_label: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_mobile: buyer?.buyer_mobile,
    buyer_email: buyer?.buyer_email,
    buyer_country: '101',
    buyer_state: '',
    buyer_city: '',
    buyer_zip: '',
    shiping_info_id: '',
  });

  const handleAddressClose = () => {
    setAddressOpen(false);
    setAdress({
      buyer_id: buyer?.buyer_id,
      buyer_name: `${buyer?.first_name} ${buyer?.last_name}`,
      address_label: '',
      buyer_short_address: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_mobile: buyer?.buyer_mobile,
      buyer_email: buyer?.buyer_email,
      buyer_country: '101',
      buyer_state: '',
      buyer_city: '',
      buyer_zip: '',
      shiping_info_id: '',
    });
  };

  // buyer address dialogue
  const handleAddressOpen = () => {
    setAddressOpen(true);
  };

  const [userInfo, setUserInfo] = useState({
    phone: '',
    loginPass: '',
  });
  const [buyerUserInfo, setBuyerUserInfo] = useState({
    first_name: '',
    last_name: '',
    buyer_gender: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_country: '101',
    country_id: '',
    buyer_state: '',
    state_id: '',
    buyer_city: '',
    buyer_zip: '',
    buyer_mobile: '',
    buyer_email: '',
    buyer_pan: '',
    buyer_password: '',
    confirmPassword: '',
    buyer_signup_channel: '1',
  });

  // otp dialogue
  const [otpOpen, setOtpOpen] = useState(false);
  const [otpInfo, setOtpInfo] = useState({
    mobile: '',
    otp: '',
    sms_id: '',
  });

  const handleOtpClose = () => {
    setOtpOpen(false);
  };

  const handleOtpOpen = () => {
    setOtpOpen(true);
    handleLoginClose();
  };

  // login dialogue
  const handleLoginOpen = () => {
    setLoginOpen(true);
    setBuyerSignOpen(false);
    handlePassClose(false);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
    setUserInfo({
      phone: '',
      loginPass: '',
    });
  };

  // pass modal
  const handlePassOpen = () => {
    setPassOpen(true);
    setLoginOpen(false);
    setBuyerSignOpen(false);
  };

  const handlePassClose = () => {
    setPassOpen(false);
  };

  // buyer signUp dialouge
  const handleBuyerSignupOpen = () => {
    setBuyerSignOpen(true);
    setLoginOpen(false);
  };
  const handleBuyerSignupClose = () => {
    setBuyerSignOpen(false);
    setBuyerUserInfo({
      first_name: '',
      last_name: '',
      buyer_gender: '',
      buyer_short_address: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_country: '101',
      country_id: '',
      buyer_state: '',
      state_id: '',
      buyer_city: '',
      buyer_zip: '',
      buyer_mobile: '',
      buyer_email: '',
      buyer_pan: '',
      buyer_password: '',
      confirmPassword: '',
      buyer_signup_channel: '1',
    });
  };

  const { productId } = useParams();

  const [selectedFilter, setSelectedFilter] = useState({
    category_id: '',
    product_name: '',
    product_variety: '',
    product_available: '',
    product_type: '',
    product_quality: '',
  });
  const [filterOptions, setFilterOptions] = useState([]);

  const handleCategoryChange = (e) => {
    history.push(`/product/${e.target.value}`);
    setSelectedFilter({
      ...selectedFilter,
      category_id: e.target.value,
      product_name: '',
      product_variety: '',
      product_available: '',
      product_type: '',
      product_quality: '',
    });
  };
  const handleProductSelect = (e) => {
    history.push(
      `/featureProduct/${selectedFilter?.category_id}/${e.target.value}`
    );
    setSelectedFilter({
      ...selectedFilter,
      product_name: e.target.value,
    });
  };

  const handleVarietySelect = (e) => {
    history.push(
      `/variety/${selectedFilter?.category_id}/${selectedFilter?.product_name}/${e.target.value}`
    );
    setSelectedFilter({
      ...selectedFilter,
      product_variety: e.target.value,
    });
  };
  const handleSelectedQuality = (e) => {
    history.push(
      `/quality/${selectedFilter?.category_id}/${selectedFilter?.product_name}/${e.target.value}`
    );
    setSelectedFilter({
      ...selectedFilter,
      product_quality: e.target.value,
    });
  };
  const handleSelectedType = (e) => {
    history.push(
      `/type/${selectedFilter?.category_id}/${selectedFilter?.product_name}/${e.target.value}`
    );
    setSelectedFilter({
      ...selectedFilter,
      product_type: e.target.value,
    });
  };
  const handleAvail = (e) => {
    history.push(
      `/avail/${selectedFilter?.category_id}/${selectedFilter?.product_name}/${e.target.value}`
    );
    setSelectedFilter({
      ...selectedFilter,
      product_available: e.target.value,
    });
  };

  const [expand, setExpand] = useState(true);

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    const buyerD = qs.stringify({
      category_id: selectedFilter?.category_id,
      product_settings_id: selectedFilter?.product_name,
    });

    axios
      .post(`${API_URL}/buyerproductfilter`, buyerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setFilterOptions(res?.data);
      });

    const cartD = qs.stringify({
      buyer_id: buyer?.buyer_id,
      shiping_info_id: storedAddress?.shiping_info_id,
    });

    if (authToken) {
      axios
        .post(`${API_URL}/viewcart`, cartD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setCartDetails(res?.data);
        });

      const addressD = qs.stringify({
        buyer_id: buyer?.buyer_id,
      });

      axios
        .post(`${API_URL}/buyeraddress`, addressD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setAddressDetails(res?.data?.result);
          if (storedAddress) {
            setDeliveryAdress(storedAddress);
            setOldAdress(storedAddress);

            const productDetailsD = qs.stringify({
              product_id: productId,
              buyer_zip: storedAddress?.buyer_zip,
            });

            axios
              .post(`${API_URL}/product`, productDetailsD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setProductDetails(res?.data?.result?.[0]);
                setUsers(res?.data?.sellers);
                setSelectedFilter({
                  ...selectedFilter,
                  category_id: res?.data?.result?.[0]?.category_id,
                  product_name: res?.data?.result?.[0]?.product_settings_id,
                  product_variety: res?.data?.result?.[0]?.product_variety,
                  product_available: res?.data?.result?.[0]?.product_available,
                  product_type: res?.data?.result?.[0]?.product_type,
                  product_quality: res?.data?.result?.[0]?.product_quality,
                });
                setLoading(false);
              });
          } else {
            // setDeliveryAdress(res?.data?.result?.[0]);
            // setOldAdress(res?.data?.result?.[0]);

            const productDetailsD = qs.stringify({
              product_id: productId,
              // buyer_zip: res?.data?.result?.[0]?.buyer_zip,
            });

            axios
              .post(`${API_URL}/product`, productDetailsD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setProductDetails(res?.data?.result?.[0]);
                setUsers(res?.data?.sellers);
                setSelectedFilter({
                  ...selectedFilter,
                  category_id: res?.data?.result?.[0]?.category_id,
                  product_name: res?.data?.result?.[0]?.product_settings_id,
                  product_variety: res?.data?.result?.[0]?.product_variety,
                  product_available: res?.data?.result?.[0]?.product_available,
                  product_type: res?.data?.result?.[0]?.product_type,
                  product_quality: res?.data?.result?.[0]?.product_quality,
                });
                setLoading(false);
              });
          }
        });
    } else {
      const productDetailsD = qs.stringify({
        product_id: productId,
      });

      axios
        .post(`${API_URL}/product`, productDetailsD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setLoading(false);
        });
    }
  }, []);

  useDidMountEffect(() => {
    setLoading(true);

    const buyerD = qs.stringify({
      category_id: selectedFilter?.category_id,
      product_settings_id: selectedFilter?.product_name,
    });

    axios
      .post(`${API_URL}/buyerproductfilter`, buyerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setFilterOptions(res?.data);
      });

    const productDetailsD = qs.stringify({
      product_id: productId,
      category_id: selectedFilter?.category_id,
      product_name: selectedFilter?.product_name,
      product_available: selectedFilter?.product_available,
      product_type: selectedFilter?.product_type,
      product_quality: selectedFilter?.product_quality,
      product_variety: selectedFilter?.product_variety,
      buyer_zip: deliveryAddress?.buyer_zip,
    });

    axios
      .post(`${API_URL}/product`, productDetailsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProductDetails(res?.data?.result?.[0]);
        setUsers(res?.data?.sellers);

        setLoading(false);
      });
  }, [
    selectedFilter?.category_id,
    selectedFilter?.product_available,
    selectedFilter?.product_name,
    selectedFilter?.product_type,
    selectedFilter?.product_quality,
    selectedFilter?.product_variety,
  ]);

  const handleAddCart = async () => {
    if (deliveryAddress?.buyer_zip == '' && deliveryAddress?.buyer_zip == 0) {
      return toastMessageWarning({
        message: `Please verify the Product availability for your Pincode / Address before proceeding !`,
      });
    } else {
      let warning = true;
      setLoading(true);

      for (let x in selectedSeller) {
        if (
          selectedSeller[x]?.req_qty == 0 ||
          selectedSeller[x]?.req_qty == undefined ||
          selectedSeller[x]?.req_qty == '' ||
          selectedSeller[x]?.req_qty == null
        ) {
          warning = true;
          break;
        } else {
          warning = false;
        }
      }

      // warning found
      if (warning == true) {
        setLoading(false);
        return toastMessageWarning({
          message: `Required quantity Can't be Zero or Empty!`,
        });
      } else {
        // no warning found
        for (let x in selectedSeller) {
          const cartD = qs.stringify({
            buyer_id: buyer?.buyer_id,
            product_qty: selectedSeller[x]?.req_qty,
            product_id: selectedSeller[x]?.product_id,
            location_id: selectedSeller[x]?.location_id,
            seller_id: selectedSeller[x]?.seller_id,
          });
          await axios.post(`${API_URL}/addtocart`, cartD, {
            headers: {
              Authorization: `${authToken}`,
            },
          });
        }
        setLoading(false);
        history.push('/checkout');
      }
    }
  };

  const ConfirmDeliveryChange = async (newAddress) => {
    if (cartDetails?.result?.length > 0) {
      if (storedAddress) {
        setOpenDel(true);
      } else {
        setOpenDel(false);
        handleDeliveryLocation(newAddress);
      }
    } else {
      setOpenDel(false);
      handleDeliveryLocation(newAddress);
    }
  };

  const handleDeliveryLocation = async (newAddress) => {
    // if (callbackAddress?.buyer_zip == '' && callbackAddress?.buyer_zip == 0) {
    //   return toastMessageError({
    //     message: `Please Enter Your Pincode First !`,
    //   });
    // } else {
    setLoading(true);
    // localStorage.setItem('deliveryAddress', JSON.stringify(deliveryAddress));
    setOpenDel(false);

    const buyerD = qs.stringify({
      buyer_id: buyer?.buyer_id,
      product_id: productDetails?.product_id,
      category_id: productDetails?.category_id,
      product_available_id: productDetails?.product_available,
      product_type_id: productDetails?.product_type,
      product_quality_id: productDetails?.product_quality,
      product_variety_id: productDetails?.product_variety,
      buyer_zip: newAddress?.buyer_zip,
    });

    await axios
      .post(`${API_URL}/product`, buyerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status == false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setSelectedSeller([]);
        }
        if (res?.data?.status == true) {
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setSelectedSeller([]);
        }
        // console.log(res?.data);
      });
    // }
  };

  const handleDeliveryLocationConfirm = async () => {
    setLoading(true);
    setOpenDel(false);

    const buyerD = qs.stringify({
      buyer_id: buyer?.buyer_id,
      product_id: productDetails?.product_id,
      category_id: productDetails?.category_id,
      product_available_id: productDetails?.product_available,
      product_type_id: productDetails?.product_type,
      product_quality_id: productDetails?.product_quality,
      product_variety_id: productDetails?.product_variety,
      buyer_zip: callbackAddress?.buyer_zip,
    });

    await axios
      .post(`${API_URL}/product`, buyerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status == false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setSelectedSeller([]);
        }
        if (res?.data?.status == true) {
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setSelectedSeller([]);
        }
        // console.log(res?.data);
      });
    // }
  };

  const handleLocationDelete = async () => {
    localStorage.removeItem('zipCode');
    localStorage.removeItem('deliveryAddress');
    setDeliveryAdress({
      address_label: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_city: '',
      buyer_country: '',
      buyer_email: '',
      buyer_pan: '',
      buyer_id: '',
      buyer_mobile: '',
      buyer_name: '',
      buyer_short_address: '',
      buyer_state: '',
      buyer_zip: '',
      city_name: '',
      country_name: '',
      created_by: '',
      created_on: '',
      first_name: '',
      last_name: '',
      order_id: '',
      seller_id: '',
      shiping_info_id: '',
      state_name: '',
      status: '',
      updated_by: '',
      updated_on: '',
    });
    setCallbackAddress({
      address_label: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_city: '',
      buyer_country: '',
      buyer_email: '',
      buyer_pan: '',
      buyer_id: '',
      buyer_mobile: '',
      buyer_name: '',
      buyer_short_address: '',
      buyer_state: '',
      buyer_zip: '',
      city_name: '',
      country_name: '',
      created_by: '',
      created_on: '',
      first_name: '',
      last_name: '',
      order_id: '',
      seller_id: '',
      shiping_info_id: '',
      state_name: '',
      status: '',
      updated_by: '',
      updated_on: '',
    });
    setLoading(true);
    setOpenDel(false);

    const buyerD = qs.stringify({
      buyer_id: buyer?.buyer_id,
      product_id: productDetails?.product_id,
      category_id: productDetails?.category_id,
      product_available_id: productDetails?.product_available,
      product_type_id: productDetails?.product_type,
      product_quality_id: productDetails?.product_quality,
      product_variety_id: productDetails?.product_variety,
    });

    await axios
      .post(`${API_URL}/product`, buyerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status == false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setSelectedSeller([]);
        }
        if (res?.data?.status == true) {
          setProductDetails(res?.data?.result?.[0]);
          setUsers(res?.data?.sellers);
          setSelectedFilter({
            ...selectedFilter,
            category_id: res?.data?.result?.[0]?.category_id,
            product_name: res?.data?.result?.[0]?.product_settings_id,
            product_variety: res?.data?.result?.[0]?.product_variety,
            product_available: res?.data?.result?.[0]?.product_available,
            product_type: res?.data?.result?.[0]?.product_type,
            product_quality: res?.data?.result?.[0]?.product_quality,
          });
          setSelectedSeller([]);
        }
        // console.log(res?.data);
      });
    // }
  };

  // const handleZipChange = (event) => {
  //   const selectedValue = event.target.value;
  //   const selectedObject = addressDetails.find(
  //     (option) => option.shiping_info_id === selectedValue
  //   );
  //   setDeliveryAdress(selectedObject);
  // };

  useDidMountEffect(() => {
    if (selectedSeller?.length > 0) {
      if (users?.length > 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
  }, [selectedSeller, users]);

  useEffect(() => {
    if (size.width <= 769) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  }, [size.width]);

  return (
    // <LoadingOverlay
    //   active={loading}
    //   styles={{
    //     overlay: (base) => ({
    //       ...base,
    //       background: 'transparent',
    //     }),
    //     wrapper: {
    //       overflow: 'hidden',
    //     },
    //   }}
    //   spinner={<ClipLoader color="#11cdef" size={90} />}
    // >
    <>
      <Loading
        loading={loading}
        // background="#F7991F"
        background="transparent"
        loaderColor="#F7991F"
        style={{ zIndex: '10000' }}
      />
      <div className="wrapper">
        <div className="innerWrapper">
          <Stack spacing={2} mt={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                {t('breadCrumbs.home')}
              </Link>
              <Link underline="hover" color="inherit" to="/products">
                {t('breadCrumbs.productPage')}
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/product/${productDetails?.category_id}`}
              >
                {productDetails?.category_name}
              </Link>
              <Typography color="text.primary">
                {productDetails?.product_name} {t('breadCrumbs.details')}
              </Typography>
            </Breadcrumbs>
          </Stack>
          <section className="productDetailSection">
            <div className="productDetailTitle">
              {productDetails?.product_name} {t('breadCrumbs.details')}
            </div>
            <div className="productDetailOuter">
              <div className="filterContainer">
                <div className="filterBox">
                  <div className="filterTitle">
                    <h4>{t('filterNames.filters')}</h4>
                    <div className="expand" onClick={handleExpand}>
                      {expand === true ? (
                        <i className="ri-add-line"></i>
                      ) : (
                        <i className="ri-subtract-line"></i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`filterList ${
                      expand === true ? 'show' : 'hide'
                    }`}
                  >
                    <hr />
                    {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4"> */}
                    {/*  Products */}
                    <div className="qna-container">
                      <label className="question" htmlFor="title">
                        {t('filterNames.products')}
                      </label>
                      <div className="answer">
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            value={selectedFilter?.category_id}
                            onChange={handleCategoryChange}
                            displayEmpty
                            // disabled
                          >
                            {filterOptions?.product_category?.map((option) => (
                              <MenuItem
                                value={option?.category_id}
                                key={option?.category_id}
                              >
                                {option?.category_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* Sub Products */}
                    <div className="qna-container">
                      <label className="question" htmlFor="title">
                        {t('filterNames.subProduct')}
                      </label>
                      <div className="answer">
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            value={selectedFilter?.product_name}
                            onChange={handleProductSelect}
                            displayEmpty
                            // disabled
                          >
                            <MenuItem value="0">
                              {t('filterNames.all')}
                            </MenuItem>
                            {filterOptions?.product_info?.map((option) => (
                              <MenuItem
                                value={option?.product_settings_id}
                                key={option?.product_settings_id}
                              >
                                {option?.product_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* Variety  */}
                    <div className="qna-container">
                      <label className="question" htmlFor="title">
                        {t('filterNames.variety')}
                      </label>
                      <div className="answer">
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            value={selectedFilter?.product_variety}
                            onChange={handleVarietySelect}
                            displayEmpty
                            disabled={
                              filterOptions?.product_variety?.length == 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem value="0">
                              {t('filterNames.all')}
                            </MenuItem>
                            {filterOptions?.product_variety?.map((option) => (
                              <MenuItem
                                value={option?.product_variety_id}
                                key={option?.product_variety_id}
                              >
                                {option?.product_variety_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* qualtiyOptions  */}
                    <div className="qna-container">
                      <label className="question" htmlFor="title">
                        {t('filterNames.quality')}
                      </label>
                      <div className="answer">
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            value={selectedFilter?.product_quality}
                            onChange={handleSelectedQuality}
                            displayEmpty
                            // disabled={
                            //   filterOptions?.product_quality?.length == 0
                            //     ? true
                            //     : false
                            // }
                          >
                            <MenuItem value="0">
                              {t('filterNames.all')}
                            </MenuItem>
                            {filterOptions?.product_quality?.map((option) => (
                              <MenuItem
                                value={option?.product_quality_id}
                                key={option?.product_quality_id}
                              >
                                {option?.product_quality_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* Available  */}
                    <div className="qna-container">
                      <label className="question" htmlFor="title">
                        {t('filterNames.avail')}
                      </label>
                      <div className="answer">
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            value={selectedFilter?.product_available}
                            onChange={handleAvail}
                            displayEmpty
                            disabled={
                              filterOptions?.product_available?.length == 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem value="0">
                              {t('filterNames.all')}
                            </MenuItem>
                            {filterOptions?.product_available?.map((option) => (
                              <MenuItem
                                value={option?.product_available_id}
                                key={option?.product_available_id}
                              >
                                {option?.product_available_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* productTypeOptions  */}
                    <div className="qna-container">
                      <label className="question" htmlFor="title">
                        {t('filterNames.productType')}
                      </label>
                      <div className="answer">
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            value={selectedFilter?.product_type}
                            onChange={handleSelectedType}
                            displayEmpty
                            disabled={
                              filterOptions?.product_type?.length == 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem value="0">
                              {t('filterNames.all')}
                            </MenuItem>
                            {filterOptions?.product_type?.map((option) => (
                              <MenuItem
                                value={option?.product_type_id}
                                key={option?.product_type_id}
                              >
                                {option?.product_type_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="productInfo">
                <div className="productInfoImg">
                  {loading == true ? (
                    <>
                      <Skeleton variant="rectangular" height="350px" />
                    </>
                  ) : (
                    <img src={productDetails?.image_thumb} alt="product img" />
                  )}
                </div>
                <div className="productCompDetails">
                  <div className="productTitle">
                    {loading == true ? (
                      <>
                        <Skeleton width="100%" height="40px" />
                      </>
                    ) : (
                      <h2>
                        {productDetails?.product_name} /{' '}
                        {productDetails?.product_type_name} /{' '}
                        {productDetails?.product_variety_name} /{' '}
                        {productDetails?.product_available_name} /{' '}
                        {productDetails?.product_quality_name}
                      </h2>
                    )}
                    {/* <h5>Description</h5>
                  <hr /> */}
                  </div>

                  <div className="productDesc">
                    {loading == true ? (
                      <>
                        <Skeleton width="100%" />
                        <Skeleton width="100%" />
                        <Skeleton width="100%" />
                        <Skeleton width="40%" />
                        <br />
                        <Skeleton width="100%" />
                        <Skeleton width="40%" />
                      </>
                    ) : (
                      <>
                        {productDetails?.short_description ? (
                          <>
                            <p>{productDetails?.description}</p>
                            {/* <p>{productDetails?.short_description}</p> */}
                          </>
                        ) : (
                          <p>No Description Found.</p>
                        )}
                      </>
                    )}

                    {productDetails?.energy_qty && (
                      <ProductInfoTable
                        productDetails={productDetails}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {authToken === null || userType !== 'buyer' ? (
              <div className="userDetailTable">
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer
                    sx={{ maxHeight: '60vh' }}
                    className="myPolicyOuterTable"
                  >
                    <Table
                      aria-label="customized table"
                      stickyHeader
                      size="small"
                    >
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            colSpan={8}
                            style={{
                              textAlign: 'center',
                              padding: '2rem 0',
                            }}
                          >
                            <p
                              style={{
                                fontWeight: '500',
                                fontSize: '15px',
                              }}
                            >
                              {t('productDetailPage.please')}{' '}
                              <span
                                className="loginLink"
                                onClick={handleLoginOpen}
                              >
                                {t('productDetailPage.log')}
                              </span>{' '}
                              {t('productDetailPage.explore')}
                            </p>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            ) : (
              <>
                <div className="locationCheckerBox">
                  {authToken && userType === 'buyer' && (
                    <div className="pincodeChecker">
                      <div
                        className="qna-container"
                        style={{ padding: '0', width: '100%' }}
                      >
                        <div className="flex justify-end ">
                          <label
                            htmlFor="locationPin"
                            className="question"
                            style={{ fontSize: '.9rem' }}
                          >
                            {t('productDetailPage.checkLoc')}
                          </label>
                        </div>

                        {addressDetails?.length > 0 ? (
                          <>
                            {deliveryAddress?.buyer_zip ? (
                              <div className="selectedPinInfocontainerOuter">
                                <div
                                  className="selectedPinInfocontainer"
                                  onClick={handleAddressListOpen}
                                >
                                  <i className="ri-map-pin-line"></i>
                                  <span>
                                    {t('productDetailPage.deliverTo')} -{' '}
                                    {buyer?.first_name} {buyer?.last_name},{' '}
                                    {deliveryAddress?.buyer_zip}
                                  </span>
                                </div>
                                <div className="deleteBtn">
                                  <Tooltip
                                    title="Delete Location To See All Seller"
                                    arrow
                                  >
                                    <IconButton onClick={handleLocationDelete}>
                                      <DeleteIcon
                                        style={{ color: '#ff5252' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            ) : (
                              <div className="selectedPinInfocontainerOuter">
                                <div
                                  className="selectedPinInfocontainer"
                                  onClick={handleAddressListOpen}
                                >
                                  <i className="ri-map-pin-line"></i>
                                  <span>{t('productDetailPage.addLoc')}</span>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="selectedPinInfocontainerOuter">
                            <div
                              className="selectedPinInfocontainer"
                              onClick={handleAddressListOpen}
                            >
                              <i className="ri-map-pin-line"></i>
                              <span>{t('productDetailPage.addLoc')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="userDetailTable mt-4">
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer
                      sx={{ maxHeight: '60vh' }}
                      className="myPolicyOuterTable"
                    >
                      <Table
                        aria-label="customized table"
                        stickyHeader
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            {/* <StyledTableCell>S.No</StyledTableCell> */}
                            <StyledTableCell>
                              {t('productDetailPage.name')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productDetailPage.viewProduct')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productDetailPage.availFrom')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productDetailPage.unitPrice')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productDetailPage.location')}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t('productDetailPage.availQty')}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {t('productDetailPage.reqQty')}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                            Selection
                          </StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users?.length == 0 ? (
                            <StyledTableRow>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                colSpan={8}
                                style={{
                                  textAlign: 'center',
                                  fontSize: '1.5rem',
                                  fontWeight: 'bold',
                                  padding: '2rem 0',
                                }}
                              >
                                {t('productDetailPage.noSeller')}
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : (
                            <>
                              {users?.map((user, index) => (
                                <UserDetailTable
                                  user={user}
                                  key={index}
                                  inputNumb={index}
                                  // setUsers={setUpdatedUsers}
                                  // users={updatedUsers}
                                  setUsers={setUsers}
                                  users={users}
                                  selectedSeller={selectedSeller}
                                  setSelectedSeller={setSelectedSeller}
                                  productDetails={productDetails}
                                  setDisable={setDisable}
                                  setCartDetails={setCartDetails}
                                  productId={productId}
                                  setProductDetails={setProductDetails}
                                  setSelectedFilter={setSelectedFilter}
                                  zipCode={deliveryAddress?.buyer_zip}
                                  cartDetails={cartDetails?.result}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </>
            )}

            <div className="addCartBtnContainer">
              <Button
                variant="contained"
                color="warning"
                size="large"
                disabled={disable}
                onClick={handleAddCart}
              >
                {t('productDetailPage.addToCart')}{' '}
                <i className="ri-shopping-cart-fill"></i>
              </Button>
              {/* <Link to="/cart"> */}
              {/* <button className="normalbtn" disabled>
              Add To Cart
              <i className="ri-shopping-cart-fill"></i>
            </button> */}
              {/* </Link> */}
            </div>
          </section>
        </div>
        {loginOpen && (
          <Login
            handleLoginClose={handleLoginClose}
            handleSignUpOpen={handleBuyerSignupOpen}
            handlePassOpen={handlePassOpen}
            loginOpen={loginOpen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            otpInfo={otpInfo}
            setOtpInfo={setOtpInfo}
            handleOtpOpen={handleOtpOpen}
            sName="buyer"
          />
        )}
        {otpOpen && (
          <OtpValidator
            handleOtpClose={handleOtpClose}
            otpOpen={otpOpen}
            sName="buyer"
            setUserInfo={setOtpInfo}
            otpInfo={otpInfo}
            setOtpInfo={setOtpInfo}
            userInfo={otpInfo}
          />
        )}
        {passOpen && (
          <ForgotPass
            handlePassClose={handlePassClose}
            handleLoginOpen={handleLoginOpen}
            passOpen={passOpen}
            sName="Buyer"
          />
        )}
        {buyerSignOpen && (
          <BuyerSignUp
            handleSignupClose={handleBuyerSignupClose}
            setUserInfo={setBuyerUserInfo}
            userInfo={buyerUserInfo}
            handleLoginOpen={handleLoginOpen}
            signUpOpen={buyerSignOpen}
            sName="buyer"
          />
        )}

        {addressOpen && (
          <DeliveryAddress
            handleAddressClose={handleAddressClose}
            setAddressDetails={setAddressDetails}
            adressOpen={addressOpen}
            address={address}
            setAdress={setAdress}
            editName="Add"
          />
        )}
        {addressListOpen && (
          <SelectAddressModal
            handleAddressListClose={handleAddressListClose}
            addressListOpen={addressListOpen}
            setDeliveryAdress={setDeliveryAdress}
            deliveryAddress={deliveryAddress}
            addressDetails={addressDetails}
            setAddressDetails={setAddressDetails}
            ConfirmDeliveryChange={ConfirmDeliveryChange}
            setCallbackAddress={setCallbackAddress}
          />
        )}
      </div>

      <Dialog onClose={handleCancelModal} open={openDel}>
        <ConfirmModal
          onConfirm={handleDeliveryLocationConfirm}
          onClose={handleCancelModal}
        />
      </Dialog>
    </>
  );
};

export default ProductDetail;

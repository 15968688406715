import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'hooks/axios';
import { FormControl, MenuItem, Select } from '@mui/material';
import BuyerLayout from 'layouts/BuyerLayout';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const BuyerAccount = () => {
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [color, setColor] = useState('red');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  const nameValidator = /^([a-zA-Z]{3,50})$/;
  const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegx = /^[6-9]\d{9}$/;
  const pincodeChecker = /^[1-9][0-9]{5}$/;
  const panChecker = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);

  const returnList = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
  ];

  const [userInfo, setUserInfo] = useState({
    first_name: '',
    last_name: '',
    buyer_gender: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_city: '',
    buyer_state: '',
    buyer_country: '101',
    buyer_zip: '',
    buyer_mobile: '',
    buyer_email: '',
    buyer_pan: '',
    buyer_password: '',
    confirmPassword: '',
    buyer_signup_channel: '1',
    buyer_return_product: '',
  });

  function handlePasswordChecker(new_pass) {
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (new_pass?.length < 6) {
      setErrorMessage('Password length should be more than 6.');
      setColor('red');
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage('Password should contains upperCase letters!');
      setColor('red');
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage('Password should contain lowerCase letters!');
      setColor('red');
    } else if (!new_pass.match(numbers)) {
      setErrorMessage('Password should contains numbers also!');
      setColor('red');
    } else {
      setErrorMessage('Password is strong!');
      setColor('green');
    }
  }

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      first_name: user?.first_name,
      last_name: user?.last_name,
      buyer_gender: user?.buyer_gender,
      buyer_short_address: user?.buyer_short_address,
      buyer_address_1: user?.buyer_address_1,
      buyer_address_2: user?.buyer_address_2,
      buyer_city: user?.buyer_city,
      buyer_state: user?.buyer_state,
      buyer_country: user?.buyer_country,
      buyer_zip: user?.buyer_zip,
      buyer_mobile: user?.buyer_mobile,
      buyer_email: user?.buyer_email,
      buyer_pan: user?.buyer_pan,
      buyer_return_product: user?.buyer_return_product
        ? user?.buyer_return_product
        : '',
      // buyer_password: user?.buyer_password,
      // confirmPassword: user?.buyer_password,
    });

    // if (user?.buyer_password) {
    //   handlePasswordChecker(user?.buyer_password);
    // }
    const countryD = qs.stringify({});

    axios
      .post(`${API_URL}/countrieslist`, countryD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setCountryList(res?.data?.result);
      });
  }, []);

  useEffect(() => {
    if (userInfo?.buyer_country) {
      const countryD = qs.stringify({
        country_id: userInfo?.buyer_country,
      });

      axios
        .post(`${API_URL}/stateslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setStateList(res?.data?.result);
        });
    }
  }, [userInfo?.buyer_country]);

  useEffect(() => {
    if (userInfo?.buyer_state) {
      const countryD = qs.stringify({
        state_id: userInfo?.buyer_state,
      });

      axios
        .post(`${API_URL}/citieslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setCityList(res?.data?.result);
        });
    }
  }, [userInfo?.buyer_state]);

  const handleCountryChange = (e) => {
    const countryValues = countryList?.filter((countryList) => {
      if (countryList?.id == e.target.value) {
        return countryList;
      }
    });

    setUserInfo({
      ...userInfo,
      buyer_country: countryValues?.[0]?.id,
    });
  };

  const handleStateChange = (e) => {
    const stateValues = stateList?.filter((stateList) => {
      if (stateList?.id == e.target.value) {
        return stateList;
      }
    });

    setUserInfo({
      ...userInfo,
      buyer_state: stateValues?.[0]?.id,
      state_id: stateValues?.[0]?.id,
    });
  };

  function handlePassword(event) {
    let new_pass = event.target.value;
    setUserInfo({
      ...userInfo,
      buyer_password: new_pass,
    });
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (new_pass.length < 6) {
      setErrorMessage('Password length should be more than 6.');
      setColor('red');
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage('Password should contains upperCase letters!');
      setColor('red');
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage('Password should contain lowerCase letters!');
      setColor('red');
    } else if (!new_pass.match(numbers)) {
      setErrorMessage('Password should contains numbers also!');
      setColor('red');
    } else {
      setErrorMessage('Password is strong!');
      setColor('green');
    }
  }

  const handleBuyerUpdate = (e) => {
    e.preventDefault();
    if (
      userInfo?.first_name !== '' &&
      nameValidator.test(userInfo.first_name) !== false &&
      userInfo?.last_name !== '' &&
      nameValidator.test(userInfo.last_name) !== false &&
      userInfo?.first_name !== null &&
      userInfo?.last_name !== null &&
      userInfo?.buyer_gender !== '' &&
      userInfo?.buyer_short_address !== '' &&
      userInfo?.buyer_address_1 !== '' &&
      // userInfo?.buyer_address_2 !== '' &&
      userInfo?.buyer_city !== '' &&
      userInfo?.buyer_state !== '' &&
      userInfo?.buyer_country !== '' &&
      userInfo?.buyer_zip !== '' &&
      pincodeChecker.test(userInfo?.buyer_zip) !== false &&
      userInfo?.buyer_mobile !== '' &&
      userInfo?.buyer_email !== '' &&
      userInfo?.buyer_pan !== '' &&
      panChecker.test(userInfo.buyer_pan) !== false &&
      // userInfo?.buyer_password !== '' &&
      // userInfo?.confirmPassword !== '' &&
      // errorMessage === 'Password is strong!' &&
      phoneRegx.test(userInfo.buyer_mobile) !== false &&
      emailRegx.test(userInfo.buyer_email) !== false
    ) {
      // if (userInfo?.buyer_password !== userInfo?.confirmPassword) {
      //   toastMessageWarning({
      //     message: `Password doesn't match. Please fill correct password!`,
      //   });
      // } else {
      setError(false);
      setLoading(true);
      const singD = qs.stringify({
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        buyer_gender: userInfo?.buyer_gender,
        buyer_short_address: userInfo?.buyer_short_address,
        buyer_address_1: userInfo?.buyer_address_1,
        buyer_address_2: userInfo?.buyer_address_2,
        buyer_city: userInfo?.buyer_city,
        buyer_state: userInfo?.buyer_state,
        buyer_country: userInfo?.buyer_country,
        buyer_zip: userInfo?.buyer_zip,
        buyer_mobile: userInfo?.buyer_mobile,
        buyer_email: userInfo?.buyer_email,
        buyer_pan: userInfo?.buyer_pan,
        // buyer_password: userInfo?.buyer_password,
        buyer_signup_channel: '1',
        buyer_id: user?.buyer_id,
        buyer_return_product: userInfo?.buyer_return_product,
      });

      axios
        .post(`${API_URL}/updatebuyer-form`, singD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            localStorage.setItem(
              'userInfo',
              JSON.stringify(res?.data?.result?.[0])
            );
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
          }
        });
      // }
    } else {
      setError(true);
      if (userInfo.first_name === '') {
        return toastMessageWarning({ message: 'First Name Cannot be empty' });
      } else if (nameValidator.test(userInfo.first_name) === false) {
        return toastMessageWarning({
          message: 'First Name is not valid',
        });
      } else if (userInfo.last_name === '') {
        return toastMessageWarning({ message: 'Last Name Cannot be empty' });
      } else if (nameValidator.test(userInfo.last_name) === false) {
        return toastMessageWarning({
          message: 'Last Name is not valid',
        });
      } else if (userInfo.buyer_gender === '') {
        return toastMessageWarning({ message: 'Gender Name Cannot be empty' });
      } else if (userInfo.buyer_short_address === '') {
        return toastMessageWarning({
          message: 'Short Address Cannot be empty',
        });
      } else if (userInfo.buyer_address_1 === '') {
        return toastMessageWarning({
          message: 'Address Line 1 Cannot be empty',
        });
      } else if (userInfo.buyer_country === '') {
        return toastMessageWarning({ message: 'Country Name Cannot be empty' });
      } else if (userInfo.buyer_state === '') {
        return toastMessageWarning({ message: 'State Name Cannot be empty' });
      } else if (userInfo.buyer_city === '') {
        return toastMessageWarning({ message: 'City Name Cannot be empty' });
      } else if (userInfo.buyer_zip === '') {
        return toastMessageWarning({ message: 'Pincode Cannot be empty' });
      } else if (pincodeChecker.test(userInfo.buyer_zip) === false) {
        return toastMessageWarning({
          message: `Please Add A Valid 6 Digit Pincode !`,
        });
      } else if (userInfo.buyer_email === '') {
        return toastMessageWarning({
          message: 'Email Address Cannot be empty',
        });
      } else if (emailRegx.test(userInfo.buyer_email) === false) {
        return toastMessageWarning({ message: 'Email address is not valid!' });
      } else if (userInfo.buyer_mobile === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(userInfo.buyer_mobile) === false) {
        return toastMessageWarning({
          message: 'Please fill valid mobile number!',
        });
      } else if (userInfo.buyer_pan === '') {
        return toastMessageWarning({
          message: 'PAN number cannot be empty',
        });
      } else if (panChecker.test(userInfo.buyer_pan) === false) {
        return toastMessageWarning({
          message: 'Please fill valid PAN number!',
        });
        // } else if (userInfo.buyer_password === '') {
        //   return toastMessageWarning({
        //     message: 'Password field cannot be empty',
        //   });
        // } else if (errorMessage !== 'Password is strong!') {
        //   return toastMessageWarning({
        //     message:
        //       'Please enter password with length of 6 characters, containing a number, uppercase and lowercase letters !',
        //   });
        // } else if (userInfo.confirmPassword === '') {
        //   return toastMessageWarning({
        //     message: 'Confirm Password field cannot be empty',
        //   });
      }
    }
  };

  const handleFormChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  return (
    <BuyerLayout>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <div className="checkoutWrapper">
          <div className="checkoutInnerWrapper">
            <Stack spacing={2} mt={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                  {t('breadCrumbs.home')}
                </Link>
                <Typography color="text.primary">
                  {t('breadCrumbs.account')}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <section className="checkoutSection">
              <h2 className="checkoutTitle">{t('loginForms.updateProfile')}</h2>

              <form
                action=""
                autoComplete="off"
                noValidate
                onSubmit={handleBuyerUpdate}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="qna-container">
                    <label htmlFor="first_name" className="question">
                      {t('loginForms.firstName')}*
                    </label>
                    <TextField
                      id="first_name"
                      name="first_name"
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.firstName')}
                      value={userInfo?.first_name}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.first_name === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="last_name" className="question">
                      {t('loginForms.lastName')}*
                    </label>
                    <TextField
                      id="last_name"
                      name="last_name"
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.lastName')}
                      value={userInfo?.last_name}
                      onChange={(event) => handleFormChange(event)}
                      error={error && userInfo?.last_name === '' ? true : false}
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_gender" className="question">
                      {t('loginForms.gender')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_gender}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            buyer_gender: e.target.value,
                          })
                        }
                        displayEmpty
                        error={
                          error && userInfo?.buyer_gender === '' ? true : false
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.gender')}
                          </em>
                        </MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_short_address" className="question">
                      {t('loginForms.shortAdd')}*
                    </label>
                    <TextField
                      id="buyer_short_address"
                      name="buyer_short_address"
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.shortAdd')}
                      value={userInfo?.buyer_short_address}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_short_address === ''
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_address_1" className="question">
                      {t('loginForms.line1')}*
                    </label>
                    <TextField
                      id="buyer_address_1"
                      name="buyer_address_1"
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.line1')}
                      value={userInfo?.buyer_address_1}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_address_1 === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_address_2" className="question">
                      {t('loginForms.line2')}
                    </label>
                    <TextField
                      id="buyer_address_2"
                      name="buyer_address_2"
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.line2')}
                      value={userInfo?.buyer_address_2}
                      onChange={(event) => handleFormChange(event)}
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_country" className="question">
                      {t('loginForms.country')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_country}
                        onChange={handleCountryChange}
                        displayEmpty
                        error={
                          error && userInfo?.buyer_country === '' ? true : false
                        }
                        // helperText={
                        // error &&
                        // userInfo?.country_id === '' &&
                        // 'This field is required'
                        // }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.country')}
                          </em>
                        </MenuItem>
                        {countryList?.map((country) => (
                          <MenuItem key={country?.id} value={country?.id}>
                            {country?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_state" className="question">
                      {t('loginForms.state')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_state}
                        onChange={handleStateChange}
                        displayEmpty
                        error={
                          error && userInfo?.buyer_state === '' ? true : false
                        }
                        // helperText={
                        // error &&
                        // userInfo?.state_id === '' &&
                        // 'This field is required'
                        // }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.state')}
                          </em>
                        </MenuItem>
                        {stateList?.map((state) => (
                          <MenuItem key={state?.id} value={state?.id}>
                            {state?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_city" className="question">
                      {t('loginForms.city')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_city}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            buyer_city: e.target.value,
                          })
                        }
                        displayEmpty
                        error={
                          error && userInfo?.buyer_city === '' ? true : false
                        }
                        // helperText={
                        // error &&
                        // userInfo?.buyer_city === '' &&
                        // 'This field is required'
                        // }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.city')}
                          </em>
                        </MenuItem>
                        {cityList?.map((city) => (
                          <MenuItem key={city?.id} value={city?.id}>
                            {city?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="qna-container">
                    <label htmlFor="buyer_zip" className="question">
                      {t('loginForms.pincode')}
                    </label>
                    <TextField
                      id="buyer_zip"
                      name="buyer_zip"
                      inputProps={{
                        maxLength: 10,
                        type: 'number',
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, e.target.maxLength);
                      }}
                      className="textField w-100"
                      placeholder={t('loginForms.pincode')}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={userInfo?.buyer_zip}
                      onChange={(event) => handleFormChange(event)}
                      error={error && userInfo?.buyer_zip === '' ? true : false}
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_email" className="question">
                      {t('loginForms.email')}
                    </label>
                    <TextField
                      id="buyer_email"
                      name="buyer_email"
                      disabled
                      type="email"
                      className="textField w-100"
                      placeholder={t('loginForms.email')}
                      value={userInfo?.buyer_email}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_email === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_mobile" className="question">
                      {t('loginForms.phone')}
                    </label>
                    <TextField
                      id="buyer_mobile"
                      name="buyer_mobile"
                      type="number"
                      disabled
                      className="textField w-100"
                      placeholder={t('loginForms.phone')}
                      min={1}
                      inputProps={{
                        maxLength: 10,
                        type: 'number',
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, e.target.maxLength);
                      }}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={userInfo?.buyer_mobile}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_mobile === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_pan" className="question">
                      {t('loginForms.panNumber')}
                    </label>
                    <TextField
                      id="buyer_pan"
                      name="buyer_pan"
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.panNumber')}
                      value={userInfo?.buyer_pan}
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          buyer_pan: e.target.value?.toUpperCase(),
                        })
                      }
                      error={error && userInfo?.buyer_pan === '' ? true : false}
                    />
                  </div>
                  {/* <div className="qna-container">
                    <label htmlFor="buyer_password" className="question">
                      {t('loginForms.password')}*
                    </label>
                    <TextField
                      id="buyer_password"
                      name="buyer_password"
                      type={showPassword ? 'text' : 'password'}
                      className="textField w-100"
                      placeholder={t('loginForms.password')}
                      value={userInfo?.buyer_password}
                      onChange={handlePassword}
                      error={
                        error && userInfo?.buyer_password === '' ? true : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div style={{ color: `${color}` }}>
                      {errorMessage !== 'Password is strong!' && errorMessage}{' '}
                    </div>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="confirmPassword" className="question">
                      {t('loginForms.confirmPass')}*
                    </label>
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPass ? 'text' : 'password'}
                      className="textField w-100"
                      placeholder={t('loginForms.confirmPass')}
                      value={userInfo?.confirmPassword}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.confirmPassword === '' ? true : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleToggleConfirmPass}
                              edge="end"
                            >
                              {showConfirmPass ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div> */}

                  <div className="qna-container">
                    <label htmlFor="buyer_return_product" className="question">
                      {t('loginForms.returnPeriod')}
                    </label>

                    <Tooltip
                      title={t('loginForms.returnPeriodInfo')}
                      placement="top"
                    >
                      <i className="ri-information-fill ml-2 cursor-pointer"></i>
                    </Tooltip>

                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_return_product}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            buyer_return_product: e.target.value,
                          })
                        }
                        displayEmpty
                        error={
                          error && userInfo?.buyer_return_product === ''
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('profileFrom.day')}
                          </em>
                        </MenuItem>
                        {returnList?.map((days) => (
                          <MenuItem key={days?.id} value={days?.id}>
                            {days?.id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="flex w-full items-center justify-center mb-8">
                  <button
                    className="loginBtn2"

                    // onClick={handleBuyerUpdate}
                  >
                    {t('loginForms.updateProfile')}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </LoadingOverlay>
    </BuyerLayout>
  );
};

export default BuyerAccount;

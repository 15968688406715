import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ViewImages from 'Components/ProductDetail/ViewImages';
import axios from 'hooks/axios';
import qs from 'qs';
import { useState } from 'react';
import { toastMessageWarning } from 'utils/toast';
import { toastMessageSuccess } from 'utils/toast';
import DispatchModal from 'Components/Seller/DispatchModal';
import useDidMountEffect from 'hooks/useDidMountEffect';
import CancelStatusModal from 'Components/BuyerOrderModal/CancelStatusModal';
import ShippingInfo from 'Components/Cart/ShippingInfo';
import FormData from 'form-data';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ReturnTable = ({
  cartImg,
  cart,
  order_id,
  setOrderDetails,
  setFullPageLoader,
}) => {
  const unit = cart?.unit;
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);

  const handleOrderStatus = async () => {
    setFullPageLoader(true);
    const cartD = qs.stringify({
      product_id: cart?.product_id,
      location_id: cart?.location_id,
      order_id: order_id,
      order_status: cart?.next_order_status_value,
      user_id: user?.seller_id,
      role: 'seller',
    });

    await axios
      .post(`${API_URL}/updateorderstatus`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          let status = [7, 8, 9];
          // const cartD = qs.stringify({
          //   seller_id: user?.seller_id,
          //   order_id: order_id,
          //   order_status: status?.toString(),
          // });
          const cartD = new FormData();

          cartD.append('seller_id', user?.seller_id);
          cartD.append('order_id', order_id);
          cartD.append('order_status', status?.toString());

          axios
            .post(`${API_URL}/salesorderdetails`, cartD, {
              headers: {
                Authorization: `${authToken}`,
              },
            })
            .then((res) => {
              setOrderDetails(res?.data?.result);
              setFullPageLoader(false);
            });
        }
      });
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  // tracking modal

  const [trackModal, setTrackModal] = useState(false);
  const handleTrackOpen = () => {
    setTrackModal(true);
  };
  const handleTrackClose = () => {
    setTrackModal(false);
  };

  // image dialogue
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [dispatchModal, setDispatchModal] = useState(false);
  const [startDate, setStartDate] = useState('');

  const [shippingInfo, setShippingInfo] = useState({
    shipping_name: '',
    shipping_no: '',
    exp_delivery_date: '',
  });
  // dispatch dialogue
  const handleDispatchOpen = () => {
    setDispatchModal(true);
  };

  const handleDispatchClose = () => {
    setDispatchModal(false);
    setStartDate('');
    setShippingInfo({
      shipping_name: '',
      shipping_no: '',
      exp_delivery_date: '',
    });
  };

  //*Close modal
  const handleCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleCancelClose = () => {
    setCancelOpen(false);
    setCancelReason('');
  };

  const handleShippingInfo = async () => {
    if (
      shippingInfo?.shipping_name !== '' &&
      shippingInfo?.shipping_no !== '' &&
      startDate !== undefined &&
      startDate !== null &&
      startDate !== ''
    ) {
      const shipD = qs.stringify({
        order_details_id: cart?.order_details_id,
        order_id: order_id,
        seller_id: cart?.seller_id,
        shipping_name: shippingInfo?.shipping_name,
        shipping_no: shippingInfo?.shipping_no,
        exp_delivery_date: shippingInfo?.exp_delivery_date,
        user_id: user?.seller_id,
        role: 'seller',
      });

      await axios
        .post(`${API_URL}/saveprodtracking`, shipD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              // message: `${res?.data?.message}`,
              message: `Shipping Info Added !`,
            });
            handleModalClose();
            handleDispatchClose();
            handleOrderStatus();
          }
        });
    } else {
      toastMessageWarning({ message: `Please fill all the fields first.` });
    }
  };

  function DateConverter(date) {
    var dd = String(date?.getDate()).padStart(2, '0');
    var mm = String(date?.getMonth() + 1).padStart(2, '0');
    var yyyy = date?.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  useDidMountEffect(() => {
    setShippingInfo({
      ...shippingInfo,
      exp_delivery_date: DateConverter(startDate?.$d),
    });
  }, [startDate]);

  const handleCancel = async () => {
    if (cancelReason !== '') {
      setFullPageLoader(true);
      const cartD = qs.stringify({
        product_id: cart?.product_id,
        location_id: cart?.location_id,
        order_id: order_id,
        order_status: 5,
        reason: cancelReason,
        canceled_by: 'seller',
        user_id: user?.seller_id,
        role: 'seller',
      });

      await axios
        .post(`${API_URL}/updateorderstatus`, cartD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            let status = [7, 8, 9];
            // const cartD = qs.stringify({
            //   seller_id: user?.seller_id,
            //   order_id: order_id,
            //   order_status: status?.toString(),
            // });
            const cartD = new FormData();

            cartD.append('seller_id', user?.seller_id);
            cartD.append('order_id', order_id);
            cartD.append('order_status', status?.toString());

            axios
              .post(`${API_URL}/salesorderdetails`, cartD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setOrderDetails(res?.data?.result);
                setFullPageLoader(false);
              });
          }
        });
    } else {
      if (cancelReason === '') {
        toastMessageWarning({
          message: `Please add a reason why you are cancelling this product !`,
        });
      }
    }
  };

  const handleRefund = async () => {
    setFullPageLoader(true);
    const cartD = qs.stringify({
      product_id: cart?.product_id,
      location_id: cart?.location_id,
      order_id: order_id,
      user_id: user?.seller_id,
      role: 'seller',
    });

    await axios
      .post(`${API_URL}/intiaterefund`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          let status = [7, 8, 9];
          // const cartD = qs.stringify({
          //   seller_id: user?.seller_id,
          //   order_id: order_id,
          //   order_status: status?.toString(),
          // });
          const cartD = new FormData();

          cartD.append('seller_id', user?.seller_id);
          cartD.append('order_id', order_id);
          cartD.append('order_status', status?.toString());
          axios
            .post(`${API_URL}/salesorderdetails`, cartD, {
              headers: {
                Authorization: `${authToken}`,
              },
            })
            .then((res) => {
              setOrderDetails(res?.data?.result);
              setFullPageLoader(false);
            });
        }
      });
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="left">
        {cart?.sub_transaction_id ? cart?.sub_transaction_id : '---'}
      </StyledTableCell>
      <StyledTableCell align="left">{cart?.product_name}</StyledTableCell>
      <StyledTableCell align="left">
        <div className="cartimage">
          <img src={cartImg} alt="cart image" />
        </div>
      </StyledTableCell>
      <StyledTableCell align="left">{cart?.product_type_name}</StyledTableCell>
      <StyledTableCell align="left">
        {cart?.product_quality_name}
      </StyledTableCell>
      <StyledTableCell align="left">₹ {cart?.order_rate}</StyledTableCell>

      <StyledTableCell align="left">
        {cart?.order_qty} (
        {unit == 'Quintals'
          ? 'Q'
          : unit == 'Ltrs'
          ? 'L'
          : unit == 'Bags'
          ? 'Bags'
          : 'Bales'}
        )
      </StyledTableCell>
      <StyledTableCell align="left">
        {cart?.location_name}
        <br />
        {cart?.location_code}
      </StyledTableCell>
      <StyledTableCell align="left">₹ {cart?.order_total}</StyledTableCell>
      <StyledTableCell align="center">
        {cart?.order_status === 5 || cart?.order_status === 7 ? (
          <>
            <Button
              variant="contained"
              size="small"
              color="warning"
              style={{
                width: '180px',
                fontSize: '11px',
              }}
              onClick={handleModalOpen}
            >
              {cart?.order_status_label}
            </Button>
          </>
        ) : (
          <>
            <span
              style={{
                fontWeight: '700',
                fontSize: '12px',
              }}
            >
              {cart?.order_status_label}
            </span>
            {(cart?.order_status === 8 || cart?.order_status === 9) && (
              <Button
                variant="contained"
                size="small"
                color="warning"
                style={{
                  width: '140px',
                  fontSize: '11px',
                }}
                onClick={handleModalOpen}
              >
                Returned Product
              </Button>
            )}
          </>
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        {cart?.order_status === 5 ||
        cart?.order_status === 7 ||
        cart?.order_status === 3 ||
        cart?.order_status === 8 ? (
          <>
            {cart?.order_status === 3 ||
            (cart?.order_status === 8 && cart?.is_refund === 0) ? (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  style={{
                    width: '140px',
                    fontSize: '11px',
                    marginBottom: '.3rem',
                  }}
                  onClick={handleTrackOpen}
                >
                  Track Order
                </Button>
                {cart?.order_status === 8 && cart?.is_refund === 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    style={{
                      width: '140px',
                      fontSize: '11px',
                    }}
                    onClick={handleRefund}
                  >
                    Initiate Refund
                  </Button>
                )}
              </>
            ) : (
              <span
                style={{
                  fontWeight: '700',
                  fontSize: '12px',
                }}
              >
                {cart?.order_status_label}
              </span>
            )}
          </>
        ) : (
          <>
            {cart?.order_status >= 3 ? (
              <span
                style={{
                  fontWeight: '700',
                  fontSize: '12px',
                }}
              >
                {cart?.order_status_label}
              </span>
            ) : (
              <>
                {cart?.order_status === 2 ? (
                  <div className="flex">
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      style={{
                        width: '140px',
                        fontSize: '11px',
                      }}
                      onClick={handleDispatchOpen}
                    >
                      {cart?.next_order_status_label}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={handleCancelOpen}
                      style={{
                        width: '100px',
                        fontSize: '11px',
                        marginLeft: '10px',
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div className="flex">
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={handleOrderStatus}
                      style={{
                        width: '120px',
                        fontSize: '11px',
                      }}
                    >
                      {cart?.next_order_status_label}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={handleCancelOpen}
                      style={{
                        width: '100px',
                        fontSize: '11px',
                        marginLeft: '10px',
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </StyledTableCell>

      {cancelOpen && (
        <CancelStatusModal
          title={`${t('orderModals.cancelProduct')}`}
          handleModalClose={handleCancelClose}
          modalOpen={cancelOpen}
          handleCancel={handleCancel}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
        />
      )}

      {modalOpen && (
        <ViewImages
          cart={cart}
          order_status={cart?.order_status}
          remark={cart?.cancel_reason}
          title={
            cart?.order_status === 7
              ? `${t('orderModals.imagesByBuyer')}`
              : cart?.order_status === 8
              ? `${t('orderModals.returnReason')}`
              : `${t('orderModals.cancelReason')}`
          }
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          imagesArray={cart?.product_gallery}
        />
      )}
      {dispatchModal && (
        <DispatchModal
          modalOpen={dispatchModal}
          handleModalClose={handleDispatchClose}
          shippingInfo={shippingInfo}
          setShippingInfo={setShippingInfo}
          handleShippingInfo={handleShippingInfo}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      )}

      {trackModal && (
        <ShippingInfo
          modalOpen={trackModal}
          handleModalClose={handleTrackClose}
          cart={cart}
          shipping_info={cart?.shipping_info}
        />
      )}
    </StyledTableRow>
  );
};

export default ReturnTable;

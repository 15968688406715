import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import ProductImg from './ProductImg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductVideo from './ProductVideo';
import { t } from 'i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

export default function VideoImages({
  handleModalClose,
  modalOpen,
  imagesArray,
}) {
  var settings = {
    dots: false,
    infinite: imagesArray?.length > 3,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        fullWidth="sm"
        maxWidth="sm"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleModalClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">{t('imageVid')}</div>
            </div>
            <>
              {imagesArray == null || imagesArray?.length == 0 ? (
                <h1> {t('noImageVid')}</h1>
              ) : (
                <>
                  <Slider {...settings}>
                    {imagesArray?.map((images, index) => {
                      if (images?.type == 'image') {
                        return <ProductImg key={index} img={images?.url} />;
                      } else {
                        return <ProductVideo key={index} video={images} />;
                      }
                    })}
                  </Slider>
                </>
              )}
            </>
          </div>
        </div>
      </Dialog>
    </>
  );
}

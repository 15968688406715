import { Button } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';

const AddPriceRange = ({
  input,
  inputNumb,
  inputFields,
  setInputFields,
  quotationLength,
  handleAddFields,
  handleRemoveField,
  handleRemoveField1,
  unit,
}) => {
  const handleFormChange = (inputNumb, event) => {
    let data = [...inputFields];
    data[inputNumb][event.target.name] = event.target.value;
    setInputFields(data);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4  mt-4">
        <div className="qna-container">
          <label htmlFor={`Min`} className="question">
            {/* {t('sellerProductForm.min')} (
            {unit == 'Quintals'
              ? 'Q'
              : unit == 'Ltrs'
              ? 'L'
              : unit == 'Bags'
              ? 'Bags'
              : 'Bales'}
            )* */}
            {t('sellerProductForm.min')}*
          </label>
          <div className="answer">
            <input
              id={`"Min"`}
              name="min"
              type="number"
              min={0}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              className="textField w-100"
              placeholder={`${t('sellerProductForm.min')}`}
              // placeholder={`${t('sellerProductForm.min')} ${
              //   unit == 'Quintals'
              //     ? 'Q'
              //     : unit == 'Ltrs'
              //     ? 'L'
              //     : unit == 'Bags'
              //     ? 'Bags'
              //     : 'Bales'
              // }
              //     `}
              value={input?.min}
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>
        <div className="qna-container">
          <label htmlFor={`"Max"`} className="question">
            {/* {t('sellerProductForm.max')} (
            {unit == 'Quintals'
              ? 'Q'
              : unit == 'Ltrs'
              ? 'L'
              : unit == 'Bags'
              ? 'Bags'
              : 'Bales'}
            )* */}
            {t('sellerProductForm.max')}*
          </label>
          <div className="answer">
            <input
              id={`"Max"`}
              name="max"
              type="number"
              min={1}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              className="textField w-100"
              placeholder={`${t('sellerProductForm.max')}`}
              // placeholder={`${t('sellerProductForm.max')} ${
              //   unit == 'Quintals'
              //     ? 'Q'
              //     : unit == 'Ltrs'
              //     ? 'L'
              //     : unit == 'Bags'
              //     ? 'Bags'
              //     : 'Bales'
              // }
              //     `}
              value={input?.max}
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>

        <div className="qna-container">
          <label htmlFor="Value (INR)" className="question">
            {t('sellerProductForm.value')}*
          </label>
          <div className="answer">
            <input
              id="Value (INR)"
              name="value"
              type="number"
              onWheel={(e) => e.target.blur()}
              className="textField w-100"
              placeholder={t('sellerProductForm.value')}
              value={input?.value}
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>
        <div className="addBtnContainer">
          {inputNumb + 1 == quotationLength && (
            <Button
              variant="contained"
              size="medium"
              color="warning"
              style={{ marginRight: '1rem' }}
              onClick={handleAddFields}
            >
              {t('sellerProductForm.newRange')}
            </Button>
          )}
          {inputNumb == 0 ? (
            <Button
              variant="contained"
              size="medium"
              color="error"
              onClick={() => handleRemoveField1(inputNumb)}
            >
              {t('sellerProductForm.remove')}
            </Button>
          ) : (
            <Button
              variant="contained"
              size="medium"
              color="error"
              onClick={() => handleRemoveField(inputNumb)}
            >
              {t('sellerProductForm.remove')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPriceRange;

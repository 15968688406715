import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';

const SellerRoutes = ({ comp: Component, ...rest }) => {
  const { setAuth } = useContext(AuthContext);

  const currentUser = localStorage.getItem('userInfo');
  const userType = localStorage.getItem('currentUser');
  const initializeState = () => !!JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    if (currentUser) {
      const foundUser = JSON.parse(currentUser);
      setAuth(foundUser);
    }
  }, [currentUser]);

  const [loggedIn, setLoggedIn] = useState(initializeState);

  useEffect(() => {
    if (localStorage.getItem('currentUser') === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {loggedIn == true ? (
              <>
                {userType == 'seller' ? (
                  <Component {...props} />
                ) : (
                  <Redirect to="/" />
                )}
              </>
            ) : (
              <Redirect to="/" />
            )}
          </>
        );
      }}
    />
  );
};

export default SellerRoutes;

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { toastMessageSuccess, toastMessageWarning } from '../../../utils/toast';
import FormData from 'form-data';
import 'dayjs/locale/en-gb';
import { useHistory } from 'react-router-dom';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { TbRuler2 } from 'react-icons/tb';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const ProfilePage = () => {
  const history = useHistory();
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [checkedStatus, setCheckedStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const returnList = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
  ];

  const handleChange1 = (e) => {
    if (e.target.checked == false) {
      setCheckedStatus(false);
    } else {
      setCheckedStatus(true);
    }
  };

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [color, setColor] = useState('red');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showAcc, setShowAcc] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  const handleToggleAcc = () => {
    setShowAcc(!showAcc);
  };
  const nameValidator = /^([a-zA-Z]{3,50})$/;
  const accNameValidator = /^[a-zA-Z ]*$/;
  const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegx = /^[6-9]\d{9}$/;
  const panChecker = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const accNoChecker = /^[0-9]{9,18}$/;
  const codeChecker = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  // const upiChecker = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]$/;
  const upiChecker = /^[a-zA-Z0-9._%+-]{2,256}@[a-zA-Z0-9.-]{2,64}$/;
  const pincodeChecker = /^[1-9][0-9]{5}$/;

  const [userInfo, setUserInfo] = useState({
    seller_name: '',
    seller_last_name: '',
    seller_cnum: '',
    seller_email: '',
    seller_delivered_refund: '',
    password: '',
    confirmPassword: '',
    seller_pan: '',
    seller_id: '',
    bank_account_no: '',
    confirmAccountNo: '',
    seller_country: '101',
    seller_state: '',
    seller_city: '',
    zip_codes: '',

    // bank fields
    bank_name: '',
    bank_location: '',
    bank_ifsc_code: '',
    bank_account_holder_name: '',
    bank_account_type: '',
    // upi fields
    upi_bank: 'Bank',
    upi_id: '',
  });

  const handleAccountType = (event) => {
    setUserInfo({
      ...userInfo,
      upi_bank: event.target.value,
    });
  };

  const [userDetails, setUserDetails] = useState([]);
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    const userD = qs.stringify({
      seller_id: user?.seller_id,
    });

    axios
      .post(`${API_URL}/getsellerinfo`, userD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setUserDetails(res?.data?.result?.[0]);
      });

    axios
      .post(`${API_URL}/getbankslist`, userD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setBankList(res?.data?.result);
      });

    const countryD = qs.stringify({});

    axios
      .post(`${API_URL}/countrieslist`, countryD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setCountryList(res?.data?.result);
      });
  }, []);

  useEffect(() => {
    if (userInfo?.seller_country) {
      const countryD = qs.stringify({
        country_id: userInfo?.seller_country,
      });

      axios
        .post(`${API_URL}/stateslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setStateList(res?.data?.result);
        });
    }
  }, [userInfo?.seller_country]);

  useEffect(() => {
    if (userInfo?.seller_state) {
      const countryD = qs.stringify({
        state_id: userInfo?.seller_state,
      });

      axios
        .post(`${API_URL}/citieslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setCityList(res?.data?.result);
        });
    }
  }, [userInfo?.seller_state]);

  useDidMountEffect(() => {
    setUserInfo({
      ...userInfo,
      seller_name: userDetails?.seller_name ? userDetails?.seller_name : '',
      seller_last_name: userDetails?.seller_last_name
        ? userDetails?.seller_last_name
        : '',
      seller_cnum: userDetails?.seller_cnum ? userDetails?.seller_cnum : '',
      seller_email: userDetails?.seller_email ? userDetails?.seller_email : '',
      seller_country: userDetails?.seller_country
        ? userDetails?.seller_country
        : '101',
      seller_state: userDetails?.seller_state ? userDetails?.seller_state : '',
      seller_city: userDetails?.seller_city ? userDetails?.seller_city : '',
      zip_codes: userDetails?.zip_codes ? userDetails?.zip_codes : '',
      seller_delivered_refund: userDetails?.seller_delivered_refund
        ? userDetails?.seller_delivered_refund
        : '',
      // password: userDetails?.password ? userDetails?.password : '',
      // confirmPassword: userDetails?.password ? userDetails?.password : '',
      seller_pan: userDetails?.seller_pan ? userDetails?.seller_pan : '',
      seller_id: userDetails?.seller_id ? userDetails?.seller_id : '',
      bank_account_no: userDetails?.bank_account_no
        ? userDetails?.bank_account_no
        : '',
      confirmAccountNo: userDetails?.bank_account_no
        ? userDetails?.bank_account_no
        : '',
      bank_name: userDetails?.bank_name ? userDetails?.bank_name : '',
      bank_location: userDetails?.bank_location
        ? userDetails?.bank_location
        : '',
      bank_ifsc_code: userDetails?.bank_ifsc_code
        ? userDetails?.bank_ifsc_code
        : '',
      bank_account_holder_name: userDetails?.bank_account_holder_name
        ? userDetails?.bank_account_holder_name
        : '',
      bank_account_type: userDetails?.bank_account_type
        ? userDetails?.bank_account_type
        : ''
        ? userDetails?.bank_account_type
        : '',
      upi_bank: userDetails?.upi_bank ? userDetails?.upi_bank : 'Bank',
      upi_id: userDetails?.upi_id ? userDetails?.upi_id : '',
    });
    // if (userDetails) {
    //   handlePasswordChecker(userDetails?.password);
    // }
  }, [userDetails]);

  useEffect(() => {
    if (userInfo?.upi_bank) {
      if (userInfo?.upi_bank === 'Bank') {
        if (
          userInfo?.bank_account_no === '' &&
          userInfo?.bank_account_no === null &&
          userInfo?.bank_account_no === undefined &&
          userInfo?.bank_name === '' &&
          userInfo?.bank_name === null &&
          userInfo?.bank_name === undefined &&
          userInfo?.bank_location === '' &&
          userInfo?.bank_location === null &&
          userInfo?.bank_location === undefined &&
          userInfo?.bank_ifsc_code === '' &&
          userInfo?.bank_ifsc_code === null &&
          userInfo?.bank_ifsc_code === undefined &&
          userInfo?.bank_account_holder_name === '' &&
          userInfo?.bank_account_holder_name === null &&
          userInfo?.bank_account_holder_name === undefined &&
          userInfo?.bank_account_type === '' &&
          userInfo?.bank_account_type === null &&
          userInfo?.bank_account_type === undefined
        ) {
          setCheckedStatus(false);
        }
      } else {
        if (
          userInfo?.upi_id === '' &&
          userInfo?.upi_id === null &&
          userInfo?.upi_id === undefined
        ) {
          setCheckedStatus(false);
        }
      }
    }
  }, [userInfo?.upi_bank]);

  const handleKeyDown = (e) => {
    // Allow only numbers between 0 and 9
    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
      e.preventDefault();
    }
  };

  function handlePassword(event) {
    let new_pass = event.target.value;
    setUserInfo({
      ...userInfo,
      password: new_pass,
    });
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (new_pass.length < 6) {
      setErrorMessage('Password length should be more than 6.');
      setColor('red');
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage('Password should contains upperCase letters!');
      setColor('red');
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage('Password should contain lowerCase letters!');
      setColor('red');
    } else if (!new_pass.match(numbers)) {
      setErrorMessage('Password should contains numbers also!');
      setColor('red');
    } else {
      setErrorMessage('Password is strong!');
      setColor('green');
    }
  }
  function handlePasswordChecker(new_pass) {
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (new_pass.length < 6) {
      setErrorMessage('Password length should be more than 6.');
      setColor('red');
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage('Password should contains upperCase letters!');
      setColor('red');
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage('Password should contain lowerCase letters!');
      setColor('red');
    } else if (!new_pass.match(numbers)) {
      setErrorMessage('Password should contains numbers also!');
      setColor('red');
    } else {
      setErrorMessage('Password is strong!');
      setColor('green');
    }
  }

  const callBankApi = () => {
    let sellerD = new FormData();
    sellerD.append('seller_name', userInfo?.seller_name);
    sellerD.append('seller_last_name', userInfo?.seller_last_name);
    sellerD.append('seller_cnum', userInfo?.seller_cnum);
    sellerD.append('seller_email', userInfo?.seller_email);
    sellerD.append('seller_country', userInfo?.seller_country);
    sellerD.append('seller_state', userInfo?.seller_state);
    sellerD.append('seller_city', userInfo?.seller_city);
    sellerD.append('zip_codes', userInfo?.zip_codes);
    sellerD.append(
      'seller_delivered_refund',
      userInfo?.seller_delivered_refund
    );
    // sellerD.append('password', userInfo?.password);
    sellerD.append('seller_pan', userInfo?.seller_pan);
    // account type type
    sellerD.append('upi_bank', userInfo?.upi_bank);
    // bank details

    sellerD.append('bank_account_no', userInfo?.bank_account_no);
    sellerD.append('bank_name', userInfo?.bank_name);
    sellerD.append('bank_location', userInfo?.bank_location);
    sellerD.append('bank_ifsc_code', userInfo?.bank_ifsc_code);
    sellerD.append('seller_id', user?.seller_id);
    sellerD.append(
      'bank_account_holder_name',
      userInfo?.bank_account_holder_name
    );
    sellerD.append('bank_account_type', userInfo?.bank_account_type);

    axios
      .post(`${API_URL}/updateseller-form`, sellerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          history.push(`/sellerHome`);
        }
      });
  };
  const callUpiApi = () => {
    let sellerD = new FormData();
    sellerD.append('seller_name', userInfo?.seller_name);
    sellerD.append('seller_last_name', userInfo?.seller_last_name);
    sellerD.append('seller_cnum', userInfo?.seller_cnum);
    sellerD.append('seller_email', userInfo?.seller_email);
    sellerD.append('seller_country', userInfo?.seller_country);
    sellerD.append('seller_state', userInfo?.seller_state);
    sellerD.append('seller_city', userInfo?.seller_city);
    sellerD.append('zip_codes', userInfo?.zip_codes);
    sellerD.append(
      'seller_delivered_refund',
      userInfo?.seller_delivered_refund
    );
    // sellerD.append('password', userInfo?.password);
    sellerD.append('seller_pan', userInfo?.seller_pan);
    sellerD.append('seller_id', user?.seller_id);
    // account type type
    sellerD.append('upi_bank', userInfo?.upi_bank);
    // upi
    sellerD.append('upi_id', userInfo?.upi_id);

    axios
      .post(`${API_URL}/updateseller-form`, sellerD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          history.push(`/sellerHome`);
        }
      });
  };

  const handleBankValidations = () => {
    if (
      userInfo?.bank_name !== '' &&
      userInfo?.bank_name !== null &&
      userInfo?.bank_name !== undefined &&
      userInfo?.bank_location !== '' &&
      userInfo?.bank_location !== null &&
      userInfo?.bank_location !== undefined &&
      userInfo?.bank_ifsc_code !== '' &&
      userInfo?.bank_ifsc_code !== null &&
      userInfo?.bank_ifsc_code !== undefined &&
      userInfo?.bank_account_holder_name !== '' &&
      userInfo?.bank_account_holder_name !== null &&
      userInfo?.bank_account_holder_name !== undefined &&
      userInfo?.bank_account_type !== '' &&
      userInfo?.bank_account_type !== null &&
      userInfo?.bank_account_type !== undefined &&
      accNameValidator.test(userInfo.bank_account_holder_name) !== false &&
      accNoChecker.test(userInfo.bank_account_no) !== false &&
      codeChecker.test(userInfo.bank_ifsc_code) !== false
    ) {
      if (userInfo?.bank_account_no !== userInfo?.confirmAccountNo) {
        toastMessageWarning({
          message: `Bank Account & Confirm Account Number doesn't match!`,
        });
      } else {
        if (checkedStatus === true) {
          setError(false);
          setLoading(false);
          axios
            .get(`https://ifsc.razorpay.com/${userInfo?.bank_ifsc_code}`)
            .then((res) => {
              if (
                res?.data?.BRANCH?.toLowerCase() ==
                  userInfo?.bank_location?.toLowerCase() &&
                res?.data?.BANK?.toLowerCase() ==
                  userInfo?.bank_name?.toLowerCase()
              ) {
                // call api
                callBankApi();
              } else {
                if (
                  res?.data?.BANK?.toLowerCase() !=
                  userInfo?.bank_name?.toLowerCase()
                ) {
                  return toastMessageWarning({
                    message: `Bank Name & IFSC Code doesn't match, Bank name for given IFSC code is ${res?.data?.BRANCH}`,
                  });
                } else if (
                  res?.data?.BRANCH?.toLowerCase() !=
                  userInfo?.bank_location?.toLowerCase()
                ) {
                  return toastMessageWarning({
                    message: `Branch Name & IFSC Code doesn't match, Branch name for given IFSC code is ${res?.data?.BRANCH}`,
                  });
                }
              }
            });
        } else {
          toastMessageWarning({
            message: 'Please give your consent before updating the values!',
          });
        }
      }
    } else {
      setError(true);
      if (
        userInfo.bank_account_holder_name === '' ||
        userInfo.bank_account_holder_name === null ||
        userInfo.bank_account_holder_name === undefined
      ) {
        return toastMessageWarning({
          message: 'Account Holder Name cannot be empty',
        });
      } else if (
        accNameValidator.test(userInfo.bank_account_holder_name) === false
      ) {
        return toastMessageWarning({
          message: 'Account Holder Name is not valid',
        });
      } else if (
        userInfo.bank_name === '' ||
        userInfo.bank_name === null ||
        userInfo.bank_name === undefined
      ) {
        return toastMessageWarning({
          message: 'Bank Name cannot be empty',
        });
      } else if (
        userInfo.bank_account_no === '' ||
        userInfo.bank_account_no === null ||
        userInfo.bank_account_no === undefined
      ) {
        return toastMessageWarning({
          message: 'Account Number cannot be empty',
        });
      } else if (accNoChecker.test(userInfo.bank_account_no) === false) {
        return toastMessageWarning({
          message: 'Please fill valid Account Number!',
        });
      } else if (
        userInfo.confirmAccountNo === '' ||
        userInfo.confirmAccountNo === null ||
        userInfo.confirmAccountNo === undefined
      ) {
        return toastMessageWarning({
          message: 'confirm Account Number cannot be empty',
        });
      } else if (
        userInfo.bank_location === '' ||
        userInfo.bank_location === null ||
        userInfo.bank_location === undefined
      ) {
        return toastMessageWarning({
          message: 'Branch Name cannot be empty',
        });
      } else if (
        userInfo.bank_ifsc_code === '' ||
        userInfo.bank_ifsc_code === null ||
        userInfo.bank_ifsc_code === undefined
      ) {
        return toastMessageWarning({
          message: 'IFSC Code cannot be empty',
        });
      } else if (codeChecker.test(userInfo.bank_ifsc_code) === false) {
        return toastMessageWarning({
          message: 'Please fill valid IFSC Code!',
        });
      } else if (
        userInfo.bank_account_type === '' ||
        userInfo.bank_account_type === null ||
        userInfo.bank_account_type === undefined
      ) {
        return toastMessageWarning({
          message: 'Account Type cannot be empty',
        });
      }
    }
  };

  const handleUpiValidations = () => {
    if (
      userInfo?.upi_id !== '' &&
      userInfo?.upi_id !== null &&
      userInfo?.upi_id !== undefined &&
      upiChecker.test(userInfo.upi_id) !== false
    ) {
      if (checkedStatus === true) {
        setError(false);
        setLoading(false);
        // call api
        callUpiApi();
      } else {
        toastMessageWarning({
          message: 'Please give your consent before updating the values!',
        });
      }
    } else {
      setError(true);
      if (
        userInfo.upi_id === '' ||
        userInfo.upi_id === null ||
        userInfo.upi_id === undefined
      ) {
        return toastMessageWarning({
          message: 'UPI Id cannot be empty',
        });
      } else if (upiChecker.test(userInfo.upi_id) === false) {
        return toastMessageWarning({
          message: 'Please fill valid UPI ID!',
        });
      }
    }
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    if (
      userInfo?.seller_name !== '' &&
      nameValidator.test(userInfo.seller_name) !== false &&
      userInfo?.seller_last_name !== '' &&
      nameValidator.test(userInfo.seller_last_name) !== false &&
      userInfo?.seller_email !== '' &&
      userInfo?.seller_cnum !== '' &&
      // userInfo?.password !== '' &&
      // userInfo?.confirmPassword !== '' &&
      userInfo?.seller_city !== '' &&
      userInfo?.seller_state !== '' &&
      userInfo?.seller_country !== '' &&
      userInfo?.zip_codes !== '' &&
      pincodeChecker.test(userInfo?.zip_codes) !== false &&
      // errorMessage === 'Password is strong!' &&
      userInfo?.seller_pan !== '' &&
      userInfo?.seller_pan !== null &&
      userInfo?.seller_pan !== undefined &&
      panChecker.test(userInfo.seller_pan) !== false &&
      emailRegx.test(userInfo.seller_email) !== false &&
      phoneRegx.test(userInfo.seller_cnum) !== false &&
      userInfo?.upi_bank !== '' &&
      userInfo?.upi_bank !== null &&
      userInfo?.upi_bank !== undefined
    ) {
      // if (userInfo?.password !== userInfo?.confirmPassword) {
      //   toastMessageWarning({
      //     message: `Password doesn't match. Please fill correct password!`,
      //   });
      // } else {
      // handle bank or upi validations
      if (userInfo?.upi_bank === 'Bank') {
        handleBankValidations();
      } else {
        handleUpiValidations();
      }
      // }
    } else {
      setError(true);

      if (userInfo.seller_name === '') {
        return toastMessageWarning({ message: 'First Name Cannot be empty' });
      } else if (nameValidator.test(userInfo.seller_name) === false) {
        return toastMessageWarning({
          message: 'First Name is not valid',
        });
      } else if (userInfo.seller_last_name === '') {
        return toastMessageWarning({ message: 'Last Name Cannot be empty' });
      } else if (nameValidator.test(userInfo.seller_last_name) === false) {
        return toastMessageWarning({
          message: 'Last Name is not valid',
        });
      } else if (userInfo.seller_email === '') {
        return toastMessageWarning({
          message: 'Email Address Cannot be empty',
        });
      } else if (emailRegx.test(userInfo.seller_email) === false) {
        return toastMessageWarning({ message: 'Email address is not valid!' });
      } else if (userInfo.seller_cnum === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(userInfo.seller_cnum) === false) {
        return toastMessageWarning({
          message: 'Please fill valid mobile number!',
        });
      } else if (userInfo.seller_country === '') {
        return toastMessageWarning({ message: 'Country Name Cannot be empty' });
      } else if (userInfo.seller_state === '') {
        return toastMessageWarning({ message: 'State Name Cannot be empty' });
      } else if (userInfo.seller_city === '') {
        return toastMessageWarning({ message: 'City Name Cannot be empty' });
      } else if (userInfo.zip_codes === '') {
        return toastMessageWarning({ message: 'Pincode Cannot be empty' });
      } else if (pincodeChecker.test(userInfo.zip_codes) === false) {
        return toastMessageWarning({
          message: `Please Add A Valid 6 Digit Pincode !`,
        });
      } else if (
        userInfo.seller_pan === '' ||
        userInfo.seller_pan === null ||
        userInfo.seller_pan === undefined
      ) {
        return toastMessageWarning({
          message: 'PAN number cannot be empty',
        });
      } else if (panChecker.test(userInfo.seller_pan) === false) {
        return toastMessageWarning({
          message: 'Please fill valid PAN number!',
        });
        // } else if (userInfo.password === '') {
        //   return toastMessageWarning({
        //     message: 'Password field cannot be empty',
        //   });
        // } else if (errorMessage !== 'Password is strong!') {
        //   return toastMessageWarning({
        //     message:
        //       'Please enter password with length of 6 characters, containing a number, uppercase and lowercase letters !',
        //   });
        // } else if (userInfo.confirmPassword === '') {
        //   return toastMessageWarning({
        //     message: 'Confirm Password field cannot be empty',
        //   });
      } else if (
        userInfo.upi_bank === '' ||
        userInfo.upi_bank === null ||
        userInfo.upi_bank === undefined
      ) {
        return toastMessageWarning({
          message: 'Please select type of bank account you want to add!',
        });
      }
    }
  };

  return (
    <section className="addProductPage">
      <Card sx={{ width: '100%' }}>
        <Typography gutterBottom variant="h6" component="div" className="p-3">
          {t('sellerDashboard.updateProfile')}
        </Typography>
      </Card>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <Card sx={{ width: '100%' }} className="mt-8 p-6">
          <form
            className="inputsContainer"
            autoComplete="off"
            noValidate
            onSubmit={handleUpdateProfile}
          >
            <div className="addProductFormContainer">
              <Typography
                gutterBottom
                variant="subtitle1"
                className="pt-2"
                style={{ fontWeight: '600' }}
                component="div"
              >
                {t('profileFrom.personalInfo')}
              </Typography>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="qna-container">
                  <label htmlFor="pName" className="question">
                    {t('profileFrom.firstName')}*
                  </label>
                  <TextField
                    id="seller_name"
                    name="seller_name"
                    type="text"
                    className="textField w-100"
                    placeholder={t('profileFrom.firstName')}
                    value={userInfo?.seller_name}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        seller_name: e.target.value,
                      })
                    }
                    error={error && userInfo?.seller_name === '' ? true : false}
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="pName" className="question">
                    {t('profileFrom.lastName')}*
                  </label>
                  <TextField
                    id="seller_last_name"
                    name="seller_last_name"
                    type="text"
                    className="textField w-100"
                    placeholder={t('profileFrom.lastName')}
                    value={userInfo?.seller_last_name}
                    error={
                      error && userInfo?.seller_last_name === '' ? true : false
                    }
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        seller_last_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_cnum" className="question">
                    {t('profileFrom.phone')}*
                  </label>
                  <TextField
                    id="seller_cnum"
                    name="seller_cnum"
                    type="number"
                    className="textField w-100"
                    disabled
                    placeholder={t('profileFrom.phone')}
                    min={1}
                    inputProps={{ maxLength: 10 }}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    value={userInfo?.seller_cnum}
                    error={error && userInfo?.seller_cnum === '' ? true : false}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        seller_cnum: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_email" className="question">
                    {t('profileFrom.email')}*
                  </label>
                  <TextField
                    id="seller_email"
                    name="seller_email"
                    type="email"
                    disabled
                    className="textField w-100"
                    placeholder={t('profileFrom.email')}
                    value={userInfo?.seller_email}
                    error={
                      error && userInfo?.seller_email === '' ? true : false
                    }
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        seller_email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_country" className="question">
                    {t('profileFrom.country')}*
                  </label>
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={userInfo?.seller_country}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          seller_country: e.target.value,
                        })
                      }
                      displayEmpty
                      error={
                        error && userInfo?.seller_country === '' ? true : false
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('profileFrom.country')}
                        </em>
                      </MenuItem>
                      {countryList?.map((country) => (
                        <MenuItem key={country?.id} value={country?.id}>
                          {country?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_state" className="question">
                    {t('profileFrom.state')}*
                  </label>
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={userInfo?.seller_state}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          seller_state: e.target.value,
                        })
                      }
                      displayEmpty
                      error={
                        error && userInfo?.seller_state === '' ? true : false
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('profileFrom.state')}
                        </em>
                      </MenuItem>
                      {stateList?.map((state) => (
                        <MenuItem key={state?.id} value={state?.id}>
                          {state?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_city" className="question">
                    {t('profileFrom.city')}*
                  </label>
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={userInfo?.seller_city}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          seller_city: e.target.value,
                        })
                      }
                      displayEmpty
                      error={
                        error && userInfo?.seller_city === '' ? true : false
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('profileFrom.city')}
                        </em>
                      </MenuItem>
                      {cityList?.map((city) => (
                        <MenuItem key={city?.id} value={city?.id}>
                          {city?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="qna-container">
                  <label htmlFor="zip_codes" className="question">
                    {t('profileFrom.pincode')}*
                  </label>
                  <TextField
                    id="zip_codes"
                    name="zip_codes"
                    min={0}
                    inputProps={{
                      maxLength: 10,
                      type: 'number',
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, e.target.maxLength);
                    }}
                    className="textField w-100"
                    placeholder={t('profileFrom.pincode')}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    value={userInfo?.zip_codes}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        zip_codes: e.target.value,
                      })
                    }
                    error={error && userInfo?.zip_codes === '' ? true : false}
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_pan" className="question">
                    {t('profileFrom.panNumber')}*
                  </label>
                  <TextField
                    id="seller_pan"
                    name="seller_pan"
                    type="text"
                    className="textField w-100"
                    placeholder={t('profileFrom.panNumber')}
                    inputProps={{ maxLength: 10 }}
                    value={userInfo?.seller_pan}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        seller_pan: e.target.value?.toUpperCase(),
                      })
                    }
                    error={error && userInfo?.seller_pan === '' ? true : false}
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="seller_delivered_refund" className="question">
                    {t('profileFrom.timePeriod')}
                  </label>
                  <Tooltip
                    title={t('loginForms.refundPeriodInfo')}
                    placement="top"
                  >
                    <i className="ri-information-fill ml-2 cursor-pointer"></i>
                  </Tooltip>

                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={userInfo?.seller_delivered_refund}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          seller_delivered_refund: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')} {t('profileFrom.day')}
                        </em>
                      </MenuItem>
                      {returnList?.map((days) => (
                        <MenuItem key={days?.id} value={days?.id}>
                          {days?.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* <div className="qna-container">
                  <label htmlFor="password" className="question">
                    {t('profileFrom.password')}*
                  </label>
                  <TextField
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    className="textField w-100"
                    placeholder={t('profileFrom.password')}
                    value={userInfo?.password}
                    error={error && userInfo?.password === '' ? true : false}
                    onChange={handlePassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ color: `${color}` }}>
                    {' '}
                    {errorMessage !== 'Password is strong!' &&
                      errorMessage}{' '}
                  </div>
                </div>
                <div className="qna-container">
                  <label htmlFor="confirmPassword" className="question">
                    {t('profileFrom.confirmPass')}*
                  </label>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPass ? 'text' : 'password'}
                    className="textField w-100"
                    placeholder={t('profileFrom.confirmPass')}
                    value={userInfo?.confirmPassword}
                    onChange={(e) =>
                      setUserInfo({
                        ...userInfo,
                        confirmPassword: e.target.value,
                      })
                    }
                    error={
                      error && userInfo?.confirmPassword === '' ? true : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPass}
                            edge="end"
                          >
                            {showConfirmPass ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div> */}
              </div>
            </div>
            <div className="addProductFormContainer">
              <Typography
                gutterBottom
                variant="subtitle1"
                className="pt-2"
                style={{ fontWeight: '600' }}
                component="div"
              >
                {t('profileFrom.bankInfo')}
              </Typography>

              <div className="accountTitle font-semibold">
                {t('profileFrom.accountType')}*
              </div>
              <div className="qna-container">
                <FormControl>
                  {/* <FormLabel
                    id="demo-radio-buttons-group-label"
                    color="warning"
                  >
                    Account Type*
                  </FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={userInfo?.upi_bank}
                    onChange={handleAccountType}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Bank"
                      control={<Radio color="warning" />}
                      label={t('profileFrom.banking')}
                    />
                    <FormControlLabel
                      value="UPI"
                      control={<Radio color="warning" />}
                      label={t('profileFrom.upi')}
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {userInfo?.upi_bank == 'Bank' ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="qna-container">
                    <label
                      htmlFor="bank_account_holder_name"
                      className="question"
                    >
                      {t('profileFrom.accName')}*
                    </label>
                    <TextField
                      id="bank_account_holder_name"
                      name="bank_account_holder_name"
                      type="text"
                      className="textField w-100"
                      placeholder={t('profileFrom.accName')}
                      value={userInfo?.bank_account_holder_name}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          bank_account_holder_name: e.target.value,
                        })
                      }
                      error={
                        error && userInfo?.bank_account_holder_name === ''
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="bank_name" className="question">
                      {t('profileFrom.bankName')}*
                    </label>
                    <Autocomplete
                      id="bank_name"
                      name="bank_name"
                      sx={{ width: '100%' }}
                      // freeSolo
                      autoComplete
                      autoHighlight
                      autoSelect
                      // options={bankList}
                      options={bankList?.map((option) => option.bank_name)}
                      value={userInfo?.bank_name}
                      onChange={(event, newValue) => {
                        setUserInfo({
                          ...userInfo,
                          bank_name: newValue,
                        });
                      }}
                      error={error && userInfo?.bank_name === '' ? true : false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('profileFrom.bankName')}
                          className="textField w-100"
                          inputProps={{
                            ...params.inputProps,
                            // autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="bank_account_no" className="question">
                      {t('profileFrom.accNo')}*
                    </label>
                    <TextField
                      id="bank_account_no"
                      name="bank_account_no"
                      type={showAcc ? 'number' : 'password'}
                      className="textField w-100"
                      placeholder={t('profileFrom.accNo')}
                      inputProps={{ maxLength: 18 }}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={handleKeyDown}
                      value={userInfo?.bank_account_no}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          bank_account_no: e.target.value,
                        })
                      }
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton onClick={handleToggleAcc} edge="end">
                      //         {showPassword ? (
                      //           <Visibility />
                      //         ) : (
                      //           <VisibilityOff />
                      //         )}
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                      error={
                        error && userInfo?.bank_account_no === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="confirmAccountNo" className="question">
                      {t('profileFrom.confirmNo')}*
                    </label>
                    <TextField
                      id="confirmAccountNo"
                      name="confirmAccountNo"
                      type="number"
                      className="textField w-100"
                      placeholder={t('profileFrom.confirmNo')}
                      inputProps={{ maxLength: 18 }}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={userInfo?.confirmAccountNo}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          confirmAccountNo: e.target.value,
                        })
                      }
                      error={
                        error && userInfo?.confirmAccountNo === ''
                          ? true
                          : false
                      }
                    />
                  </div>

                  <div className="qna-container">
                    <label htmlFor="bank_location" className="question">
                      {t('profileFrom.branchName')}*
                    </label>
                    <TextField
                      id="bank_location"
                      name="bank_location"
                      type="text"
                      className="textField w-100"
                      placeholder={t('profileFrom.branchName')}
                      value={userInfo?.bank_location}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          bank_location: e.target.value,
                        })
                      }
                      error={
                        error && userInfo?.bank_location === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="bank_ifsc_code" className="question">
                      {t('profileFrom.ifsc')}*
                    </label>
                    <TextField
                      id="bank_ifsc_code"
                      name="bank_ifsc_code"
                      type="text"
                      className="textField w-100"
                      placeholder={t('profileFrom.ifsc')}
                      inputProps={{ maxLength: 11 }}
                      value={userInfo?.bank_ifsc_code}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          bank_ifsc_code: e.target.value?.toUpperCase(),
                        })
                      }
                      error={
                        error && userInfo?.bank_ifsc_code === '' ? true : false
                      }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="bank_account_type" className="question">
                      {t('profileFrom.type')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.bank_account_type}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            bank_account_type: e.target.value,
                          })
                        }
                        displayEmpty
                        error={
                          error && userInfo?.bank_account_type === ''
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('sellerProductForm.select')}{' '}
                            {t('profileFrom.type')}
                          </em>
                        </MenuItem>
                        <MenuItem value="Saving">
                          {t('profileFrom.saving')}
                        </MenuItem>
                        <MenuItem value="Current">
                          {t('profileFrom.current')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="qna-container">
                    <label htmlFor="upi_id" className="question">
                      {t('profileFrom.upiId')}*
                    </label>
                    <TextField
                      id="upi_id"
                      name="upi_id"
                      type="text"
                      className="textField w-100"
                      placeholder={t('profileFrom.upiId')}
                      value={userInfo?.upi_id}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          upi_id: e.target.value,
                        })
                      }
                      error={error && userInfo?.upi_id === '' ? true : false}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="agreeLink">
              <FormControlLabel
                label={t('profileFrom.consent')}
                style={{
                  margin: '0',
                }}
                control={
                  <Checkbox
                    checked={checkedStatus == true ? true : false}
                    onChange={handleChange1}
                    sx={{
                      color: '#f7991f',
                      '&.Mui-checked': {
                        color: '#f7991f',
                      },
                    }}
                  />
                }
              />
            </div>

            <div className="btnContainer">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  size="medium"
                  color="warning"
                  onClick={handleUpdateProfile}
                >
                  {t('sellerDashboard.updateProfile')}
                </Button>
                {/* <Button variant="contained" size="medium" color="error">
              Cancel
            </Button> */}
              </Stack>
            </div>
          </form>
        </Card>
      </LoadingOverlay>
    </section>
  );
};

export default ProfilePage;

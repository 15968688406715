import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const VideoContainer = ({ video, removeVideo, index }) => {
  return (
    <div className="image-item">
      {video?.file == 'none' ? (
        <video controls>
          <source src={video?.data_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video controls>
          <source src={URL.createObjectURL(video)} type={video.type} />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="image-item__btn-wrapper">
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => removeVideo(index)}
        >
          <ClearIcon />
        </Button>
      </div>
    </div>
  );
};

export default VideoContainer;

import ProductListTable from './ProductListTable';
import './productlist.scss';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BuyerLayout from 'layouts/BuyerLayout';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const GlobalSearch = () => {
  var currentLocation = window.location.pathname;
  const searchKey = currentLocation.replace(`/globalsearch/searchkey=/`, '');
  const searchValue = searchKey.replace(/%20/g, ' ');

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const searchD = qs.stringify({
      search_key: searchValue,
    });

    axios
      .post(`${API_URL}/globalsearch`, searchD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProducts(res?.data?.result);
      });
  }, [searchValue]);

  return (
    <BuyerLayout>
      <div className="wrapper">
        <div className="innerWrapper">
          <Stack spacing={2} mt={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                {t('breadCrumbs.home')}
              </Link>
              <Typography color="text.primary">
                {t('searchPage.searchedResult')}
              </Typography>
            </Breadcrumbs>
          </Stack>
          <section className="productListSection" style={{ minHeight: '60vh' }}>
            <div className="productListTitle">
              {t('searchPage.avail')}{' '}
              <span className="capitalize">{searchValue}</span>
            </div>
            <div className="productListOuter">
              <div className="productListTable" style={{ width: '100%' }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer
                    sx={{ maxHeight: '60vh' }}
                    className="myPolicyOuterTable"
                  >
                    <Table
                      aria-label="customized table"
                      stickyHeader
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          {/* <StyledTableCell>S.No</StyledTableCell> */}
                          <StyledTableCell>
                            {t('productListPage.product')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productListPage.image')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productListPage.type')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productListPage.variety')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productListPage.avail')}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t('productListPage.quality')}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t('productListPage.action')}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products?.length == 0 ? (
                          <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              colSpan={8}
                              style={{
                                textAlign: 'center',
                                fontSize: '1.25rem',
                                padding: '2rem 0',
                              }}
                            >
                              {t('searchPage.noSearchData')}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <>
                            {products?.map((product) => (
                              <ProductListTable
                                product={product}
                                key={product?.product_id}
                              />
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </section>
        </div>
      </div>
    </BuyerLayout>
  );
};

export default GlobalSearch;

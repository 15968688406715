import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useContext, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'hooks/axios';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
// import { IconButton, InputAdornment } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import AuthContext from 'context/AuthProvider';
import { useHistory } from 'react-router-dom';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SignUp({
  handleSignupClose,
  signUpOpen,
  handleLoginOpen,
  sName,
  sellerUserInfo,
  setSellerUserInfo,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [color, setColor] = useState('red');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuth, setToken } = useContext(AuthContext);

  const headers = {
    'Content-Type': 'application/json',
  };

  const startTimer = () => {
    setIsActive(true);
    setTimer(60);

    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(countdown);
          setIsActive(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const nameValidator = /^([a-zA-Z]{3,50})$/;
  const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegx = /^[6-9]\d{9}$/;
  const panChecker = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const history = useHistory();

  // const handleTerms = () => {
  //   handleSignupClose();
  //   history.push(`/terms`);
  // };
  // const handlePolicy = () => {
  //   handleSignupClose();
  //   history.push(`/privacyPolicy`);
  // };

  function handlePassword(event) {
    let new_pass = event.target.value;
    setSellerUserInfo({
      ...sellerUserInfo,
      password: new_pass,
    });
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (new_pass.length < 6) {
      setErrorMessage('Password length should be more than 6.');
      setColor('red');
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage('Password should contains upperCase letters!');
      setColor('red');
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage('Password should contain lowerCase letters!');
      setColor('red');
    } else if (!new_pass.match(numbers)) {
      setErrorMessage('Password should contains numbers also!');
      setColor('red');
    } else {
      setErrorMessage('Password is strong!');
      setColor('green');
    }
  }

  const handleSellerSignUp = () => {
    setLoading(true);
    const singD = qs.stringify({
      seller_name: sellerUserInfo?.firstName,
      seller_last_name: sellerUserInfo?.lastName,
      seller_email: sellerUserInfo?.email,
      seller_cnum: sellerUserInfo?.phone,
      seller_pan: sellerUserInfo?.seller_pan,
    });

    axios
      .post(`${API_URL}/sellersignup-form`, singD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          handleSignupClose();
          localStorage.setItem('token', JSON.stringify(res?.data?.token));
          localStorage.setItem('currentUser', sName);
          localStorage.setItem(
            'userInfo',
            JSON.stringify(res?.data?.result?.[0])
          );
          setAuth(res?.data?.result?.[0]);
          setToken(res?.data?.token);
          history.push('/sellerHome');
          window.location.reload();
        }
      });
  };

  const handleFormChange = (event) => {
    setSellerUserInfo({
      ...sellerUserInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange1 = (e) => {
    if (e.target.checked == false) {
      setCheckedStatus(false);
    } else {
      setCheckedStatus(true);
    }
  };

  const [OTP, setOTP] = useState('');
  const [otpInfo, setOtpInfo] = useState({
    mobile: '',
    sms_id: '',
    otp: '',
  });

  function handleChange(OTP) {
    setOTP(OTP);
    setOtpInfo({ ...otpInfo, otp: OTP });
  }

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (
      sellerUserInfo?.firstName !== '' &&
      nameValidator.test(sellerUserInfo.firstName) !== false &&
      sellerUserInfo?.lastName !== '' &&
      nameValidator.test(sellerUserInfo.lastName) !== false &&
      sellerUserInfo?.email !== '' &&
      sellerUserInfo?.phone !== '' &&
      sellerUserInfo?.seller_pan !== '' &&
      emailRegx.test(sellerUserInfo.email) !== false &&
      panChecker.test(sellerUserInfo.seller_pan) !== false &&
      phoneRegx.test(sellerUserInfo.phone) !== false
    ) {
      if (checkedStatus === true) {
        setError(false);
        setLoading(true);
        const singD = qs.stringify({
          seller_cnum: sellerUserInfo?.phone,
          seller_email: sellerUserInfo?.email,
        });

        axios
          .post(`${API_URL}/sellersignupotp`, singD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            setLoading(false);
            if (res?.data?.status === false) {
              toastMessageWarning({
                message: `${res?.data?.message}`,
              });
            } else {
              toastMessageSuccess({
                message: `${res?.data?.message}`,
              });
              setOtpInfo({
                ...otpInfo,
                mobile: res?.data?.result?.seller_cnum,
                sms_id: res?.data?.result?.sms_id,
              });
              setDisableFields(true);
            }
          });
      } else {
        toastMessageWarning({
          message: 'Please accept Terms & Conditions before signing up!',
        });
      }
    } else {
      setError(true);

      if (sellerUserInfo.firstName === '') {
        return toastMessageWarning({ message: 'First Name Cannot be empty' });
      } else if (nameValidator.test(sellerUserInfo.firstName) === false) {
        return toastMessageWarning({
          message: 'First Name is not valid',
        });
      } else if (sellerUserInfo.lastName === '') {
        return toastMessageWarning({ message: 'Last Name Cannot be empty' });
      } else if (nameValidator.test(sellerUserInfo.lastName) === false) {
        return toastMessageWarning({
          message: 'Last Name is not valid',
        });
      } else if (sellerUserInfo.email === '') {
        return toastMessageWarning({
          message: 'Email Address Cannot be empty',
        });
      } else if (emailRegx.test(sellerUserInfo.email) === false) {
        return toastMessageWarning({ message: 'Email address is not valid!' });
      } else if (sellerUserInfo.phone === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(sellerUserInfo.phone) === false) {
        return toastMessageWarning({
          message: 'Please fill valid phone number!',
        });
      } else if (sellerUserInfo.seller_pan === '') {
        return toastMessageWarning({
          message: 'PAN number cannot be empty',
        });
      } else if (panChecker.test(sellerUserInfo.seller_pan) === false) {
        return toastMessageWarning({
          message: 'Please fill valid PAN number!',
        });
      }
    }
  };

  const handleResendSignupOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    const loginD = qs.stringify({
      seller_cnum: sellerUserInfo?.phone,
      seller_email: sellerUserInfo?.email,
      sms_id: otpInfo?.sms_id,
    });

    axios
      .post(`${API_URL}/resendsellersignupotp`, loginD, headers)
      .then((res) => {
        setLoading(false);

        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          setOtpInfo({
            ...otpInfo,
            otp: '',
            mobile: res?.data?.result?.seller_cnum,
            sms_id: res?.data?.result?.sms_id,
          });
          setDisableFields(true);
          startTimer();
        }
      });
  };

  const handleSignupOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    const loginD = qs.stringify({
      seller_cnum: otpInfo?.mobile,
      sms_id: otpInfo?.sms_id,
      otp: otpInfo?.otp,
    });

    axios
      .post(`${API_URL}/validatesellersignupotp`, loginD, headers)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          handleSellerSignUp();
        }
      });
  };

  return (
    <>
      <Dialog
        open={signUpOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSignupClose}
        // fullWidth="sm"
        maxWidth="sm"
        style={{
          borderRadius: '20px',
        }}
      >
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        >
          <div className="modal-body p-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleSignupClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <div className="loginContainer">
              <div className="modal-header">
                <div className="loginTitle">
                  {sName == 'seller'
                    ? `${t('loginForms.seller')}`
                    : `${t('loginForms.buyer')}`}{' '}
                  {t('loginForms.signUp')}
                </div>
              </div>

              <hr />
              <form
                className="inputsContainer"
                autoComplete="off"
                noValidate
                onSubmit={handleSendOtp}
              >
                <div className="qna-container">
                  <label htmlFor="firstName" className="question">
                    {t('loginForms.firstName')}*
                  </label>
                  <TextField
                    id="firstName"
                    name="firstName"
                    type="text"
                    inputProps={{
                      maxLength: 50,
                    }}
                    disabled={disableFields}
                    className="textField w-100"
                    placeholder={t('loginForms.firstName')}
                    onChange={(event) => handleFormChange(event)}
                    value={sellerUserInfo?.firstName}
                    error={
                      error && sellerUserInfo?.firstName === '' ? true : false
                    }
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="lastName" className="question">
                    {t('loginForms.lastName')}*
                  </label>
                  <TextField
                    id="lastName"
                    name="lastName"
                    type="text"
                    inputProps={{
                      maxLength: 50,
                    }}
                    disabled={disableFields}
                    className="textField w-100"
                    placeholder={t('loginForms.lastName')}
                    value={sellerUserInfo?.lastName}
                    onChange={(event) => handleFormChange(event)}
                    error={
                      error && sellerUserInfo?.lastName === '' ? true : false
                    }
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="email" className="question">
                    {t('loginForms.email')}*
                  </label>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    inputProps={{
                      maxLength: 250,
                    }}
                    disabled={disableFields}
                    className="textField w-100"
                    placeholder={t('loginForms.email')}
                    value={sellerUserInfo?.email}
                    onChange={(event) => handleFormChange(event)}
                    error={error && sellerUserInfo?.email === '' ? true : false}
                  />
                </div>
                <div className="qna-container">
                  <label htmlFor="phone" className="question">
                    {t('loginForms.phone')}*
                  </label>
                  <TextField
                    id="phone"
                    name="phone"
                    min={0}
                    inputProps={{
                      maxLength: 10,
                      type: 'number',
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, e.target.maxLength);
                    }}
                    disabled={disableFields}
                    className="textField w-100"
                    onWheel={(e) => e.target.blur()}
                    placeholder={t('loginForms.phone')}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onChange={(event) => handleFormChange(event)}
                    value={sellerUserInfo?.phone}
                    error={error && sellerUserInfo?.phone === '' ? true : false}
                  />
                </div>
                {/* <div className="qna-container">
                <label htmlFor="aadhar" className="question">
                  Aadhar Number*
                </label>
                <TextField
                  id="aadhar"
                  name="aadhar"
                  value={sellerUserInfo?.aadhar}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(evt) =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  className="textField w-100"
                  placeholder="Aadhar Number"
                  onChange={(event) => handleFormChange(event)}
                  error={error && sellerUserInfo?.aadhar === '' ? true : false}
                />
              </div> */}
                <div className="qna-container">
                  <label htmlFor="seller_pan" className="question">
                    {t('loginForms.panNumber')}*
                  </label>
                  <TextField
                    id="seller_pan"
                    name="seller_pan"
                    type="text"
                    disabled={disableFields}
                    className="textField w-100"
                    placeholder={t('loginForms.panNumber')}
                    inputProps={{ maxLength: 10 }}
                    value={sellerUserInfo?.seller_pan}
                    onChange={(e) =>
                      setSellerUserInfo({
                        ...sellerUserInfo,
                        seller_pan: e.target.value?.toUpperCase(),
                      })
                    }
                    error={
                      error && sellerUserInfo?.seller_pan === '' ? true : false
                    }
                  />
                </div>
                {/* <div className="qna-container">
                <label htmlFor="password" className="question">
                  {t('loginForms.password')}*
                </label>
                <TextField
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={sellerUserInfo?.password}
                  className="textField w-100"
                  placeholder={t('loginForms.password')}
                  onChange={handlePassword}
                  error={
                    error && sellerUserInfo?.password === '' ? true : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ color: `${color}` }}> {errorMessage} </div>
              </div>
              <div className="qna-container">
                <label htmlFor="confirmPassword" className="question">
                  {t('loginForms.confirmPass')}*
                </label>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPass ? 'text' : 'password'}
                  value={sellerUserInfo?.confirmPassword}
                  className="textField w-100"
                  placeholder={t('loginForms.confirmPass')}
                  onChange={(event) => handleFormChange(event)}
                  error={
                    error && sellerUserInfo?.confirmPassword === ''
                      ? true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleToggleConfirmPass}
                          edge="end"
                        >
                          {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div> */}
                <div className="agreeLink mt-2">
                  <FormControlLabel
                    label=""
                    style={{
                      margin: '0',
                    }}
                    control={
                      <Checkbox
                        checked={checkedStatus == true ? true : false}
                        onChange={handleChange1}
                        disabled={disableFields}
                        sx={{
                          color: '#f7991f',
                          '&.Mui-checked': {
                            color: '#f7991f',
                          },
                        }}
                      />
                    }
                  />
                  <h4>
                    {t('loginForms.agree')}{' '}
                    <Link
                      // onClick={handleTerms}
                      to="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('loginForms.term')}
                    </Link>{' '}
                    &{' '}
                    <Link
                      to="/privacyPolicy"
                      // onClick={handlePolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('loginForms.privacy')}
                    </Link>
                  </h4>
                </div>
                {disableFields === true ? (
                  <div className="qna-container">
                    <label htmlFor="otp" className="question">
                      {t('loginForms.validateOtp')}
                    </label>
                    <OTPInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="inputStyle"
                      numInputs={5}
                      inputType="number"
                      renderInput={(props) => (
                        <input {...props} className="inputStyle" max={1} />
                      )}
                      separator={<span></span>}
                    />
                  </div>
                ) : null}
                {disableFields === true ? (
                  <button
                    className="validateButton"
                    type="button"
                    onClick={handleSignupOtp}
                  >
                    {t('loginForms.validateOtp')}
                  </button>
                ) : (
                  <button
                    className="loginBtn"
                    // type="submit"
                    onClick={handleSendOtp}
                  >
                    {t('loginForms.signUp')}
                  </button>
                )}
                <div className="signUpLink">
                  <h4>
                    {t('loginForms.existing')} {t('loginForms.seller')}?
                    <span onClick={handleLoginOpen}>
                      {t('loginForms.signHere')}
                    </span>
                  </h4>
                </div>

                {disableFields === true ? (
                  <div
                    className="resendLink"
                    style={{
                      textAlign: 'end',
                    }}
                  >
                    {isActive === false ? (
                      <button
                        className="resend"
                        type="button"
                        // onClick={handleResendBuyerOtp}
                        onClick={handleResendSignupOtp}
                        disabled={isActive}
                      >
                        Resend OTP
                        <i className="ri-arrow-right-s-fill"></i>
                      </button>
                    ) : (
                      <div className={`timer ${isActive ? 'counting' : ''}`}>
                        {isActive ? `Resend OTP in ${timer}s` : ''}
                      </div>
                    )}
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </LoadingOverlay>
      </Dialog>
    </>
  );
}

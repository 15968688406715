import { Link } from 'react-router-dom';

const CategoryCard = ({ img, title }) => {
  return (
    <div className="categoryCard">
      <div className="productImg">
        <img src={img} alt="product image" />
        <div className="bgShadow"></div>
        <div className="categoryTitle">
          <div className="title">
            <Link to="/categories">{title}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;

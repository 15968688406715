import { createContext, useEffect, useState } from 'react';
import qs from 'qs';
import axios from '../hooks/axios';

const CartContext = createContext({});

export const CartProvider = ({ children }) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const [cartDetails, setCartDetails] = useState([]);
  const currentUser = localStorage.getItem('userInfo');
  const userType = localStorage.getItem('currentUser');
  const buyer = JSON.parse(currentUser);
  // const storedAddress = JSON.parse(localStorage.getItem('deliveryAddress'));
  const [deliveryAddress, setDeliveryAdress] = useState({
    address_label: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_city: '',
    buyer_country: '',
    buyer_email: '',
    buyer_pan: '',
    buyer_id: '',
    buyer_mobile: '',
    buyer_name: '',
    buyer_short_address: '',
    buyer_state: '',
    buyer_zip: '',
    city_name: '',
    country_name: '',
    created_by: '',
    created_on: '',
    first_name: '',
    last_name: '',
    order_id: '',
    seller_id: '',
    shiping_info_id: '',
    state_name: '',
    status: '',
    updated_by: '',
    updated_on: '',
  });

  // useEffect(() => {
  //   if (userType == 'buyer') {
  //     if (location?.pathname != '/checkout') {
  //     const cartD = qs.stringify({
  //       buyer_id: buyer?.buyer_id,
  //       // shiping_info_id: storedAddress?.shiping_info_id,
  //     });

  //     axios
  //       .post(`${API_URL}/viewcart`, cartD, {
  //         headers: {
  //           Authorization: `${authToken}`,
  //         },
  //       })
  //       .then((res) => {
  //         setCartDetails(res?.data);
  //       });
  //     }
  //   }
  // }, [userType]);

  return (
    <CartContext.Provider
      value={{
        cartDetails,
        setCartDetails,
        deliveryAddress,
        setDeliveryAdress,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;

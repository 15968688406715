import BuyerLayout from 'layouts/BuyerLayout';
import Success from '../../Components/Success/Success';

const SuccessPage = () => {
  return (
    <BuyerLayout>
      <Success />
    </BuyerLayout>
  );
};

export default SuccessPage;

import { Link } from 'react-router-dom';
import { Breadcrumbs, Card, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import MyOrder from '../MyOrders/MyOrder';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import BuyerLayout from 'layouts/BuyerLayout';
import { useTranslation } from 'react-i18next';
import SalesOrdersMobile from 'Components/MobileTables/SalesOrders/SalesOrders';
import { useWindowSize } from '@uidotdev/usehooks';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const MyOrders = () => {
  const size = useWindowSize();
  const { t } = useTranslation();
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [salesOrders, setSalesOrders] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    buyer_id: user?.buyer_id,
    page: 1,
    per_page: 10,
    search: '',
  });

  useEffect(() => {
    const productsD = qs.stringify({
      buyer_id: filters?.buyer_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/myorderslist`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  }, []);

  const handleSearch = () => {
    setLoading(true);
    const productsD = qs.stringify({
      buyer_id: filters?.buyer_id,
      page: 1,
      per_page: filters?.per_page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/myorderslist`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  };

  // limiting search calls and search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filters?.search]);

  // handleLoad more
  const handleLoadMore = () => {
    setFilters({ ...filters, page: filters?.page + 1 });
  };

  useDidMountEffect(() => {
    setLoading(true);
    const productsD = qs.stringify({
      buyer_id: filters?.buyer_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/myorderslist`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        const oldArray = salesOrders;
        const moreItems = res?.data?.result;
        const newArray = [...oldArray, ...moreItems];
        setSalesOrders(newArray);
        setFullData(res?.data);
        setLoading(false);
      });
  }, [filters?.page]);

  return (
    <BuyerLayout>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <div className="checkoutWrapper">
          <div className="checkoutInnerWrapper">
            <Stack spacing={2} mt={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                  {t('breadCrumbs.home')}
                </Link>
                <Typography color="text.primary">
                  {t('breadCrumbs.myOrders')}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <section className="buyerAddress">
              <div className="flex items-center justify-between my-3">
                <h2 className="checkoutTitle">{t('breadCrumbs.myOrders')}</h2>
              </div>
              {size.width <= 769 ? (
                <SalesOrdersMobile
                  salesOrders={salesOrders}
                  loading={loading}
                  filters={filters}
                  setFilters={setFilters}
                  fullData={fullData}
                  handleLoadMore={handleLoadMore}
                />
              ) : (
                <MyOrder
                  salesOrders={salesOrders}
                  loading={loading}
                  filters={filters}
                  setFilters={setFilters}
                  fullData={fullData}
                  handleLoadMore={handleLoadMore}
                />
              )}
            </section>
          </div>
        </div>
      </LoadingOverlay>
    </BuyerLayout>
  );
};

export default MyOrders;

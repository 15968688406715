import { createContext, useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
const ContactContext = createContext({});

export const ContactProvider = ({ children }) => {
  const [contactDetails, setContactDetails] = useState({});
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);

  useEffect(() => {
    const cartD = qs.stringify({});

    axios
      .post(`${API_URL}/getcmscontactinfo`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setContactDetails(res?.data?.result);
      });
  }, []);

  return (
    <ContactContext.Provider value={{ contactDetails, setContactDetails }}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactContext;

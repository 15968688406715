import BuyerLayout from 'layouts/BuyerLayout';
import ProductList from '../../Components/ProductList/ProductList';

const ProductListPage = () => {
  return (
    <BuyerLayout>
      <ProductList />
    </BuyerLayout>
  );
};

export default ProductListPage;

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'axios';
import { TextField } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ForgotPass({
  handlePassClose,
  passOpen,
  handleLoginOpen,
  sName,
}) {
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const [userInfo, setUserInfo] = useState({
    email: '',
  });
  const [loading, setLoading] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handlePassUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (userInfo?.email !== '' && emailRegx.test(userInfo.email) !== false) {
      const loginD = qs.stringify({
        email: userInfo?.email,
      });

      axios
        .post(
          `${API_URL}/${
            sName == 'seller' ? 'resetloginseller' : 'resetloginbuyer'
          }`,
          loginD,
          headers
        )
        .then((res) => {
          setLoading(false);
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            handlePassClose();
          }
        });
    } else {
      if (userInfo.email === '') {
        return toastMessageWarning({
          message: `Email Address can't be empty!`,
        });
      } else if (emailRegx.test(userInfo.email) === false) {
        return toastMessageWarning({ message: 'Email address is not valid!' });
      }
    }
  };

  const handleFormChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Dialog
        open={passOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePassClose}
        // fullWidth="xs"
        maxWidth="xs"
        style={{
          borderRadius: '20px',
        }}
      >
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
            wrapper: {
              overflow: 'hidden',
            },
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        >
          <div className="modal-body p-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handlePassClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <div className="loginContainer">
              <div className="modal-header">
                <div className="loginTitle">
                  {t('loginForms.update')}{' '}
                  {sName == 'seller'
                    ? `${t('loginForms.seller')}`
                    : `${t('loginForms.buyer')}`}{' '}
                  {t('loginForms.password')}
                </div>
              </div>
              <hr />
              <form
                className="inputsContainer"
                autoComplete="off"
                noValidate
                onSubmit={handlePassUpdate}
              >
                <div className="qna-container">
                  <label htmlFor="email" className="question">
                    {t('loginForms.email')}*
                  </label>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    className="textField w-100"
                    placeholder={t('loginForms.email')}
                    value={userInfo?.email}
                    onChange={(event) => handleFormChange(event)}
                  />
                </div>
                <button className="loginBtn">{t('loginForms.submit')}</button>

                <div className="signUpLink">
                  <h4>
                    {t('loginForms.rem')}?
                    <span onClick={handleLoginOpen}>
                      {t('loginForms.loginNow')}
                    </span>
                  </h4>
                </div>
              </form>
            </div>
          </div>
        </LoadingOverlay>
      </Dialog>
    </>
  );
}

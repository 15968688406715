import SellerLayout from 'layouts/SellerLayout';
import ZeroProduct from './ZeroProduct';

const ZeroProductPage = () => {
  return (
    <SellerLayout>
      <ZeroProduct />
    </SellerLayout>
  );
};

export default ZeroProductPage;

// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import qs from 'qs';
import axios from 'hooks/axios';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import { useEffect, useState } from 'react';
import EditQuantityModal from './EditQuantityModal';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { Dialog } from '@mui/material';
import ConfirmModal from 'Components/ProductDetail/ConfirmModal';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const CartProduct = ({
  cartImg,
  cart,
  cartTitle2,
  sellerLocation,
  cart_id,
  setCartDetails,
  storedAddress,
  setLoading,
}) => {
  const [reqValue, setReqValue] = useState('');
  const [error, setError] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const history = useHistory();
  const [openDel, setOpenDel] = useState(false);

  const currentUser = localStorage.getItem('userInfo');
  const buyer = JSON.parse(currentUser);
  // const storedAddress = JSON.parse(localStorage.getItem('deliveryAddress'));

  const unit = cart?.product_info?.unit;

  const handleOpenProduct = () => {
    history.push(`/productDetail/${cart?.product_info?.product_id}`);
  };

  // const history = useHistory();

  const handleRemove = () => {
    setLoading(true);
    setOpenDel(false);
    const cartD = qs.stringify({
      buyer_id: buyer?.buyer_id,
      cart_id: cart_id,
    });
    axios
      .post(`${API_URL}/deletefromcart`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        toastMessageSuccess({
          message: `${res?.data?.message}`,
        });
        const viewCartD = qs.stringify({
          buyer_id: buyer?.buyer_id,
          shiping_info_id: storedAddress?.shiping_info_id,
        });

        axios
          .post(`${API_URL}/viewcart`, viewCartD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            setLoading(false);
            setCartDetails(res?.data);
          });
      });

    // if (newArr?.length == 0) {
    //   history.push('/products');
    // } else {
    //   window.location.reload();
    // }
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setReqValue(cart?.product_qty);
  };

  const handleEdit = () => {
    if (
      reqValue == '' ||
      reqValue == undefined ||
      reqValue == null ||
      reqValue == 0
    ) {
      setError(true);
      toastMessageWarning({
        message: `Required Can't be empty or 0`,
      });
    } else {
      const qty = cart?.quantity;
      if (reqValue > qty) {
        setError(true);
        toastMessageWarning({
          message: `Entered Req Qty Can't be greater than available quantity ${' '}(${
            cart?.quantity
          }   ${
            unit == 'Quintals'
              ? 'Quintals'
              : unit == 'Ltrs'
              ? 'Ltrs'
              : unit == 'Bags'
              ? 'Bags'
              : 'Bales'
          })`,
        });
      } else {
        setError(false);
        const cartD = qs.stringify({
          buyer_id: buyer?.buyer_id,
          product_qty: reqValue,
          product_id: cart?.product_id,
          location_id: cart?.location_id,
          seller_id: cart?.seller_id,
          // cart_id: cart_id,
        });
        axios
          .post(`${API_URL}/addtocart`, cartD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            setEditOpen(false);
            const viewCartD = qs.stringify({
              buyer_id: buyer?.buyer_id,
              shiping_info_id: storedAddress?.shiping_info_id,
            });

            axios
              .post(`${API_URL}/viewcart`, viewCartD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setCartDetails(res?.data);
              });
          });
      }
    }
  };

  useEffect(() => {
    setReqValue(cart?.product_qty);
  }, [cart]);

  return (
    <>
      <div
        className={`${
          cart?.is_delivered == 0 ? 'cartOuterDisabled' : 'cartOuter'
        }`}
      >
        <div className="cartProductImg" onClick={handleOpenProduct}>
          <img
            src={cartImg}
            alt="image of product in cart"
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="cartProductInfoBox">
          <div className="grid items-center grid-cols-8 gap-1">
            <h3
              className="col-span-2"
              onClick={handleOpenProduct}
              style={{ cursor: 'pointer' }}
            >
              {cart?.product_info?.product_name}
            </h3>
            <h3>{cart?.product_info?.product_type_name}</h3>
            <h3>{cart?.product_info?.product_quality_name}</h3>
            <h3>₹ {cart?.unit_cost}</h3>
            <h3>
              {cart?.product_qty} (
              {unit == 'Quintals'
                ? 'Q'
                : unit == 'Ltrs'
                ? 'L'
                : unit == 'Bags'
                ? 'Bags'
                : 'Bales'}
              )
            </h3>
            <h3>₹ {cart?.item_total}</h3>

            {/* <div className="moreBtnContainer">/ */}
            <div className="flex">
              <button
                type="button"
                disabled={cart?.is_delivered === 1 ? false : true}
                className={`removeBtn mr-2 ${
                  cart?.is_delivered === 1 ? 'green' : 'disabledBtn'
                }`}
                onClick={handleEditOpen}
              >
                <i className="ri-pencil-line"></i>
              </button>
              <button
                type="button"
                // disabled={cart?.is_delivered === 1 ? false : true}
                className={`removeBtn red`}
                onClick={() => setOpenDel(true)}
              >
                <i className="ri-delete-bin-7-fill" />
              </button>
            </div>
            {/* </div> */}
          </div>

          {/* row 2 */}
          <div className="grid items-center grid-cols-8 gap-2 mt-2">
            <h2 className="col-span-3">
              {t('seller')} - {cartTitle2} ({sellerLocation})
            </h2>
            {cart?.is_delivered === 1 ? (
              <>
                <h2 className="col-span-2">
                  {t('checkoutPage.delDistance')}: {cart?.delivered_distance}{' '}
                  Kms
                </h2>
                <h2 className="col-span-2">
                  {t('checkoutPage.deliveryFee')}: ₹{cart?.delivery_charge}
                </h2>
              </>
            ) : (
              <>
                <h2 className="col-span-6">
                  ({t('checkoutPage.notDelivered')})
                </h2>
              </>
            )}
          </div>

          {/* <h2 className="">({cartTitle2})</h2> */}
        </div>
      </div>

      {editOpen && (
        <EditQuantityModal
          editOpen={editOpen}
          handleEditClose={handleEditClose}
          handleEdit={handleEdit}
          reqValue={reqValue}
          setReqValue={setReqValue}
          error={error}
        />
      )}

      <Dialog onClose={() => setOpenDel(false)} open={openDel}>
        <ConfirmModal
          onConfirm={handleRemove}
          onClose={() => setOpenDel(false)}
          deleteMessage={`Do you really want to remove this product from cart ?`}
          btnName={`Delete`}
        />
      </Dialog>
    </>
  );
};

export default CartProduct;

import { TextField, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';
import { t } from 'i18next';
// import dayjs from 'dayjs';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DispatchModal = ({
  modalOpen,
  handleModalClose,
  shippingInfo,
  setShippingInfo,
  handleShippingInfo,
  startDate,
  setStartDate,
  showQty,
  unit,
}) => {
  const handleFormChange = (event) => {
    setShippingInfo({
      ...shippingInfo,
      [event.target.name]: event.target.value,
    });
  };
  const textFieldRef = useRef(null);
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setOpen(true);
    }
  };

  const handleFocus = () => {
    setOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // var date = new Date();

  // add a day

  // const minDate = date.setDate(date.getDate() + 1);

  return (
    <Dialog
      open={modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      // fullWidth="xs"
      maxWidth="sm"
      style={{
        borderRadius: '20px',
      }}
    >
      <div className="modal-body p-0">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleModalClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <div className="loginContainer">
          <div className="modal-header">
            <div className="loginTitle">{t('dispatchModal.addDetails')}</div>
          </div>

          <hr />

          <div className="inputsContainer">
            <div className="qna-container">
              <label htmlFor="shipping_name" className="question">
                {t('dispatchModal.name')}*
              </label>
              <TextField
                id="shipping_name"
                name="shipping_name"
                type="text"
                className="textField w-100"
                placeholder={t('dispatchModal.name')}
                onChange={(event) => handleFormChange(event)}
                value={shippingInfo?.shipping_name}
              />
            </div>
            <div className="qna-container">
              <label htmlFor="shipping_no" className="question">
                {t('dispatchModal.number')}*
              </label>
              <TextField
                id="shipping_no"
                name="shipping_no"
                type="text"
                className="textField w-100"
                placeholder={t('dispatchModal.number')}
                value={shippingInfo?.shipping_no}
                onChange={(event) => handleFormChange(event)}
              />
            </div>
            {showQty === true && (
              <div className="qna-container">
                <label htmlFor="quantity" className="question">
                  {/* Quantity To Return Back in{' '}
                  {unit == 'Quintals'
                    ? 'Quintals'
                    : unit == 'Ltrs'
                    ? 'Ltrs'
                    : unit == 'Bags'
                    ? 'Bags'
                    : 'Bales'} */}
                  {t('dispatchModal.qty')}*
                </label>
                <TextField
                  id="quantity"
                  name="quantity"
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(evt) =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  className="textField w-100"
                  placeholder={t('dispatchModal.returnQty')}
                  value={shippingInfo?.quantity}
                  onChange={(event) => handleFormChange(event)}
                />
              </div>
            )}
            <div className="qna-container">
              <label htmlFor="date" className="question">
                {t('dispatchModal.date')}*
              </label>
              <div className="answer">
                <LocalizationProvider
                  adapterLocale="en-gb"
                  dateAdapter={AdapterDayjs}
                >
                  <DemoContainer
                    components={['DatePicker', 'DatePicker', 'DatePicker']}
                  >
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                        setOpen(false);
                      }}
                      disablePast
                      open={open}
                      onClose={() => setOpen(false)}
                      slotProps={{
                        textField: {
                          onClick: () => setOpen(true),
                          error: false,
                          onFocus: () => {
                            handleFocus;
                          },
                          onKeyDown: () => {
                            handleKeyDown;
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          ref={textFieldRef}
                          onClick={() => setOpen(true)}
                          onFocus={handleFocus}
                          onKeyDown={handleKeyDown}
                          inputProps={{
                            ...params.inputProps,
                            tabIndex: 0,
                            style: { cursor: 'pointer' },
                          }}
                          fullWidth
                          sx={{
                            '&:focus': {
                              outline: `2px solid ${theme.palette.primary.main}`,
                            },
                          }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <button className="loginBtn" onClick={handleShippingInfo}>
              {t('dispatchModal.add')}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DispatchModal;

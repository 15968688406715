import MyOrdersTable from './MyOrdersTable';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Card, Typography } from '@mui/material';
import { t } from 'i18next';
// import '../../assets/css/argon-dashboard-pro-react.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const MyOrder = ({
  salesOrders,
  loading,
  fullData,
  // filters,
  // setFilters,
  handleLoadMore,
  page,
}) => {
  const userType = localStorage.getItem('currentUser');

  return (
    <Card>
      {/* <div className="tableHeader">
        <div className="tableTitle">My Orders</div>
        <div className="tableSearch">
          <TextField
            id="search"
            type="text"
            className="textField w-100"
            placeholder="Search..."
            onChange={(e) =>
              setFilters({
                ...filters,
                search: e.target.value,
              })
            }
          />
        </div>
      </div> */}
      {page === 'home' && (
        <Typography
          gutterBottom
          variant="subtitle1"
          className="px-3 pt-2"
          component="div"
        >
          {t('sellerDashboard.recent')}
        </Typography>
      )}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
            wrapper: {
              overflow: 'hidden',
            },
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        >
          <TableContainer
            sx={{ maxHeight: '50vh' }}
            className="myPolicyOuterTable"
          >
            <Table aria-label="customized table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    {t('sellerDashboard.sNo')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t('sellerDashboard.id')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t('sellerDashboard.amount')}
                  </StyledTableCell>
                  {userType == 'seller' && (
                    <StyledTableCell align="left">
                      {t('sellerDashboard.buyerName')}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    {t('sellerDashboard.date')}
                  </StyledTableCell>
                  {userType == 'seller' && (
                    <StyledTableCell align="left">
                      {t('sellerDashboard.payment')}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    {t('sellerDashboard.items')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t('sellerDashboard.status')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {salesOrders?.length == 0 ? (
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        colSpan={10}
                        style={{
                          textAlign: 'center',
                          fontSize: '1.25rem',
                          padding: '2rem 0',
                        }}
                      >
                        {userType == 'buyer' && <>{t('noProductData')}</>}
                        {userType == 'seller' && <>{t('noOrderYet')}</>}
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    <>
                      {salesOrders?.map((order, index) => (
                        <MyOrdersTable
                          order={order}
                          key={order?.order_id}
                          index={index}
                          page={page}
                        />
                      ))}
                    </>
                  )}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {page === 'home' ? null : (
            <>
              {fullData && (
                <div className="tableLoadMoreSection">
                  <div className="table-responsive">
                    <Table aria-label="customized table">
                      {fullData && (
                        <>
                          {fullData?.page * fullData?.per_page <
                            fullData?.total && (
                            <tbody className="list">
                              <tr
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                <td
                                  colSpan="10"
                                  style={{
                                    fontSize: '1.2rem',
                                    padding: '1rem 0',
                                  }}
                                >
                                  <Button
                                    // className="my-2"
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    onClick={handleLoadMore}
                                  >
                                    <span className="btn-inner--icon mr-1">
                                      <i className="fas fa-arrow-alt-circle-down"></i>
                                    </span>
                                    <span className="btn-inner--text">
                                      {t('sellerDashboard.loadMore')}
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </>
                      )}
                    </Table>
                  </div>
                  <div className="row react-bootstrap-table-pagination">
                    {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                  <div
                    className="dataTables_length"
                    id="datatable-basic_length"
                  >
                    <label>
                      Show{' '}
                      {
                        <select
                          name="datatable-basic_length"
                          aria-controls="datatable-basic"
                          className="form-control form-control-sm"
                          value={filters?.per_page}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              per_page: e.target.value,
                              page: 1,
                            })
                          }
                        >
                          <option className="options" value="10">
                            10
                          </option>
                          <option className="options" value="25">
                            25
                          </option>
                          <option className="options" value="50">
                            50
                          </option>
                          <option className="options" value="75">
                            75
                          </option>
                          <option className="options" value="100">
                            100
                          </option>
                        </select>
                      }
                      entries.
                    </label>
                  </div>
                </div> */}
                    <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6 ml-auto">
                      <div
                        className="pagination react-bootstrap-table-page-btns-ul"
                        style={{ alignItems: 'center', height: '100%' }}
                      >
                        <span className="react-bootstrap-table-pagination-total ">
                          {t('sellerDashboard.showing')}{' '}
                          {fullData?.total
                            ? fullData?.total == '1'
                              ? '1'
                              : fullData?.per_page * fullData?.page >
                                fullData?.total
                              ? fullData?.total
                              : fullData?.per_page * fullData?.page
                            : '0'}{' '}
                          {t('sellerDashboard.of')}{' '}
                          {fullData?.total ? fullData?.total : '0'}{' '}
                          {t('sellerDashboard.results')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </LoadingOverlay>
      </Paper>
    </Card>
  );
};

export default MyOrder;

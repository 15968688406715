import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ProductListTable = ({ product, btnName }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handlePush = () => {
    history.push(`/productDetail/${product?.product_id}`);
  };

  return (
    <StyledTableRow>
      {/* <StyledTableCell component="th" scope="row">
        {product?.id}
      </StyledTableCell> */}
      <StyledTableCell align="left">{product?.product_name}</StyledTableCell>
      <StyledTableCell align="left">
        <div className="productimage">
          <img src={product?.image_thumb} alt="product image" />
        </div>
      </StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_type_name}
      </StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_variety_name}
      </StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_available_name}
      </StyledTableCell>
      <StyledTableCell align="center">
        {product?.product_quality_name}
      </StyledTableCell>
      <StyledTableCell align="left">
        <button className="greenBtn" onClick={handlePush}>
          <p>
            {btnName == 'edit'
              ? `${t('productListPage.edit')}`
              : `${t('productListPage.view')}`}
          </p>
        </button>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ProductListTable;

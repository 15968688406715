const ConfirmModal = ({ onConfirm, onClose, deleteMessage, btnName }) => {
  return (
    <div>
      <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
        <div className="">
          <div className="text-center p-5 flex-auto justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-16 h-16 flex items-center text-orange-500 mx-auto"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
            <p className="text-md font-medium text-gray-500 px-8">
              {deleteMessage
                ? deleteMessage
                : 'Changing The Delivery Location Will Affect Products In Your Cart !'}
            </p>
          </div>

          <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button
              onClick={() => onClose()}
              className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={() => onConfirm()}
              className="mb-2 md:mb-0 bg-orange-500 border border-orange-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-orange-600"
            >
              {btnName == 'Delete' ? btnName : 'Continue'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;

import { useHistory, useParams } from 'react-router-dom';
import './login.scss';
import { useEffect, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from 'utils/toast';
import qs from 'qs';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import BuyerLayout from 'layouts/BuyerLayout';
import { TextField } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ResetBuyerPass = () => {
  const { t } = useTranslation();
  const { keyID } = useParams();
  const history = useHistory();
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);

  const [userInfo, setUserInfo] = useState({
    actkey: '',
    email: '',
    buyer_id: '',
    password: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [color, setColor] = useState('red');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  function handlePassword(event) {
    let new_pass = event.target.value;
    setUserInfo({
      ...userInfo,
      password: new_pass,
    });
    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (new_pass.length < 6) {
      setErrorMessage('Password length should be more than 6.');
      setColor('red');
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage('Password should contains upperCase letters!');
      setColor('red');
    } else if (!new_pass.match(lowerCase)) {
      setErrorMessage('Password should contain lowerCase letters!');
      setColor('red');
    } else if (!new_pass.match(numbers)) {
      setErrorMessage('Password should contains numbers also!');
      setColor('red');
    } else {
      setErrorMessage('Password is strong!');
      setColor('green');
    }
  }

  const handleFormChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (
      userInfo?.password !== '' &&
      userInfo?.confirmPassword !== '' &&
      errorMessage === 'Password is strong!'
    ) {
      if (userInfo?.password !== userInfo?.confirmPassword) {
        toastMessageWarning({
          message: `Password doesn't match. Please fill correct password!`,
        });
      } else {
        setLoading(true);
        setError(false);
        const signUpD = qs.stringify({
          actkey: userInfo?.actkey,
          email: userInfo?.email,
          buyer_id: userInfo?.buyer_id,
          password: userInfo?.password,
        });

        axios
          .post(`${API_URL}/updatepasswordbuyer`, signUpD, headers)
          .then((res) => {
            if (res?.data?.status === false) {
              setLoading(false);
              toastMessageWarning({
                message: `${res?.data?.message}`,
              });
            } else {
              setLoading(false);
              setColor('red');
              setErrorMessage(false);
              setError(false);
              history.push('/');
              toastMessageSuccess({
                message: `${res?.data?.message}`,
              });
            }
          });
      }
    } else {
      setError(true);
      if (userInfo?.password === '') {
        return toastMessageWarning({
          message: 'Password field cannot be empty',
        });
      } else if (errorMessage !== 'Password is strong!') {
        return toastMessageWarning({
          message:
            'Please enter password with length of 6 characters, containing a number, uppercase and lowercase letters !',
        });
      } else if (userInfo.confirmPassword === '') {
        return toastMessageWarning({
          message: 'Confirm Password field cannot be empty',
        });
      }
    }
  };

  useEffect(() => {
    const keyData = qs.stringify({
      actkey: keyID,
    });

    axios
      .post(`${API_URL}/validateresetbuyer`, keyData, headers)
      .then((res) => {
        if (res?.data?.status === false) {
          // history.push('/');
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          setUserInfo({
            ...userInfo,
            actkey: res?.data?.result?.actkey,
            email: res?.data?.result?.email,
            buyer_id: res?.data?.result?.id,
          });
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
        }
      });
  }, [keyID]);

  return (
    <BuyerLayout>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <div className="bg-gray-100" style={{ minHeight: '60vh' }}>
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-1xl md:text-3xl font-bold mb-4 pt-4 header-text">
              {t('loginForms.resetBuyerPass')}
            </h1>
            <form
              className="inputsContainer"
              autoComplete="off"
              noValidate
              onSubmit={handleReset}
            >
              <div className="qna-container">
                <label htmlFor="email" className="question">
                  {t('loginForms.email')}*
                </label>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  disabled
                  className="textField w-100"
                  placeholder={t('loginForms.email')}
                  value={userInfo?.email}
                  onChange={(event) => handleFormChange(event)}
                />
              </div>
              <div className="qna-container">
                <label htmlFor="password" className="question">
                  {t('loginForms.password')}*
                </label>
                <TextField
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={userInfo?.password}
                  className="textField w-100"
                  placeholder={t('loginForms.password')}
                  onChange={handlePassword}
                  error={error && userInfo?.password === '' ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ color: `${color}` }}> {errorMessage} </div>
              </div>
              <div className="qna-container">
                <label htmlFor="confirmPassword" className="question">
                  {t('loginForms.confirmPass')}*
                </label>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPass ? 'text' : 'password'}
                  value={userInfo?.confirmPassword}
                  className="textField w-100"
                  placeholder={t('loginForms.confirmPass')}
                  onChange={(event) => handleFormChange(event)}
                  error={
                    error && userInfo?.confirmPassword === '' ? true : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleToggleConfirmPass}
                          edge="end"
                        >
                          {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <button className="loginBtn">{t('loginForms.reset')}</button>
            </form>
          </div>
        </div>
      </LoadingOverlay>
    </BuyerLayout>
  );
};

export default ResetBuyerPass;

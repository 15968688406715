import ProductListName from 'Components/ProductList/ProductListName';
import BuyerLayout from 'layouts/BuyerLayout';

const ProductListWithNamePage = () => {
  return (
    <BuyerLayout>
      <ProductListName />
    </BuyerLayout>
  );
};

export default ProductListWithNamePage;

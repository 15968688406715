import SellerLayout from 'layouts/SellerLayout';
import ViewProduct from './ViewProduct';

const ViewProductPage = () => {
  return (
    <SellerLayout>
      <ViewProduct />
    </SellerLayout>
  );
};

export default ViewProductPage;

import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
// import OrderedProduct from '../Cart/OrderedProduct';
import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import OrderDetailsTable from './OrderDetailsTable';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import ShippingDetail from '../Success/ShippingDetail';
import SellerLayout from 'layouts/SellerLayout';
import { useContext } from 'react';
import ContactContext from 'context/ContactProvider';
import EmptyRow from './EmptyRow';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    // minWidth: '1px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // minWidth: '1px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SellerOrderDetails = () => {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const [orderDetails, setOrderDetails] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(true);
  const { order_id } = useParams();
  const { contactDetails } = useContext(ContactContext);

  useEffect(() => {
    const cartD = qs.stringify({
      seller_id: user?.seller_id,
      order_id: order_id,
    });

    axios
      .post(`${API_URL}/salesorderdetails`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setOrderDetails(res?.data?.result);
        setFullPageLoader(false);
      });
  }, []);

  return (
    <SellerLayout>
      <Card sx={{ width: '100%' }}>
        <Typography gutterBottom variant="h6" component="div" className="p-3">
          {t('sellerOrderDetails.orderDetailId')} #{order_id}
        </Typography>
      </Card>
      {fullPageLoader === true ? (
        <LoadingOverlay
          active={fullPageLoader}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
            wrapper: {
              overflow: 'hidden',
            },
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        />
      ) : (
        <Card sx={{ width: '100%' }} className="mt-4 px-6 pb-2">
          <section className="successSection">
            <div className="productInfo">
              <section className="checkoutSection">
                <div className="shippingContainer mb-4">
                  <div className="shippingTitle">
                    {t('sellerOrderDetails.transId')} #
                    {orderDetails?.order_sub_transaction_id}
                  </div>
                  <div className="shippingTitle">
                    {t('sellerOrderDetails.orderedProducts')}
                  </div>
                  {/* <hr /> */}

                  <div className="productListTable mb-6">
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                      <TableContainer
                        sx={{ maxHeight: '60vh' }}
                        className="myPolicyOuterTable"
                      >
                        <Table
                          aria-label="customized table"
                          stickyHeader
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              {/* <StyledTableCell>S.No</StyledTableCell> */}
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.transactionId')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.name')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.image')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.type')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.quality')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.price')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.qty')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.loc')}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t('sellerOrderDetails.total')}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t('sellerOrderDetails.status')}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t('sellerOrderDetails.nextAction')}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails?.product_ino?.length == 0 ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  colSpan={12}
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '1.25rem',
                                    padding: '2rem 0',
                                  }}
                                >
                                  {t('noData')}
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              <>
                                {orderDetails?.product_ino?.map((cart) => (
                                  <OrderDetailsTable
                                    cart={cart}
                                    key={cart?.product_id}
                                    cartImg={cart?.image_thumb}
                                    order_id={order_id}
                                    setOrderDetails={setOrderDetails}
                                    setFullPageLoader={setFullPageLoader}
                                  />
                                ))}

                                <EmptyRow
                                  name={t('sellerOrderDetails.subTotal')}
                                  value={`₹ ${orderDetails?.order_total}`}
                                />
                                <EmptyRow
                                  name={t('sellerOrderDetails.shippings')}
                                  value={`₹ ${orderDetails?.delivery_charge}`}
                                />
                                <EmptyRow
                                  name={t('sellerOrderDetails.grandTotal')}
                                  value={`₹ ${orderDetails?.grand_total}`}
                                />
                                {orderDetails?.total_refund_amount &&
                                orderDetails?.total_refund_amount > 0 ? (
                                  <>
                                    <EmptyRow
                                      name={t(
                                        'sellerOrderDetails.refundedAmount'
                                      )}
                                      value={`- ${orderDetails?.total_refund_amount}`}
                                    />
                                    <EmptyRow
                                      name={t('sellerOrderDetails.total')}
                                      value={`₹ ${orderDetails?.order_total_after_refund}`}
                                    />
                                  </>
                                ) : null}
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </section>
            </div>

            <div className="shippingContainer mt-6">
              <h3 className="shippingTitle">
                {t('sellerOrderDetails.statusHistory')}
              </h3>
              {/* <hr /> */}
              <div className="mb-6">
                <ShippingDetail
                  status_histort={orderDetails?.status_histort}
                  loading={fullPageLoader}
                />
              </div>
            </div>

            <div className="shippingContainer mt-8">
              <h3 className="shippingTitle">
                {t('sellerOrderDetails.shippingAddress')}
              </h3>
              <hr />
              <div className="addressinfo">
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10">
                  <div className="infoList">
                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.buyerName')}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.buyer_name}
                      </div>
                    </div>

                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.zip')}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.buyer_zip}
                      </div>
                    </div>

                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.city')}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.city_name}
                      </div>
                    </div>
                  </div>
                  <div className="infoList">
                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.email')}
                      </div>
                      <div className="infoDetail">{contactDetails?.email}</div>
                    </div>
                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.short')}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.buyer_short_address}
                      </div>
                    </div>

                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.country')}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.country_name}
                      </div>
                    </div>
                  </div>

                  <div className="infoList">
                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.phone')}
                      </div>
                      <div className="infoDetail">{contactDetails?.phone}</div>
                    </div>

                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.line')}{' '}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.buyer_address_1}

                        {orderDetails?.shipping_info?.[0]?.buyer_address_2 && (
                          <>
                            ,{' '}
                            {orderDetails?.shipping_info?.[0]?.buyer_address_2}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="infoDetail">
                      <div className="infoHeading">
                        {t('sellerOrderDetails.state')}
                      </div>
                      <div className="infoDetail">
                        {orderDetails?.shipping_info?.[0]?.state_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Card>
      )}
    </SellerLayout>
  );
};

export default SellerOrderDetails;

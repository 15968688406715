import './checkout.scss';

import CartProduct from '../Cart/CartProduct';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import qs from 'qs';
import axios from 'hooks/axios';
import { useContext, useEffect, useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmptyCart from '../Cart/EmptyCart';
import { toastMessageWarning } from '../../utils/toast';
import AddressLists from '../Login/AddressLists';
import CartContext from '../../context/CartProvider';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
// import Loading from 'react-fullscreen-loading';
import { useWindowSize } from '@uidotdev/usehooks';
import CartProductMobile from '../Cart/CartProductMobile';
import BuyerLayout from 'layouts/BuyerLayout';
import Loading from 'Components/Loading/Loading';
import { useTranslation } from 'react-i18next';
import AuthContext from 'context/AuthProvider';
import EmptyCart2 from 'Components/EmptyCart2/EmptyCart2';
// import WarningModal from './WarningModal';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const RAZORPAY_KEY = process.env.REACT_RAZORPAY_KEY;
// const token = localStorage.getItem('token');
// const authToken = JSON.parse(token);

const Checkout = () => {
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);
  const [loading, setLoading] = useState(false);
  const [outerLoader, setOuterLoader] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const storedAddress = JSON.parse(localStorage.getItem('deliveryAddress'));
  const { cartDetails, setCartDetails, deliveryAddress, setDeliveryAdress } =
    useContext(CartContext);
  const history = useHistory();

  const size = useWindowSize();

  // const [deliveryAddress, setDeliveryAdress] = useState({
  //   address_label: '',
  //   buyer_address_1: '',
  //   buyer_address_2: '',
  //   buyer_city: '',
  //   buyer_country: '',
  //   buyer_email: '',
  //   buyer_pan: '',
  //   buyer_id: '',
  //   buyer_mobile: '',
  //   buyer_name: '',
  //   buyer_short_address: '',
  //   buyer_state: '',
  //   buyer_zip: '',
  //   city_name: '',
  //   country_name: '',
  //   created_by: '',
  //   created_on: '',
  //   first_name: '',
  //   last_name: '',
  //   order_id: '',
  //   seller_id: '',
  //   shiping_info_id: '',
  //   state_name: '',
  //   status: '',
  //   updated_by: '',
  //   updated_on: '',
  // });

  const [addressDetails, setAddressDetails] = useState([]);
  const [addressOpen, setAddressOpen] = useState(false);

  // buyer signUp dialouge
  const handleAddressOpen = () => {
    setAddressOpen(true);
  };
  const handleAddressClose = () => {
    setAddressOpen(false);
  };

  const [selectedFilter, setSelectedFilter] = useState({
    category_id: '',
    product_name: '',
    product_variety: '',
    product_available: '',
    product_type: '',
    product_quality: '',
  });
  const [filterOptions, setFilterOptions] = useState([]);

  const handleCategoryChange = (e) => {
    history.push(`/product/${e.target.value}`);
    setSelectedFilter({
      ...selectedFilter,
      category_id: e.target.value,
      product_name: '',
      product_variety: '',
      product_available: '',
      product_type: '',
      product_quality: '',
    });
  };
  const handleProductSelect = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_name: e.target.value,
    });
  };
  const handleVarietySelect = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_variety: e.target.value,
    });
  };
  const handleSelectedQuality = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_quality: e.target.value,
    });
  };
  const handleSelectedType = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_type: e.target.value,
    });
  };
  const handleAvail = (e) => {
    setSelectedFilter({
      ...selectedFilter,
      product_available: e.target.value,
    });
  };

  const [expand, setExpand] = useState(true);

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    if (size.width <= 769) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  }, [size.width]);

  useEffect(() => {
    const addDD = qs.stringify({
      buyer_id: user?.buyer_id,
    });
    axios
      .post(`${API_URL}/buyeraddress`, addDD, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setAddressDetails(res?.data?.result);
        if (storedAddress?.shiping_info_id) {
          setDeliveryAdress(storedAddress);
        } else {
          setDeliveryAdress(res?.data?.result?.[0]);
          localStorage.setItem(
            'deliveryAddress',
            JSON.stringify(res?.data?.result?.[0])
          );
        }
      });

    const buyerD = qs.stringify({
      category_id: selectedFilter?.category_id,
      product_settings_id: selectedFilter?.product_name,
    });

    axios
      .post(`${API_URL}/buyerproductfilter`, buyerD, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setFilterOptions(res?.data);
      });
  }, []);

  useEffect(() => {
    if (deliveryAddress?.shiping_info_id) {
      setLoading(true);
      setOuterLoader(true);
      const cartD = qs.stringify({
        buyer_id: user?.buyer_id,
        shiping_info_id: deliveryAddress?.shiping_info_id,
      });

      axios
        .post(`${API_URL}/viewcart`, cartD, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setCartDetails(res?.data);
          setLoading(false);
          setOuterLoader(false);
        });
    }
  }, [deliveryAddress?.buyer_zip]);

  // const calculateShippingCost = async () => {
  //   const data = cartDetails?.result;
  //   let fees = 0;

  //   for (let x in data) {
  //     const locData = qs.stringify({
  //       buyer_id: data[x]?.buyer_id,
  //       product_id: data[x]?.product_id,
  //       seller_id: data[x]?.product_info?.seller_id,
  //       location_id: data[x]?.location_id,
  //       shiping_info_id: deliveryAddress?.shiping_info_id,
  //     });

  //     await axios
  //       .post(`${API_URL}/checkoutaddress`, locData, {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         fees += parseInt(res?.data?.result?.delivery_charge);
  //       });
  //   }

  //   return fees;
  // };

  // useDidMountEffect(async () => {
  //   if (cartDetails?.result?.length > 0 && deliveryAddress?.shiping_info_id) {
  //     setLoading(true);
  //     let calculatedFee = await calculateShippingCost();

  //     if (calculatedFee === 0) {
  //       toastMessageWarning({
  //         message: `Sellers are not delivering any product in your current location, update your Delivery Address !`,
  //       });
  //     }
  //     setTotalDeliveryFee(calculatedFee);
  //     setLoading(false);
  //   }
  // }, [deliveryAddress?.shiping_info_id]);

  useDidMountEffect(() => {
    const buyerD = qs.stringify({
      category_id: selectedFilter?.category_id,
      product_settings_id: selectedFilter?.product_name,
    });

    axios
      .post(`${API_URL}/buyerproductfilter`, buyerD, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setFilterOptions(res?.data);
      });
  }, [
    selectedFilter?.category_id,
    selectedFilter?.product_available,
    selectedFilter?.product_name,
    selectedFilter?.product_type,
    selectedFilter?.product_quality,
    selectedFilter?.product_variety,
  ]);

  const checkAddress = async () => {
    setLoading(true);
    if (cartDetails?.sub_total > 25000) {
      setLoading(false);
      toastMessageWarning({
        message: `Cart Total Value Can't Exceed ₹25,000 !`,
      });
    } else {
      const data = cartDetails?.result;
      function hasFalse(arr) {
        return arr.includes(false);
      }

      let statuses = [];

      for (let x in data) {
        const cartD = qs.stringify({
          buyer_id: data[x]?.buyer_id,
          product_id: data[x]?.product_id,
          seller_id: data[x]?.product_info?.seller_id,
          location_id: data[x]?.location_id,
          shiping_info_id: deliveryAddress?.shiping_info_id,
        });

        await axios
          .post(`${API_URL}/checkoutaddress`, cartD, {
            headers: {
              Authorization: `${token}`,
            },
          })
          .then((res) => {
            statuses.push(res?.data?.status);
            if (res?.data?.status == false) {
              toastMessageWarning({
                message: `${res?.data?.message}`,
              });
            }
          });
      }
      const warning = hasFalse(statuses);
      setLoading(false);

      if (warning === false) {
        paymentHandler();
      }
    }
  };

  const paymentHandler = async (e) => {
    setLoading(true);
    const cartItems = cartDetails?.result;
    if (addressDetails) {
      let productDetails = new FormData();
      productDetails.append('buyer_id', user?.buyer_id);
      productDetails.append('delivery_time', '');
      productDetails.append('total_amount', cartDetails?.sub_total);
      productDetails.append('order_discount', '');
      productDetails.append('platform_fee', cartDetails?.platform_fee);
      productDetails.append('paid_amount', cartDetails?.sub_total);
      productDetails.append('delivery_charge', cartDetails?.delivery_charge);
      productDetails.append('due_amount', 0);
      productDetails.append('order_from', 0);
      productDetails.append('order_option', 1);
      productDetails.append('order_status', 1);
      productDetails.append('payment_mode', 'razorpay');
      productDetails.append('shipping_id', deliveryAddress?.shiping_info_id);

      for (let x in cartItems) {
        productDetails.append(
          `order_items[${x}][product_id]`,
          cartItems?.[x]?.product_id
        );
        productDetails.append(
          `order_items[${x}][seller_id]`,
          cartItems?.[x]?.product_info?.seller_id
        );
        productDetails.append(
          `order_items[${x}][quantity]`,
          cartItems?.[x]?.product_qty
        );
        productDetails.append(
          `order_items[${x}][price]`,
          cartItems?.[x]?.unit_cost
        );
        productDetails.append(
          `order_items[${x}][total]`,
          cartItems?.[x]?.item_total
        );
        productDetails.append(
          `order_items[${x}][location_id]`,
          cartItems?.[x]?.location_id
        );
      }

      const response = await axios.post(
        `${API_URL}/generateorder`,
        productDetails,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setLoading(false);

      var options = {
        key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: cartDetails?.sub_total * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        // currency,
        currency: 'INR',
        name: 'Krushi', //your business name
        description: 'Test Transaction',
        // image: logo,
        order_id: response?.data?.orderid, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (res) {
          setFullPageLoader(true);
          // This function will be called on successful payment
          let paymentDetails = new FormData();
          paymentDetails.append(
            'razorpay_payment_id',
            res?.razorpay_payment_id
          );
          paymentDetails.append('orderid', response?.data?.orderid);
          paymentDetails.append('payment_mode', 'razorpay');
          paymentDetails.append('buyer_id', user?.buyer_id);
          paymentDetails.append('order_id', response?.data?.order_id);

          axios
            .post(`${API_URL}/paymentcallback`, paymentDetails, {
              headers: {
                Authorization: `${token}`,
              },
            })
            .then((res) => {
              if (res?.data?.status === false) {
                setFullPageLoader(false);
                toastMessageWarning({
                  message: `${res?.data?.message}`,
                });
              } else {
                setFullPageLoader(false);
                localStorage.setItem(
                  'orderId',
                  JSON.stringify(response?.data?.order_id)
                );
                history.push('/success');
              }
            });

          // console.log(res);
        },

        prefill: {
          // the prefill parameter to auto-fill customer's contact information, especially their phone number
          name: `${user?.first_name} ${user?.last_name}`, //your customer's name
          email: user?.buyer_email,
          contact: user?.buyer_mobile, // the customer's phone number for better conversion rates
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#f7991f',
        },
      };

      var rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        setFullPageLoader(false);
        rzp1.close();
        history.push('/failure');
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      rzp1.open();
      // e.preventDefault();
    } else {
      toastMessageWarning({
        message: `Please Add Delivery Address First !`,
      });
    }
  };

  return (
    <BuyerLayout>
      {/* <Loading
        loading={fullPageLoader}
        background="#F7991F"
        // background="transparent"
        loaderColor="#fefefe"
        style={{ zIndex: '10000' }}
        text="loading"
      /> */}
      {fullPageLoader === true ? (
        <Loading text={t('checkoutPage.loadingText')} />
      ) : (
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        >
          <>
            <div className="wrapper">
              <div className="innerWrapper">
                <Stack spacing={2} mt={2}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                      {t('breadCrumbs.home')}
                    </Link>
                    <Typography color="text.primary">
                      {t('breadCrumbs.checkout')}
                    </Typography>
                  </Breadcrumbs>
                </Stack>

                <h2 className="checkoutTitle">
                  {t('checkoutPage.billingDetails')}
                </h2>
                <div className="productDetailOuter">
                  <div className="filterContainer">
                    <div className="filterBox">
                      <div className="filterTitle">
                        <h4>{t('filterNames.filters')}</h4>
                        <div className="expand" onClick={handleExpand}>
                          {expand === true ? (
                            <i className="ri-add-line"></i>
                          ) : (
                            <i className="ri-subtract-line"></i>
                          )}
                        </div>
                      </div>
                      <div
                        className={`filterList ${
                          expand === true ? 'show' : 'hide'
                        }`}
                      >
                        <hr />
                        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4"> */}
                        {/*  Products */}
                        <div className="qna-container">
                          <label className="question" htmlFor="title">
                            {t('filterNames.products')}
                          </label>
                          <div className="answer">
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                value={selectedFilter?.category_id}
                                onChange={handleCategoryChange}
                                displayEmpty
                              >
                                {filterOptions?.product_category?.map(
                                  (option) => (
                                    <MenuItem
                                      value={option?.category_id}
                                      key={option?.category_id}
                                    >
                                      {option?.category_name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Sub Products */}
                        <div className="qna-container">
                          <label className="question" htmlFor="title">
                            {t('filterNames.subProduct')}
                          </label>
                          <div className="answer">
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                value={selectedFilter?.product_name}
                                onChange={handleProductSelect}
                                displayEmpty
                                disabled={
                                  filterOptions?.product_info?.length == 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  {t('filterNames.all')}
                                </MenuItem>
                                {filterOptions?.product_info?.map((option) => (
                                  <MenuItem
                                    value={option?.product_settings_id}
                                    key={option?.product_settings_id}
                                  >
                                    {option?.product_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* Variety  */}
                        <div className="qna-container">
                          <label className="question" htmlFor="title">
                            {t('filterNames.variety')}
                          </label>
                          <div className="answer">
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                value={selectedFilter?.product_variety}
                                onChange={handleVarietySelect}
                                displayEmpty
                                disabled={
                                  filterOptions?.product_variety?.length == 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  {t('filterNames.all')}
                                </MenuItem>
                                {filterOptions?.product_variety?.map(
                                  (option) => (
                                    <MenuItem
                                      value={option?.product_variety_id}
                                      key={option?.product_variety_id}
                                    >
                                      {option?.product_variety_name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* qualtiyOptions  */}
                        <div className="qna-container">
                          <label className="question" htmlFor="title">
                            {t('filterNames.quality')}
                          </label>
                          <div className="answer">
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                value={selectedFilter?.product_quality}
                                onChange={handleSelectedQuality}
                                displayEmpty
                                disabled={
                                  filterOptions?.product_quality?.length == 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  {t('filterNames.all')}
                                </MenuItem>
                                {filterOptions?.product_quality?.map(
                                  (option) => (
                                    <MenuItem
                                      value={option?.product_quality_id}
                                      key={option?.product_quality_id}
                                    >
                                      {option?.product_quality_name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* availbale  */}
                        <div className="qna-container">
                          <label className="question" htmlFor="title">
                            {t('filterNames.avail')}
                          </label>
                          <div className="answer">
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                value={selectedFilter?.product_available}
                                onChange={handleAvail}
                                displayEmpty
                                disabled={
                                  filterOptions?.product_available?.length == 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  {t('filterNames.all')}
                                </MenuItem>
                                {filterOptions?.product_available?.map(
                                  (option) => (
                                    <MenuItem
                                      value={option?.product_available_id}
                                      key={option?.product_available_id}
                                    >
                                      {option?.product_available_name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* productTypeOptions  */}
                        <div className="qna-container">
                          <label className="question" htmlFor="title">
                            {t('filterNames.productType')}
                          </label>
                          <div className="answer">
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                value={selectedFilter?.product_type}
                                onChange={handleSelectedType}
                                displayEmpty
                                disabled={
                                  filterOptions?.product_type?.length == 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  {t('filterNames.all')}
                                </MenuItem>
                                {filterOptions?.product_type?.map((option) => (
                                  <MenuItem
                                    value={option?.product_type_id}
                                    key={option?.product_type_id}
                                  >
                                    {option?.product_type_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                  {outerLoader === true ? null : (
                    <>
                      {cartDetails?.result?.length == 0 ? (
                        <div style={{ width: '80%' }}>
                          <EmptyCart2 />
                        </div>
                      ) : (
                        <div className="productInfo">
                          <div className="checkoutLeft">
                            <section className="checkoutSection">
                              {/* Billing */}
                              <div className="flex items-center justify-between">
                                <div className="titles">
                                  {t('checkoutPage.deliveryAddress')}
                                </div>
                                <Button
                                  // className="my-2"
                                  variant="contained"
                                  size="small"
                                  color="info"
                                  onClick={handleAddressOpen}
                                >
                                  {t('checkoutPage.editDeliveryAddress')}
                                </Button>
                              </div>
                              <hr />
                              <div className="billingContainerOuter">
                                {deliveryAddress?.buyer_short_address != '' &&
                                deliveryAddress?.buyer_short_address != null &&
                                deliveryAddress?.buyer_short_address !=
                                  undefined ? (
                                  <>
                                    <p style={{ fontWeight: 'bold' }}>
                                      {deliveryAddress?.buyer_name},{' '}
                                      {deliveryAddress?.buyer_mobile},{' '}
                                      {deliveryAddress?.buyer_short_address}
                                    </p>
                                    <p style={{ fontWeight: 'bold' }}>
                                      {t('checkoutPage.line1')}:{' '}
                                      {deliveryAddress?.buyer_address_1}
                                    </p>
                                    {deliveryAddress?.buyer_address_2 && (
                                      <p style={{ fontWeight: 'bold' }}>
                                        {t('checkoutPage.line2')}:{' '}
                                        {deliveryAddress?.buyer_address_2}
                                      </p>
                                    )}
                                    <p style={{ fontWeight: 'bold' }}>
                                      {deliveryAddress?.country_name},{' '}
                                      {deliveryAddress?.state_name},{' '}
                                      {deliveryAddress?.city_name},{' '}
                                      {deliveryAddress?.buyer_zip}
                                    </p>
                                  </>
                                ) : (
                                  <Button
                                    // className="my-2"
                                    variant="contained"
                                    size="Large"
                                    color="success"
                                    onClick={handleAddressOpen}
                                  >
                                    {t('checkoutPage.addDeliveryAddress')}
                                  </Button>
                                )}
                              </div>

                              <div className="titles">
                                {t('checkoutPage.product')}
                              </div>
                              <hr />

                              {/* product */}
                              {size.width >= 769 ? (
                                <div className="cartContainerOuter">
                                  <div className="cartOuter">
                                    <div
                                      className="cartProductInfoBox"
                                      style={{ width: '100%', marginLeft: '0' }}
                                    >
                                      <div className="grid items-center grid-cols-9 gap-1">
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.image')}
                                        </h3>
                                        <h3 className="col-span-2 bolderWeight">
                                          {t('checkoutPage.name')}
                                        </h3>
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.type')}
                                        </h3>
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.quality')}
                                        </h3>
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.price')}
                                        </h3>
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.quality')}
                                        </h3>
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.total')}
                                        </h3>
                                        <h3 className="bolderWeight">
                                          {t('checkoutPage.actions')}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  {cartDetails?.result?.map((cart) => (
                                    <CartProduct
                                      key={cart?.cart_id}
                                      setLoading={setLoading}
                                      cart_id={cart?.cart_id}
                                      setCartDetails={setCartDetails}
                                      storedAddress={deliveryAddress}
                                      cart={cart}
                                      cartImg={cart?.product_info?.image_thumb}
                                      sellerLocation={
                                        cart?.sellers?.[0]?.locations?.[0]
                                          ?.location_name
                                      }
                                      cartTitle2={`${
                                        cart?.product_info?.seller_name
                                      } ${
                                        cart?.product_info?.seller_last_name ==
                                        null
                                          ? ''
                                          : cart?.product_info?.seller_last_name
                                      } `}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <div className="cartContainerMobile">
                                  {cartDetails?.result?.map((cart) => (
                                    <CartProductMobile
                                      key={cart?.cart_id}
                                      setLoading={setLoading}
                                      cart_id={cart?.cart_id}
                                      setCartDetails={setCartDetails}
                                      storedAddress={deliveryAddress}
                                      cart={cart}
                                      cartImg={cart?.product_info?.image_thumb}
                                      sellerLocation={
                                        cart?.sellers?.[0]?.locations?.[0]
                                          ?.location_name
                                      }
                                      cartTitle2={`${
                                        cart?.product_info?.seller_name
                                      } ${
                                        cart?.product_info?.seller_last_name ==
                                        null
                                          ? ''
                                          : cart?.product_info?.seller_last_name
                                      } `}
                                    />
                                  ))}
                                </div>
                              )}

                              {/* payment */}
                            </section>
                          </div>

                          <div className="checkoutRight">
                            <div className="cartTotalContainer">
                              <h2>{t('checkoutPage.summary')}</h2>
                              <hr />
                              <div className="cartInfoContainer">
                                <div className="infoBox">
                                  <div className="infoTitle">
                                    {t('checkoutPage.subTotal')}
                                  </div>
                                  <div className="infoValue">
                                    ₹{' '}
                                    {cartDetails?.cart_total
                                      ? cartDetails?.cart_total
                                      : 0}
                                  </div>
                                </div>
                                <div className="infoBox">
                                  <div className="infoTitle">
                                    {t('checkoutPage.fee')}
                                  </div>
                                  <div className="infoValue">
                                    ₹{' '}
                                    {cartDetails?.platform_fee
                                      ? cartDetails?.platform_fee
                                      : 0}
                                  </div>
                                </div>
                                <div className="infoBox">
                                  <div className="infoTitle">
                                    {t('checkoutPage.shipping')}
                                  </div>
                                  <div className="infoValue">
                                    ₹ {cartDetails?.delivery_charge}
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="cartTotal">
                                <h3>{t('checkoutPage.grandTotal')}</h3>
                                <h2>
                                  ₹{' '}
                                  {cartDetails?.sub_total
                                    ? cartDetails?.sub_total
                                    : 0}
                                </h2>
                              </div>
                            </div>
                            <button
                              style={{ marginLeft: 'auto' }}
                              className="buttonWithIcon2 green"
                              onClick={checkAddress}
                              disabled={
                                cartDetails?.result?.length == 0 ? true : false
                              }
                            >
                              <p>{t('checkoutPage.place')}</p>
                              <i className="ri-shopping-cart-fill"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <AddressLists
                  handleAddressListClose={handleAddressClose}
                  addressListOpen={addressOpen}
                  setDeliveryAdress={setDeliveryAdress}
                  deliveryAddress={deliveryAddress}
                />

                {/* <Dialog onClose={handleCancelModal} open={openDel}>
        <WarningModal
          onConfirm={handleDeliveryLocation}
          onClose={handleCancelModal}
        />
      </Dialog> */}
              </div>
            </div>
          </>
        </LoadingOverlay>
      )}
    </BuyerLayout>
  );
};

export default Checkout;

import ReviewCard from './ReviewCard';
import './satisfaction.scss';

const CustomerSatisfaction = () => {
  return (
    <section className="satisfactionContainerOuter">
      <div className="satisfactionContainer">
        <ReviewCard />
      </div>
    </section>
  );
};

export default CustomerSatisfaction;

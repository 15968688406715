import { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import SubMenu from './SubMenu';
import { motion } from 'framer-motion';

// * React icons
import { SlSettings } from 'react-icons/sl';
import { AiOutlineAppstore } from 'react-icons/ai';
import { TbReportAnalytics } from 'react-icons/tb';
import { RiBuilding3Line } from 'react-icons/ri';
import { useMediaQuery } from 'react-responsive';
import { MdMenuOpen } from 'react-icons/md';
import { NavLink, useLocation } from 'react-router-dom';
import { IoIosLogOut } from 'react-icons/io';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { t } from 'i18next';
import AuthContext from 'context/AuthProvider';

const Sidebar = () => {
  let isTabletMid = useMediaQuery({ query: '(max-width: 768px)' });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();
  const { setToken, setAuth } = useContext(AuthContext);

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: '24rem',
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: '24rem',
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: '4rem',
          transition: {
            damping: 40,
          },
        },
      };

  const manageProductList = [
    {
      name: `${t('sellerMenu.manageProducts')}`,
      icon: RiBuilding3Line,
      // menus: ['config product', 'view products', 'zero quantity products'],
      menus: [
        { link: 'config product', name: `${t('sellerMenu.config')}` },
        { link: 'view products', name: `${t('sellerMenu.view')}` },
        { link: 'zero quantity products', name: `${t('sellerMenu.zero')}` },
      ],
    },
    {
      name: `${t('sellerMenu.manageOrders')}`,
      icon: TbReportAnalytics,
      menus: [
        { link: 'sales orders', name: `${t('sellerMenu.salesOrders')}` },
        {
          link: 'cancelled orders',
          name: `${t('sellerMenu.cancelledOrders')}`,
        },
        { link: 'return orders', name: `${t('sellerMenu.returnedOrders')}` },
        { link: 'sales transaction', name: `${t('sellerMenu.salesTrans')}` },
      ],
    },
  ];

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    setAuth('');
    setToken('');
    history.push('/');
    window.location.reload();
  };

  return (
    <>
      <div
        onClick={() => setOpen(false)}
        style={{ zIndex: '99' }}
        // className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${
        //   open ? 'block' : 'hidden'
        // } `}
        className={`md:hidden fixed inset-0  bg-black/50 ${
          open ? 'block' : 'hidden'
        } `}
      ></div>
      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? 'open' : 'closed'}
        // className=" bg-white text-gray shadow-xl z-[999] max-w-[16rem]  w-[16rem]
        //     overflow-hidden md:relative fixed
        //  h-screen "
        className=" bg-white text-gray shadow-xl z-[999] max-w-[24rem]  w-[24rem]
            overflow-hidden md:relative fixed"
      >
        <div className="flex flex-col mdHeight  md:h-full">
          <div className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 ">
            <div className="border-b border-slate-300 pb-1">
              <NavLink to={'/sellerHome'} className="link">
                <AiOutlineAppstore size={23} className="min-w-max" />
                {t('sellerMenu.home')}
              </NavLink>
            </div>

            {manageProductList?.map((menu) => (
              <div key={menu.name} className="flex flex-col gap-1">
                <SubMenu data={menu} />
              </div>
            ))}

            <div className="border-b border-slate-300 pb-1">
              <NavLink to={'/settings'} className="link">
                <SlSettings size={23} className="min-w-max" />
                {t('sellerMenu.manageProfile')}
              </NavLink>
            </div>

            <div className="border-b border-slate-300 pb-1">
              <div className="link" onClick={handleLogout}>
                <IoIosLogOut size={23} className="min-w-max" />
                Logout
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="my-2 mx-4 md:hidden" onClick={() => setOpen(true)}>
        <div className="sellerLogContainer">
          <MdMenuOpen size={25} />
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import ViewProduct from '../ViewProduct/ViewProduct';
import SellerLayout from 'layouts/SellerLayout';
import qs from 'qs';
import axios from 'hooks/axios';
import { useContext, useEffect, useState } from 'react';
import MyOrder from 'Components/MyOrders/MyOrder';
import { t } from 'i18next';
import AuthContext from 'context/AuthProvider';
import { useWindowSize } from '@uidotdev/usehooks';
import SalesOrders from 'Components/MobileTables/SalesOrders/SalesOrders';

const SellerDashboard = () => {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const authToken = JSON.parse(localStorage.getItem('token'));
  const { token } = useContext(AuthContext);
  const size = useWindowSize();
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [tiles, setTiles] = useState([]);
  const [salesOrders, setSalesOrders] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(true);
  const [fullData, setFullData] = useState([]);
  const [filters, setFilters] = useState({
    seller_id: user?.seller_id,
    page: 1,
    per_page: 10,
    search: '',
  });

  useEffect(() => {
    const tilesD = qs.stringify({
      seller_id: user?.seller_id,
    });

    axios
      .post(`${API_URL}/salesdashboard`, tilesD, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setTiles(res?.data?.result);
      });

    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/salesorders`, productsD, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result?.slice(0, 6));
        setFullPageLoader(false);
      });
  }, []);

  // // handleLoad more
  // const handleLoadMore = () => {
  //   setFilters({ ...filters, page: filters?.page + 1 });
  // };

  // useDidMountEffect(() => {
  //   setFullPageLoader(true);
  //   const productsD = qs.stringify({
  //     seller_id: filters?.seller_id,
  //     per_page: filters?.per_page,
  //     page: filters?.page,
  //     search: filters?.search,
  //   });

  //   axios
  //     .post(`${API_URL}/salesorders`, productsD, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       const oldArray = salesOrders;
  //       const moreItems = res?.data?.result;
  //       const newArray = [...oldArray, ...moreItems];
  //       setSalesOrders(newArray);
  //       setFullData(res?.data);
  //       setFullPageLoader(false);
  //     });
  // }, [filters?.page]);

  return (
    <SellerLayout>
      <div className="dashboardCardContainer">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="dashboardCard bg-gradient-default">
            <div className="mb-2">
              <span className="h3 text-white text-capitalize">
                {t('sellerDashboard.overallRevenue')}
              </span>
              <div>
                <span className="text-white mt-2 text-lg">
                  ₹ {tiles?.overall_revenue}
                </span>
              </div>
            </div>
          </div>
          <div className="dashboardCard bg-gradient-dark">
            <div className="mb-2">
              <span className="h3 text-white text-capitalize">
                {t('sellerDashboard.productSold')}
              </span>
              <div>
                <span className="text-white mt-2 text-lg">
                  {tiles?.products_sold}
                </span>
              </div>
            </div>
          </div>
          <div className="dashboardCard bg-gradient-red">
            <div className="mb-2">
              <span className="h3 text-white text-capitalize">
                {t('sellerDashboard.unitSold')}
              </span>
              <div>
                <span className="text-white mt-2 text-lg">
                  {tiles?.unts_sold}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-6">
        {size.width <= 769 ? (
          <SalesOrders
            salesOrders={salesOrders}
            loading={fullPageLoader}
            fullData={fullData}
            filters={filters}
            setFilters={setFilters}
            // handleLoadMore={handleLoadMore}
            page="home"
          />
        ) : (
          <MyOrder
            salesOrders={salesOrders}
            loading={fullPageLoader}
            fullData={fullData}
            filters={filters}
            setFilters={setFilters}
            // handleLoadMore={handleLoadMore}
            page="home"
          />
        )}
      </div>
      <ViewProduct page="home" />
    </SellerLayout>
  );
};

export default SellerDashboard;

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import './addressLists.scss';
import { Button } from '@mui/material';
import DeliveryAddress from './DeliveryAddress';
import ListOfAddress from './ListOfAddress';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddressLists({
  handleAddressListClose,
  addressListOpen,
  setDeliveryAdress,
  deliveryAddress,
}) {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);

  const [addressDetails, setAddressDetails] = useState([]);
  const [editName, setEditName] = useState('');
  const [addressOpen, setAddressOpen] = useState(false);
  const [address, setAdress] = useState({
    buyer_id: user?.buyer_id,
    buyer_name: `${user?.first_name} ${user?.last_name}`,
    address_label: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_mobile: user?.buyer_mobile,
    buyer_email: user?.buyer_email,
    buyer_country: '101',
    buyer_state: '',
    buyer_city: '',
    buyer_zip: '',
    shiping_info_id: '',
  });

  const handleAddressClose = () => {
    setAddressOpen(false);
    setAdress({
      buyer_id: user?.buyer_id,
      buyer_name: `${user?.first_name} ${user?.last_name}`,
      address_label: '',
      buyer_short_address: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_mobile: user?.buyer_mobile,
      buyer_email: user?.buyer_email,
      buyer_country: '101',
      buyer_state: '',
      buyer_city: '',
      buyer_zip: '',
      shiping_info_id: '',
    });
  };

  // buyer signUp dialouge
  const handleAddressOpen = () => {
    setEditName('Add');
    setAddressOpen(true);
  };

  useEffect(() => {
    const cartD = qs.stringify({
      buyer_id: user?.buyer_id,
    });

    axios
      .post(`${API_URL}/buyeraddress`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setAddressDetails(res?.data?.result);
      });
  }, []);

  return (
    <>
      <Dialog
        open={addressListOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAddressListClose}
        fullWidth="md"
        maxWidth="md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleAddressListClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="flex items-center justify-between mt-3">
              <div className="modal-header">
                <div className="loginTitle">
                  {t('buyerOrderPages.selectAddress')}
                </div>
              </div>
            </div>
            <hr />
            <Button
              style={{ marginTop: '1rem' }}
              variant="contained"
              size="small"
              color="success"
              onClick={handleAddressOpen}
            >
              {t('buyerOrderPages.addAddress')}
            </Button>
            {addressDetails?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                {addressDetails?.map((address) => (
                  <ListOfAddress
                    key={address?.shiping_info_id}
                    address={address}
                    deliveryAddress={deliveryAddress}
                    setDeliveryAdress={setDeliveryAdress}
                    handleAddressListClose={handleAddressListClose}
                  />
                ))}
              </div>
            ) : (
              <h2
                style={{
                  textAlign: 'center',
                  fontSize: '1.25rem',
                  padding: '1rem 0',
                }}
              >
                {t('buyerOrderPages.noList')} !
              </h2>
            )}
          </div>
        </div>
      </Dialog>
      {addressOpen && (
        <DeliveryAddress
          handleAddressClose={handleAddressClose}
          setAddressDetails={setAddressDetails}
          adressOpen={addressOpen}
          address={address}
          setAdress={setAdress}
          editName={editName}
        />
      )}
    </>
  );
}

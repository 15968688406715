import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

import { Button, TextField } from '@mui/material';
import './updateStatus.scss';
import { t } from 'i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CancelStatusModal = ({
  handleModalClose,
  modalOpen,
  handleCancel,
  cancelReason,
  setCancelReason,
  title,
}) => {
  return (
    <>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        fullWidth="md"
        maxWidth="md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleModalClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">{title}</div>
            </div>
            <hr />
            <div className="updateStatusContainer">
              <div className="reasonContainer">
                <div className="qna-container">
                  <label htmlFor="reason" className="question">
                    {t('orderModals.addCancelReason')}*
                  </label>
                  <TextField
                    id="reason"
                    name="reason"
                    type="text"
                    className="textField w-100"
                    placeholder={t('orderModals.addCancelReason')}
                    value={cancelReason}
                    onChange={(event) => setCancelReason(event.target.value)}
                  />
                </div>
              </div>
              <div className="updateDeliveryBtns">
                <div className="btnOuter">
                  <Button
                    style={{ width: '100%' }}
                    variant="contained"
                    size="large"
                    color="error"
                    onClick={handleModalClose}
                  >
                    {t('orderModals.close')}
                  </Button>
                </div>
                <div className="btnOuter2">
                  <Button
                    style={{ width: '100%' }}
                    variant="contained"
                    size="large"
                    color="success"
                    onClick={handleCancel}
                  >
                    {t('orderModals.cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CancelStatusModal;

import { ClipLoader } from 'react-spinners';
import './Loading.scss';
import footerLogo from 'assets/Logo_white.png';

const Loading = ({ text }) => {
  return (
    <div className="loaderOuterContainer">
      <div className="loader">
        <div className="logoContainer">
          <img src={footerLogo} alt="" />
        </div>
        <div className="textStyle">{text}</div>
        <ClipLoader color="#fff" size={50} />

        {/* <div className="blob-4"></div> */}
      </div>
    </div>
  );
};

export default Loading;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emptyCartContainer .image {
  width: 300px;
}
.emptyCartContainer .emptyCartCard {
  margin-top: 50px;
  border: none !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
}
.emptyCartContainer .emptyCartCard h1 {
  letter-spacing: 1.5px;
  margin-top: 50px !important;
}
.emptyCartContainer .emptyCartCard p {
  margin-top: 20px !important;
  font-size: 18px;
}
.emptyCartContainer .paddingRemove {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.emptyCartContainer .btn-orange {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5821f !important;
  width: 200px;
  height: 50px !important;
  color: white !important;
  border-radius: 50px !important;
  margin-top: 20px;
}
.emptyCartContainer .btn-orange span {
  margin-left: 0.5rem;
}
.emptyCartContainer .shpimg {
  width: 16px;
  position: relative;
  bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/Components/EmptyCart2/emptyCart.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAGE;EACE,gBAAA;EACA,uBAAA;EACA,wCAAA;EACA,aAAA;AADJ;AAEI;EACE,qBAAA;EACA,2BAAA;AAAN;AAEI;EACE,2BAAA;EACA,eAAA;AAAN;AAGE;EACE,4BAAA;EACA,6BAAA;AADJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,8BAAA;EACA,YAAA;EACA,uBAAA;EACA,uBAAA;EACA,8BAAA;EACA,gBAAA;AADJ;AAEI;EACE,mBAAA;AAAN;AAGE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;AADJ","sourcesContent":[".emptyCartContainer {\r\n  .image {\r\n    width: 300px;\r\n  }\r\n\r\n  .emptyCartCard {\r\n    margin-top: 50px;\r\n    border: none !important;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);\r\n    padding: 20px;\r\n    h1 {\r\n      letter-spacing: 1.5px;\r\n      margin-top: 50px !important;\r\n    }\r\n    p {\r\n      margin-top: 20px !important;\r\n      font-size: 18px;\r\n    }\r\n  }\r\n  .paddingRemove {\r\n    padding-left: 0px !important;\r\n    padding-right: 0px !important;\r\n  }\r\n  .btn-orange {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background: #f5821f !important;\r\n    width: 200px;\r\n    height: 50px !important;\r\n    color: white !important;\r\n    border-radius: 50px !important;\r\n    margin-top: 20px;\r\n    span {\r\n      margin-left: 0.5rem;\r\n    }\r\n  }\r\n  .shpimg {\r\n    width: 16px;\r\n    position: relative;\r\n    bottom: 2px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

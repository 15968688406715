// import { useEffect, useState } from 'react';

import CartContext from 'context/CartProvider';
import { useContext } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import { t } from 'i18next';
import AuthContext from 'context/AuthProvider';

const API_URL = process.env.REACT_APP_BACKEND_URL;
// const token = localStorage.getItem('token');
// const authToken = JSON.parse(token);

const ListOfAddress = ({
  address,
  setDeliveryAdress,
  deliveryAddress,
  handleAddressListClose,
}) => {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);

  const { setCartDetails } = useContext(CartContext);
  const { token } = useContext(AuthContext);

  const handleDeliveryAddressChange = () => {
    const cartD = qs.stringify({
      buyer_id: user?.buyer_id,
      shiping_info_id: address?.shiping_info_id,
    });
    axios
      .post(`${API_URL}/viewcart`, cartD, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setCartDetails(res?.data);
      });
    localStorage.setItem('deliveryAddress', JSON.stringify(address));
    localStorage.removeItem('zipCode');
    setDeliveryAdress(address);
    handleAddressListClose();
  };

  return (
    <>
      <label className="radioCard">
        <input
          name="plan"
          className="radio"
          type="radio"
          // value={address?.shiping_info_id}
          checked={
            (deliveryAddress?.shiping_info_id == address?.shiping_info_id) ==
            true
              ? true
              : false
          }
          onClick={handleDeliveryAddressChange}
        />
        <span className="plan-details">
          <span className="plan-type">{t('buyerOrderPages.location')}</span>
          <span className="plan-cost">
            <abbr className="plan-cycle" title="month">
              {address?.buyer_name}
            </abbr>
          </span>
          <span>{address?.buyer_mobile}</span>
          <span>{address?.buyer_short_address}</span>
          <span>
            {address?.buyer_address_1}

            {address?.buyer_address_2 && <>, {address?.buyer_address_2}</>}
          </span>
          <span>
            {address?.country_name}, {address?.state_name}, {address?.city_name}
            , {address?.buyer_zip}
          </span>
        </span>
      </label>
    </>
  );
};

export default ListOfAddress;

import './Success.scss';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import OrderedProduct from '../Cart/OrderedProduct';
import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import ShippingDetail from './ShippingDetail';
import OrderedProductMobile from '../Cart/OrderedProductMobile';
import { useWindowSize } from '@uidotdev/usehooks';
import BuyerLayout from 'layouts/BuyerLayout';
import { toastMessageWarning } from 'utils/toast';
import { toastMessageSuccess } from 'utils/toast';
import FormData from 'form-data';
import CancelStatusModal from 'Components/BuyerOrderModal/CancelStatusModal';
import { t } from 'i18next';

const BuyerOrderDetails = () => {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const [orderDetails, setOrderDetails] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(true);
  const { order_id } = useParams();
  const size = useWindowSize();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  //*Close modal
  const handleCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleCancelClose = () => {
    setCancelOpen(false);
    setCancelReason('');
  };

  useEffect(() => {
    const cartD = qs.stringify({
      buyer_id: user?.buyer_id,
      order_id: order_id,
    });

    axios
      .post(`${API_URL}/buyerorderdetails`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setOrderDetails(res?.data?.result);
        setFullPageLoader(false);
      });
  }, []);

  const handleEntireCancel = async () => {
    if (cancelReason !== '') {
      handleCancelClose();
      setFullPageLoader(true);
      let cartD = new FormData();
      cartD.append('order_id', order_id);
      cartD.append('order_status', 5);
      cartD.append('reason', cancelReason);
      cartD.append('user_id', user?.buyer_id);
      cartD.append('role', 'buyer');

      await axios
        .post(`${API_URL}/cancelorderstatus`, cartD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setFullPageLoader(false);

          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            const cartD = qs.stringify({
              buyer_id: user?.buyer_id,
              order_id: order_id,
            });

            axios
              .post(`${API_URL}/buyerorderdetails`, cartD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setOrderDetails(res?.data?.result);
              });
          }
        });
    } else {
      if (cancelReason === '') {
        toastMessageWarning({
          message: `Please add a reason why you are cancelling this product !`,
        });
      }
    }
  };

  return (
    <BuyerLayout>
      <div className="wrapper">
        <div className="innerWrapper">
          <Stack spacing={2} mt={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                {t('buyerOrderDetails.home')}
              </Link>
              <Link underline="hover" color="inherit" to="/myOrders">
                {t('buyerOrderDetails.myOrders')}
              </Link>
              <Typography color="text.primary">
                {t('buyerOrderDetails.orderDetail')}
              </Typography>
            </Breadcrumbs>
          </Stack>
          <section className="successSection">
            {fullPageLoader === true ? (
              <LoadingOverlay
                active={fullPageLoader}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: 'transparent',
                  }),
                  wrapper: {
                    overflow: 'hidden',
                  },
                }}
                spinner={<ClipLoader color="#F7991F" size={90} />}
              />
            ) : (
              <>
                <div className="successContainer">
                  <div className="leftContainer2">
                    <h2 className="orderTitle">
                      {t('buyerOrderDetails.orderId')} # <span>{order_id}</span>
                    </h2>
                    {orderDetails?.is_cancel_order === 0 && (
                      <button
                        className="loginBtn2 red"
                        style={{ width: '200px', margin: '0' }}
                        onClick={handleCancelOpen}
                      >
                        <p>{t('buyerOrderDetails.cancelOrder')}</p>
                      </button>
                    )}
                  </div>
                </div>
                <div className="productDetailOuter">
                  <div className="productInfo" style={{ width: '100%' }}>
                    <div className="checkoutLeft">
                      <section className="checkoutSection">
                        {size.width >= 769 ? (
                          <div
                            className="cartContainerOuter"
                            style={{ marginTop: '0' }}
                          >
                            <div className="cartOuter">
                              <div
                                className="cartProductInfoBox"
                                style={{ width: '100%', marginLeft: '0' }}
                              >
                                <div className="grid items-center grid-cols-12 gap-1">
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.Image')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.name')}
                                  </h3>
                                  <h3 className="bolderWeight text-center">
                                    {t('buyerOrderDetails.id')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.type')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.quality')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.unitPrice')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.quantity')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.location')}
                                  </h3>
                                  <h3 className="bolderWeight">
                                    {t('buyerOrderDetails.seller')}
                                  </h3>
                                  <h3 className="bolderWeight text-center">
                                    {t('buyerOrderDetails.total')}
                                  </h3>
                                  <h3 className="col-span-2 bolderWeight text-center">
                                    {t('buyerOrderDetails.status')}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            {orderDetails?.product_ino?.map((cart) => (
                              <OrderedProduct
                                key={cart?.product_id}
                                cart={cart}
                                cartImg={cart?.image_thumb}
                                order_id={order_id}
                                setFullPageLoader={setFullPageLoader}
                                setOrderDetails={setOrderDetails}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="cartContainerMobile">
                            {orderDetails?.product_ino?.map((cart) => (
                              <OrderedProductMobile
                                key={cart?.product_id}
                                cart={cart}
                                cartImg={cart?.image_thumb}
                                order_id={order_id}
                                setFullPageLoader={setFullPageLoader}
                                setOrderDetails={setOrderDetails}
                              />
                            ))}
                          </div>
                        )}
                      </section>
                    </div>
                    <div className="checkoutRight">
                      <div className="cartTotalContainer">
                        <h2>{t('buyerOrderDetails.summary')}</h2>
                        <hr />
                        <div className="cartInfoContainer">
                          <div className="infoBox">
                            <div className="infoTitle">
                              {t('buyerOrderDetails.subtotal')}
                            </div>
                            <div className="infoValue">
                              ₹{' '}
                              {orderDetails?.sub_total
                                ? orderDetails?.sub_total
                                : 0}
                            </div>
                          </div>
                          <div className="infoBox">
                            <div className="infoTitle">
                              {t('buyerOrderDetails.fee')}
                            </div>
                            <div className="infoValue">
                              ₹{' '}
                              {orderDetails?.platform_fee
                                ? orderDetails?.platform_fee
                                : 0}
                            </div>
                          </div>
                          <div className="infoBox">
                            <div className="infoTitle">
                              {t('buyerOrderDetails.shipping')}
                            </div>
                            <div className="infoValue">
                              ₹{' '}
                              {orderDetails?.delivery_charge
                                ? orderDetails?.delivery_charge
                                : 0}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="cartTotal">
                          <h3> {t('buyerOrderDetails.grand')}</h3>
                          <h2>
                            ₹{' '}
                            {orderDetails?.order_total
                              ? orderDetails?.order_total
                              : 0}
                          </h2>
                        </div>
                        {orderDetails?.total_refund_amount &&
                        orderDetails?.total_refund_amount > 0 ? (
                          <>
                            <div className="cartTotal">
                              <h3>{t('buyerOrderDetails.refunded')}</h3>
                              <h2>
                                -{' '}
                                {orderDetails?.total_refund_amount
                                  ? orderDetails?.total_refund_amount
                                  : 0}
                              </h2>
                            </div>
                            <div className="cartTotal">
                              <h3>{t('buyerOrderDetails.total')}</h3>
                              <h2>
                                ₹{' '}
                                {orderDetails?.order_total_after_refund
                                  ? orderDetails?.order_total_after_refund
                                  : 0}
                              </h2>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shippingContainer">
                  <h3 className="shippingTitle">
                    {t('buyerOrderDetails.statusHistory')}
                  </h3>
                  <hr />
                  <div className="my-6">
                    <ShippingDetail
                      status_histort={orderDetails?.status_histort}
                      loading={fullPageLoader}
                    />
                  </div>
                </div>
                <div className="shippingContainer">
                  <h3 className="shippingTitle">
                    {t('buyerOrderDetails.shippingAddress')}
                  </h3>
                  <hr />
                  <div className="addressinfo">
                    <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10">
                      <div className="infoList">
                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.buyerName')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.buyer_name}
                          </div>
                        </div>
                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.short')}
                          </div>
                          <div className="infoDetail">
                            {
                              orderDetails?.shipping_info?.[0]
                                ?.buyer_short_address
                            }
                          </div>
                        </div>

                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.country')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.country_name}
                          </div>
                        </div>
                      </div>
                      <div className="infoList">
                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.email')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.buyer_email}
                          </div>
                        </div>
                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.line')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.buyer_address_1}

                            {orderDetails?.shipping_info?.[0]
                              ?.buyer_address_2 && (
                              <>
                                ,{' '}
                                {
                                  orderDetails?.shipping_info?.[0]
                                    ?.buyer_address_2
                                }
                              </>
                            )}
                          </div>
                        </div>

                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.state')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.state_name}
                          </div>
                        </div>
                      </div>

                      <div className="infoList">
                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.phone')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.buyer_mobile}{' '}
                          </div>
                        </div>

                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.zip')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.buyer_zip}
                          </div>
                        </div>
                        <div className="infoDetail">
                          <div className="infoHeading">
                            {t('buyerOrderDetails.city')}
                          </div>
                          <div className="infoDetail">
                            {orderDetails?.shipping_info?.[0]?.city_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      </div>

      {cancelOpen && (
        <CancelStatusModal
          title={`${t('orderModals.cancelEntire')}`}
          handleModalClose={handleCancelClose}
          modalOpen={cancelOpen}
          handleCancel={handleEntireCancel}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
        />
      )}
    </BuyerLayout>
  );
};

export default BuyerOrderDetails;

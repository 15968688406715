import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import ShippingDetailTable from './ShippingDetailTable';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ShippingDetail = ({ status_histort, loading }) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '60vh' }} className="myPolicyOuterTable">
        <Table aria-label="customized table" stickyHeader size="small">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="left">Product Name</StyledTableCell> */}
              {/* <StyledTableCell align="left">Order Status</StyledTableCell> */}
              <StyledTableCell align="left">
                {t('sellerOrderDetails.UpdatedDate')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {t('sellerOrderDetails.message')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {status_histort?.length == 0 ? (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={10}
                    style={{
                      textAlign: 'center',
                      fontSize: '1.25rem',
                      padding: '2rem 0',
                    }}
                  >
                    {t('noData')}
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <>
                  {loading === true ? (
                    <LoadingOverlay
                      active={loading}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: 'transparent',
                        }),
                        wrapper: {
                          overflow: 'hidden',
                        },
                      }}
                      spinner={<ClipLoader color="#F7991F" size={90} />}
                    />
                  ) : (
                    <>
                      {status_histort?.map((order) => (
                        <ShippingDetailTable
                          order={order}
                          key={order?.order_status_id}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ShippingDetail;

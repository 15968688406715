import Header from 'Components/Header/Header';
// import BuyerLayout from './BuyerLayout';
import Sidebar from './sidebar';
import SellerFooter from 'Components/Footer/SellerFooter';

function SellerLayout({ children }) {
  return (
    <>
      <div className="headerWrapperOuter">
        <div className="wrapper">
          <div className="innerWrapper">
            <Header />
          </div>
        </div>
      </div>
      <div className="sellerOuterContainer">
        <Sidebar />
        <main className="sellerSectionOuter">
          <div className="sellerSection">{children}</div>
          <SellerFooter />
        </main>
      </div>
    </>
  );
}

export default SellerLayout;

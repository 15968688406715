import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { t } from 'i18next';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EditQuantityModal = ({
  editOpen,
  handleEditClose,
  handleEdit,
  reqValue,
  setReqValue,
  error,
}) => {
  const handleChange = (e) => {
    setReqValue(e.target.value);
  };

  return (
    <Dialog
      open={editOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleEditClose}
      // fullWidth="sm"
      maxWidth="sm"
      style={{
        borderRadius: '20px',
      }}
    >
      <div className="modal-body p-0">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleEditClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <div className="loginContainer">
          <div className="modal-header">
            <div className="loginTitle"> {t('checkoutPage.update')}</div>
          </div>
          <hr />
          <div className="inputsContainer">
            <div className="qna-container">
              <label htmlFor="req_qty" className="question">
                {t('checkoutPage.reqQty')}
              </label>
              <TextField
                id="req_qty"
                name="req_qty"
                min={1}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(evt) =>
                  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                }
                type="number"
                className="textField w-100"
                placeholder={t('checkoutPage.reqQty')}
                onChange={handleChange}
                value={reqValue}
                error={error && reqValue === '' ? true : false}
              />
            </div>

            <button className="loginBtn" onClick={handleEdit}>
              {t('checkoutPage.submit')}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditQuantityModal;

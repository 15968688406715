import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ZeroProductTable = ({ product, btnName }) => {
  const history = useHistory();

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const product_model = new Date(product?.product_model);
  const product_available_from = new Date(product?.product_available_from);
  // let productMonth = month[product_model?.getMonth() - 1];

  const handlePush = () => {
    history.push(`/zeroProduct/${product?.product_id}`);
  };

  return (
    <StyledTableRow>
      {/* <StyledTableCell component="th" scope="row">
        {product?.product_id}
      </StyledTableCell> */}
      <StyledTableCell align="left">{product?.product_name}</StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_type_name}
      </StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_variety_name}
      </StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_available_name}
      </StyledTableCell>

      <StyledTableCell align="left">
        {`${padL(product_available_from.getDate())}-${padL(
          product_available_from.getMonth() + 1
        )}-${product_available_from.getFullYear()}`}
        {/* <br />
        <span className="italicSpan">{product?.harvestPeriod}</span> */}
      </StyledTableCell>
      <StyledTableCell align="left">
        {/* {`${productMonth} - ${product_model?.getFullYear()}`} */}
        {`${
          month[product_model?.getMonth()]
            ? `${month[product_model?.getMonth()]} - `
            : ''
        }`}
        {`${product_model?.getFullYear()}`}
      </StyledTableCell>
      <StyledTableCell align="left">{product?.prod_units}</StyledTableCell>
      <StyledTableCell align="left">
        <span className="italicSpan">
          {product?.onsale_price && `₹ ${product?.onsale_price}`}{' '}
        </span>
        {product?.price_type == 2 && (
          <>
            {product?.prices?.map((price, index) => (
              <React.Fragment key={index}>
                <span className="italicSpan">
                  {price?.minimum} - {price?.maximum}
                  {product?.unit == 'Quintals'
                    ? 'Q'
                    : product?.unit == 'Ltrs'
                    ? 'L'
                    : product?.unit == 'Bags'
                    ? 'Bags'
                    : 'Bales'}{' '}
                  ₹{price?.price && ` ${price?.price}`}
                </span>
                <br />
              </React.Fragment>
            ))}
          </>
        )}

        {/* <br />
        <span className="italicSpan">{user?.availQty1}</span>  */}
      </StyledTableCell>
      <StyledTableCell align="left">
        {product?.product_quality_name}
      </StyledTableCell>
      <StyledTableCell align="left">
        <button className="greenBtn" onClick={handlePush}>
          <p>
            {btnName == 'edit'
              ? `${t('productListPage.edit')}`
              : `${t('productListPage.view')}`}
          </p>
        </button>
        {/* <button
          className="greenBtn ml-2"
          style={{ background: '#ff5252', color: 'white' }}
          onClick={handlePush}
        >
          <p>View</p>
        </button> */}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ZeroProductTable;

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IconButton, InputAdornment, TextField } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Login({
  handleLoginClose,
  loginOpen,
  handleSignUpOpen,
  handlePassOpen,
  userInfo,
  setUserInfo,
  handleOtpOpen,
  sName,
  otpInfo,
  setOtpInfo,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const API_URL = process.env.REACT_APP_BACKEND_URL;

  // const [showPassword, setShowPassword] = useState(false);

  // const handleTogglePassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const headers = {
    'Content-Type': 'application/json',
  };

  const phoneRegx = /^[6-9]\d{9}$/;

  // const [otpOpen, setOtpOpen] = useState(false);
  // const [otpInfo, setOtpInfo] = useState({
  //   otp: '',
  // });

  // const handleOtpClose = () => {
  //   setOtpOpen(false);
  // };

  // const handleOtpOpen = () => {
  //   setOtpOpen(false);
  // };

  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   if (
  //     userInfo?.phone !== '' &&
  //     userInfo?.loginPass !== '' &&
  //     phoneRegx.test(userInfo.phone) !== false
  //   ) {
  //     const loginD = qs.stringify({
  //       buyer_mobile: userInfo?.phone,
  //       password: userInfo?.loginPass,
  //     });

  //     axios.post(`${API_URL}/buyerlogin-form`, loginD, headers).then((res) => {
  //       if (res?.data?.status === false) {
  //         toastMessageWarning({
  //           message: `${res?.data?.message}`,
  //         });
  //       } else {
  //         toastMessageSuccess({
  //           message: `${res?.data?.message}`,
  //         });
  //         localStorage.setItem('token', JSON.stringify(res?.data?.token));
  //         handleLoginClose();
  //         localStorage.setItem('currentUser', sName);
  //         localStorage.setItem(
  //           'userInfo',
  //           JSON.stringify(res?.data?.result?.[0])
  //         );
  //         setUserInfo({});
  //         // setAuth(res?.data?.result?.[0]);
  //         window.location.reload();
  //       }
  //     });
  //   } else {
  //     if (userInfo.phone === '') {
  //       return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
  //     } else if (phoneRegx.test(userInfo.phone) === false) {
  //       return toastMessageWarning({
  //         message: 'Please fill valid mobile number!',
  //       });
  //     } else if (userInfo?.loginPass === '') {
  //       return toastMessageWarning({
  //         message: 'Password field cannot be empty',
  //       });
  //     }
  //   }
  // };

  const handleLoginOtp = (e) => {
    e.preventDefault();
    if (userInfo?.phone !== '' && phoneRegx.test(userInfo.phone) !== false) {
      const loginD = qs.stringify({
        buyer_mobile: userInfo?.phone,
      });

      axios.post(`${API_URL}/buyerloginotp`, loginD, headers).then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          handleLoginClose();
          handleOtpOpen();
          setUserInfo({});
          setOtpInfo({
            ...otpInfo,
            mobile: res?.data?.result?.buyer_mobile,
            sms_id: res?.data?.result?.sms_id,
          });
        }
      });
    } else {
      if (userInfo.phone === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(userInfo.phone) === false) {
        return toastMessageWarning({
          message: 'Please fill valid mobile number!',
        });
      }
    }
  };

  // const handleSellerLogin = (e) => {
  //   e.preventDefault();
  //   if (
  //     userInfo?.phone !== '' &&
  //     userInfo?.loginPass !== '' &&
  //     phoneRegx.test(userInfo.phone) !== false
  //   ) {
  //     const loginD = qs.stringify({
  //       seller_cnum: userInfo?.phone,
  //       password: userInfo?.loginPass,
  //     });

  //     axios.post(`${API_URL}/sellerlogin-form`, loginD, headers).then((res) => {
  //       if (res?.data?.status === false) {
  //         toastMessageWarning({
  //           message: `${res?.data?.message}`,
  //         });
  //       } else {
  //         toastMessageSuccess({
  //           message: `${res?.data?.message}`,
  //         });
  //         handleLoginClose();
  //         localStorage.setItem('token', JSON.stringify(res?.data?.token));
  //         localStorage.setItem('currentUser', sName);
  //         localStorage.setItem(
  //           'userInfo',
  //           JSON.stringify(res?.data?.result?.[0])
  //         );
  //         setUserInfo({});
  //         history.push('/sellerHome');
  //         window.location.reload();
  //       }
  //     });
  //   } else {
  //     if (userInfo.phone === '') {
  //       return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
  //     } else if (phoneRegx.test(userInfo.phone) === false) {
  //       return toastMessageWarning({
  //         message: 'Please fill valid mobile number!',
  //       });
  //     } else if (userInfo?.loginPass === '') {
  //       return toastMessageWarning({
  //         message: 'Password field cannot be empty',
  //       });
  //     }
  //   }
  // };

  const handleSellerLoginOtp = (e) => {
    e.preventDefault();
    if (userInfo?.phone !== '' && phoneRegx.test(userInfo.phone) !== false) {
      const loginD = qs.stringify({
        seller_cnum: userInfo?.phone,
      });

      axios.post(`${API_URL}/sellerloginotp`, loginD, headers).then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          handleLoginClose();
          handleOtpOpen();
          setOtpInfo({
            ...otpInfo,
            mobile: res?.data?.result?.seller_cnum,
            sms_id: res?.data?.result?.sms_id,
          });
          setUserInfo({});
        }
      });
    } else {
      if (userInfo.phone === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(userInfo.phone) === false) {
        return toastMessageWarning({
          message: 'Please fill valid mobile number!',
        });
      }
    }
  };

  const handleFormChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Dialog
        open={loginOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleLoginClose}
        // fullWidth="xs"
        maxWidth="xs"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleLoginClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">
                {sName == 'seller'
                  ? `${t('loginForms.seller')}`
                  : `${t('loginForms.buyer')}`}{' '}
                {t('loginForms.login')}
              </div>
            </div>
            <hr />
            <form
              className="inputsContainer"
              autoComplete="off"
              noValidate
              onSubmit={
                sName == 'seller' ? handleSellerLoginOtp : handleLoginOtp
              }
            >
              <div className="qna-container">
                <label htmlFor="phone" className="question">
                  {t('loginForms.phone')}
                </label>

                <TextField
                  id="phone"
                  name="phone"
                  min={0}
                  inputProps={{
                    maxLength: 10,
                    type: 'number',
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, e.target.maxLength);
                  }}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(evt) =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t('loginForms.phone')}
                  value={userInfo?.phone}
                  onChange={(event) => handleFormChange(event)}
                  className="textField w-100"
                />
              </div>
              {/* <div className="qna-container">
                <label htmlFor="loginPass" className="question">
                  {t('loginForms.password')}
                </label>

                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="loginPass"
                  name="loginPass"
                  placeholder={t('loginForms.password')}
                  value={userInfo?.loginPass}
                  onChange={(event) => handleFormChange(event)}
                  className="textField w-100"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div> */}

              {/* <div className="forgotPass" onClick={handlePassOpen}>
                {t('loginForms.forgotPass')}?
              </div> */}

              <button
                className="loginBtn"
                // onClick={sName == 'seller' ? handleSellerLogin : handleLogin}
              >
                {t('loginForms.sendOtp')}
              </button>

              <div className="signUpLink">
                <h4>
                  {t('loginForms.createAcc')}
                  <span onClick={handleSignUpOpen}>
                    {t('loginForms.signUpNow')}
                  </span>
                </h4>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useHistory } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TransactionTable = ({ order, index }) => {
  const userType = localStorage.getItem('currentUser');

  const history = useHistory();

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const created_on = new Date(order?.order_date);
  const updated_on = new Date(order?.updated_on);

  const handlePush = () => {
    {
      userType == 'buyer'
        ? history.push(`/orderDetails/${order?.order_id}`)
        : history.push(`/sellerOrderDetails/${order?.order_id}`);
    }
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="left">{index + 1}</StyledTableCell>
      <StyledTableCell
        align="left"
        onClick={handlePush}
        className="hoveredDataNavlink"
      >
        {/* {userType == 'seller' ? (
          <>{order?.sub_transaction_id ? order?.sub_transaction_id : '---'}</>
        ) : (
          <>{order?.order_id}</>
        )} */}
        {order?.order_id}
      </StyledTableCell>
      {userType == 'seller' && (
        <StyledTableCell align="left">{order?.buyer_name}</StyledTableCell>
      )}

      <StyledTableCell align="left">₹{order?.amount}</StyledTableCell>
      <StyledTableCell align="left">
        {`${padL(created_on.getDate())}-${padL(
          created_on.getMonth() + 1
        )}-${created_on.getFullYear()} ${padL(created_on.getHours())}:${padL(
          created_on.getMinutes()
        )}:${padL(created_on.getSeconds())}`}
      </StyledTableCell>
      {userType == 'seller' && (
        <StyledTableCell align="left">
          {order?.payment_gateway_id}
        </StyledTableCell>
      )}
      <StyledTableCell align="center">
        {order?.order_status_label}
      </StyledTableCell>
      <StyledTableCell align="left">
        {`${padL(updated_on.getDate())}-${padL(
          updated_on.getMonth() + 1
        )}-${updated_on.getFullYear()} ${padL(updated_on.getHours())}:${padL(
          updated_on.getMinutes()
        )}:${padL(updated_on.getSeconds())}`}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TransactionTable;

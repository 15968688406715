import './Success.scss';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import ContactContext from 'context/ContactProvider';
import BuyerLayout from 'layouts/BuyerLayout';
import { t } from 'i18next';

const Failure = () => {
  const { contactDetails } = useContext(ContactContext);

  const history = useHistory();
  const handleCont = () => {
    history.push(`/`);
  };

  useEffect(() => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);

  return (
    <BuyerLayout>
      {/* <Loading
        loading={fullPageLoader}
        background="#F7991F"
        // background="transparent"
        loaderColor="#fefefe"
        style={{ zIndex: '10000' }}
      /> */}
      <div className="successWrapper">
        <div className="successInner">
          {/* <div className="wrapper">
      <div className="innerWrapper"> */}
          <Stack spacing={2} mt={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                {t('breadCrumbs.home')}
              </Link>
              <Typography color="text.primary">
                {t('breadCrumbs.failure')}
              </Typography>
            </Breadcrumbs>
          </Stack>
          <section className="successSection">
            <div className="successContainer">
              <div className="leftContainer">
                <h2 className="orderTitle2"> {t('failurePage.failure')}.</h2>
                <h3>
                  {t('failurePage.sorry')} {contactDetails?.email}{' '}
                  {t('failurePage.call')} {contactDetails?.phone}.
                </h3>
                <h3 className="orderTitle">{t('failurePage.thanks')}!</h3>

                <button
                  className="buttonWithIcon green"
                  style={{ width: '300px' }}
                  onClick={handleCont}
                >
                  <p>{t('failurePage.continue')}</p>
                  <i className="ri-shopping-cart-fill"></i>
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </BuyerLayout>
  );
};

export default Failure;

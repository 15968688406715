import { Link, useHistory } from 'react-router-dom';
import footerLogo from '../../assets/Logo_white.png';
// import footerleft from '../../assets/footerwheatleft.png';
// import footerRight from '../../assets/footerwheatright.png';
import './_footer.scss';
import { useContext } from 'react';
import ContactContext from 'context/ContactProvider';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { contactDetails } = useContext(ContactContext);

  const handleHome = () => {
    history.push('/');
  };
  return (
    <section className="footerSection">
      {/* <div className="footerLeftImg">
        <img src={footerleft} alt="" />
      </div> */}

      {/* <div className="footerRightImg">
        <img src={footerRight} alt="" />
      </div> */}
      <div className="footerSectionInner">
        <div className="footerContainer">
          <div className="upperFooter">
            <div className="leftContainer">
              <div className="logoContainer" onClick={handleHome}>
                <img src={footerLogo} alt="" />
              </div>
            </div>
            <div className="rightContainer">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {/* first */}
                <div className=" list">
                  <div className="listTitle">{t('company')}</div>
                  <ul>
                    <li>
                      <Link to="/aboutUS">{t('aboutUs')}</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">{t('contactUs')}</Link>
                    </li>
                    <li>
                      <Link to="/products">{t('products')}</Link>
                    </li>
                  </ul>
                </div>
                {/* Second */}
                <div className="list">
                  <div className="listTitle">{t('legal')}</div>
                  <ul>
                    <li>{t('faq')}</li>
                    <li>
                      <Link to="/privacyPolicy">{t('privacy')}</Link>
                    </li>
                    <li>
                      <Link to="/terms">{t('terms')}</Link>
                    </li>
                  </ul>
                </div>
                {/* Third */}
                <div className="list">
                  <div className="listTitle">{t('contactUs')}</div>
                  <ul>
                    <li>
                      <i className="ri-map-pin-fill" />
                      {contactDetails?.address}
                    </li>
                    <li>
                      <i className="ri-phone-fill" />
                      <a href={`tel:${contactDetails?.phone}`}>
                        {contactDetails?.phone}
                      </a>
                    </li>
                    <li>
                      <i className="ri-mail-send-line" />
                      <a href={`mailto:${contactDetails?.email}`}>
                        {contactDetails?.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrightContainer">
        <div className="text"> &#169; 2024 - Krushi</div>
      </div>
    </section>
  );
};

export default Footer;

import SellerLayout from 'layouts/SellerLayout';
import EditProductPage from '../../Components/Seller/AddProduct/EditProductPage';

const EditProduct = () => {
  return (
    <SellerLayout>
      <EditProductPage />
    </SellerLayout>
  );
};

export default EditProduct;

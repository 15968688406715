import { Checkbox } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import { toastMessageSuccess } from 'utils/toast';
import { toastMessageWarning } from 'utils/toast';
import useDidMountEffect from 'hooks/useDidMountEffect';
import CartContext from 'context/CartProvider';

const TableInner = ({
  user,
  location,
  locations,
  inputNumb,
  locNumb,
  // cartId,
  setSelectedSeller,
  selectedSeller,
  users,
  setUsers,
  setDisable,
  zipCode,
  // cartDetails,
}) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const currentUser = localStorage.getItem('userInfo');
  const buyer = JSON.parse(currentUser);
  const [checkedStatus, setCheckedStatus] = useState(false);

  const [reqValue, setReqValue] = useState('');
  const { cartDetails, setCartDetails } = useContext(CartContext);

  const [cartId, setCartId] = useState('');
  let sellerArrayMain = selectedSeller;

  function removeDuplicates(array, key) {
    const seen = new Set();
    return array.filter((item) => {
      const identifier = key ? item[key] : JSON.stringify(item);
      if (seen.has(identifier)) {
        return false;
      } else {
        seen.add(identifier);
        return true;
      }
    });
  }

  useEffect(() => {
    let sellerArray = sellerArrayMain;
    let cartItem = cartDetails?.result;

    function locationExit(location_id) {
      return sellerArray.some(function (el) {
        return el.location_id === location_id;
      });
    }

    for (let x in cartItem) {
      if (
        cartItem?.[x]?.sellers?.[0]?.locations?.[0]?.location_id ===
        location?.location_id
      ) {
        let data = [...users];
        data[inputNumb].locations[locNumb].req_qty =
          cartItem?.[x]?.sellers?.[0]?.locations?.[0].req_qty;

        if (
          !locationExit(
            cartItem?.[x]?.sellers?.[0]?.locations?.[0]?.location_id
          )
        ) {
          sellerArray.push(cartItem?.[x]?.sellers?.[0]?.locations?.[0]);
        }

        setUsers(data);
      }
    }
    const uniqueArray = removeDuplicates(sellerArray, 'location_id');
    setSelectedSeller(uniqueArray);

    if (sellerArray?.length > 0) {
      if (users?.length > 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
  }, [location, cartDetails?.result]);

  useEffect(() => {
    cartDetails?.result?.filter(function (o1) {
      if (o1.location_id === location?.location_id) {
        setCartId(o1.cart_id);
      }
    });
  }, [cartDetails?.result]);

  const handleRemove = () => {
    const cartD = qs.stringify({
      buyer_id: buyer?.buyer_id,
      cart_id: cartId,
    });
    axios
      .post(`${API_URL}/deletefromcart`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        toastMessageSuccess({
          message: `${res?.data?.message}`,
        });

        const storedAddress = JSON.parse(
          localStorage.getItem('deliveryAddress')
        );
        const viewCartD = qs.stringify({
          buyer_id: buyer?.buyer_id,
          shiping_info_id: storedAddress?.shiping_info_id,
        });

        axios
          .post(`${API_URL}/viewcart`, viewCartD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            setCartDetails(res?.data);
          });
        // window.location.reload();
      });
  };

  const handleChange1 = (e) => {
    if (e.target.checked == false) {
      setReqValue('');
      setCheckedStatus(false);

      function removeObjectWithId(arr, location_id) {
        return arr.filter((obj) => obj.location_id !== location_id);
      }

      const newArr = removeObjectWithId(selectedSeller, location?.location_id);
      setSelectedSeller(newArr);
      if (cartId) {
        handleRemove();
      }
    }
  };

  const handleChange = async (e) => {
    if (zipCode == '' && zipCode == 0) {
      return toastMessageWarning({
        message: `Please check if products are delivered in your Pincode First !`,
      });
    } else {
      const qty = location?.quantity;
      setReqValue(e.target.value);
      let data = locations;
      data[locNumb][e.target.name] = e.target.value;
      if (e.target.value > 0) {
        setCheckedStatus(true);

        if (e.target.value > qty) {
          toastMessageWarning({
            message: `Entered  Req Qty Can't be greater than available quantity!`,
          });
          setDisable(true);
        } else {
          setDisable(false);
          let arraytoPush = [];
          for (let x in data) {
            if (
              data[x]?.req_qty != 0 &&
              data[x]?.req_qty != undefined &&
              data[x]?.req_qty != '' &&
              data[x]?.req_qty != null
            ) {
              arraytoPush.push(data[x]);
            }
          }

          let newArr = await selectedSeller.concat(arraytoPush);

          await setSelectedSeller(newArr);

          // let data = [...users];
          // data[inputNumb][e.target.name] = e.target.value;
          // setUsers(data);
        }
      } else {
        setReqValue('');
        setCheckedStatus(false);

        function removeObjectWithId(arr, location_id) {
          return arr.filter((obj) => obj.location_id !== location_id);
        }

        const newArr = removeObjectWithId(
          selectedSeller,
          location?.location_id
        );
        await setSelectedSeller(newArr);
        if (cartId) {
          handleRemove();
        }
      }
    }
  };

  useEffect(() => {
    if (location?.req_qty != '' && location?.req_qty != null) {
      setCheckedStatus(true);
      setReqValue(location?.req_qty);
    } else {
      setCheckedStatus(false);
    }
  }, [location]);

  return (
    <div className="flex items-center">
      <div className="qna-container" style={{ padding: '0' }}>
        <div className="answer">
          <input
            id="price"
            type="number"
            name="req_qty"
            className="textField"
            style={{ minWidth: '150px', background: 'white' }}
            // placeholder={`${t('productDetailPage.qtyIn')} in ${
            //   user?.unit == 'Quintals'
            //     ? 'Q'
            //     : user?.unit == 'Ltrs'
            //     ? 'L'
            //     : user?.unit == 'Bags'
            //     ? 'Bags'
            //     : 'Bales'
            // }`}
            placeholder={`Req qty in ${
              user?.unit == 'Quintals'
                ? 'Q'
                : user?.unit == 'Ltrs'
                ? 'L'
                : user?.unit == 'Bags'
                ? 'Bags'
                : 'Bales'
            }`}
            min={1}
            max={location?.quantity}
            value={reqValue}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <Checkbox
        style={{ marginLeft: '1rem' }}
        checked={checkedStatus == true ? true : false}
        onChange={handleChange1}
        disabled={reqValue == '' ? true : false}
        sx={{
          color: '#f7991f',
          '&.Mui-checked': {
            color: '#f7991f',
          },
        }}
      />
    </div>
  );
};

export default TableInner;

import BuyerLayout from 'layouts/BuyerLayout';
import ContactContext from 'context/ContactProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyPage = () => {
  const { contactDetails } = useContext(ContactContext);
  const { t } = useTranslation();

  return (
    <BuyerLayout>
      <div className="bg-gray-100">
        {/* <div className="wrapper">
          <div className="innerWrapper"> */}
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-1xl header-text md:text-3xl font-bold mb-4 pt-4">
            {t('privacyPolicy.privacyPolicy')}
          </h1>

          {/* <p className="mb-4 font-medium">Privacy Policy for KRUSHI</p> */}

          {/* intro */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.introduction')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            {/* <li>{t('privacyPolicy.policyDescription')}</li> */}
            {t('privacyPolicy.policyDescription')}
          </ul>

          {/* info we collect */}

          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.infoCollected')}
          </h2>

          {/* <p className="mb-4">We may collect the following information:</p> */}

          <ul className="list-disc list-inside mb-4">
            <li>{t('privacyPolicy.informationDescription1')}</li>
            <li>{t('privacyPolicy.informationDescription2')}</li>
            <li>{t('privacyPolicy.informationDescription3')}</li>
          </ul>

          {/*how we use info we collect */}

          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.howInfo')}
          </h2>

          {/* <p className="mb-4">
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p> */}

          <ul className="list-disc list-inside mb-4">
            <li>{t('privacyPolicy.usageDescription1')}</li>
            <li>{t('privacyPolicy.usageDescription2')}</li>
            <li>{t('privacyPolicy.usageDescription3')}</li>
          </ul>

          {/* sharing */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.sharingInfo')}
          </h2>

          {/* <p className="mb-4">
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p> */}

          <ul className="list-disc list-inside mb-4">
            <li>{t('privacyPolicy.sharingDescription1')}</li>
            <li>{t('privacyPolicy.sharingDescription2')}</li>
            <li>{t('privacyPolicy.sharingDescription3')}</li>
          </ul>

          {/* data security */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.dataSecurity')}
          </h2>

          {/* <p className="mb-4">
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic, and managerial procedures to
            safeguard and secure the information we collect online.
          </p> */}
          <ul className="list-disc list-inside mb-4">
            <li>{t('privacyPolicy.securityDescription1')}</li>
            <li>{t('privacyPolicy.securityDescription2')}</li>
          </ul>

          {/* rights */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.yourRights')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('privacyPolicy.rightsDescription1')}</li>
            <li>{t('privacyPolicy.rightsDescription2')}</li>
          </ul>

          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.changePolicy')}
          </h2>

          <p className="mb-4 font-medium">
            {t('privacyPolicy.changesDescription')}
          </p>

          <h2 className="text-2xl header-text font-bold mb-2">
            {t('privacyPolicy.contactUs')}
          </h2>

          <p className="mb-4 font-medium">
            {t('privacyPolicy.contactDesc')}
            <a
              href={`mailto: ${contactDetails?.email}`}
              className="text-underline"
            >
              {contactDetails?.email}
            </a>
          </p>

          {/* <p className="pb-4">
            This privacy policy is subject to change without notice.
          </p> */}
        </div>
        {/* </div>
        </div> */}
      </div>
    </BuyerLayout>
  );
};

export default PrivacyPolicyPage;

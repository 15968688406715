import './showcase.scss';
// import showCase from '../../../assets/show.jpg';
// import showCase from '../../../assets/show22.jpg';
import showCase from '../../../assets/LongImg1.jpg';
// import showCase from '../../../assets/LongImg2.jpg';
import showCase1 from '../../../assets/slider11.jpg';
import showCase2 from '../../../assets/show2.jpg';
import showCase4 from '../../../assets/show4.jpg';
import ShowCaseImg from './ShowCaseImg';

const ShowCaseSection = () => {
  return (
    <section className="showcaseSection">
      <div className="showcase">
        <div className="showcase__left">
          <ShowCaseImg img={showCase} alt="showcase" />
        </div>
        <div className="showcase__right">
          {/* top */}
          <div className="showcase__right__top">
            <div className="showcase__right__top__left">
              <ShowCaseImg img={showCase1} alt="showcase" />
            </div>
            <div className="showcase__right__top__right">
              <ShowCaseImg img={showCase2} alt="showcase" />
            </div>
          </div>
          {/* bottom */}
          <div className="showcase__right__bottom">
            <div className="showcase__right__bottom__left">
              <ShowCaseImg img={showCase4} alt="showcase" />
            </div>
            <div className="showcase__right__bottom__right">
              <ShowCaseImg
                img="https://media.istockphoto.com/id/1284409357/photo/young-indian-farmer-showing-money-and-talking-on-mobile-phone-at-home.jpg?s=2048x2048&w=is&k=20&c=2cL0PFEQ9gY5lNVZGVgqSMhWXpB7qd8CzyumiEzm7RI="
                alt="showcase"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowCaseSection;

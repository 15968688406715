import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

import { Button, TextField } from '@mui/material';
import './updateStatus.scss';
import ImageUploading from 'react-images-uploading';
import ClearIcon from '@mui/icons-material/Clear';
import { useWindowSize } from '@uidotdev/usehooks';
import { t } from 'i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UpdateStatusModal = ({
  handleModalClose,
  modalOpen,
  handleDeliveredFine,
  handleReturn,
  setStep,
  step,
  images,
  setImages,
  reason,
  setReason,
}) => {
  const size = useWindowSize();

  const maxNumber = 5;
  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        fullWidth="md"
        maxWidth="md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleModalClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">
                {t('orderModals.deliveryStatus')}
              </div>
            </div>

            <hr />
            <div className="updateStatusContainer">
              {step === 1 && (
                <div className="updateDeliveryBtns">
                  <div className="btnOuter">
                    <Button
                      style={{ width: '100%' }}
                      variant="contained"
                      size={size.width <= 769 ? 'small' : 'large'}
                      color="success"
                      disabled={step > 1 ? true : false}
                      onClick={handleDeliveredFine}
                    >
                      {t('orderModals.expected')}
                    </Button>
                  </div>
                  <div className="btnOuter2">
                    <Button
                      style={{ width: '100%' }}
                      variant="contained"
                      size={size.width <= 769 ? 'small' : 'large'}
                      color="error"
                      disabled={step > 1 ? true : false}
                      onClick={() => setStep(step + 1)}
                    >
                      {t('orderModals.notExpected')}
                    </Button>
                  </div>
                </div>
              )}

              {step > 1 && (
                <>
                  <div className="reasonContainer">
                    <div className="qna-container">
                      <label htmlFor="first_name" className="question">
                        {t('orderModals.addReturnReason')}*
                      </label>
                      <TextField
                        id="reason"
                        name="reason"
                        type="text"
                        className="textField w-100"
                        placeholder={t('orderModals.addReturnReason')}
                        value={reason}
                        onChange={(event) => setReason(event.target.value)}
                      />
                    </div>
                    <div className="imageContainer">
                      <div className="imagesTitle">
                        {t('orderModals.uploadReturnImg')}
                      </div>
                      {/* image container */}
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChangeImage}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        // className="mb-4"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          // onImageUpdate,
                          onImageRemove,
                          // isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            {/* <div className="grid grid-cols-1  md:grid-cols-2 gap-2 md:gap-12 mb-2"> */}
                            <Button
                              style={{ marginTop: '.5rem' }}
                              variant="contained"
                              size="small"
                              color="warning"
                              // style={isDragging ? { color: 'red' } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              {t('orderModals.uploadImg')}
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              style={{ marginTop: '.5rem' }}
                              variant="contained"
                              size="small"
                              color="error"
                              onClick={onImageRemoveAll}
                              disabled={imageList?.length == 0 ? true : false}
                            >
                              {t('orderModals.removeAll')}
                            </Button>
                            {/* </div> */}
                            <div className="grid grid-cols-1  place-items-center md:grid-cols-2  gap-1 md:gap-2 mt-1">
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={image['data_url']}
                                    alt=""
                                    width="100"
                                  />
                                  <div className="image-item__btn-wrapper">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="error"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <ClearIcon />
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                  <div className="updateDeliveryBtns">
                    <div className="btnOuter">
                      <Button
                        style={{ width: '100%' }}
                        variant="contained"
                        size="large"
                        color="error"
                        onClick={handleModalClose}
                      >
                        {t('orderModals.close')}
                      </Button>
                    </div>
                    <div className="btnOuter2">
                      <Button
                        style={{ width: '100%' }}
                        variant="contained"
                        size="large"
                        color="success"
                        onClick={handleReturn}
                      >
                        {t('orderModals.returnProduct')}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UpdateStatusModal;

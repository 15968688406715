import './relevantCard.scss';
import { Link } from 'react-router-dom';

const RelevantCard = ({ img, title, product_id, product_settings_id }) => {
  return (
    <div className="relevantCardOuter">
      <Link to={`/featureProduct/${product_id}/${product_settings_id}`}>
        <div className="featureProductImg">
          <img src={img} alt="featured product image" />
          <div className="infoContainer">
            <div className="producTitle">{title}</div>
            <div className="productButton">
              <button className="normalbtn">View Details</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RelevantCard;

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useHistory } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const MyOrdersTable = ({ order, index, page }) => {
  const userType = localStorage.getItem('currentUser');

  const history = useHistory();

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const order_date = new Date(order?.order_date);

  const handlePush = () => {
    {
      userType == 'buyer'
        ? history.push(`/orderDetails/${order?.order_id}`)
        : page == 'cancel'
        ? history.push(`/cancelOrderDetails/${order?.order_id}`)
        : page == 'return'
        ? history.push(`/returnedOrderDetails/${order?.order_id}`)
        : history.push(`/sellerOrderDetails/${order?.order_id}`);
    }
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="left">{index + 1}</StyledTableCell>
      <StyledTableCell
        align="left"
        onClick={handlePush}
        className="hoveredDataNavlink"
      >
        {order?.order_id}
      </StyledTableCell>
      <StyledTableCell align="left">₹{order?.amount}</StyledTableCell>
      {userType == 'seller' && (
        <StyledTableCell align="left">{order?.buyer_name}</StyledTableCell>
      )}
      <StyledTableCell align="left">
        {`${padL(order_date.getDate())}-${padL(
          order_date.getMonth() + 1
        )}-${order_date.getFullYear()} ${padL(order_date.getHours())}:${padL(
          order_date.getMinutes()
        )}:${padL(order_date.getSeconds())}`}
      </StyledTableCell>
      {userType == 'seller' && (
        <StyledTableCell align="left">
          {order?.payment_gateway_id}
        </StyledTableCell>
      )}
      <StyledTableCell align="center">
        {order?.order_items?.length}
      </StyledTableCell>
      <StyledTableCell align="center">
        {order?.order_status_label}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default MyOrdersTable;

import { t } from 'i18next';

import ViewProductsTable from './ViewProductsTable';
import { Card, Typography } from '@mui/material';

const ViewProducts = ({ page, products }) => {
  return (
    <Card>
      {page === 'home' && (
        <Typography
          gutterBottom
          variant="subtitle1"
          className="px-3 pt-2"
          component="div"
          style={{
            borderBottom: '1px solid #9f9e9e',
            fontWeight: '700',
          }}
        >
          {t('sellerMenu.view')}
        </Typography>
      )}
      <div className="mobileTableBoxOuter p-2">
        <div className="mobileTableRow">
          {products?.length == 0 ? (
            <div className="mobileNoData">{t('noViewProduct')}</div>
          ) : (
            <>
              {products?.map((product, index) => (
                <ViewProductsTable
                  product={product}
                  key={product?.product_id}
                  index={index}
                  page={page}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ViewProducts;

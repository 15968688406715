import './orderedProducts.scss';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UpdateStatusModal from 'Components/BuyerOrderModal/UpdateStatusModal';
import { toastMessageWarning } from 'utils/toast';
import { toastMessageSuccess } from 'utils/toast';
import FormData from 'form-data';
import qs from 'qs';
import axios from 'hooks/axios';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import ViewImages from 'Components/ProductDetail/ViewImages';
import ShippingInfo from './ShippingInfo';
import Compress from 'compress.js';
import CancelStatusModal from 'Components/BuyerOrderModal/CancelStatusModal';
import useDidMountEffect from 'hooks/useDidMountEffect';
import DispatchModal from 'Components/Seller/DispatchModal';
import { t } from 'i18next';

const OrderedProductMobile = ({
  cartImg,
  cart,
  cartTitle2,
  order_id,
  setOrderDetails,
}) => {
  const unit = cart?.unit;
  const history = useHistory();

  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);

  const handleOpenProduct = () => {
    history.push(`/productDetail/${cart?.product_id}`);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [images, setImages] = useState([]);
  const [compImgs, setCompImgs] = useState([]);
  const [size, setSize] = useState(0);
  const [reason, setReason] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [loading, setLoading] = useState(false);

  const [trackModal, setTrackModal] = useState(false);
  const handleTrackOpen = () => {
    setTrackModal(true);
  };
  const handleTrackClose = () => {
    setTrackModal(false);
  };

  const [imageModal, setImageModal] = useState(false);
  // image dialogue
  const handleImageModalOpen = () => {
    setImageModal(true);
  };
  const handleImgModalClose = () => {
    setImageModal(false);
  };
  //* return modal
  const handleModalOpen = () => {
    setModalOpen(true);
    setStep(1);
  };

  const handleModalOpen2 = () => {
    setModalOpen(true);
    setStep(2);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setStep(1);
    setReason('');
    setImages([]);
  };

  //*Close modal
  const handleCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleCancelClose = () => {
    setCancelOpen(false);
    setCancelReason('');
  };

  const handleDeliveredFine = async () => {
    handleModalClose();
    setLoading(true);
    const cartD = qs.stringify({
      product_id: cart?.product_id,
      location_id: cart?.location_id,
      order_id: order_id,
      order_status: 4,
      user_id: user?.buyer_id,
      role: 'buyer',
    });

    await axios
      .post(`${API_URL}/updateorderstatus`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          const cartD = qs.stringify({
            buyer_id: user?.buyer_id,
            order_id: order_id,
          });

          axios
            .post(`${API_URL}/buyerorderdetails`, cartD, {
              headers: {
                Authorization: `${authToken}`,
              },
            })
            .then((res) => {
              setOrderDetails(res?.data?.result);
            });
        }
      });
  };

  const compressImgs = () => {
    const compress = new Compress();
    let files = [];
    for (var i = 0, j = images?.length; i < j; i++) {
      files.push(images[i]?.file);
    }

    compress
      .compress(files, {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.5, // the quality of the image, max is 1,
        resize: false, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
      })
      .then((results) => {
        // returns an array of compressed images
        const imgs = [];

        for (var i = 0, j = results?.length; i < j; i++) {
          const img1 = results[i];
          const base64str = img1.data;
          const imgExt = img1.ext;
          const imgName = img1.alt;
          const blobFile = Compress.convertBase64ToFile(base64str, imgExt);
          var imgFile = new File([blobFile], imgName);
          imgs.push(imgFile);
        }

        setCompImgs(imgs);
      });
  };

  useEffect(() => {
    compressImgs();
  }, [images]);

  useEffect(() => {
    let sum = 0;

    for (let i = 0; i < compImgs.length; i++) {
      if (compImgs[i]?.size) {
        sum += parseInt(compImgs[i]?.size);
      }
    }
    setSize(sum);
  }, [compImgs]);

  const handleReturn = async () => {
    if (reason !== '' && compImgs?.length >= 1) {
      if (size >= 1048576) {
        toastMessageWarning({
          message: `Product Images exceeded the size of 1mb, Please add appropriate images !`,
        });
      } else {
        handleModalClose();
        setLoading(true);
        let cartD = new FormData();
        cartD.append('product_id', cart?.product_id);
        cartD.append('location_id', cart?.location_id);
        cartD.append('order_id', order_id);
        cartD.append('order_status', 7);
        cartD.append('reason', reason);
        cartD.append('user_id', user?.buyer_id);
        cartD.append('role', 'buyer');

        for (let x in compImgs) {
          cartD.append(`product_gallery[${x}]`, compImgs?.[x]);
        }

        await axios
          .post(`${API_URL}/updateorderstatus`, cartD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            setLoading(false);

            if (res?.data?.status === false) {
              toastMessageWarning({
                message: `${res?.data?.message}`,
              });
            } else {
              toastMessageSuccess({
                message: `${res?.data?.message}`,
              });
              const cartD = qs.stringify({
                buyer_id: user?.buyer_id,
                order_id: order_id,
              });

              axios
                .post(`${API_URL}/buyerorderdetails`, cartD, {
                  headers: {
                    Authorization: `${authToken}`,
                  },
                })
                .then((res) => {
                  setOrderDetails(res?.data?.result);
                });
            }
          });
      }
    } else {
      if (reason === '') {
        toastMessageWarning({
          message: `Please add a reason why you are returning this product !`,
        });
      } else {
        toastMessageWarning({
          message: `Please attach some pictures of returned products !`,
        });
      }
    }
  };

  const handleCancel = async () => {
    if (cancelReason !== '') {
      handleCancelClose();
      setLoading(true);
      let cartD = new FormData();
      cartD.append('product_id', cart?.product_id);
      cartD.append('location_id', cart?.location_id);
      cartD.append('order_id', order_id);
      cartD.append('order_status', 5);
      cartD.append('reason', cancelReason);
      cartD.append('canceled_by', 'buyer');
      cartD.append('user_id', user?.buyer_id);
      cartD.append('role', 'buyer');

      await axios
        .post(`${API_URL}/updateorderstatus`, cartD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setLoading(false);

          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            const cartD = qs.stringify({
              buyer_id: user?.buyer_id,
              order_id: order_id,
            });

            axios
              .post(`${API_URL}/buyerorderdetails`, cartD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setOrderDetails(res?.data?.result);
              });
          }
        });
    } else {
      if (cancelReason === '') {
        toastMessageWarning({
          message: `Please add a reason why you are cancelling this product !`,
        });
      }
    }
  };

  const [dispatchModal, setDispatchModal] = useState(false);
  const [startDate, setStartDate] = useState('');

  function DateConverter(date) {
    var dd = String(date?.getDate()).padStart(2, '0');
    var mm = String(date?.getMonth() + 1).padStart(2, '0');
    var yyyy = date?.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  const [shippingInfo, setShippingInfo] = useState({
    shipping_name: '',
    shipping_no: '',
    exp_delivery_date: '',
  });
  // dispatch dialogue
  const handleDispatchOpen = () => {
    setDispatchModal(true);
  };

  const handleDispatchClose = () => {
    setDispatchModal(false);
    setStartDate('');
    setShippingInfo({
      shipping_name: '',
      shipping_no: '',
      exp_delivery_date: '',
    });
  };
  const handleOrderStatus = async () => {
    setLoading(true);
    const cartD = qs.stringify({
      product_id: cart?.product_id,
      location_id: cart?.location_id,
      order_id: order_id,
      order_status: 8,
      user_id: user?.buyer_id,
      role: 'buyer',
    });

    await axios
      .post(`${API_URL}/updateorderstatus`, cartD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);

        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          const cartD = qs.stringify({
            buyer_id: user?.buyer_id,
            order_id: order_id,
          });

          axios
            .post(`${API_URL}/buyerorderdetails`, cartD, {
              headers: {
                Authorization: `${authToken}`,
              },
            })
            .then((res) => {
              setOrderDetails(res?.data?.result);
            });
        }
      });
  };

  const handleShippingInfo = async () => {
    if (
      shippingInfo?.shipping_name !== '' &&
      shippingInfo?.shipping_no !== '' &&
      shippingInfo?.quantity !== '' &&
      startDate !== undefined &&
      startDate !== null &&
      startDate !== ''
    ) {
      if (shippingInfo?.quantity > cart?.order_qty) {
        toastMessageWarning({
          message: `Entered Returned Quantity Can't be greater than ordered quantity (${
            cart?.order_qty
          }   ${
            unit == 'Quintals'
              ? 'Quintals'
              : unit == 'Ltrs'
              ? 'Ltrs'
              : unit == 'Bags'
              ? 'Bags'
              : 'Bales'
          })!`,
        });
      } else {
        const shipD = qs.stringify({
          order_details_id: cart?.order_details_id,
          order_id: order_id,
          seller_id: cart?.seller_id,
          shipping_name: shippingInfo?.shipping_name,
          shipping_no: shippingInfo?.shipping_no,
          exp_delivery_date: shippingInfo?.exp_delivery_date,
          quantity: shippingInfo?.quantity,
          user_id: user?.buyer_id,
          role: 'buyer',
        });

        await axios
          .post(`${API_URL}/saveprodtracking`, shipD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            if (res?.data?.status === false) {
              toastMessageWarning({
                message: `${res?.data?.message}`,
              });
            } else {
              toastMessageSuccess({
                // message: `${res?.data?.message}`,
                message: `Shipping Info Added !`,
              });
              handleDispatchClose();
              handleOrderStatus();
            }
          });
      }
    } else {
      toastMessageWarning({ message: `Please fill all the fields first.` });
    }
  };

  useDidMountEffect(() => {
    setShippingInfo({
      ...shippingInfo,
      exp_delivery_date: DateConverter(startDate?.$d),
    });
  }, [startDate]);

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'transparent',
        }),
        wrapper: {
          overflow: 'hidden',
        },
      }}
      spinner={<ClipLoader color="#F7991F" size={90} />}
    >
      <div className="cartOuterMobile">
        <div className="cartProductInfoBox2">
          <div className="cartProductImg" onClick={handleOpenProduct}>
            <img
              src={cartImg}
              alt="image of product in cart"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="info">
            <div className="productTitleContainer">
              <div
                className="productTitle"
                onClick={handleOpenProduct}
                style={{ cursor: 'pointer' }}
              >
                {cart?.product_name}
              </div>
            </div>

            <div className="grid items-center grid-cols-2 gap-1 mt-2">
              <div className="productType">{cart?.sub_transaction_id}</div>
              <div className="productType">
                {t('checkoutPage.type')} - {cart?.product_type_name}
              </div>
              <div className="productType">
                {t('checkoutPage.quality')} - {cart?.product_quality_name}
              </div>
              <div className="productType">
                {t('checkoutPage.price')} - ₹ {cart?.order_rate}
              </div>
              <div className="productType">
                {t('checkoutPage.quantity')} - {cart?.order_qty} (
                {unit == 'Quintals'
                  ? 'Q'
                  : unit == 'Ltrs'
                  ? 'L'
                  : unit == 'Bags'
                  ? 'Bags'
                  : 'Bales'}
                )
              </div>
              <div className="productType">
                {t('buyerOrderDetails.location')} - {cart?.location_code} /{' '}
                {cart?.location_name}
              </div>
              <div className="productType">
                {t('seller')} - {cart?.seller_name} {cart?.seller_last_name}
              </div>
              <div className="productType">
                {t('buyerOrderDetails.total')} - ₹ {cart?.order_total}
              </div>
              <div className="productType">
                {t('buyerOrderDetails.deliveryFee')} - ₹ {cart?.delivery_fee}
              </div>
              <div className="productType">
                {t('buyerOrderDetails.status')} - {cart?.order_status_label}
              </div>

              <>
                {cart?.order_status === 5 ||
                cart?.order_status === 7 ||
                cart?.order_status === 8 ||
                cart?.order_status === 9 ? (
                  <>
                    {cart?.order_status === 7 ? (
                      <div
                        className="deliveryUpdateButtonMobile mt-1"
                        onClick={handleDispatchOpen}
                      >
                        Dispatch From Buyer
                      </div>
                    ) : (
                      <h3
                        style={{
                          cursor: 'pointer',
                          textAlign: 'center',
                          fontSize: '.62rem',
                          fontWeight: '600',
                          background: '#f5821f',
                          borderRadius: '10px',
                          padding: '4px',
                          color: 'white',
                        }}
                        onClick={handleImageModalOpen}
                      >
                        {cart?.order_status_label}
                      </h3>
                    )}
                  </>
                ) : (
                  <>
                    {cart?.order_status < 3 && (
                      <div className="cancelButton" onClick={handleCancelOpen}>
                        Cancel Product
                      </div>
                    )}
                  </>
                )}
              </>

              {cart?.order_status === 3 && (
                <>
                  <div
                    className="deliveryUpdateButtonMobile"
                    onClick={handleModalOpen}
                  >
                    Update Delivery
                  </div>
                  <div className="trackButton" onClick={handleTrackOpen}>
                    Track Order
                  </div>
                </>
              )}

              {cart?.order_status === 8 && cart?.is_refund === 0 && (
                <div className="trackButton" onClick={handleTrackOpen}>
                  Track Order
                </div>
              )}

              {cart?.order_status === 4 && (
                <>
                  {cart?.is_refund_enable === 1 && (
                    <div
                      className="deliveryUpdateButtonMobile"
                      onClick={handleModalOpen2}
                    >
                      Return Product
                    </div>
                  )}
                </>
              )}
            </div>

            {cartTitle2 && <h2 className="">({cartTitle2})</h2>}
          </div>
        </div>
      </div>
      {modalOpen && (
        <UpdateStatusModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          handleDeliveredFine={handleDeliveredFine}
          handleReturn={handleReturn}
          setStep={setStep}
          step={step}
          setImages={setImages}
          reason={reason}
          setReason={setReason}
          images={images}
          // loading={loading}
        />
      )}

      {modalOpen && (
        <ViewImages
          order_status={cart?.order_status}
          remark={cart?.cancel_reason}
          cart={cart}
          title={
            cart?.order_status === 7
              ? 'Returned Product Images'
              : cart?.order_status === 8
              ? `${t('orderModals.returnReason')}`
              : `${t('orderModals.cancelReason')}`
          }
          modalOpen={imageModal}
          handleModalClose={handleImgModalClose}
          imagesArray={cart?.product_gallery}
        />
      )}

      {cancelOpen && (
        <CancelStatusModal
          title={`${t('orderModals.cancelProduct')}`}
          handleModalClose={handleCancelClose}
          modalOpen={cancelOpen}
          handleCancel={handleCancel}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
        />
      )}

      {imageModal && (
        <ViewImages
          cart={cart}
          order_status={cart?.order_status}
          remark={cart?.cancel_reason}
          title={
            cart?.order_status === 7
              ? 'Returned Product Images'
              : cart?.order_status === 8
              ? `${t('orderModals.returnReason')}`
              : `${t('orderModals.cancelReason')}`
          }
          modalOpen={imageModal}
          handleModalClose={handleImgModalClose}
          imagesArray={cart?.product_gallery}
        />
      )}
      {trackModal && (
        <ShippingInfo
          modalOpen={trackModal}
          handleModalClose={handleTrackClose}
          cart={cart}
          shipping_info={cart?.shipping_info}
        />
      )}

      {dispatchModal && (
        <DispatchModal
          modalOpen={dispatchModal}
          handleModalClose={handleDispatchClose}
          shippingInfo={shippingInfo}
          setShippingInfo={setShippingInfo}
          handleShippingInfo={handleShippingInfo}
          startDate={startDate}
          setStartDate={setStartDate}
          showQty={true}
          unit={unit}
        />
      )}
    </LoadingOverlay>
  );
};

export default OrderedProductMobile;

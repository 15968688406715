import { Button, Card, Paper, Table } from '@mui/material';
import { t } from 'i18next';
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';

import SalesTransTable from './SalesTransTable';

const SalesTrans = ({ salesOrders, loading, fullData, handleLoadMore }) => {
  const userType = localStorage.getItem('currentUser');

  return (
    <Card>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
            wrapper: {
              overflow: 'hidden',
            },
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        >
          <div className="mobileTableBoxOuter">
            <div className="mobileTableRow">
              {salesOrders?.length == 0 ? (
                <div className="mobileNoData">
                  {userType == 'buyer' && <>{t('noProductData')}</>}
                  {userType == 'seller' && <>{t('noOrderYet')}</>}
                </div>
              ) : (
                <>
                  {salesOrders?.map((order, index) => (
                    <SalesTransTable
                      order={order}
                      key={order?.order_id}
                      index={index}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          {fullData && (
            <div className="tableLoadMoreSection">
              <div className="table-responsive">
                <Table aria-label="customized table">
                  {fullData && (
                    <>
                      {fullData?.page * fullData?.per_page <
                        fullData?.total && (
                        <tbody className="list">
                          <tr
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <td
                              colSpan="10"
                              style={{
                                fontSize: '1.2rem',
                                padding: '1rem 0',
                              }}
                            >
                              <Button
                                // className="my-2"
                                variant="contained"
                                size="small"
                                color="info"
                                onClick={handleLoadMore}
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-arrow-alt-circle-down"></i>
                                </span>
                                <span className="btn-inner--text">
                                  {t('sellerDashboard.loadMore')}
                                </span>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </Table>
              </div>
              <div className="row react-bootstrap-table-pagination">
                <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6 ml-auto">
                  <div
                    className="pagination react-bootstrap-table-page-btns-ul"
                    style={{ alignItems: 'center', height: '100%' }}
                  >
                    <span className="react-bootstrap-table-pagination-total ">
                      {t('sellerDashboard.showing')}{' '}
                      {fullData?.total
                        ? fullData?.total == '1'
                          ? '1'
                          : fullData?.per_page * fullData?.page >
                            fullData?.total
                          ? fullData?.total
                          : fullData?.per_page * fullData?.page
                        : '0'}{' '}
                      {t('sellerDashboard.of')}{' '}
                      {fullData?.total ? fullData?.total : '0'}{' '}
                      {t('sellerDashboard.results')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>
      </Paper>
    </Card>
  );
};

export default SalesTrans;

import { createContext, useState } from 'react';

const OrderContext = createContext({});

export const OrderProvider = ({ children }) => {
  const [orderDetails, setOrderDetails] = useState([]);

  return (
    <OrderContext.Provider value={{ orderDetails, setOrderDetails }}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;

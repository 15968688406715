// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buyerAddress {
  min-height: 55vh;
  margin-bottom: 2rem;
}

.addressCard {
  background: #ffffff;
  border: 1px solid rgba(20, 20, 20, 0.05);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 2rem;
}
.addressCard .adsHeading {
  font-size: 20px;
  font-weight: bold;
  color: #141414;
  margin-bottom: 0.4rem;
}
.addressCard .adsInfo {
  font-size: 14px;
  font-weight: normal;
  color: #141414;
  opacity: 0.8;
  margin-bottom: 0.4rem;
}
.addressCard .adsButton {
  /* Button */
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  background: linear-gradient(258.9deg, #fd955c -7.56%, #f7991f 112.86%);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/Components/BuyerAddress/addressCard.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,wCAAA;EACA,2CAAA;EACA,kBAAA;EACA,aAAA;AAAF;AAEE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,qBAAA;AAAJ;AAEE;EACE,eAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EAEA,qBAAA;AADJ;AAGE;EACE,WAAA;EACA,0BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,sEAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ","sourcesContent":["@import '../../breakpoints.scss';\r\n.buyerAddress {\r\n  min-height: 55vh;\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n.addressCard {\r\n  background: #ffffff;\r\n  border: 1px solid rgba(20, 20, 20, 0.05);\r\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);\r\n  border-radius: 8px;\r\n  padding: 2rem;\r\n\r\n  .adsHeading {\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    color: #141414;\r\n    margin-bottom: 0.4rem;\r\n  }\r\n  .adsInfo {\r\n    font-size: 14px;\r\n    font-weight: normal;\r\n    color: #141414;\r\n    opacity: 0.8;\r\n    // text-transform: capitalize;\r\n    margin-bottom: 0.4rem;\r\n  }\r\n  .adsButton {\r\n    /* Button */\r\n    text-transform: capitalize;\r\n    cursor: pointer;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 10px 30px;\r\n    gap: 8px;\r\n    background: linear-gradient(258.9deg, #fd955c -7.56%, #f7991f 112.86%);\r\n    border-radius: 4px;\r\n    font-style: normal;\r\n    font-weight: 500;\r\n    font-size: 16px;\r\n    line-height: 150%;\r\n    color: #ffffff;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

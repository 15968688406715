import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ShippingDetailTable = ({ order }) => {
  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const order_date = new Date(order?.created_at);

  return (
    <StyledTableRow>
      {/* <StyledTableCell align="left">{order?.product_name}</StyledTableCell> */}
      {/* <StyledTableCell align="left">
        {order?.order_status_label}
      </StyledTableCell> */}
      <StyledTableCell align="left">
        {`${padL(order_date.getDate())}-${padL(
          order_date.getMonth() + 1
        )}-${order_date.getFullYear()} ${padL(order_date.getHours())}:${padL(
          order_date.getMinutes()
        )}:${padL(order_date.getSeconds())}`}
      </StyledTableCell>
      <StyledTableCell align="left">{order?.statusMessage}</StyledTableCell>
    </StyledTableRow>
  );
};

export default ShippingDetailTable;

import { Link } from 'react-router-dom';
import { Breadcrumbs, Card, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';

import useDidMountEffect from '../../hooks/useDidMountEffect';
import Transaction from 'Components/MyOrders/Transaction';
import BuyerLayout from 'layouts/BuyerLayout';
import { t } from 'i18next';
import { useWindowSize } from '@uidotdev/usehooks';
import SalesTrans from 'Components/MobileTables/SalesTrans/SalesTrans';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const MyTransOrders = () => {
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [salesOrders, setSalesOrders] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [tiles, setTiles] = useState([]);
  const size = useWindowSize();

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    buyer_id: user?.buyer_id,
    page: 1,
    per_page: 10,
    search: '',
  });

  useEffect(() => {
    const tilesD = qs.stringify({
      buyer_id: user?.buyer_id,
    });

    axios
      .post(`${API_URL}/buyerdashboard`, tilesD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setTiles(res?.data?.result);
      });

    const productsD = qs.stringify({
      buyer_id: filters?.buyer_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/buyertransactions`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  }, []);

  const handleSearch = () => {
    setLoading(true);
    const productsD = qs.stringify({
      buyer_id: filters?.buyer_id,
      page: 1,
      per_page: filters?.per_page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/buyertransactions`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  };

  // limiting search calls and search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filters?.search]);

  // handleLoad more
  const handleLoadMore = () => {
    setFilters({ ...filters, page: filters?.page + 1 });
  };

  useDidMountEffect(() => {
    setLoading(true);
    const productsD = qs.stringify({
      buyer_id: filters?.buyer_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/buyertransactions`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        const oldArray = salesOrders;
        const moreItems = res?.data?.result;
        const newArray = [...oldArray, ...moreItems];
        setSalesOrders(newArray);
        setFullData(res?.data);
        setLoading(false);
      });
  }, [filters?.page]);

  return (
    <BuyerLayout>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <div className="checkoutWrapper">
          <div className="checkoutInnerWrapper">
            <Stack spacing={2} mt={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                  {t('breadCrumbs.home')}
                </Link>
                <Typography color="text.primary">
                  {t('breadCrumbs.purchasedOrders')}
                </Typography>
              </Breadcrumbs>
            </Stack>

            <section className="buyerAddress">
              <div className="flex items-center justify-between mt-2 mb-1">
                <h2 className="checkoutTitle">
                  {t('breadCrumbs.purchasedOrders')}
                </h2>
              </div>

              <div className="dashboardCardContainer mb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="dashboardCard bg-gradient-default">
                    <div className="mb-2">
                      <span className="h3 text-white text-capitalize">
                        {t('buyerOrderPages.overall')}
                      </span>
                      <div>
                        <span className="text-white mt-2 text-lg">
                          ₹ {tiles?.overall_purchases}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="dashboardCard bg-gradient-dark">
                    <div className="mb-2">
                      <span className="h3 text-white text-capitalize">
                        {t('buyerOrderPages.productsPurchased')}
                      </span>
                      <div>
                        <span className="text-white mt-2 text-lg">
                          {tiles?.products_purchased}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {size.width <= 769 ? (
                <SalesTrans
                  salesOrders={salesOrders}
                  loading={loading}
                  filters={filters}
                  setFilters={setFilters}
                  fullData={fullData}
                  handleLoadMore={handleLoadMore}
                />
              ) : (
                <Transaction
                  salesOrders={salesOrders}
                  loading={loading}
                  filters={filters}
                  setFilters={setFilters}
                  fullData={fullData}
                  handleLoadMore={handleLoadMore}
                />
              )}
            </section>
          </div>
        </div>
      </LoadingOverlay>
    </BuyerLayout>
  );
};

export default MyTransOrders;

import './productionSection.scss';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import CardSkeleton from '../../ProductCard/CardSkeleton';
import RelevantCard from 'Components/ProductCard/RelevantCard';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const FeaturedProducts = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  var settings = {
    dots: false,
    infinite: products?.length > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: products?.length > 3,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const productsD = qs.stringify({
      category_id: '',
      product_settings_id: '',
    });

    axios
      .post(`${API_URL}/featuredproducts`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProducts(res?.data?.result);
        setLoading(false);
      });
  }, []);

  return (
    <section className="productSection">
      <div className="productSection__title">{t('featureProducts')}</div>
      <div className="productContainer">
        {loading == true ? (
          <Slider {...settings}>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </Slider>
        ) : (
          <Slider {...settings}>
            {products?.map((product) => (
              <RelevantCard
                key={product?.category_id}
                img={product?.image_thumb}
                title={product?.product_name}
                desc={product?.description}
                product_id={product?.category_id}
                product_settings_id={product?.product_settings_id}
              />
            ))}
          </Slider>
        )}
        {/* <ProductCard
            img={Pulse2}
            title="Kidney Beans"
            desc="The kidney bean is a variety of the common bean (P. vulgaris), so named because of its kidney-like shape and its colour."
          /> */}
      </div>
    </section>
  );
};

export default FeaturedProducts;

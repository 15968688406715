import axios from 'axios';
import { useEffect, useState } from 'react';
import { toastMessageError } from 'utils/toast';
import { useTranslation } from 'react-i18next';

const BUILD_URL = process.env.REACT_APP_BACKEND_URL;

// axios instance
const instance = axios.create({
  baseURL: BUILD_URL,
});

// AxiosInterceptor component
const AxiosInterceptor = ({ children }) => {
  const { i18n } = useTranslation();

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    // Request interceptor to add language and additional payload to each request
    const reqInterceptor = (config) => {
      // Function to serialize object to URLSearchParams format
      const serialize = (obj) => {
        const params = new URLSearchParams();
        Object.entries(obj).forEach(([key, value]) => {
          params.append(key, value);
        });
        return params;
      };

      // Check if config.data is already a FormData instance
      if (config.data instanceof FormData) {
        // Append additional payload to existing FormData
        const currentLanguageCode = i18n.language;
        config.data.append(
          'language',
          currentLanguageCode.startsWith('en-') ? 'en' : currentLanguageCode
        );
      } else {
        // Create a new FormData object
        const formData = new FormData();

        // Append existing payload to formData if it exists
        if (config.data) {
          if (typeof config.data === 'string') {
            // Split default payload using '&'
            const defaultPayloadArray = config.data.split('&');
            defaultPayloadArray.forEach((pair) => {
              const [key, value] = pair.split('=');
              formData.append(key, decodeURIComponent(value)); // Decoding the value to prevent URL encoding
            });
          } else {
            // If default payload is an object, append its entries directly
            const params = serialize(config.data);
            params.forEach((value, key) => {
              formData.append(key, decodeURIComponent(value)); // Decoding the value to prevent URL encoding
            });
          }
        }

        // Append additional payload to formData
        const currentLanguageCode = i18n.language;
        formData.append(
          'language',
          currentLanguageCode.startsWith('en-') ? 'en' : currentLanguageCode
        );
        // formData.append(
        //   'language',
        //   `${i18n.language == 'en-US' ? 'en' : i18n.language}`
        // );

        // Assign formData to config.data
        config.data = formData;
      }

      return config;
    };

    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error?.response?.status === 401) {
        //redirect logic here
        toastMessageError({ message: `${error?.response?.data?.message}` });
        setTimeout(() => {
          // Clear token form db
          localStorage.removeItem('userInfo');
          localStorage.removeItem('sellerUserInfo');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('token');
          localStorage.removeItem('orderId');
          window.location.href = '/';
        }, 2000);
      } else if (error?.response?.status === 400) {
        toastMessageError({ message: `${error?.response?.data?.message}` });
      } else if (error?.response?.status === 413) {
        toastMessageError({
          message: `Please upload valid file size, it exceeds the limit`,
        });
      } else {
        toastMessageError({ message: `${error?.response?.data?.message}` });
      }

      return Promise.reject(error);
    };
    setIsSet(true);

    const requestInterceptor =
      instance.interceptors.request.use(reqInterceptor);
    const responseInterceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => {
      instance.interceptors.request.eject(requestInterceptor);
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return isSet && children;
};

export default instance;
export { AxiosInterceptor };

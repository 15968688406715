import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSkeleton from './TableSkeleton';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, content, qty) {
  return { name, content, qty };
}

export default function ProductInfoTable({ productDetails, loading }) {
  const { t } = useTranslation();
  const rows = [
    createData(
      `${t('productDetailServings.calories')}`,
      productDetails?.calories,
      productDetails?.calories_qty
    ),
    createData('Calcium', productDetails?.calcium, productDetails?.calcium_qty),
    createData(
      'Carbohydrates',
      productDetails?.carbohydrates,
      productDetails?.carbohydrates_qty
    ),
    createData(
      'Cholesterol',
      productDetails?.cholesterol,
      productDetails?.cholesterol_qty
    ),
    createData('Energy', productDetails?.energy, productDetails?.energy_qty),
    createData('Fat', productDetails?.fat, productDetails?.fat_qty),
    createData('Fiber', productDetails?.fiber, productDetails?.fiber_qty),
    createData('Iron', productDetails?.iron, productDetails?.iron_qty),
    createData(
      'Manganese',
      productDetails?.manganese,
      productDetails?.manganese_qty
    ),
    createData(
      'Monosaturated Fats',
      productDetails?.monosaturated_fats,
      productDetails?.monosaturated_fats_qty
    ),
    createData(
      'Polyunsaturated Fats',
      productDetails?.polyunsaturated_fats,
      productDetails?.polyunsaturated_fats_qty
    ),
    createData(
      'Potassium',
      productDetails?.potassium,
      productDetails?.potassium_qty
    ),
    createData(
      'Saturated Fats',
      productDetails?.saturated_fats,
      productDetails?.saturated_fats_qty
    ),
    createData('Sodium', productDetails?.sodium, productDetails?.sodium_qty),
    createData('Sugar', productDetails?.sugar, productDetails?.sugar_qty),
    createData('Thiamin', productDetails?.thiamin, productDetails?.thiamin_qty),
  ];
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              {productDetails?.product_name} (
              {t('productDetailServings.serving')})
            </StyledTableCell>
            <StyledTableCell align="right">
              % {t('productDetailServings.dailyValue')}*
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {loading == true ? (
            <>
              <TableSkeleton />
              <TableSkeleton />
              <TableSkeleton />
              <TableSkeleton />
              <TableSkeleton />
              <TableSkeleton />
            </>
          ) : (
            <>
              {productDetails ? (
                <>
                  {rows.map((row, index) => (
                    <>
                      {row.content != null && (
                        <StyledTableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.content} {row.qty}
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  <StyledTableRow style={{ width: '100%' }}>
                    <StyledTableCell colSpan={2}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          fontSize: '1rem',
                          padding: '1rem 0',
                        }}
                      >
                        {t('noData')}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

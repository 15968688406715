// import { Breadcrumbs, Stack, Typography } from '@mui/material';
// import { Link } from 'react-router-dom';

import { t } from 'i18next';

const AboutUs = () => {
  return (
    <div className="bg-gray-100">
      {/* <div> */}
      {/* <div className="wrapper">
        <div className="innerWrapper"> */}
      <div className="container mx-auto px-4 py-8">
        {/* <Stack spacing={2} mt={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Typography color="text.primary">About Us Page</Typography>
          </Breadcrumbs>
        </Stack> */}
        <h1 className="text-1xl md:text-3xl font-bold mb-4 pt-4 header-text">
          {t('aboutPage.about')}
        </h1>

        {/* <p className="mb-4 font-medium">Welcome to KRUSHI Agromarketplace</p> */}
        <p className="mb-4 font-medium">
          <span className="font-bold">KRUSHI</span> {t('aboutPage.intro')}
        </p>

        {/* farmers */}
        <h2 className="text-2xl header-text font-bold mb-2">
          {t('aboutPage.seller')}:
        </h2>
        <p className="mb-4 font-medium">{t('aboutPage.sellerIntro')}</p>
        <p className="mb-4 font-medium">{t('aboutPage.sellerSupport')}:</p>

        <ul className="list-inside mb-4">
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.directSales')} :</span>{' '}
            {t('aboutPage.sellerIntro1')}
          </li>
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.visibility')} :</span>{' '}
            {t('aboutPage.sellerIntro2')}
          </li>
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.marketing')} :</span>{' '}
            {t('aboutPage.sellerIntro3')}
          </li>
        </ul>

        {/* buyers */}
        <h2 className="text-2xl header-text font-bold mb-2">
          {t('aboutPage.buyer')}:
        </h2>
        <p className="mb-4 font-medium">{t('aboutPage.buyerIntro')}</p>
        <p className="mb-4 font-medium">{t('aboutPage.buyerSupport')}</p>

        <ul className="list-inside mb-4">
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.directSales')} :</span>{' '}
            {t('aboutPage.buyerIntro1')}
          </li>
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.quality')}:</span>{' '}
            {t('aboutPage.buyerIntro2')}
          </li>
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.variety')} :</span>{' '}
            {t('aboutPage.buyerIntro3')}
          </li>
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.support')} :</span>
            {t('aboutPage.buyerIntro4')}
          </li>
          <li className="pb-2">
            <span className="font-bold">{t('aboutPage.mission')} :</span>{' '}
            {t('aboutPage.buyerIntro5')}
          </li>
        </ul>

        <p className="pb-8 font-bold">{t('aboutPage.join')}</p>

        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default AboutUs;

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useContext, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';
import AuthContext from 'context/AuthProvider';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function OtpValidator({
  handleOtpClose,
  otpOpen,
  sName,
  setUserInfo,
  userInfo,
  otpInfo,
  setOtpInfo,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const [timer, setTimer] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const { setAuth, setToken } = useContext(AuthContext);

  const startTimer = () => {
    setIsActive(true);
    setTimer(60);

    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(countdown);
          setIsActive(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleLoginOtp = (e) => {
    e.preventDefault();
    if (userInfo?.otp !== '') {
      const loginD = qs.stringify({
        buyer_mobile: userInfo?.mobile,
        otp: userInfo?.otp,
        sms_id: userInfo?.sms_id,
      });

      axios
        .post(`${API_URL}/validateloginotpvalidate`, loginD, headers)
        .then((res) => {
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            localStorage.setItem('token', JSON.stringify(res?.data?.token));
            handleOtpClose();
            localStorage.setItem('currentUser', sName);
            localStorage.setItem(
              'userInfo',
              JSON.stringify(res?.data?.result?.[0])
            );
            setUserInfo({});
            setAuth(res?.data?.result?.[0]);
            setToken(res?.data?.token);
            window.location.reload();
          }
        });
    } else {
      if (userInfo.otp === '') {
        return toastMessageWarning({
          message: 'Please validate your Otp to login !',
        });
      }
    }
  };

  const handleSellerLoginOtp = (e) => {
    e.preventDefault();
    if (userInfo?.otp !== '') {
      const loginD = qs.stringify({
        seller_cnum: userInfo?.mobile,
        otp: userInfo?.otp,
        sms_id: userInfo?.sms_id,
      });

      axios
        .post(`${API_URL}/sellerloginotpvalidate`, loginD, headers)
        .then((res) => {
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            handleOtpClose();
            localStorage.setItem('token', JSON.stringify(res?.data?.token));
            localStorage.setItem('currentUser', sName);
            localStorage.setItem(
              'userInfo',
              JSON.stringify(res?.data?.result?.[0])
            );
            setUserInfo({});
            setAuth(res?.data?.result?.[0]);
            setToken(res?.data?.token);
            history.push('/sellerHome');
            window.location.reload();
          }
        });
    } else {
      if (userInfo.otp === '') {
        return toastMessageWarning({
          message: 'Please validate your Otp to login !',
        });
      }
    }
  };

  const handleResendBuyerOtp = () => {
    const loginD = qs.stringify({
      buyer_mobile: userInfo?.mobile,
      sms_id: userInfo?.sms_id,
    });

    axios.post(`${API_URL}/resndbuyerloginotp`, loginD, headers).then((res) => {
      if (res?.data?.status === false) {
        toastMessageWarning({
          message: `${res?.data?.message}`,
        });
      } else {
        toastMessageSuccess({
          message: `${res?.data?.message}`,
        });
        setOtpInfo({
          ...otpInfo,
          otp: '',
          mobile: res?.data?.result?.buyer_mobile,
          sms_id: res?.data?.result?.sms_id,
        });

        startTimer();
      }
    });
  };

  const handleResendSellerOtp = () => {
    const loginD = qs.stringify({
      seller_cnum: userInfo?.mobile,
      sms_id: userInfo?.sms_id,
    });

    axios
      .post(`${API_URL}/resndsellerloginotp`, loginD, headers)
      .then((res) => {
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          setOtpInfo({
            ...otpInfo,
            otp: '',
            mobile: res?.data?.result?.seller_cnum,
            sms_id: res?.data?.result?.sms_id,
          });

          startTimer();
        }
      });
  };

  // const handleFormChange = (event) => {
  //   setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  // };

  const [OTP, setOTP] = useState('');

  function handleChange(OTP) {
    setOTP(OTP);
    setUserInfo({ ...userInfo, otp: OTP });
  }

  return (
    <>
      <Dialog
        open={otpOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOtpClose}
        fullWidth="sm"
        maxWidth="sm"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleOtpClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">
                {sName == 'seller'
                  ? `${t('loginForms.seller')}`
                  : `${t('loginForms.buyer')}`}{' '}
                {t('loginForms.login')}
              </div>
            </div>
            <hr />
            <form
              className="inputsContainer"
              autoComplete="off"
              noValidate
              onSubmit={
                sName == 'seller' ? handleSellerLoginOtp : handleLoginOtp
              }
            >
              <div className="qna-container">
                <label htmlFor="otp" className="question">
                  {t('loginForms.validateOtp')}
                </label>
                <OTPInput
                  onChange={handleChange}
                  value={OTP}
                  inputStyle="inputStyle"
                  numInputs={5}
                  inputType="number"
                  renderInput={(props) => (
                    <input {...props} className="inputStyle" max={1} />
                  )}
                  separator={<span></span>}
                />
              </div>

              <button className="loginBtn">{t('loginForms.otp')}</button>

              <div className="resendLink">
                {isActive === false ? (
                  <button
                    className="resend"
                    type="button"
                    // onClick={handleResendBuyerOtp}
                    onClick={
                      sName == 'seller'
                        ? handleResendSellerOtp
                        : handleResendBuyerOtp
                    }
                    disabled={isActive}
                  >
                    Resend OTP
                    <i className="ri-arrow-right-s-fill"></i>
                  </button>
                ) : (
                  <div className={`timer ${isActive ? 'counting' : ''}`}>
                    {isActive ? `Resend OTP in ${timer}s` : ''}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
}

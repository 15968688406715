import { Button } from '@mui/material';
import { t } from 'i18next';

const AddLocations = ({
  input,
  inputNumb,
  inputFields,
  setInputFields,
  quotationLength,
  handleAddFields,
  handleRemoveField,
  handleRemoveField1,
}) => {
  const handleFormChange = (inputNumb, event) => {
    let data = [...inputFields];
    data[inputNumb][event.target.name] = event.target.value;
    setInputFields(data);
  };

  return (
    <>
      <div className="grid grid-cols-1  md:grid-cols-6 gap-4 mt-2">
        <div className="qna-container">
          <label htmlFor="name" className="question">
            {t('sellerProductForm.locName')}*
          </label>
          <div className="answer">
            <input
              id="name"
              name="name"
              type="text"
              onWheel={(e) => e.target.blur()}
              className="textField w-100"
              placeholder={t('sellerProductForm.locName')}
              value={input?.name}
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>
        <div className="qna-container">
          <label htmlFor="code" className="question">
            {t('sellerProductForm.pincode')}*
          </label>
          <div className="answer">
            <input
              id="code"
              name="code"
              type="number"
              min={1}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              className="textField w-100"
              placeholder={t('sellerProductForm.pincode')}
              value={input?.code}
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>

        <div className="qna-container">
          <label htmlFor="radius" className="question">
            {t('sellerProductForm.radius')}*
          </label>
          <div className="answer">
            <input
              id="radius"
              name="radius"
              type="number"
              min={1}
              onWheel={(e) => e.target.blur()}
              className="textField w-100"
              placeholder={t('sellerProductForm.radius')}
              value={input?.radius}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>

        <div className="qna-container">
          <label htmlFor="quantity" className="question">
            {t('sellerProductForm.quantity')}*
          </label>
          <div className="answer">
            <input
              id="quantity"
              name="quantity"
              type="number"
              min={1}
              onWheel={(e) => e.target.blur()}
              className="textField w-100"
              placeholder={t('sellerProductForm.quantity')}
              value={input?.quantity}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              onChange={(event) => handleFormChange(inputNumb, event)}
            />
          </div>
        </div>
        <div className="addBtnContainer col-span-2">
          {inputNumb + 1 == quotationLength && (
            <Button
              variant="contained"
              size="small"
              color="warning"
              style={{ marginRight: '.5rem', fontSize: '.85rem' }}
              onClick={handleAddFields}
            >
              {t('sellerProductForm.addLoc')}
            </Button>
          )}
          {inputNumb == 0 ? (
            <Button
              variant="contained"
              size="small"
              style={{ fontSize: '.85rem' }}
              color="error"
              onClick={() => handleRemoveField1(inputNumb)}
            >
              {t('sellerProductForm.removeLoc')}
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{ fontSize: '.85rem' }}
              color="error"
              onClick={() => handleRemoveField(inputNumb)}
            >
              {t('sellerProductForm.removeLoc')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AddLocations;

import BuyerLayout from 'layouts/BuyerLayout';
import ContactContext from 'context/ContactProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { contactDetails } = useContext(ContactContext);
  const { t } = useTranslation();

  return (
    <BuyerLayout>
      <div className="bg-gray-100">
        {/* <div className="wrapper">
          <div className="innerWrapper"> */}
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-1xl header-text md:text-3xl font-bold mb-4 pt-4">
            {t('term.terms&condition')}
          </h1>

          {/* <p className="mb-4 font-medium">
            Terms and Conditions for KRUSHI Marketplace
          </p> */}

          {/* intro */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.intro')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.policyDescription1')}</li>
            <li>{t('term.policyDescription2')}</li>
          </ul>

          {/* Registration */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.registration')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.registrationDescription1')}</li>
            <li>{t('term.registrationDescription2')}</li>
          </ul>

          {/*Buying & SElling */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.BuyingAndSelling')}
          </h2>

          <ul className="list-disc list-inside mb-4">
            <li>{t('term.BuyingAndSellingDescription1')}</li>
            <li>{t('term.BuyingAndSellingDescription2')}</li>
            <li>{t('term.BuyingAndSellingDescription3')}</li>
          </ul>

          {/*  Escrow Transactions*/}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.EscrowTransactions')}
          </h2>

          <ul className="list-disc list-inside mb-4">
            <li>{t('term.EscrowDescription1')}</li>
            <li>{t('term.EscrowDescription2')}</li>
            <li>{t('term.EscrowDescription3')}</li>
          </ul>

          {/* Fees */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.Fees')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.FeesDescription1')}</li>
            <li>{t('term.FeesDescription2')}</li>
          </ul>

          {/* Dispute Resolution*/}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.DisputeResolution')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.DisputeResolutionDescription1')}</li>
            <li>{t('term.DisputeResolutionDescription2')}</li>
          </ul>

          {/* Intellectual Property */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.IntellectualProperty')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.IntellectualPropertyDescription1')}</li>
            <li>{t('term.IntellectualPropertyDescription2')}</li>
          </ul>

          {/* Limitation of Liability */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.LimitationOfLiability')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.LimitationDescription')}</li>
          </ul>

          {/* Governing Law */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.GoverningLaw')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              {t('term.GoverningLawDescription')}
              {/* These terms and conditions shall be governed by and construed in
              accordance with the laws of
              <span className="font-bold"> INDIA</span>. Any disputes arising
              out of or relating to these terms and conditions shall be subject
              to the exclusive jurisdiction of the courts of
              <span className="font-bold"> Indian Jurisdiction</span>. */}
            </li>
          </ul>

          {/*cancellation */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.cancellation')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            {/* <li>{t('term.buyerCancellation')}</li>
             */}
            {t('term.buyerCancellation')}
            <li>{t('term.beforeShipment')}</li>
            <li>{t('term.afterShipment')}</li>
            {t('term.sellerCancellation')}
            <li>{t('term.reason1')}</li>
          </ul>

          {/* refundPolicy */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.refundPolicy')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            {t('term.refundElig')}
            <li>{t('term.refundElig1')}</li>
            <li>{t('term.refundElig2')}</li>
            <li>{t('term.refundElig3')}</li>
            <li>{t('term.refundElig4')}</li>
          </ul>

          {/* refundProcess */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.refundProcess')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.returnReq')}</li>
            <li>{t('term.approvalPickup')}</li>
            <li>{t('term.inspection')}</li>
            <li>{t('term.refundTimeline')}</li>
          </ul>

          {/* nonRefundable */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.nonRefundable')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.nonRefundable1')}</li>
            <li>{t('term.nonRefundable2')}</li>
            <li>{t('term.nonRefundable3')}</li>
            <li>{t('term.nonRefundable4')}</li>
          </ul>
          {/* dispute */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.dispute')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.dispute1')}</li>
            <li>{t('term.dispute2')}</li>
          </ul>

          {/*Amendments */}
          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.amendments')}
          </h2>
          <ul className="list-disc list-inside mb-4">
            <li>{t('term.amendmentsDescription')}</li>
          </ul>

          <h2 className="text-2xl header-text font-bold mb-2">
            {t('term.contactUs')}
          </h2>
          <p className="mb-4 font-medium">
            {t('term.contactDesc')}{' '}
            <a
              href={`mailto: ${contactDetails?.email}`}
              className="text-underline"
            >
              {contactDetails?.email}
            </a>
          </p>

          {/* <p className="pb-4">
            This privacy policy is subject to change without notice.
          </p> */}
        </div>
        {/* </div>
        </div> */}
      </div>
    </BuyerLayout>
  );
};

export default Terms;

import './emptyCart.scss';
import Svg from '../../assets/shopping.svg';
import EmptyCart from '../../assets/cart.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EmptyCart2 = () => {
  const history = useHistory();
  const handleProductClick = () => {
    history.push(`/products`);
  };

  return (
    <div className="container-fluid emptyCartContainer">
      <div className="row" align="center">
        <div className="col-md-12 paddingRemove">
          <img src={EmptyCart} className="image" />
          <div className="emptyCartCard">
            <h1>
              Your Cart is <span className="text-danger">Empty!</span>
            </h1>
            <p>Please add some products to cart and proceed for checkout.</p>
            <div>
              <button className="btn btn-orange" onClick={handleProductClick}>
                <img src={Svg} class="shpimg" /> <span>VIEW PRODUCTS</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCart2;

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import slider1 from '../../../assets/slider1.png';
import slider2 from '../../../assets/slider2.png';
import './video.scss';
import { useTranslation } from 'react-i18next';

const VideoShowCaseSection = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const pushToProducts = () => {
    history.push('/products');
  };
  return (
    <section className="videoShowCaseSection">
      <div className="videoContainerOuter">
        <div className="leftContainer">
          <div className="infoHeading">
            <h2>{t('heading1')}</h2>
            <h4>{t('heading3')}</h4>
            <p>{t('paragraph')}</p>
            <button className="buttonWithIcon green" onClick={pushToProducts}>
              <p>{t('startBtn')}</p>
              <i className="ri-arrow-right-fill"></i>
            </button>
          </div>
        </div>
        <div className="rightContainer">
          <div className="leftImg">
            <img src={slider1} alt="video img 1" />
          </div>

          <div className="rightImg">
            <img src={slider2} alt="videoimg2" />
          </div>
        </div>

        <div className="playBtnContainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="124"
            height="124"
            viewBox="0 0 124 124"
            fill="none"
            className="playBtnSvg"
          >
            <g clipPath="url(#clip0_76_1078)">
              <path
                d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z"
                fill="#008F2A"
              />
              <path
                d="M62.5 86C57.8521 86 53.3087 84.6218 49.4441 82.0395C45.5796 79.4573 42.5675 75.7871 40.7888 71.4931C39.0102 67.199 38.5448 62.4739 39.4516 57.9154C40.3583 53.3568 42.5965 49.1695 45.883 45.883C49.1695 42.5965 53.3568 40.3583 57.9154 39.4516C62.4739 38.5448 67.199 39.0102 71.4931 40.7888C75.7871 42.5675 79.4573 45.5796 82.0395 49.4441C84.6218 53.3087 86 57.8521 86 62.5C86 65.5861 85.3922 68.6419 84.2112 71.4931C83.0302 74.3442 81.2992 76.9348 79.117 79.117C76.9348 81.2992 74.3442 83.0302 71.4931 84.2112C68.6419 85.3922 65.5861 86 62.5 86ZM62.5 81.3C66.2183 81.3 69.8531 80.1974 72.9447 78.1316C76.0364 76.0659 78.446 73.1297 79.8689 69.6945C81.2919 66.2592 81.6642 62.4792 80.9388 58.8323C80.2134 55.1855 78.4228 51.8356 75.7936 49.2064C73.1644 46.5772 69.8146 44.7866 66.1677 44.0612C62.5209 43.3358 58.7408 43.7081 55.3056 45.1311C51.8703 46.554 48.9342 48.9636 46.8684 52.0553C44.8026 55.1469 43.7 58.7817 43.7 62.5C43.7 67.4861 45.6807 72.2679 49.2064 75.7936C52.7321 79.3193 57.5139 81.3 62.5 81.3ZM59.261 54.074L70.726 61.718C70.8547 61.8039 70.9602 61.9202 71.0332 62.0566C71.1062 62.193 71.1444 62.3453 71.1444 62.5C71.1444 62.6547 71.1062 62.807 71.0332 62.9435C70.9602 63.0799 70.8547 63.1962 70.726 63.282L59.261 70.926C59.1195 71.0203 58.955 71.0744 58.7851 71.0825C58.6153 71.0907 58.4464 71.0526 58.2964 70.9724C58.1465 70.8921 58.0211 70.7727 57.9337 70.6268C57.8463 70.4809 57.8001 70.3141 57.8 70.144V54.856C57.8001 54.6859 57.8463 54.5191 57.9337 54.3732C58.0211 54.2273 58.1465 54.1079 58.2964 54.0277C58.4464 53.9474 58.6153 53.9093 58.7851 53.9175C58.955 53.9257 59.1195 53.9798 59.261 54.074Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_76_1078">
                <rect width="124" height="124" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
};

export default VideoShowCaseSection;

import BuyerLayout from 'layouts/BuyerLayout';
import ProductDetail from '../../Components/ProductDetail/ProductDetail';

const ProductDetailPage = () => {
  return (
    <BuyerLayout>
      <ProductDetail />
    </BuyerLayout>
  );
};

export default ProductDetailPage;

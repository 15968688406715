import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import React, { useState } from 'react';
import ViewImages from './ViewImages';
import QualityImage from 'assets/certified.png';
// import { useHistory } from 'react-router-dom';

// import qs from 'qs';
// import axios from 'hooks/axios';
import TableInner from './TableInner';
import ProductCertificateModal from './ProductCertificateModal';
import VideoImages from './VideoImages';
import { t } from 'i18next';

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: 'capitalize',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// const API_URL = process.env.REACT_APP_BACKEND_URL;
// const token = localStorage.getItem('token');
// const authToken = JSON.parse(token);

const UserDetailTable = ({
  user,
  zipCode,
  selectedSeller,
  setSelectedSeller,
  // setCartDetails,
  users,
  setUsers,
  setDisable,
  inputNumb,
  cartDetails,
}) => {
  // const [checkedStatus, setCheckedStatus] = useState(false);
  // const [reqValue, setReqValue] = useState('');
  // const currentUser = localStorage.getItem('userInfo');
  // const buyer = JSON.parse(currentUser);

  // const handleRemove = () => {
  //   const cartD = qs.stringify({
  //     buyer_id: buyer?.buyer_id,
  //     cart_id: cartId,
  //   });
  //   axios
  //     .post(`${API_URL}/deletefromcart`, cartD, {
  //       headers: {
  //         Authorization: `${authToken}`,
  //       },
  //     })
  //     .then((res) => {
  //       toastMessageSuccess({
  //         message: `${res?.data?.message}`,
  //       });
  //       window.location.reload();
  //       // const cartD = qs.stringify({
  //       //   buyer_id: buyer?.buyer_id,
  //       // });

  //       // axios
  //       //   .post(`${API_URL}/viewcart`, cartD, {
  //       //     headers: {
  //       //       Authorization: `${authToken}`,
  //       //     },
  //       //   })
  //       //   .then((res) => {
  //       //     setCartDetails(res?.data);

  //       //   });
  //     });
  // };

  // const handleChange1 = (e) => {
  //   if (e.target.checked == false) {
  //     setReqValue('');
  //     setCheckedStatus(false);

  //     function removeObjectWithId(arr, seller_id) {
  //       return arr.filter((obj) => obj.seller_id !== seller_id);
  //     }

  //     const newArr = removeObjectWithId(selectedSeller, user?.seller_id);
  //     // let data = [...users];
  //     // const found = data.find((data) => data?.seller_id === user?.seller_id);
  //     // data[inputNumb][e.target.name] = e.target.value;
  //     // setUsers(data);
  //     setSelectedSeller(newArr);
  //     // if (cartId) {
  //     //   // handleRemove();
  //     // }
  //   }
  // };

  // const handleChange = (e) => {
  //   const qty = user?.prod_units;
  //   setReqValue(e.target.value);
  //   if (e.target.value > 0) {
  //     setCheckedStatus(true);
  //   } else {
  //     setCheckedStatus(false);
  //     if (cartId) {
  //       handleRemove();
  //     }
  //   }

  //   if (e.target.value > qty) {
  //     toastMessageWarning({
  //       message: `Entered  Req Qty Can't be greater than available quantity!`,
  //     });
  //     setDisable(true);
  //   } else {
  //     let data = [...users];
  //     data[inputNumb][e.target.name] = e.target.value;
  //     setUsers(data);
  //   }
  // };

  const [modalOpen, setModalOpen] = useState(false);
  const [certificateModal, SetCertificateModal] = useState(false);
  // image dialogue
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // certificate dialogue
  const handleCertificateOpen = () => {
    SetCertificateModal(true);
  };
  const handleCertificateClose = () => {
    SetCertificateModal(false);
  };

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const product_model = new Date(user?.product_model);
  const product_available_from = new Date(user?.product_available_from);
  // let productMonth = month[product_model?.getMonth() - 1];

  // useEffect(() => {
  //   if (user?.req_qty != '' && user?.req_qty != null) {
  //     setCheckedStatus(true);
  //     setReqValue(user?.req_qty);
  //   } else {
  //     setCheckedStatus(false);
  //   }
  // }, [user]);

  return (
    <>
      <StyledTableRow>
        {/* <StyledTableCell component="th" scope="row">
        {user?.number}
      </StyledTableCell> */}
        <StyledTableCell align="left">
          {user?.seller_name} {user?.seller_last_name}
        </StyledTableCell>
        <StyledTableCell align="left">
          <div className="flex items-center">
            <Button
              variant="contained"
              size="small"
              color="warning"
              // style={{ marginRight: '1rem' }}
              onClick={handleModalOpen}
            >
              {t('productDetailPage.viewProduct')}
            </Button>

            {user?.is_certified === 1 && (
              <Tooltip
                title="Certified Product - Please click here to view the certificate"
                placement="top"
              >
                <button
                  className="image-button ml-2"
                  onClick={handleCertificateOpen}
                >
                  <img src={QualityImage} alt="Button Image" />
                </button>
              </Tooltip>
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell align="left">
          {`${padL(product_available_from.getDate())}-${padL(
            product_available_from.getMonth() + 1
          )}-${product_available_from.getFullYear()}`}
          <br />
          <span className="italicSpan">
            {/* {`${productMonth} - ${product_model.getFullYear()}`} */}
            {`${
              month[product_model?.getMonth()]
                ? `${month[product_model?.getMonth()]} - `
                : ''
            }`}
            {`${product_model?.getFullYear()}`}
          </span>
        </StyledTableCell>
        <StyledTableCell align="left">
          {user?.price_type == 1 && (
            <>{user?.onsale_price && `₹ ${user?.onsale_price}`}</>
          )}

          {user?.price_type == 2 && (
            <>
              {user?.prices?.map((price) => (
                <>
                  <span className="italicSpan">
                    {price?.minimum} - {price?.maximum} {user?.unit} ₹
                    {price?.price && ` ${price?.price}`}
                  </span>
                  <br />
                </>
              ))}
            </>
          )}

          {/* <br />
        <span className="italicSpan">{user?.availQty1}</span>  */}
        </StyledTableCell>
        <StyledTableCell align="left">
          {/* {(index ? ', ' : '') + location?.location_code} */}
          {user?.locations?.length > 0 ? (
            <>
              {user?.locations?.map((location, index) => (
                <React.Fragment key={index}>
                  {index ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  {location?.location_name}, {location?.location_code}
                </React.Fragment>
              ))}
            </>
          ) : (
            <> No Location Found</>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {user?.locations?.map((location, index) => (
            <React.Fragment key={index}>
              {index ? (
                <>
                  <br />
                  <br />
                </>
              ) : (
                ''
              )}
              {location?.quantity} (
              {user?.unit == 'Quintals'
                ? 'Q'
                : user?.unit == 'Ltrs'
                ? 'L'
                : user?.unit == 'Bags'
                ? 'Bags'
                : 'Bales'}
              )
            </React.Fragment>
          ))}
        </StyledTableCell>

        <StyledTableCell align="center">
          <div>
            {user?.locations?.map((location, index) => {
              if (location?.quantity > 0) {
                return (
                  <TableInner
                    key={index}
                    zipCode={zipCode}
                    inputNumb={inputNumb}
                    locNumb={index}
                    user={user}
                    location={location}
                    locations={user?.locations}
                    // cartId={cartId}
                    selectedSeller={selectedSeller}
                    setSelectedSeller={setSelectedSeller}
                    users={users}
                    setUsers={setUsers}
                    setDisable={setDisable}
                    cartDetails={cartDetails}
                  />
                );
              } else {
                return (
                  <div className="flex items-center">
                    <Button
                      variant="contained"
                      color="warning"
                      size="large"
                      disabled
                      style={{
                        width: '100%',
                        marginTop: '.5rem',
                      }}
                    >
                      Out Of Stock
                    </Button>
                  </div>
                );
              }
            })}
          </div>
        </StyledTableCell>
        {/* <StyledTableCell align="center"> */}
        {/* {user?.locations?.map((location, index) => (
            <>
              {index ? (
                <>
                  <br />
                </>
              ) : (
                ''
              )}
              <FormControlLabel
                key={index}
                label=""
                control={
                  <Checkbox
                    checked={checkedStatus == true ? true : false}
                    onChange={handleChange1}
                    disabled={reqValue == '' ? true : false}
                    sx={{
                      color: '#f7991f',
                      '&.Mui-checked': {
                        color: '#f7991f',
                      },
                    }}
                  />
                }
              />
            </>
          ))} */}
        {/* </StyledTableCell> */}
      </StyledTableRow>

      {modalOpen && (
        <VideoImages
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          imagesArray={user?.gallery}
        />
      )}

      {certificateModal && (
        <ProductCertificateModal
          title="Product Quality Certificate"
          modalOpen={certificateModal}
          handleModalClose={handleCertificateClose}
          images={user?.certificate_url}
        />
      )}
    </>
  );
};

export default UserDetailTable;

import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';

const BuyerLayout = ({ children }) => {
  return (
    <div className="appContainer">
      <div className="headerWrapperOuter">
        <div className="wrapper">
          <div className="innerWrapper">
            <Header />
          </div>
        </div>
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default BuyerLayout;

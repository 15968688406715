import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import './addProduct.scss';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddPriceRange from './AddPriceRange';
import { styled, useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import qs from 'qs';
import axios from 'hooks/axios';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { toastMessageSuccess, toastMessageWarning } from '../../../utils/toast';
import FormData from 'form-data';
import 'dayjs/locale/en-gb';
import { useHistory } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import AddLocations from './AddLocations';
import ClearIcon from '@mui/icons-material/Clear';
import Compress from 'compress.js';
import VideoContainer from './VideoContainer';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.warning.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.warning.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const ConfigProductPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState('');
  const [selectedVariety, setSelectedVariety] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('Fixed');
  const [selectedUnit, setSelectedUnit] = useState('Quintals');
  const [selectedType, setSelectedType] = useState('');
  const [avail, setAvail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [publish, setPublish] = useState(true);
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  // const [stateList, setStateList] = useState([]);
  // const [cityList, setCityList] = useState([]);
  const [images, setImages] = useState([]);
  const [compImgs, setCompImgs] = useState([]);
  const [size, setSize] = useState(0);
  const [certificates, setCertificates] = useState([]);
  const [compCertificates, setCompCertificates] = useState([]);
  const [certificateSize, setCertificateSize] = useState(0);
  const pincodeChecker = /^[1-9][0-9]{5}$/;
  const [loading, setLoading] = useState(false);

  const maxNumber = 10;
  const maxCertifications = 5;

  const textFieldRef = useRef(null);
  const textFieldRef2 = useRef(null);
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setOpen(true);
    }
  };
  const handleKeyDown2 = (event) => {
    if (event.key === 'Enter') {
      setOpen(true);
    }
  };

  const handleFocus = () => {
    setOpen(true);
  };
  const handleFocus2 = () => {
    setOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    const handleOutsideClick2 = (event) => {
      if (
        textFieldRef2.current &&
        !textFieldRef2.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('mousedown', handleOutsideClick2);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('mousedown', handleOutsideClick2);
    };
  }, []);

  const [productInfo, setProductInfo] = useState({
    prod_units: '0',
    product_available_from: '',
    product_model: '',
    product_city: '',
    product_district: '',
    product_state_id: '',
    product_state: '',
    product_pincode: '',
    onsale_price: '',
  });

  const [nameOptions, setNameOptions] = useState([]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const userD = qs.stringify({
      seller_id: user?.seller_id,
    });

    axios
      .post(`${API_URL}/getsellerinfo`, userD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        if (
          res?.data?.result?.[0]?.upi_bank === '' ||
          res?.data?.result?.[0]?.upi_bank === null ||
          res?.data?.result?.[0]?.upi_bank === undefined
        ) {
          history.push('/sellerHome');
          toastMessageWarning({
            message: `Please fill your bank info in Manage Profile, to perform following actions !`,
          });
        }
      });

    const productsD = qs.stringify({});

    axios
      .post(`${API_URL}/categorys`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setNameOptions(res?.data?.result);
      });
  }, []);

  useDidMountEffect(() => {
    const productsD = qs.stringify({
      category_id: selectedCategory,
    });

    axios
      .post(`${API_URL}/productsvalues`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        if (res?.data?.result?.length == 0) {
          toastMessageWarning({
            message: `This Product is currently not available, try different one!`,
          });
          setProducts([]);
        } else {
          setProducts(res?.data?.result);
        }
      });
  }, [selectedCategory]);

  function DateConverter(date) {
    var dd = String(date?.getDate()).padStart(2, '0');
    var mm = String(date?.getMonth() + 1).padStart(2, '0');
    var yyyy = date?.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  const handlePrice = (event) => {
    setSelectedPrice(event.target.value);
  };
  const handleUnit = (event) => {
    setSelectedUnit(event.target.value);
  };
  const handleCategory = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedName('');
    setSelectedProduct([]);
    setSelectedQuality('');
    setSelectedVariety('');
    setSelectedType('');
    setAvail('');
  };

  const handleProductChange = (e) => {
    const productValues = products?.filter((product) => {
      if (product?.product_settings_id == e.target.value) {
        return product;
      }
    });

    setSelectedName(e.target.value);
    setSelectedProduct(productValues?.[0]);
    setSelectedQuality('');
    setSelectedVariety('');
    setSelectedType('');
    setAvail('');
  };

  const handleQuality = (e) => {
    setSelectedQuality(e.target.value);
  };
  const handleVariety = (e) => {
    setSelectedVariety(e.target.value);
  };
  const handleType = (e) => {
    setSelectedType(e.target.value);
  };
  const handleAvail = (e) => {
    setAvail(e.target.value);
  };

  useDidMountEffect(() => {
    setProductInfo({
      ...productInfo,
      product_available_from: DateConverter(startDate?.$d),
    });
  }, [startDate]);

  const [inputFields, setInputFields] = useState([
    {
      min: '',
      max: '',
      value: '',
    },
  ]);
  const [locationFields, setLocationFields] = useState([
    {
      name: '',
      code: '',
      radius: '',
      quantity: '',
    },
  ]);
  const [totalQty, setTotalQty] = useState(0);
  const [quotationLength, setQuotationLenght] = useState(1);
  const [locationLength, setLocationLength] = useState(1);
  const handleAddFields = () => {
    let newField = {
      min: '',
      max: '',
      value: '',
    };
    setInputFields([...inputFields, newField]);
    setQuotationLenght(quotationLength + 1);
  };
  const locationFieldAdd = () => {
    let newField = {
      name: '',
      code: '',
      radius: '',
      quantity: '',
    };
    setLocationFields([...locationFields, newField]);
    setLocationLength(locationLength + 1);
  };

  const handleRemoveField = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
    setQuotationLenght(quotationLength - 1);
  };

  const handleRemoveField1 = () => {
    let newField = {
      min: '',
      max: '',
      value: '',
    };

    let data = [...inputFields];

    data.shift();

    quotationLength > 1 ? setInputFields(data) : setInputFields([newField]);

    let newLength = quotationLength > 1 ? quotationLength - 1 : 1;
    setQuotationLenght(newLength);
  };

  const locationFieldRemove = (index) => {
    let data = [...locationFields];
    data.splice(index, 1);
    setLocationFields(data);
    setLocationLength(locationLength - 1);
  };

  const locationFieldRemove1 = () => {
    let newField = {
      name: '',
      code: '',
      radius: '',
      quantity: '',
    };

    let data = [...locationFields];

    data.shift();

    locationLength > 1
      ? setLocationFields(data)
      : setLocationFields([newField]);

    let newLength = locationLength > 1 ? locationLength - 1 : 1;
    setLocationLength(newLength);
  };

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const onChangeCertifications = (imageList, addUpdateIndex) => {
    // data for submit
    setCertificates(imageList);
  };

  const compressImgs = () => {
    const compress = new Compress();
    let files = [];
    for (var i = 0, j = images?.length; i < j; i++) {
      files.push(images[i]?.file);
    }

    compress
      .compress(files, {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.5, // the quality of the image, max is 1,
        resize: false, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
      })
      .then((results) => {
        // returns an array of compressed images
        const imgs = [];

        for (var i = 0, j = results?.length; i < j; i++) {
          const img1 = results[i];
          const base64str = img1.data;
          const imgExt = img1.ext;
          const imgName = img1.alt;
          const blobFile = Compress.convertBase64ToFile(base64str, imgExt);
          var imgFile = new File([blobFile], imgName);
          imgs.push(imgFile);
        }

        setCompImgs(imgs);
      });
  };
  const compressCertificateImages = () => {
    const compress = new Compress();
    let files = [];
    for (var i = 0, j = certificates?.length; i < j; i++) {
      files.push(certificates[i]?.file);
    }

    compress
      .compress(files, {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.5, // the quality of the image, max is 1,
        resize: false, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
      })
      .then((results) => {
        // returns an array of compressed certificates
        const imgs = [];

        for (var i = 0, j = results?.length; i < j; i++) {
          const img1 = results[i];
          const base64str = img1.data;
          const imgExt = img1.ext;
          const imgName = img1.alt;
          const blobFile = Compress.convertBase64ToFile(base64str, imgExt);
          var imgFile = new File([blobFile], imgName);
          imgs.push(imgFile);
        }

        setCompCertificates(imgs);
      });
  };

  useEffect(() => {
    if (images) {
      compressImgs();
    }
  }, [images]);

  useEffect(() => {
    if (certificates) {
      compressCertificateImages();
    }
  }, [certificates]);

  useEffect(() => {
    let sum = 0;

    for (let i = 0; i < compImgs.length; i++) {
      if (compImgs[i]?.size) {
        sum += parseInt(compImgs[i]?.size);
      }
    }
    setSize(sum);
  }, [compImgs]);

  useEffect(() => {
    let sum = 0;

    for (let i = 0; i < compCertificates.length; i++) {
      if (compCertificates[i]?.size) {
        sum += parseInt(compCertificates[i]?.size);
      }
    }
    setCertificateSize(sum);
  }, [compCertificates]);

  // videos flow
  const [videos, setVideos] = useState([]);

  const videoRef = useRef(null);
  const handleVideoClick = (e) => {
    e.preventDefault();
    videoRef.current.click();
  };

  const onVideoChange = (e) => {
    const files = e.target.files[0];
    if (files.size > 20 * 1024 * 1024) {
      toastMessageWarning({
        message: `Maximum product video size allowed is 20mb !`,
      });
      setVideos([]);
    } else {
      // const files = Array.from(e.target.files).slice(0, 2); // Take only the first two files

      setVideos([files]);
    }
  };

  const removeVideos = () => {
    setVideos([]);
  };
  const removeVideo = (index) => {
    let data = [...videos];
    data.splice(index, 1);
    setVideos(data);
  };

  // certification flow
  // const [certificateImage, setCertificateImage] = useState([]);
  // const [compCertificateImage, setCompCertificateImage] = useState([]);
  // const [certificateSize, setCertificateSize] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState(false);
  // const inputRef = useRef(null);
  // const [src, setSrc] = useState(null);

  // const handleInputClick = (e) => {
  //   e.preventDefault();
  //   inputRef.current.click();
  // };

  // const onCertificationChange = (e) => {
  //   setCertificateImage(e.target.files);
  //   setSrc(URL.createObjectURL(e.target.files[0]));
  // };
  // const removeCertification = () => {
  //   setSrc(null);
  //   setCertificateImage([]);
  // };
  // const compressCertificate = () => {
  //   const compress = new Compress();
  //   let files = [];

  //   for (var i = 0, j = certificateImage?.length; i < j; i++) {
  //     files.push(certificateImage[i]);
  //   }

  //   compress
  //     .compress(files, {
  //       size: 1, // the max size in MB, defaults to 2MB
  //       quality: 0.5, // the quality of the image, max is 1,
  //       resize: false, // defaults to true, set false if you do not want to resize the image width and height
  //       rotate: false, // See the rotation section below
  //     })
  //     .then((results) => {
  //       // returns an array of compressed images
  //       const imgs = [];

  //       for (var i = 0, j = results?.length; i < j; i++) {
  //         const img1 = results[i];
  //         const base64str = img1.data;
  //         const imgExt = img1.ext;
  //         const imgName = img1.alt;
  //         const blobFile = Compress.convertBase64ToFile(base64str, imgExt);
  //         var imgFile = new File([blobFile], imgName);
  //         imgs.push(imgFile);
  //       }

  //       setCompCertificateImage(imgs);
  //     });
  // };

  // useEffect(() => {
  //   if (certificateImage) {
  //     compressCertificate();
  //   }
  // }, [certificateImage]);

  // useEffect(() => {
  //   let sum = 0;

  //   for (let i = 0; i < compCertificateImage.length; i++) {
  //     if (compCertificateImage[i]?.size) {
  //       sum += parseInt(compCertificateImage[i]?.size);
  //     }
  //   }
  //   setCertificateSize(sum);
  // }, [compCertificateImage]);

  // certification check
  const handleChangeCertification = (e) => {
    if (e.target.checked == false) {
      setCheckedStatus(false);
    } else {
      setCheckedStatus(true);
    }
  };

  const handleSaveProducts = () => {
    if (
      selectedName !== '' &&
      selectedCategory !== '' &&
      selectedQuality !== '' &&
      selectedType !== '' &&
      avail !== '' &&
      selectedVariety !== '' &&
      selectedUnit !== '' &&
      productInfo?.prod_units !== '' &&
      productInfo?.product_available_from !== '' &&
      productInfo?.product_model !== '' &&
      productInfo?.product_model !== undefined
      // productInfo?.product_city !== '' &&
      // productInfo?.product_district !== '' &&
      // productInfo?.product_state !== '' &&
      // productInfo?.product_pincode !== '' &&
      // pincodeChecker.test(productInfo?.product_pincode) !== false
    ) {
      if (size >= 1048576) {
        toastMessageWarning({
          message: `Product Images exceeded the size of 1mb, Please add appropriate images !`,
        });
      } else {
        if (checkedStatus === true) {
          if (compCertificates?.length <= 0) {
            return toastMessageWarning({
              message: `Please Upload Your Product Certification first !`,
            });
          } else {
            if (certificateSize >= 1048576) {
              toastMessageWarning({
                message: `Certification Images exceeded the size of 1mb, Please add appropriate images !`,
              });
            } else {
              callApi();
            }
          }
        } else {
          callApi();
        }
      }
    } else {
      if (
        selectedName === '' ||
        selectedCategory === '' ||
        selectedQuality === '' ||
        selectedType === '' ||
        avail === '' ||
        selectedVariety === '' ||
        selectedUnit === '' ||
        productInfo?.prod_units === '' ||
        productInfo?.product_available_from === '' ||
        productInfo?.product_model === '' ||
        productInfo?.product_model === undefined
        // productInfo?.product_city === '' ||
        // productInfo?.product_district === '' ||
        // productInfo?.product_state === '' ||
        // productInfo?.product_pincode === ''
      ) {
        return toastMessageWarning({
          message: `Please fill all the required fields first.`,
        });
      }
    }
  };

  const callApi = () => {
    if (selectedPrice == 'Fixed') {
      if (productInfo?.onsale_price !== '') {
        const data = locationFields;
        let warning = true;
        // check for warnings
        for (let x in data) {
          if (
            data[x]?.name === '' ||
            data[x]?.name === null ||
            data[x]?.name === undefined ||
            data[x]?.code === '' ||
            data[x]?.code === null ||
            data[x]?.code === undefined ||
            pincodeChecker.test(data[x]?.code) !== true ||
            data[x]?.radius === '' ||
            data[x]?.radius === null ||
            data[x]?.radius === undefined ||
            data[x]?.quantity === null ||
            data[x]?.quantity === undefined ||
            data[x]?.quantity === ''
          ) {
            warning = true;
            break;
          } else {
            warning = false;
          }
        }
        // warning found
        if (warning === true) {
          for (let x in data) {
            if (
              data[x]?.name === '' ||
              data[x]?.name === null ||
              data[x]?.name === undefined ||
              data[x]?.code === '' ||
              data[x]?.code === null ||
              data[x]?.code === undefined ||
              data[x]?.radius === '' ||
              data[x]?.radius === null ||
              data[x]?.radius === undefined ||
              data[x]?.quantity === null ||
              data[x]?.quantity === undefined ||
              data[x]?.quantity === ''
            ) {
              return toastMessageWarning({
                message: `Please Fill All The Delivery Location Values in ${
                  parseInt(x) + 1
                } row !`,
              });
            } else if (pincodeChecker.test(data[x]?.code) === false) {
              return toastMessageWarning({
                message: `Please Add A Valid 6 Digit Delivery Location Pincode in ${
                  parseInt(x) + 1
                } row !`,
              });
            }
          }
        }

        // no warning found
        if (warning === false) {
          setLoading(true);
          let productsD = new FormData();
          productsD.append('product_name', selectedName);
          productsD.append('category_id', selectedCategory);
          productsD.append('seller_id', user?.seller_id);
          productsD.append('product_quality', selectedQuality);
          productsD.append('product_type', selectedType);
          productsD.append('product_available', avail);
          productsD.append('product_variety', selectedVariety);
          productsD.append('prod_units', productInfo?.prod_units);
          productsD.append(
            'product_available_from',
            productInfo?.product_available_from
          );
          productsD.append('product_model', productInfo?.product_model?.$d);
          productsD.append('price_type', selectedPrice == 'Fixed' ? 1 : 2);
          productsD.append('unit', selectedUnit);
          productsD.append('status', publish === true ? 1 : 2);
          productsD.append('onsale_price', productInfo?.onsale_price);
          productsD.append('is_certified', checkedStatus === true ? 1 : 0);
          if (checkedStatus === true) {
            // productsD.append('certificate_file', compCertificates?.[0]);
            for (let x in compCertificates) {
              productsD.append(`certificate_file[${x}]`, compCertificates?.[x]);
            }
          }
          // for (let x in images) {
          //   productsD.append(`product_gallery[${x}]`, images?.[x]?.file);
          // }
          for (let x in compImgs) {
            productsD.append(`product_gallery[${x}]`, compImgs?.[x]);
          }
          for (let x in videos) {
            productsD.append(`video_gallery[${x}]`, videos?.[x]);
          }
          for (let x in data) {
            productsD.append(
              `location[${x}]`,
              JSON.stringify({
                name: data[x].name,
                code: data[x].code,
                radius: data[x].radius,
                quantity: data[x].quantity,
              })
            );
          }

          axios
            .post(`${API_URL}/saveproduct-form`, productsD, {
              headers: {
                Authorization: `${authToken}`,
              },
            })
            .then((res) => {
              setLoading(false);
              if (res?.data?.status === false) {
                toastMessageWarning({
                  message: `${res?.data?.message}`,
                });
              } else {
                toastMessageSuccess({
                  message: `${res?.data?.message}`,
                });
                setSelectedCategory('');
                setSelectedName('');
                setSelectedProduct([]);
                setSelectedQuality('');
                setSelectedVariety('');
                setSelectedType('');
                setAvail('');
                setStartDate('');
                setPublish(true);
                setSelectedPrice('Fixed');
                setSelectedUnit('Quintals');
                setProductInfo({
                  prod_units: '',
                  product_available_from: '',
                  product_model: '',
                  product_city: '',
                  product_district: '',
                  product_state: '',
                  product_pincode: '',
                  onsale_price: '',
                });
                history.push(`/view products`);
              }
            });
        }
      } else {
        toastMessageWarning({ message: `Please add price value` });
      }
    } else {
      const data = inputFields;
      let warning = true;
      const locData = locationFields;
      // check for location warnings
      for (let x in locData) {
        if (
          locData[x]?.name === '' ||
          locData[x]?.name === null ||
          locData[x]?.name === undefined ||
          locData[x]?.code === '' ||
          locData[x]?.code === null ||
          locData[x]?.code === undefined ||
          pincodeChecker.test(locData[x]?.code) !== true ||
          locData[x]?.radius === '' ||
          locData[x]?.radius === null ||
          locData[x]?.radius === undefined ||
          locData[x]?.quantity === null ||
          locData[x]?.quantity === undefined ||
          locData[x]?.quantity === ''
        ) {
          warning = true;
          break;
        } else {
          warning = false;
        }
      }
      // warning found
      if (warning === true) {
        for (let x in locData) {
          if (
            locData[x]?.name === '' ||
            locData[x]?.name === null ||
            locData[x]?.name === undefined ||
            locData[x]?.code === '' ||
            locData[x]?.code === null ||
            locData[x]?.code === undefined ||
            locData[x]?.radius === '' ||
            locData[x]?.radius === null ||
            locData[x]?.radius === undefined ||
            locData[x]?.quantity === null ||
            locData[x]?.quantity === undefined ||
            locData[x]?.quantity === ''
          ) {
            return toastMessageWarning({
              message: `Please Fill All The Delivery Location Values in ${
                parseInt(x) + 1
              } row !`,
            });
          } else if (pincodeChecker.test(locData[x]?.code) === false) {
            return toastMessageWarning({
              message: `Please Add A Valid 6 Digit Delivery Location Pincode in ${
                parseInt(x) + 1
              } row !`,
            });
          }
        }
      }

      // check for warnings
      for (let x in data) {
        if (
          data[x]?.min === '' ||
          data[x]?.max === '' ||
          data[x]?.value === ''
        ) {
          warning = true;
          break;
        } else {
          warning = false;
        }
      }
      // warning found
      if (warning === true) {
        for (let x in data) {
          if (
            data[x]?.min === '' ||
            data[x]?.max === '' ||
            data[x]?.value === ''
          ) {
            return toastMessageWarning({
              message: `Please Fill All The Price Ranges !`,
            });
          }
        }
      }

      // no warning found
      if (warning === false) {
        // check if there is and duplicate field in it
        function checkDuplicates(arr) {
          let numCount = {};

          for (let i = 0; i < arr.length; i++) {
            let min = arr[i].min;
            let max = arr[i].max;

            // Check occurrences in min
            numCount[min] = (numCount[min] || 0) + 1;
            // Check occurrences in max
            numCount[max] = (numCount[max] || 0) + 1;
          }

          // Check if any number exists more than once
          for (let num in numCount) {
            if (numCount[num] > 1) {
              return true;
            }
          }

          return false;
        }

        let newWarning = checkDuplicates(data);
        // duplicate  found
        if (newWarning == true) {
          return toastMessageWarning({
            message: `In Min-Max quantity is repeated, please fill valid quantity without any duplications !`,
          });
        }
        // duplicate  not found
        if (newWarning === false) {
          // check if max is less than min or not
          function hasInvalidRange(arr) {
            for (let i = 0; i < arr.length; i++) {
              const min = parseFloat(arr[i].min);
              const max = parseFloat(arr[i].max);

              if (isNaN(min) || isNaN(max)) {
                console.error(
                  `Invalid number at index ${i}: min=${arr[i].min}, max=${arr[i].max}`
                );
                continue; // Skip invalid entries
              }

              if (max < min) {
                return true;
              }
            }
            return false;
          }

          let minMaxWarning = hasInvalidRange(data);

          if (minMaxWarning == true) {
            return toastMessageWarning({
              message: `Max value should always be greater than Min value !`,
            });
          }
          if (minMaxWarning == false) {
            setLoading(true);
            let productsD = new FormData();
            productsD.append('product_name', selectedName);
            productsD.append('category_id', selectedCategory);
            productsD.append('seller_id', user?.seller_id);
            productsD.append('product_quality', selectedQuality);
            productsD.append('product_type', selectedType);
            productsD.append('product_available', avail);
            productsD.append('product_variety', selectedVariety);
            productsD.append('prod_units', productInfo?.prod_units);
            productsD.append(
              'product_available_from',
              productInfo?.product_available_from
            );
            productsD.append('product_model', productInfo?.product_model?.$y);
            // productsD.append('product_city', productInfo?.product_city);
            // productsD.append('product_district', productInfo?.product_district);
            // productsD.append('product_state', productInfo?.product_state);
            // productsD.append('product_pincode', productInfo?.product_pincode);
            productsD.append('price_type', selectedPrice == 'Fixed' ? 1 : 2);
            productsD.append('unit', selectedUnit);
            productsD.append('status', publish === true ? 1 : 2);
            productsD.append('is_certified', checkedStatus === true ? 1 : 0);
            if (checkedStatus === true) {
              // productsD.append('certificate_file', compCertificates?.[0]);
              for (let x in compCertificates) {
                productsD.append(
                  `certificate_file[${x}]`,
                  compCertificates?.[x]
                );
              }
            }
            // productsD.append('onsale_price', productInfo?.onsale_price);
            for (let x in data) {
              productsD.append(
                `varible_prices[${x}]`,
                JSON.stringify({
                  min: data[x].min,
                  max: data[x].max,
                  price: data[x].value,
                })
              );
            }
            for (let x in locData) {
              productsD.append(
                `location[${x}]`,
                JSON.stringify({
                  name: locData[x].name,
                  code: locData[x].code,
                  radius: locData[x].radius,
                  quantity: locData[x].quantity,
                })
              );
            }
            // for (let x in images) {
            //   productsD.append(`product_gallery[${x}]`, images?.[x]?.file);
            // }
            for (let x in compImgs) {
              productsD.append(`product_gallery[${x}]`, compImgs?.[x]);
            }

            for (let x in videos) {
              productsD.append(`video_gallery[${x}]`, videos?.[x]);
            }

            axios
              .post(`${API_URL}/saveproduct-form`, productsD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setLoading(false);
                if (res?.data?.status === false) {
                  toastMessageWarning({
                    message: `${res?.data?.message}`,
                  });
                } else {
                  toastMessageSuccess({
                    message: `${res?.data?.message}`,
                  });
                  setSelectedCategory('');
                  setSelectedName('');
                  setSelectedProduct([]);
                  setSelectedQuality('');
                  setSelectedVariety('');
                  setSelectedType('');
                  setAvail('');
                  setStartDate('');
                  setPublish(true);
                  setSelectedPrice('Fixed');
                  setSelectedUnit('Quintals');
                  setProductInfo({
                    prod_units: '',
                    product_available_from: '',
                    product_model: '',
                    product_city: '',
                    product_district: '',
                    product_state: '',
                    product_pincode: '',
                    onsale_price: '',
                  });
                  history.push(`/view products`);
                }
              });
          }
        }
      }
    }
  };

  useEffect(() => {
    let sum = 0;

    for (let i = 0; i < locationFields.length; i++) {
      if (locationFields[i]?.quantity) {
        sum += parseInt(locationFields[i]?.quantity);
      }
    }
    setTotalQty(sum);
  }, [locationFields]);

  return (
    <section className="addProductPage">
      <Card sx={{ width: '100%' }}>
        <Typography gutterBottom variant="h6" component="div" className="p-3">
          {t('sellerProductForm.config')}
        </Typography>
      </Card>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
          }),
          wrapper: {
            overflow: 'hidden',
          },
        }}
        spinner={<ClipLoader color="#F7991F" size={90} />}
      >
        <Card sx={{ width: '100%' }} className="mt-8 p-6">
          <div className="addProductFormContainer">
            <div className="publishBtn">
              <FormControlLabel
                control={
                  <Android12Switch
                    color="warning"
                    checked={publish === true ? true : false}
                    onChange={() => setPublish(!publish)}
                  />
                }
                label={t('sellerProductForm.publish')}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="qna-container">
                <label htmlFor="pName" className="question">
                  {t('sellerProductForm.category')}*
                </label>
                <div className="answer">
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={selectedCategory}
                      onChange={handleCategory}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('sellerProductForm.category')}
                        </em>
                      </MenuItem>
                      {nameOptions.map((option) => (
                        <MenuItem
                          value={option?.category_id}
                          key={option?.category_id}
                        >
                          {option?.category_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="pName" className="question">
                  {t('sellerProductForm.name')}*
                </label>
                <div className="answer">
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={selectedName}
                      onChange={handleProductChange}
                      displayEmpty
                      disabled={
                        selectedCategory == ''
                          ? true
                          : products?.length > 0
                          ? false
                          : true
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('sellerProductForm.name')}
                        </em>
                      </MenuItem>
                      {products?.map((option) => (
                        <MenuItem
                          value={option?.product_settings_id}
                          key={option?.product_settings_id}
                        >
                          {option?.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="pQuality" className="question">
                  {t('sellerProductForm.quality')}*
                </label>
                <div className="answer">
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={selectedQuality}
                      onChange={handleQuality}
                      displayEmpty
                      disabled={selectedProduct?.length == 0 ? true : false}
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('sellerProductForm.quality')}
                        </em>
                      </MenuItem>
                      {selectedProduct?.product_quality?.map((option) => (
                        <MenuItem
                          value={option?.product_quality_id}
                          key={option?.product_quality_id}
                        >
                          {option?.product_quality_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="pType" className="question">
                  {t('sellerProductForm.type')}*
                </label>
                <div className="answer">
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={selectedType}
                      onChange={handleType}
                      displayEmpty
                      disabled={selectedProduct?.length == 0 ? true : false}
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('sellerProductForm.type')}
                        </em>
                      </MenuItem>
                      {selectedProduct?.product_type?.map((option) => (
                        <MenuItem
                          value={option?.product_type_id}
                          key={option?.product_type_id}
                        >
                          {option?.product_type_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="Variety" className="question">
                  {t('sellerProductForm.variety')}*
                </label>
                <div className="answer">
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={selectedVariety}
                      onChange={handleVariety}
                      displayEmpty
                      disabled={selectedProduct?.length == 0 ? true : false}
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('sellerProductForm.variety')}
                        </em>
                      </MenuItem>
                      {selectedProduct?.product_variety?.map((option) => (
                        <MenuItem
                          value={option?.product_variety_id}
                          key={option?.product_variety_id}
                        >
                          {option?.product_variety_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="pAvail" className="question">
                  {t('sellerProductForm.availIn')}*
                </label>
                <div className="answer">
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      value={avail}
                      onChange={handleAvail}
                      displayEmpty
                      disabled={selectedProduct?.length == 0 ? true : false}
                    >
                      <MenuItem value="" disabled>
                        <em>
                          {t('sellerProductForm.select')}{' '}
                          {t('sellerProductForm.availIn')}
                        </em>
                      </MenuItem>
                      {selectedProduct?.product_available?.map((option) => (
                        <MenuItem
                          value={option?.product_available_id}
                          key={option?.product_available_id}
                        >
                          {option?.product_available_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="availFrom" className="question">
                  {t('sellerProductForm.availFrom')}*
                </label>
                <div className="answer">
                  <LocalizationProvider
                    adapterLocale="en-gb"
                    dateAdapter={AdapterDayjs}
                  >
                    <DemoContainer
                      components={['DatePicker', 'DatePicker', 'DatePicker']}
                    >
                      <DatePicker
                        inputFormat="DD-MM-YYYY"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          setOpen(false);
                        }}
                        open={open}
                        onClose={() => setOpen(false)}
                        slotProps={{
                          textField: {
                            onClick: () => setOpen(true),
                            error: false,
                            onFocus: () => {
                              handleFocus;
                            },
                            onKeyDown: () => {
                              handleKeyDown;
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            ref={textFieldRef}
                            onClick={() => setOpen(true)}
                            onFocus={handleFocus}
                            onKeyDown={handleKeyDown}
                            inputProps={{
                              ...params.inputProps,
                              tabIndex: 0,
                              style: { cursor: 'pointer' },
                            }}
                            fullWidth
                            sx={{
                              '&:focus': {
                                outline: `2px solid ${theme.palette.primary.main}`,
                              },
                            }}
                          />
                        )}
                        // disableFuture={true}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="qna-container">
                <label htmlFor="cropYear" className="question">
                  {t('sellerProductForm.year')}*
                </label>
                <div className="answer">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={['DatePicker', 'DatePicker', 'DatePicker']}
                    >
                      <DatePicker
                        value={productInfo?.product_model}
                        onChange={(newValue) => {
                          setProductInfo({
                            ...productInfo,
                            product_model: newValue,
                          });
                          setOpen2(false);
                        }}
                        open={open2}
                        onClose={() => setOpen2(false)}
                        slotProps={{
                          textField: {
                            onClick: () => setOpen2(true),
                            error: false,
                            onFocus: () => {
                              handleFocus2;
                            },
                            onKeyDown: () => {
                              handleKeyDown2;
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            ref={textFieldRef2}
                            onClick={() => setOpen2(true)}
                            onFocus={handleFocus2}
                            onKeyDown={handleKeyDown2}
                            inputProps={{
                              ...params.inputProps,
                              tabIndex: 0,
                              style: { cursor: 'pointer' },
                            }}
                            fullWidth
                            sx={{
                              '&:focus': {
                                outline: `2px solid ${theme.palette.primary.main}`,
                              },
                            }}
                          />
                        )}
                        disableFuture={true}
                        views={['month', 'year']}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>

          {/* price range */}
          <div className="priceContainer">
            <div className="priceTitle">
              {t('sellerProductForm.priceConfig')}
            </div>
            <div className="qna-container">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label" color="warning">
                  {t('sellerProductForm.unitType')}*
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedUnit}
                  onChange={handleUnit}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Quintals"
                    control={<Radio color="warning" />}
                    label={t('sellerProductForm.quintals')}
                  />
                  <FormControlLabel
                    value="Ltrs"
                    control={<Radio color="warning" />}
                    label={t('sellerProductForm.ltrs')}
                  />
                  <FormControlLabel
                    value="Bags"
                    control={<Radio color="warning" />}
                    label={t('sellerProductForm.bags')}
                  />
                  <FormControlLabel
                    value="Bales"
                    control={<Radio color="warning" />}
                    label={t('sellerProductForm.bales')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="qna-container">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label" color="warning">
                  {t('sellerProductForm.priceType')}*
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedPrice}
                  onChange={handlePrice}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Fixed"
                    control={<Radio color="warning" />}
                    label={t('sellerProductForm.fixed')}
                  />
                  <FormControlLabel
                    value="Variables"
                    control={<Radio color="warning" />}
                    label={t('sellerProductForm.variable')}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {/* <div className="qna-container">
            <label htmlFor="pAvail" className="question">
              Available Qty
            </label>
            <div className="answer">
              <input
                id="availQty"
                name="availQty"
                type="number"
                className="textField w-100"
                placeholder="Available Qty"
                min={1}
                value={productInfo?.prod_units}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(evt) =>
                  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                }
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    prod_units: e.target.value,
                  })
                }
              />
            </div>
          </div> */}

            {selectedPrice != 'Variables' && (
              <div className="qna-container">
                <label htmlFor="Value (INR)" className="question">
                  {t('sellerProductForm.per')} {selectedUnit}
                </label>
                <div className="answer">
                  <input
                    id="Value (INR)"
                    name="Value (INR)"
                    type="number"
                    className="textField w-100"
                    placeholder={t('sellerProductForm.priceType')}
                    min={1}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    value={productInfo?.onsale_price}
                    onChange={(e) =>
                      setProductInfo({
                        ...productInfo,
                        onsale_price: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
            {selectedPrice == 'Variables' && (
              <>
                {inputFields?.map((input, index) => (
                  <AddPriceRange
                    key={index}
                    inputNumb={index}
                    input={input}
                    inputFields={inputFields}
                    setInputFields={setInputFields}
                    handleRemoveField={handleRemoveField}
                    handleRemoveField1={handleRemoveField1}
                    handleAddFields={handleAddFields}
                    quotationLength={quotationLength}
                    unit={selectedUnit}
                  />
                ))}
              </>
            )}
          </div>

          {/* delivery range */}
          <div className="priceContainer mb-4">
            <div className="priceTitle">{t('sellerProductForm.availLoc')}</div>
            {locationFields?.map((input, index) => (
              <AddLocations
                key={index}
                inputNumb={index}
                input={input}
                inputFields={locationFields}
                setInputFields={setLocationFields}
                handleRemoveField={locationFieldRemove}
                handleRemoveField1={locationFieldRemove1}
                handleAddFields={locationFieldAdd}
                quotationLength={locationLength}
              />
            ))}
          </div>

          <div className="priceContainer" style={{ marginBottom: '0' }}>
            <div className="qna-container">
              <label htmlFor="totalQty" className="question">
                Total Available Qty*
              </label>
              <TextField
                id="totalQty"
                name="totalQty"
                type="number"
                className="textField w-100"
                placeholder="Available Qty"
                disabled
                value={totalQty}
                min={1}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(evt) =>
                  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                }
              />
            </div>
          </div>

          <div className="priceContainer">
            <div className="priceTitle">
              {t('sellerProductForm.productImgs')}
            </div>
            {/* image container */}
            <ImageUploading
              multiple
              value={images}
              onChange={onChangeImage}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              // className="mb-4"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                // onImageUpdate,
                onImageRemove,
                // isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {/* <div className="grid grid-cols-1  md:grid-cols-2 gap-2 md:gap-12 mb-2"> */}
                  <Button
                    style={{ marginTop: '.5rem' }}
                    variant="contained"
                    size="small"
                    color="warning"
                    // style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    {t('sellerProductForm.uploadImages')}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    style={{ marginTop: '.5rem' }}
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={onImageRemoveAll}
                    disabled={imageList?.length == 0 ? true : false}
                  >
                    {t('sellerProductForm.removeImages')}
                  </Button>
                  {/* </div> */}
                  <div className="grid grid-cols-1  place-items-center md:grid-cols-2 lg:grid-cols-3  gap-1 md:gap-2 lg:gap-4 mt-3">
                    {imageList?.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => onImageRemove(index)}
                          >
                            <ClearIcon />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>

          {/* videos */}
          <div className="priceContainer">
            <div className="priceTitle" style={{ marginBottom: '0' }}>
              {t('sellerProductForm.productVideos')}
            </div>
            <span>{t('sellerProductForm.note')}</span>
            {/* videos container */}
            <div className="upload__image-wrapper">
              <Button
                style={{ marginTop: '.5rem' }}
                variant="contained"
                size="small"
                color="warning"
                onClick={handleVideoClick}
              >
                {t('sellerProductForm.uploadVideo')}
              </Button>
              <input
                id="file-upload"
                // multiple
                type="file"
                // accept="video/*"
                accept="mp4"
                ref={videoRef}
                // onClick={() => setVideos([])}
                onChange={onVideoChange}
                onClick={(event) => {
                  event.target.value = null;
                  setVideos([]);
                }}
              />
              &nbsp; &nbsp;
              <Button
                style={{ marginTop: '.5rem' }}
                variant="contained"
                size="small"
                color="error"
                onClick={removeVideos}
                disabled={videos?.length > 0 ? false : true}
              >
                {t('sellerProductForm.remove')}
              </Button>
              {/* </div> */}
              <div className="grid grid-cols-1  place-items-center md:grid-cols-2 lg:grid-cols-3  gap-1 md:gap-2 lg:gap-4 mt-3">
                {videos?.map((video, index) => (
                  <VideoContainer
                    key={index}
                    removeVideo={removeVideo}
                    index={index}
                    video={video}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="agreeLink">
            <FormControlLabel
              label={t('sellerProductForm.certified')}
              style={{
                margin: '0',
              }}
              control={
                <Checkbox
                  checked={checkedStatus == true ? true : false}
                  onChange={handleChangeCertification}
                  sx={{
                    color: '#f7991f',
                    '&.Mui-checked': {
                      color: '#f7991f',
                    },
                  }}
                />
              }
            />
          </div>

          {/* certificationContainer */}
          {checkedStatus === true && (
            <div className="priceContainer">
              <div className="priceTitle" style={{ marginTop: '0' }}>
                {t('sellerProductForm.certImage')}
              </div>

              {/* <div className="upload__image-wrapper">
                <Button
                  style={{ marginTop: '.5rem' }}
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={handleInputClick}
                >
                  {t('sellerProductForm.uploadCert')}
                </Button>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={onCertificationChange}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
                &nbsp; &nbsp;
                <Button
                  style={{ marginTop: '.5rem' }}
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={removeVideos}
                  disabled={src ? false : true}
                >
                  {t('sellerProductForm.remove')}
                </Button>
                {src && (
                  <div className="grid grid-cols-1  place-items-center md:grid-cols-2 lg:grid-cols-3  gap-1 md:gap-2 lg:gap-4 mt-3">
                    <div className="image-item">
                      <img src={src} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={removeCertification}
                        >
                          <ClearIcon />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div> */}

              {/* new Way multi certificates */}
              <ImageUploading
                multiple
                value={certificates}
                onChange={onChangeCertifications}
                maxNumber={maxCertifications}
                dataURLKey="data_url"
                // className="mb-4"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageRemove,
                  // isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <Button
                      style={{ marginTop: '.5rem' }}
                      variant="contained"
                      size="small"
                      color="warning"
                      // style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {t('sellerProductForm.uploadCert')}
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      style={{ marginTop: '.5rem' }}
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={onImageRemoveAll}
                      disabled={imageList?.length == 0 ? true : false}
                    >
                      {t('sellerProductForm.removeCertificates')}
                    </Button>
                    {/* </div> */}
                    <div className="grid grid-cols-1  place-items-center md:grid-cols-2 lg:grid-cols-3  gap-1 md:gap-2 lg:gap-4 mt-3">
                      {imageList?.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image['data_url']} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <Button
                              variant="contained"
                              size="small"
                              color="error"
                              onClick={() => onImageRemove(index)}
                            >
                              <ClearIcon />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
          )}

          <div className="btnContainer mt-3">
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                size="medium"
                color="success"
                onClick={handleSaveProducts}
              >
                {t('sellerProductForm.save')}
              </Button>
              {/* <Button variant="contained" size="medium" color="error">
              Cancel
            </Button> */}
            </Stack>
          </div>
        </Card>
      </LoadingOverlay>
    </section>
  );
};

export default ConfigProductPage;

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useEffect, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'hooks/axios';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DeliveryAddress({
  handleAddressClose,
  setAddressDetails,
  handleEditClose,
  adressOpen,
  address,
  setAdress,
  editName,
}) {
  const [error, setError] = useState(false);
  const pincodeChecker = /^[1-9][0-9]{5}$/;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const countryD = qs.stringify({});

    axios
      .post(`${API_URL}/countrieslist`, countryD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setCountryList(res?.data?.result);
      });
  }, [address]);

  useEffect(() => {
    if (address?.buyer_country) {
      const countryD = qs.stringify({
        country_id: address?.buyer_country,
      });

      axios
        .post(`${API_URL}/stateslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setStateList(res?.data?.result);
        });
    }
  }, [address]);

  useEffect(() => {
    if (address?.buyer_state) {
      const countryD = qs.stringify({
        state_id: address?.buyer_state,
      });

      axios
        .post(`${API_URL}/citieslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setCityList(res?.data?.result);
        });
    }
  }, [address]);

  const handleCountryChange = (e) => {
    const countryValues = countryList?.filter((countryList) => {
      if (countryList?.id == e.target.value) {
        return countryList;
      }
    });

    setAdress({
      ...address,
      buyer_country: countryValues?.[0]?.id,
    });
  };

  const handleStateChange = (e) => {
    const stateValues = stateList?.filter((stateList) => {
      if (stateList?.id == e.target.value) {
        return stateList;
      }
    });

    setAdress({
      ...address,
      buyer_state: stateValues?.[0]?.id,
    });
  };

  const handleAddAddress = () => {
    if (
      address?.address_label !== '' &&
      address?.buyer_short_address !== '' &&
      address?.buyer_address_1 !== '' &&
      // address?.buyer_address_2 !== '' &&
      address?.buyer_country !== '' &&
      address?.buyer_state !== '' &&
      address?.buyer_city !== '' &&
      address?.buyer_zip !== '' &&
      pincodeChecker.test(address?.buyer_zip) !== false
    ) {
      setError(false);
      const singD = qs.stringify({
        // order_id: address?.order_id,
        buyer_id: address?.buyer_id,
        buyer_name: address?.buyer_name,
        address_label: address?.address_label,
        buyer_short_address: address?.buyer_short_address,
        buyer_address_1: address?.buyer_address_1,
        buyer_address_2: address?.buyer_address_2,
        buyer_city: address?.buyer_city,
        buyer_state: address?.buyer_state,
        buyer_country: address?.buyer_country,
        buyer_zip: address?.buyer_zip,
        buyer_mobile: address?.buyer_mobile,
        buyer_email: address?.buyer_email,
      });

      axios
        .post(`${API_URL}/addbuyeraddress`, singD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            const cartD = qs.stringify({
              buyer_id: address?.buyer_id,
            });

            axios
              .post(`${API_URL}/buyeraddress`, cartD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setAddressDetails(res?.data?.result);
              });
            handleAddressClose();
            setAdress({
              ...address,
              // buyer_name: '',
              address_label: '',
              buyer_short_address: '',
              buyer_address_1: '',
              buyer_address_2: '',
              // buyer_mobile: '',
              // buyer_email: '',
              buyer_country: '101',
              buyer_state: '',
              buyer_city: '',
              buyer_zip: '',
            });
          }
        });
    } else {
      setError(true);

      if (address.address_label === '') {
        return toastMessageWarning({
          message: 'Address Label Cannot be empty',
        });
      } else if (address.buyer_short_address === '') {
        return toastMessageWarning({
          message: 'Short Address Cannot be empty',
        });
      } else if (address.buyer_address_1 === '') {
        return toastMessageWarning({
          message: 'Address Line 1 Cannot be empty',
        });
      } else if (address.buyer_country === '') {
        return toastMessageWarning({ message: 'Country Name Cannot be empty' });
      } else if (address.buyer_state === '') {
        return toastMessageWarning({ message: 'State Name Cannot be empty' });
      } else if (address.buyer_city === '') {
        return toastMessageWarning({ message: 'City Name Cannot be empty' });
      } else if (address.buyer_zip === '') {
        return toastMessageWarning({ message: 'Pincode Cannot be empty' });
      } else if (pincodeChecker.test(address.buyer_zip) === false) {
        return toastMessageWarning({
          message: `Please Add A Valid 6 Digit Pincode !`,
        });
      }
    }
  };
  const handleEditAddress = () => {
    if (
      address?.address_label !== '' &&
      address?.buyer_short_address !== '' &&
      address?.buyer_address_1 !== '' &&
      // address?.buyer_address_2 !== '' &&
      address?.buyer_country !== '' &&
      address?.buyer_state !== '' &&
      address?.buyer_city !== '' &&
      address?.buyer_zip !== '' &&
      pincodeChecker.test(address?.buyer_zip) !== false
    ) {
      setError(false);
      const singD = qs.stringify({
        // order_id: address?.order_id,
        buyer_id: address?.buyer_id,
        buyer_name: address?.buyer_name,
        address_label: address?.address_label,
        buyer_short_address: address?.buyer_short_address,
        buyer_address_1: address?.buyer_address_1,
        buyer_address_2: address?.buyer_address_2,
        buyer_city: address?.buyer_city,
        buyer_state: address?.buyer_state,
        buyer_country: address?.buyer_country,
        buyer_zip: address?.buyer_zip,
        buyer_mobile: address?.buyer_mobile,
        buyer_email: address?.buyer_email,
        shiping_info_id: address?.shiping_info_id,
      });

      axios
        .post(`${API_URL}/editbuyeraddress`, singD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            const cartD = qs.stringify({
              buyer_id: address?.buyer_id,
            });

            axios
              .post(`${API_URL}/buyeraddress`, cartD, {
                headers: {
                  Authorization: `${authToken}`,
                },
              })
              .then((res) => {
                setAddressDetails(res?.data?.result);
              });
            handleEditClose();
          }
        });
    } else {
      setError(true);

      if (address.address_label === '') {
        return toastMessageWarning({
          message: 'Address Label Cannot be empty',
        });
      } else if (address.buyer_short_address === '') {
        return toastMessageWarning({
          message: 'Short Address Cannot be empty',
        });
      } else if (address.buyer_address_1 === '') {
        return toastMessageWarning({
          message: 'Address Line 1 Cannot be empty',
        });
      } else if (address.buyer_country === '') {
        return toastMessageWarning({ message: 'Country Name Cannot be empty' });
      } else if (address.buyer_state === '') {
        return toastMessageWarning({ message: 'State Name Cannot be empty' });
      } else if (address.buyer_city === '') {
        return toastMessageWarning({ message: 'City Name Cannot be empty' });
      } else if (address.buyer_zip === '') {
        return toastMessageWarning({ message: 'Pincode Cannot be empty' });
      } else if (pincodeChecker.test(address.buyer_zip) === false) {
        return toastMessageWarning({
          message: `Please Add A Valid 6 Digit Pincode !`,
        });
      }
    }
  };

  const handleFormChange = (event) => {
    setAdress({ ...address, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Dialog
        open={adressOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAddressClose}
        fullWidth="md"
        maxWidth="md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleAddressClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">
                {editName == 'Edit' ? (
                  <>{t('buyerOrderPages.edit')}</>
                ) : (
                  <>{t('buyerOrderPages.add')}</>
                )}{' '}
                {t('buyerOrderPages.deliveryAddress')}
              </div>
            </div>
            <hr />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* <div className="qna-container">
              <label htmlFor="buyer_name" className="question">
                Buyer Name*
              </label>
              <TextField
                id="buyer_name"
                name="buyer_name"
                type="text"
                className="textField w-100"
                placeholder="Buyer Name"
                value={address?.buyer_name}
                onChange={(event) => handleFormChange(event)}
                error={error && address?.buyer_name === '' ? true : false}
                // helperText={
                // error &&
                // address?.buyer_name === '' &&
                // 'This field is required'
                // }
              />
            </div> */}

              <div className="qna-container">
                <label htmlFor="address_label" className="question">
                  {t('buyerOrderPages.deliveryAddress')}*
                </label>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={address?.address_label}
                    onChange={(e) =>
                      setAdress({
                        ...address,
                        address_label: e.target.value,
                      })
                    }
                    displayEmpty
                    error={
                      error && address?.address_label === '' ? true : false
                    }
                    // helperText={
                    // error &&
                    // address?.address_label === '' &&
                    // 'This field is required'
                    // }
                  >
                    <MenuItem value="" disabled>
                      <em>
                        {t('buyerOrderPages.select')}{' '}
                        {t('buyerOrderPages.AddressLocation')}
                      </em>
                    </MenuItem>
                    <MenuItem value="Home">
                      {t('buyerOrderPages.home')}
                    </MenuItem>
                    <MenuItem value="Office">
                      {t('buyerOrderPages.office')}
                    </MenuItem>
                    <MenuItem value="Other">
                      {t('buyerOrderPages.other')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="qna-container">
                <label htmlFor="buyer_short_address" className="question">
                  {t('buyerOrderPages.ShortAddress')}*
                </label>
                <TextField
                  id="buyer_short_address"
                  name="buyer_short_address"
                  type="text"
                  inputProps={{
                    maxLength: 100,
                    type: 'text',
                  }}
                  className="textField w-100"
                  placeholder={t('buyerOrderPages.ShortAddress')}
                  value={address?.buyer_short_address}
                  onChange={(event) => handleFormChange(event)}
                  error={
                    error && address?.buyer_short_address === '' ? true : false
                  }
                  // helperText={
                  // error &&
                  // address?.buyer_short_address === '' &&
                  // 'This field is required'
                  // }
                />
              </div>
              <div className="qna-container">
                <label htmlFor="buyer_address_1" className="question">
                  {t('buyerOrderPages.Line1')}*
                </label>
                <TextField
                  id="buyer_address_1"
                  name="buyer_address_1"
                  type="text"
                  inputProps={{
                    maxLength: 100,
                    type: 'text',
                  }}
                  className="textField w-100"
                  placeholder={t('buyerOrderPages.Line1')}
                  value={address?.buyer_address_1}
                  onChange={(event) => handleFormChange(event)}
                  error={
                    error && address?.buyer_address_1 === '' ? true : false
                  }
                  // helperText={
                  // error &&
                  // address?.buyer_address_1 === '' &&
                  // 'This field is required'
                  // }
                />
              </div>
              <div className="qna-container">
                <label htmlFor="buyer_address_2" className="question">
                  {t('buyerOrderPages.Line2')}
                </label>
                <TextField
                  id="buyer_address_2"
                  name="buyer_address_2"
                  type="text"
                  inputProps={{
                    maxLength: 100,
                    type: 'text',
                  }}
                  className="textField w-100"
                  placeholder={t('buyerOrderPages.Line2')}
                  value={address?.buyer_address_2}
                  onChange={(event) => handleFormChange(event)}
                  // error={error && address?.buyer_address_2 === '' ? true : false}
                  // helperText={
                  // error &&
                  // address?.buyer_address_2 === '' &&
                  // 'This field is required'
                  // }
                />
              </div>
              <div className="qna-container">
                <label htmlFor="buyer_country" className="question">
                  {t('buyerOrderPages.YourCountry')}*
                </label>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={address?.buyer_country}
                    onChange={handleCountryChange}
                    displayEmpty
                    error={
                      error && address?.buyer_country === '' ? true : false
                    }
                    // helperText={
                    // error &&
                    // address?.country_id === '' &&
                    // 'This field is required'
                    // }
                  >
                    <MenuItem value="" disabled>
                      <em>
                        {t('buyerOrderPages.select')}{' '}
                        {t('buyerOrderPages.YourCountry')}
                      </em>
                    </MenuItem>
                    {countryList?.map((country) => (
                      <MenuItem key={country?.id} value={country?.id}>
                        {country?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="qna-container">
                <label htmlFor="buyer_state" className="question">
                  {t('buyerOrderPages.YourState')}*
                </label>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={address?.buyer_state}
                    onChange={handleStateChange}
                    displayEmpty
                    error={error && address?.buyer_state === '' ? true : false}
                    // helperText={
                    // error &&
                    // address?.state_id === '' &&
                    // 'This field is required'
                    // }
                  >
                    <MenuItem value="" disabled>
                      <em>
                        {t('buyerOrderPages.select')}{' '}
                        {t('buyerOrderPages.YourState')}
                      </em>
                    </MenuItem>
                    {stateList?.map((state) => (
                      <MenuItem key={state?.id} value={state?.id}>
                        {state?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="qna-container">
                <label htmlFor="buyer_city" className="question">
                  {t('buyerOrderPages.YourCity')}*
                </label>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={address?.buyer_city}
                    onChange={(e) =>
                      setAdress({
                        ...address,
                        buyer_city: e.target.value,
                      })
                    }
                    displayEmpty
                    error={error && address?.buyer_city === '' ? true : false}
                    // helperText={
                    // error &&
                    // address?.buyer_city === '' &&
                    // 'This field is required'
                    // }
                  >
                    <MenuItem value="" disabled>
                      <em>
                        {t('buyerOrderPages.select')}{' '}
                        {t('buyerOrderPages.YourCity')}
                      </em>
                    </MenuItem>
                    {cityList?.map((city) => (
                      <MenuItem key={city?.id} value={city?.id}>
                        {city?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="qna-container">
                <label htmlFor="buyer_zip" className="question">
                  {t('buyerOrderPages.YourPincode')}*
                </label>
                <TextField
                  id="buyer_zip"
                  name="buyer_zip"
                  inputProps={{
                    maxLength: 10,
                    type: 'number',
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, e.target.maxLength);
                  }}
                  className="textField w-100"
                  placeholder={t('buyerOrderPages.YourPincode')}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(evt) =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={address?.buyer_zip}
                  onChange={(event) => handleFormChange(event)}
                  error={error && address?.buyer_zip === '' ? true : false}
                  // helperText={
                  // error &&
                  // address?.buyer_zip === '' &&
                  // 'This field is required'
                  // }
                />
              </div>
              {/* <div className="qna-container">
              <label htmlFor="buyer_email" className="question">
                Email Address*
              </label>
              <TextField
                id="buyer_email"
                name="buyer_email"
                type="email"
                className="textField w-100"
                placeholder="Email Address"
                value={address?.buyer_email}
                onChange={(event) => handleFormChange(event)}
                error={error && address?.buyer_email === '' ? true : false}
                // helperText={
                // error &&
                // address?.buyer_email === '' &&
                // 'This field is required'
                // }
              />
            </div>
            <div className="qna-container">
              <label htmlFor="buyer_mobile" className="question">
                Phone Number*
              </label>
              <TextField
                id="buyer_mobile"
                name="buyer_mobile"
                type="number"
                className="textField w-100"
                placeholder="Enter Your 10 digit number"
                value={address?.buyer_mobile}
                onChange={(event) => handleFormChange(event)}
                error={error && address?.buyer_mobile === '' ? true : false}
                // helperText={
                // error &&
                // address?.buyer_mobile === '' &&
                // 'This field is required'
                // }
              />
            </div> */}

              <button
                className="loginBtn"
                onClick={
                  editName == 'Edit' ? handleEditAddress : handleAddAddress
                }
              >
                {editName == 'Edit' ? (
                  <>{t('buyerOrderPages.edit')}</>
                ) : (
                  <>{t('buyerOrderPages.add')}</>
                )}{' '}
                {t('buyerOrderPages.address')}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import CategoryCard from '../../Components/HomePage/CategoriesSection/CategoryCard';

import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import BuyerLayout from 'layouts/BuyerLayout';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const CategoriesPage = () => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoryD = qs.stringify({
      // language: i18n.language,
    });

    axios
      .post(`${API_URL}/categorys`, categoryD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setCategories(res?.data?.result);
      });
  }, []);
  return (
    <BuyerLayout>
      <div className="wrapper">
        <div className="innerWrapper">
          <section className="categorySection">
            <div className="categorySection__title">Product Categories</div>
            <div className="categoryContainer">
              <div className="grid grid-cols-1 place-items-center md:grid-cols-4 gap-4">
                {categories?.map((category) => (
                  <CategoryCard
                    key={category?.category_id}
                    img={category?.cat_image}
                    title={category?.category_name}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </BuyerLayout>
  );
};

export default CategoriesPage;

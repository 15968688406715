import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import ProductImg from './ProductImg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { t } from 'i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

export default function ViewImages({
  handleModalClose,
  modalOpen,
  imagesArray,
  title,
  remark,
  order_status,
  cart,
}) {
  var settings = {
    dots: false,
    infinite: imagesArray?.length > 3,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        fullWidth="sm"
        maxWidth="sm"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleModalClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="modal-header">
              <div className="loginTitle">{title}</div>
            </div>
            {order_status === 5 ? (
              <>
                {remark && (
                  <h5 className="clientRemarks">
                    {t('orderModals.remarks')}: <span>{remark}</span>
                  </h5>
                )}
              </>
            ) : (
              <>
                {imagesArray == null || imagesArray?.length == 0 ? (
                  <h1>{t('orderModals.noImg')}</h1>
                ) : (
                  <>
                    {(order_status === 8 || order_status === 9) && (
                      <>
                        {cart?.return_quantity && (
                          <h5
                            className="clientRemarks"
                            style={{ paddingBottom: '0' }}
                          >
                            {t('orderModals.returnedQty')}:{' '}
                            <span>
                              {cart?.return_quantity} (
                              {cart?.unit == 'Quintals'
                                ? 'Q'
                                : cart?.unit == 'Ltrs'
                                ? 'L'
                                : cart?.unit == 'Bags'
                                ? 'Bags'
                                : 'Bales'}
                              )
                            </span>
                          </h5>
                        )}
                      </>
                    )}
                    {remark && (
                      <h5 className="clientRemarks">
                        {t('orderModals.remarks')}: <span>{remark}</span>
                      </h5>
                    )}

                    <Slider {...settings}>
                      {imagesArray?.map((images, index) => (
                        <ProductImg key={index} img={images} />
                      ))}
                    </Slider>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}

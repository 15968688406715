import { useEffect, useState } from 'react';
import DeliveryAddress from '../Login/DeliveryAddress';
import './addressCard.scss';
import PushPinIcon from '@mui/icons-material/PushPin';
import { t } from 'i18next';

const AddressCards = ({ addressDetails, setAddressDetails }) => {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);

  const [address, setAdress] = useState({
    buyer_id: user?.buyer_id,
    buyer_name: `${user?.first_name} ${user?.last_name}`,
    address_label: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_mobile: user?.buyer_mobile,
    buyer_email: user?.buyer_email,
    buyer_country: '101',
    buyer_state: '',
    buyer_city: '',
    buyer_zip: '',
    shiping_info_id: '',
  });

  useEffect(() => {
    setAdress({
      buyer_id: user?.buyer_id,
      buyer_name: addressDetails?.buyer_name,
      address_label: addressDetails?.address_label,
      buyer_short_address: addressDetails?.buyer_short_address,
      buyer_address_1: addressDetails?.buyer_address_1,
      buyer_address_2: addressDetails?.buyer_address_2,
      buyer_mobile: user?.buyer_mobile,
      buyer_email: user?.buyer_email,
      buyer_country: addressDetails?.buyer_country,
      buyer_state: addressDetails?.buyer_state,
      buyer_city: addressDetails?.buyer_city,
      buyer_zip: addressDetails?.buyer_zip,
      shiping_info_id: addressDetails?.shiping_info_id,
    });
  }, [addressDetails]);

  const handleAddressClose = () => {
    setAddressOpen(false);
  };

  const [editName, setEditName] = useState('');
  const [addressOpen, setAddressOpen] = useState(false);

  const handleEditOpen = () => {
    setEditName('Edit');
    setAddressOpen(true);
  };
  const handleEditClose = () => {
    setAddressOpen(false);
  };

  return (
    <div className="addressCard">
      <div className="adsHeading">
        <PushPinIcon fontSize="small" style={{ marginRight: '1rem' }} />
        {t('buyerOrderPages.locInfo')}
      </div>
      <div className="adsInfo">
        <p style={{ fontWeight: 'bold' }}>
          {addressDetails?.buyer_name}, {addressDetails?.buyer_mobile}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('buyerOrderPages.short')}:
          </span>{' '}
          {addressDetails?.buyer_short_address}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('buyerOrderPages.addressLine1')}:
          </span>{' '}
          {addressDetails?.buyer_address_1}
        </p>
        {addressDetails?.buyer_address_2 && (
          <p>
            <span style={{ fontWeight: 'bold' }}>
              {t('buyerOrderPages.addressLine2')}:
            </span>{' '}
            {addressDetails?.buyer_address_2}
          </p>
        )}
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('buyerOrderPages.country')}:
          </span>{' '}
          {addressDetails?.country_name}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('buyerOrderPages.state')}:
          </span>{' '}
          {addressDetails?.state_name}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('buyerOrderPages.city')}:
          </span>{' '}
          {addressDetails?.city_name}
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            {t('buyerOrderPages.zipcode')}:
          </span>{' '}
          {addressDetails?.buyer_zip}
        </p>
      </div>
      <div className="adsButton" onClick={handleEditOpen}>
        {t('buyerOrderPages.editAddress')}
      </div>
      {addressOpen && (
        <DeliveryAddress
          handleEditClose={handleEditClose}
          handleAddressClose={handleAddressClose}
          setAddressDetails={setAddressDetails}
          adressOpen={addressOpen}
          address={address}
          setAdress={setAdress}
          editName={editName}
        />
      )}
    </div>
  );
};

export default AddressCards;

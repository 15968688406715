import './contact.scss';

import { useContext, useState } from 'react';
import ContactContext from 'context/ContactProvider';
import { toastMessageWarning } from 'utils/toast';
import qs from 'qs';
import axios from 'axios';
import { toastMessageSuccess } from 'utils/toast';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { t } from 'i18next';

const ContactUs = () => {
  const { contactDetails } = useContext(ContactContext);
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const [loading, setLoading] = useState(false);

  const nameValidator = /^([a-zA-Z]{3,50})$/;
  const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegx = /^[6-9]\d{9}$/;

  const [sellerUserInfo, setSellerUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: '',
  });

  const handleContact = (e) => {
    e.preventDefault();
    if (
      sellerUserInfo?.firstName !== '' &&
      nameValidator.test(sellerUserInfo.firstName) !== false &&
      sellerUserInfo?.lastName !== '' &&
      nameValidator.test(sellerUserInfo.lastName) !== false &&
      sellerUserInfo?.email !== '' &&
      sellerUserInfo?.mobile !== '' &&
      emailRegx.test(sellerUserInfo.email) !== false &&
      phoneRegx.test(sellerUserInfo.mobile) !== false
    ) {
      setLoading(true);

      const singD = qs.stringify({
        first_name: sellerUserInfo?.firstName,
        last_name: sellerUserInfo?.lastName,
        email: sellerUserInfo?.email,
        mobile: sellerUserInfo?.mobile,
        message: sellerUserInfo?.message,
      });

      axios
        .post(`${API_URL}/sendcontactemail`, singD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setLoading(false);

          if (res?.data?.status === false) {
            toastMessageWarning({
              message: `${res?.data?.message}`,
            });
          } else {
            toastMessageSuccess({
              message: `${res?.data?.message}`,
            });
            setSellerUserInfo({
              firstName: '',
              lastName: '',
              email: '',
              mobile: '',
              message: '',
            });
          }
        });
    } else {
      if (sellerUserInfo.firstName === '') {
        return toastMessageWarning({ message: 'First Name Cannot be empty' });
      } else if (nameValidator.test(sellerUserInfo.firstName) === false) {
        return toastMessageWarning({
          message: 'First Name is not valid',
        });
      } else if (sellerUserInfo.lastName === '') {
        return toastMessageWarning({ message: 'Last Name Cannot be empty' });
      } else if (nameValidator.test(sellerUserInfo.lastName) === false) {
        return toastMessageWarning({
          message: 'Last Name is not valid',
        });
      } else if (sellerUserInfo.email === '') {
        return toastMessageWarning({
          message: 'Email Address Cannot be empty',
        });
      } else if (emailRegx.test(sellerUserInfo.email) === false) {
        return toastMessageWarning({ message: 'Email address is not valid!' });
      } else if (sellerUserInfo.mobile === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(sellerUserInfo.mobile) === false) {
        return toastMessageWarning({
          message: 'Please fill valid phone number!',
        });
      } else if (sellerUserInfo.message === '') {
        return toastMessageWarning({
          message: 'Please enter a message !',
        });
      }
    }
  };

  const handleFormChange = (event) => {
    setSellerUserInfo({
      ...sellerUserInfo,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'transparent',
        }),
        wrapper: {
          overflow: 'hidden',
        },
      }}
      spinner={<ClipLoader color="#F7991F" size={90} />}
    >
      <div className="contactContainerOuter">
        <div className="wrapper">
          <div className="innerWrapper">
            <div className="contactContainerInner">
              <div className="contactContainer">
                <div className="content">
                  <div className="left-side">
                    <div className="address details">
                      <i className="fas fa-map-marker-alt"></i>
                      <div className="topic">{t('contact.address')}</div>
                      <div className="text-one">{contactDetails?.address}</div>
                      {/* <div className="text-two">India</div> */}
                    </div>
                    <div className="phone details">
                      <i className="fas fa-phone-alt"></i>
                      <div className="topic">{t('contact.phone')}</div>
                      <div className="text-one">{contactDetails?.phone}</div>
                    </div>
                    <div className="email details">
                      <i className="fas fa-envelope"></i>
                      <div className="topic">{t('contact.emailInfo')}</div>
                      <div className="text-one">{contactDetails?.email}</div>
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="topic-text">{t('contact.sendMessage')}</div>
                    <p>{t('contact.enquiry')}</p>
                    <form
                      autoComplete="off"
                      noValidate
                      onSubmit={handleContact}
                    >
                      <div className="input-box">
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={sellerUserInfo?.firstName}
                          placeholder={t('contact.firstName')}
                          onChange={(event) => handleFormChange(event)}
                        />
                      </div>
                      <div className="input-box">
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={sellerUserInfo?.lastName}
                          placeholder={t('contact.lastName')}
                          onChange={(event) => handleFormChange(event)}
                        />
                      </div>
                      <div className="input-box">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          value={sellerUserInfo?.email}
                          placeholder={t('contact.email')}
                          onChange={(event) => handleFormChange(event)}
                        />
                      </div>
                      <div className="input-box">
                        <input
                          id="mobile"
                          name="mobile"
                          type="number"
                          maxLength="10"
                          placeholder={t('contact.mobile')}
                          onWheel={(e) => e.target.blur()}
                          min={1}
                          onKeyDown={(evt) =>
                            ['e', 'E', '+', '-'].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          value={sellerUserInfo?.mobile}
                          onChange={(event) => handleFormChange(event)}
                        />
                      </div>
                      <div className="input-box message-box">
                        <textarea
                          id="message"
                          name="message"
                          maxLength="500"
                          value={sellerUserInfo?.message}
                          placeholder={t('contact.message')}
                          onChange={(event) => handleFormChange(event)}
                        />
                      </div>
                      <div className="button">
                        <button type="submit" className="sendBtn">
                          {t('contact.send')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default ContactUs;

import { Skeleton } from '@mui/material';
import './productCard.scss';

const CardSkeleton = () => {
  return (
    <div className="productCardOuter">
      <div className="productImg">
        <Skeleton variant="rectangular" height="100%" />
        <div className="infoContainer">
          <div className="producTitle">
            <Skeleton width="150px" height="40px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const EmptyRow = ({ name, value }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f7991f',
      color: theme.palette.common.white,
      // minWidth: '1px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      // minWidth: '1px',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <StyledTableRow>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell align="right" colSpan={3}>
        <span
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {name} :
        </span>
      </StyledTableCell>
      <StyledTableCell align="left">
        <span
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {value}
        </span>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default EmptyRow;

import '../ViewProduct/viewProducts.scss';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ZeroProductTable from './ZeroProductTable';
import { Card, Typography } from '@mui/material';
import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { Button } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { t } from 'i18next';
import ViewProducts from 'Components/MobileTables/ViewProducts/ViewProducts';
import { useWindowSize } from '@uidotdev/usehooks';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ZeroProduct = ({ page }) => {
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const size = useWindowSize();
  const [products, setProducts] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    seller_id: user?.seller_id,
    page: 1,
    per_page: 10,
    search: '',
  });

  useEffect(() => {
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/zeroquantityproducts`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProducts(res?.data?.result);
        setFullData(res?.data);
        setLoading(false);
      });
  }, []);

  // handleLoad more
  const handleLoadMore = () => {
    setFilters({ ...filters, page: filters?.page + 1 });
  };

  useDidMountEffect(() => {
    setLoading(true);
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/zeroquantityproducts`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        const oldArray = products;
        const moreItems = res?.data?.result;
        const newArray = [...oldArray, ...moreItems];
        setProducts(newArray);
        setFullData(res?.data);
        setLoading(false);
      });
  }, [filters?.page]);

  return (
    <>
      {page !== 'home' && (
        <Card sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h6" component="div" className="p-3">
            {t('sellerMenu.zero')}
          </Typography>
        </Card>
      )}
      <section className="viewProductSection">
        <div className="viewProductOuter">
          <div className="viewProductTable">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: 'transparent',
                  }),
                  wrapper: {
                    overflow: 'hidden',
                  },
                }}
                spinner={<ClipLoader color="#F7991F" size={90} />}
              >
                {size.width <= 769 ? (
                  <ViewProducts
                    products={products}
                    page={page}
                    btnName="edit"
                  />
                ) : (
                  <TableContainer
                    sx={{ maxHeight: '60vh' }}
                    className="myPolicyOuterTable"
                  >
                    <Table
                      aria-label="customized table"
                      stickyHeader
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            {t('productListPage.product')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('filterNames.productType')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productDetailPage.variety')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productDetailPage.avail')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productDetailPage.availFrom')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('sellerDashboard.crop')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productDetailPage.availQty')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('productDetailPage.unitPrice')}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {t('sellerDashboard.quality')}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {t('sellerDashboard.action')}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products?.length == 0 ? (
                          <StyledTableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              colSpan={10}
                              style={{
                                textAlign: 'center',
                                fontSize: '1.25rem',
                                padding: '2rem 0',
                              }}
                            >
                              {t('noData')}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <>
                            {products?.map((product) => (
                              <ZeroProductTable
                                product={product}
                                key={product?.product_id}
                                btnName="edit"
                              />
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {fullData && (
                  <div className="tableLoadMoreSection">
                    <div className="table-responsive">
                      <Table aria-label="customized table">
                        {fullData && (
                          <>
                            {fullData?.page * fullData?.per_page <
                              fullData?.total && (
                              <tbody className="list">
                                <tr
                                  style={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <td
                                    colSpan="10"
                                    style={{
                                      fontSize: '1.2rem',
                                      padding: '1rem 0',
                                    }}
                                  >
                                    <Button
                                      // className="my-2"
                                      variant="contained"
                                      size="small"
                                      color="info"
                                      onClick={handleLoadMore}
                                    >
                                      <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-arrow-alt-circle-down"></i>
                                      </span>
                                      <span className="btn-inner--text">
                                        Load more
                                      </span>
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </>
                        )}
                      </Table>
                    </div>
                    <div className="row react-bootstrap-table-pagination">
                      {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                      <div
                        className="dataTables_length"
                        id="datatable-basic_length"
                      >
                        <label>
                          Show{' '}
                          {
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm"
                              value={filters?.per_page}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  per_page: e.target.value,
                                  page: 1,
                                })
                              }
                            >
                              <option className="options" value="10">
                                10
                              </option>
                              <option className="options" value="25">
                                25
                              </option>
                              <option className="options" value="50">
                                50
                              </option>
                              <option className="options" value="75">
                                75
                              </option>
                              <option className="options" value="100">
                                100
                              </option>
                            </select>
                          }
                          entries.
                        </label>
                      </div>
                    </div> */}
                      <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6 ml-auto">
                        <div
                          className="pagination react-bootstrap-table-page-btns-ul"
                          style={{ alignItems: 'center', height: '100%' }}
                        >
                          <span className="react-bootstrap-table-pagination-total ">
                            Showing{' '}
                            {fullData?.total
                              ? fullData?.total == '1'
                                ? '1'
                                : fullData?.per_page * fullData?.page >
                                  fullData?.total
                                ? fullData?.total
                                : fullData?.per_page * fullData?.page
                              : '0'}{' '}
                            of {fullData?.total ? fullData?.total : '0'} results
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </LoadingOverlay>
            </Paper>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZeroProduct;

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import sellerFooterLogo from '../../assets/Logo_white.png';
import './_sellerFooter.scss';
import ContactContext from 'context/ContactProvider';
import { useContext } from 'react';
import { t } from 'i18next';

const SellerFooter = () => {
  const { contactDetails } = useContext(ContactContext);

  return (
    <>
      <section className="sellerFooterSection">
        <div className="sellerFooterContainer">
          <div className="upperSellerFooter">
            <div className="leftContainer">
              <div className="logoContainer">
                <img src={sellerFooterLogo} alt="" />
              </div>
            </div>
            <div className="rightContainer">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {/* first */}
                <div className=" list">
                  <div className="listTitle">{t('company')}</div>
                  <ul>
                    <li>
                      <Link to="/aboutUS">{t('aboutUs')}</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">{t('contactUs')}</Link>
                    </li>
                    <li>
                      <Link to="/products">{t('products')}</Link>
                    </li>
                  </ul>
                </div>
                {/* Second */}
                <div className="list">
                  <div className="listTitle">{t('legal')}</div>
                  <ul>
                    <li>{t('faq')}</li>
                    <li>
                      <Link to="/privacyPolicy">{t('privacy')}</Link>
                    </li>
                    <li>
                      <Link to="/terms">{t('terms')}</Link>
                    </li>
                  </ul>
                </div>
                {/* Third */}
                <div className="list">
                  <div className="listTitle">{t('contactUs')}</div>
                  <ul>
                    <li>
                      <i className="ri-map-pin-fill" />
                      {contactDetails?.address}
                    </li>
                    <li>
                      <i className="ri-phone-fill" />
                      <a href={`tel:${contactDetails?.phone}`}>
                        {contactDetails?.phone}
                      </a>
                    </li>
                    <li>
                      <i className="ri-mail-send-line" />
                      <a href={`mailto:${contactDetails?.email}`}>
                        {contactDetails?.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <hr /> */}
        </div>
      </section>
      <div className="copyrightContainer">
        <div className="text"> &#169; 2024 - Krushi</div>
      </div>
    </>
  );
};

export default SellerFooter;

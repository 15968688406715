import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { toastMessageSuccess, toastMessageWarning } from '../../utils/toast';
import qs from 'qs';
import axios from 'hooks/axios';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
// import { IconButton, InputAdornment } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';
import { ClipLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import AuthContext from 'context/AuthProvider';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function BuyerSignUp({
  handleSignupClose,
  signUpOpen,
  handleLoginOpen,
  sName,
  userInfo,
  setUserInfo,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [color, setColor] = useState('red');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuth, setToken } = useContext(AuthContext);

  const headers = {
    'Content-Type': 'application/json',
  };

  // const handleTogglePassword = () => {
  //   setShowPassword(!showPassword);
  // };
  // const handleToggleConfirmPass = () => {
  //   setShowConfirmPass(!showConfirmPass);
  // };
  const nameValidator = /^([a-zA-Z]{3,50})$/;
  const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegx = /^[6-9]\d{9}$/;
  const pincodeChecker = /^[1-9][0-9]{5}$/;
  const panChecker = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const history = useHistory();

  const startTimer = () => {
    setIsActive(true);
    setTimer(60);

    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(countdown);
          setIsActive(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  // const handleTerms = () => {
  //   handleSignupClose();
  //   history.push(`/terms`);
  // };
  // const handlePolicy = () => {
  //   handleSignupClose();
  //   history.push(`/privacyPolicy`);
  // };

  useEffect(() => {
    const countryD = qs.stringify({});

    axios
      .post(`${API_URL}/countrieslist`, countryD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setCountryList(res?.data?.result);
      });
  }, []);

  useEffect(() => {
    if (userInfo?.buyer_country) {
      const countryD = qs.stringify({
        country_id: userInfo?.buyer_country,
      });

      axios
        .post(`${API_URL}/stateslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setStateList(res?.data?.result);
        });
    }
  }, [userInfo?.buyer_country]);

  useEffect(() => {
    if (userInfo?.buyer_state) {
      const countryD = qs.stringify({
        state_id: userInfo?.buyer_state,
      });

      axios
        .post(`${API_URL}/citieslist`, countryD, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((res) => {
          setCityList(res?.data?.result);
        });
    }
  }, [userInfo?.state_id]);

  const handleCountryChange = (e) => {
    const countryValues = countryList?.filter((countryList) => {
      if (countryList?.id == e.target.value) {
        return countryList;
      }
    });

    setUserInfo({
      ...userInfo,
      buyer_country: countryValues?.[0]?.id,
      country_id: countryValues?.[0]?.id,
    });
  };

  const handleStateChange = (e) => {
    const stateValues = stateList?.filter((stateList) => {
      if (stateList?.id == e.target.value) {
        return stateList;
      }
    });

    setUserInfo({
      ...userInfo,
      buyer_state: stateValues?.[0]?.id,
      state_id: stateValues?.[0]?.id,
    });
  };

  // function handlePassword(event) {
  //   let new_pass = event.target.value;
  //   setUserInfo({
  //     ...userInfo,
  //     buyer_password: new_pass,
  //   });
  //   // regular expressions to validate password
  //   var lowerCase = /[a-z]/g;
  //   var upperCase = /[A-Z]/g;
  //   var numbers = /[0-9]/g;
  //   if (new_pass.length < 6) {
  //     setErrorMessage('Password length should be more than 6.');
  //     setColor('red');
  //   } else if (!new_pass.match(upperCase)) {
  //     setErrorMessage('Password should contains upperCase letters!');
  //     setColor('red');
  //   } else if (!new_pass.match(lowerCase)) {
  //     setErrorMessage('Password should contain lowerCase letters!');
  //     setColor('red');
  //   } else if (!new_pass.match(numbers)) {
  //     setErrorMessage('Password should contains numbers also!');
  //     setColor('red');
  //   } else {
  //     setErrorMessage('Password is strong!');
  //     setColor('green');
  //   }
  // }

  const handleBuyerSignup = () => {
    setLoading(true);

    const singD = qs.stringify({
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      buyer_gender: userInfo?.buyer_gender,
      buyer_short_address: userInfo?.buyer_short_address,
      buyer_address_1: userInfo?.buyer_address_1,
      buyer_address_2: userInfo?.buyer_address_2,
      buyer_city: userInfo?.buyer_city,
      buyer_state: userInfo?.buyer_state,
      buyer_country: userInfo?.buyer_country,
      buyer_zip: userInfo?.buyer_zip,
      buyer_mobile: userInfo?.buyer_mobile,
      buyer_email: userInfo?.buyer_email,
      buyer_pan: userInfo?.buyer_pan,
      buyer_signup_channel: '1',
    });

    axios
      .post(`${API_URL}/buyersignup-form`, singD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          handleSignupClose();
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          localStorage.setItem('token', JSON.stringify(res?.data?.token));
          localStorage.setItem('currentUser', sName);
          localStorage.setItem(
            'userInfo',
            JSON.stringify(res?.data?.result?.[0])
          );
          setAuth(res?.data?.result?.[0]);
          setToken(res?.data?.token);
          window.location.reload();
        }
      });
  };

  const handleFormChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };
  const handleChange1 = (e) => {
    if (e.target.checked == false) {
      setCheckedStatus(false);
    } else {
      setCheckedStatus(true);
    }
  };

  const [OTP, setOTP] = useState('');
  const [otpInfo, setOtpInfo] = useState({
    mobile: '',
    sms_id: '',
    otp: '',
  });

  function handleChange(OTP) {
    setOTP(OTP);
    setOtpInfo({ ...otpInfo, otp: OTP });
  }

  const handleSendOtp = (e) => {
    e.preventDefault();

    if (
      userInfo?.first_name !== '' &&
      nameValidator.test(userInfo.first_name) !== false &&
      userInfo?.last_name !== '' &&
      nameValidator.test(userInfo.last_name) !== false &&
      userInfo?.buyer_gender !== '' &&
      userInfo?.buyer_short_address !== '' &&
      userInfo?.buyer_address_1 !== '' &&
      userInfo?.buyer_country !== '' &&
      userInfo?.buyer_state !== '' &&
      userInfo?.buyer_city !== '' &&
      userInfo?.buyer_zip !== '' &&
      pincodeChecker.test(userInfo?.buyer_zip) !== false &&
      userInfo?.buyer_mobile !== '' &&
      userInfo?.buyer_email !== '' &&
      userInfo?.buyer_pan !== '' &&
      phoneRegx.test(userInfo.buyer_mobile) !== false &&
      panChecker.test(userInfo.buyer_pan) !== false &&
      emailRegx.test(userInfo.buyer_email) !== false
    ) {
      if (checkedStatus === true) {
        setError(false);
        setLoading(true);
        const singD = qs.stringify({
          buyer_mobile: userInfo?.buyer_mobile,
          buyer_email: userInfo?.buyer_email,
        });

        axios
          .post(`${API_URL}/buyersignupotp`, singD, {
            headers: {
              Authorization: `${authToken}`,
            },
          })
          .then((res) => {
            setLoading(false);
            if (res?.data?.status === false) {
              toastMessageWarning({
                message: `${res?.data?.message}`,
              });
            } else {
              toastMessageSuccess({
                message: `${res?.data?.message}`,
              });
              setOtpInfo({
                ...otpInfo,
                mobile: res?.data?.result?.buyer_mobile,
                sms_id: res?.data?.result?.sms_id,
              });
              setDisableFields(true);
            }
          });
      } else {
        toastMessageWarning({
          message: 'Please accept Terms & Conditions before signing up!',
        });
      }
    } else {
      setError(true);

      if (userInfo.first_name === '') {
        return toastMessageWarning({ message: 'First Name Cannot be empty' });
      } else if (nameValidator.test(userInfo.first_name) === false) {
        return toastMessageWarning({
          message: 'First Name is not valid',
        });
      } else if (userInfo.last_name === '') {
        return toastMessageWarning({ message: 'Last Name Cannot be empty' });
      } else if (nameValidator.test(userInfo.last_name) === false) {
        return toastMessageWarning({
          message: 'Last Name is not valid',
        });
      } else if (userInfo.buyer_gender === '') {
        return toastMessageWarning({ message: 'Gender Name Cannot be empty' });
      } else if (userInfo.buyer_short_address === '') {
        return toastMessageWarning({
          message: 'Short Address Cannot be empty',
        });
      } else if (userInfo.buyer_address_1 === '') {
        return toastMessageWarning({
          message: 'Address Line 1 Cannot be empty',
        });
      } else if (userInfo.buyer_country === '') {
        return toastMessageWarning({ message: 'Country Name Cannot be empty' });
      } else if (userInfo.buyer_state === '') {
        return toastMessageWarning({ message: 'State Name Cannot be empty' });
      } else if (userInfo.buyer_city === '') {
        return toastMessageWarning({ message: 'City Name Cannot be empty' });
      } else if (userInfo.buyer_zip === '') {
        return toastMessageWarning({ message: 'Pincode Cannot be empty' });
      } else if (pincodeChecker.test(userInfo.buyer_zip) === false) {
        return toastMessageWarning({
          message: `Please Add A Valid 6 Digit Pincode !`,
        });
      } else if (userInfo.buyer_email === '') {
        return toastMessageWarning({
          message: 'Email Address Cannot be empty',
        });
      } else if (emailRegx.test(userInfo.buyer_email) === false) {
        return toastMessageWarning({ message: 'Email address is not valid!' });
      } else if (userInfo.buyer_mobile === '') {
        return toastMessageWarning({ message: 'Phone Number Cannot be empty' });
      } else if (phoneRegx.test(userInfo.buyer_mobile) === false) {
        return toastMessageWarning({
          message: 'Please fill valid mobile number!',
        });
      } else if (userInfo.buyer_pan === '') {
        return toastMessageWarning({
          message: 'PAN number cannot be empty',
        });
      } else if (panChecker.test(userInfo.buyer_pan) === false) {
        return toastMessageWarning({
          message: 'Please fill valid PAN number!',
        });
      }
    }
  };

  const handleResendSignupOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    const loginD = qs.stringify({
      buyer_mobile: userInfo?.buyer_mobile,
      buyer_email: userInfo?.buyer_email,
      sms_id: otpInfo?.sms_id,
    });

    axios
      .post(`${API_URL}/resendbuyersignupotp`, loginD, headers)
      .then((res) => {
        setLoading(false);

        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          setOtpInfo({
            ...otpInfo,
            otp: '',
            mobile: res?.data?.result?.buyer_mobile,
            sms_id: res?.data?.result?.sms_id,
          });
          setDisableFields(true);
          startTimer();
        }
      });
  };

  const handleSignupOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    const loginD = qs.stringify({
      buyer_mobile: otpInfo?.mobile,
      sms_id: otpInfo?.sms_id,
      otp: otpInfo?.otp,
    });

    axios
      .post(`${API_URL}/validatebuyersignupotp`, loginD, headers)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === false) {
          toastMessageWarning({
            message: `${res?.data?.message}`,
          });
        } else {
          toastMessageSuccess({
            message: `${res?.data?.message}`,
          });
          handleBuyerSignup();
        }
      });
  };

  return (
    <>
      <Dialog
        open={signUpOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSignupClose}
        fullWidth="md"
        maxWidth="md"
        style={{
          borderRadius: '20px',
        }}
      >
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'transparent',
            }),
          }}
          spinner={<ClipLoader color="#F7991F" size={90} />}
        >
          <div className="modal-body p-0">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleSignupClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <div className="loginContainer">
              <div className="modal-header">
                <div className="loginTitle">
                  {sName == 'seller'
                    ? `${t('loginForms.seller')}`
                    : `${t('loginForms.buyer')}`}{' '}
                  {t('loginForms.signUp')}
                </div>
              </div>
              <hr />
              <form
                className="inputsContainer"
                autoComplete="off"
                noValidate
                onSubmit={handleBuyerSignup}
              >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="qna-container">
                    <label htmlFor="first_name" className="question">
                      {t('loginForms.firstName')}*
                    </label>
                    <TextField
                      id="first_name"
                      name="first_name"
                      type="text"
                      disabled={disableFields}
                      className="textField w-100"
                      inputProps={{
                        maxLength: 50,
                        type: 'text',
                      }}
                      placeholder={t('loginForms.firstName')}
                      value={userInfo?.first_name}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.first_name === '' ? true : false
                      }
                      // helperText={
                      // error &&
                      // userInfo?.first_name === '' &&
                      // 'This field is required'
                      // }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="last_name" className="question">
                      {t('loginForms.lastName')}*
                    </label>
                    <TextField
                      id="last_name"
                      name="last_name"
                      inputProps={{
                        maxLength: 50,
                        type: 'text',
                      }}
                      type="text"
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.lastName')}
                      value={userInfo?.last_name}
                      onChange={(event) => handleFormChange(event)}
                      error={error && userInfo?.last_name === '' ? true : false}
                      // helperText={
                      // error &&
                      // userInfo?.last_name === '' &&
                      // 'This field is required'
                      // }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_gender" className="question">
                      {t('loginForms.gender')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_gender}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            buyer_gender: e.target.value,
                          })
                        }
                        displayEmpty
                        disabled={disableFields}
                        error={
                          error && userInfo?.buyer_gender === '' ? true : false
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.gender')}
                          </em>
                        </MenuItem>
                        <MenuItem value="Male">{t('loginForms.male')}</MenuItem>
                        <MenuItem value="Female">
                          {t('loginForms.female')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_short_address" className="question">
                      {t('loginForms.shortAdd')}*
                    </label>
                    <TextField
                      id="buyer_short_address"
                      name="buyer_short_address"
                      inputProps={{
                        maxLength: 100,
                        type: 'text',
                      }}
                      type="text"
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.shortAdd')}
                      value={userInfo?.buyer_short_address}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_short_address === ''
                          ? true
                          : false
                      }
                      // helperText={
                      // error &&
                      // userInfo?.buyer_short_address === '' &&
                      // 'This field is required'
                      // }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_address_1" className="question">
                      {t('loginForms.line1')}*
                    </label>
                    <TextField
                      id="buyer_address_1"
                      name="buyer_address_1"
                      inputProps={{
                        maxLength: 100,
                        type: 'text',
                      }}
                      type="text"
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.line1')}
                      value={userInfo?.buyer_address_1}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_address_1 === '' ? true : false
                      }
                      // helperText={
                      // error &&
                      // userInfo?.buyer_address_1 === '' &&
                      // 'This field is required'
                      // }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_address_2" className="question">
                      {t('loginForms.line2')}
                    </label>
                    <TextField
                      id="buyer_address_2"
                      name="buyer_address_2"
                      inputProps={{
                        maxLength: 100,
                        type: 'text',
                      }}
                      disabled={disableFields}
                      type="text"
                      className="textField w-100"
                      placeholder={t('loginForms.line2')}
                      value={userInfo?.buyer_address_2}
                      onChange={(event) => handleFormChange(event)}
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_country" className="question">
                      {t('loginForms.country')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.country_id}
                        onChange={handleCountryChange}
                        displayEmpty
                        disabled={disableFields}
                        error={
                          error && userInfo?.country_id === '' ? true : false
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.country')}
                          </em>
                        </MenuItem>
                        {countryList?.map((country) => (
                          <MenuItem key={country?.id} value={country?.id}>
                            {country?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_state" className="question">
                      {t('loginForms.state')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.state_id}
                        onChange={handleStateChange}
                        displayEmpty
                        disabled={disableFields}
                        error={
                          error && userInfo?.state_id === '' ? true : false
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.state')}
                          </em>
                        </MenuItem>
                        {stateList?.map((state) => (
                          <MenuItem key={state?.id} value={state?.id}>
                            {state?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_city" className="question">
                      {t('loginForms.city')}*
                    </label>
                    <FormControl style={{ width: '100%' }}>
                      <Select
                        value={userInfo?.buyer_city}
                        disabled={disableFields}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            buyer_city: e.target.value,
                          })
                        }
                        displayEmpty
                        error={
                          error && userInfo?.buyer_city === '' ? true : false
                        }
                        // helperText={
                        // error &&
                        // userInfo?.buyer_city === '' &&
                        // 'This field is required'
                        // }
                      >
                        <MenuItem value="" disabled>
                          <em>
                            {t('loginForms.select')} {t('loginForms.city')}
                          </em>
                        </MenuItem>
                        {cityList?.map((city) => (
                          <MenuItem key={city?.id} value={city?.id}>
                            {city?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="qna-container">
                    <label htmlFor="buyer_zip" className="question">
                      {t('loginForms.pincode')}*
                    </label>
                    <TextField
                      id="buyer_zip"
                      name="buyer_zip"
                      inputProps={{
                        maxLength: 10,
                        type: 'number',
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, e.target.maxLength);
                      }}
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.pincode')}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={userInfo?.buyer_zip}
                      onChange={(event) => handleFormChange(event)}
                      error={error && userInfo?.buyer_zip === '' ? true : false}
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_email" className="question">
                      {t('loginForms.email')}*
                    </label>
                    <TextField
                      id="buyer_email"
                      name="buyer_email"
                      type="email"
                      inputProps={{
                        maxLength: 250,
                      }}
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.email')}
                      value={userInfo?.buyer_email}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_email === '' ? true : false
                      }
                      // helperText={
                      // error &&
                      // userInfo?.buyer_email === '' &&
                      // 'This field is required'
                      // }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_mobile" className="question">
                      {t('loginForms.phone')}*
                    </label>
                    <TextField
                      id="buyer_mobile"
                      name="buyer_mobile"
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.phone')}
                      value={userInfo?.buyer_mobile}
                      min={1}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      inputProps={{
                        maxLength: 10,
                        type: 'number',
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, e.target.maxLength);
                      }}
                      onChange={(event) => handleFormChange(event)}
                      error={
                        error && userInfo?.buyer_mobile === '' ? true : false
                      }
                      // helperText={
                      // error &&
                      // userInfo?.buyer_mobile === '' &&
                      // 'This field is required'
                      // }
                    />
                  </div>
                  <div className="qna-container">
                    <label htmlFor="buyer_pan" className="question">
                      {t('loginForms.panNumber')}*
                    </label>
                    <TextField
                      id="buyer_pan"
                      name="buyer_pan"
                      type="text"
                      disabled={disableFields}
                      className="textField w-100"
                      placeholder={t('loginForms.panNumber')}
                      value={userInfo?.buyer_pan}
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) =>
                        setUserInfo({
                          ...userInfo,
                          buyer_pan: e.target.value?.toUpperCase(),
                        })
                      }
                      error={error && userInfo?.buyer_pan === '' ? true : false}
                    />
                  </div>
                  {/* <div className="qna-container">
                  <label htmlFor="buyer_password" className="question">
                    {t('loginForms.password')}*
                  </label>
                  <TextField
                    id="buyer_password"
                    name="buyer_password"
                    type={showPassword ? 'text' : 'password'}
                    className="textField w-100"
                    placeholder={t('loginForms.password')}
                    // onChange={(event) => handleFormChange(event)}
                    value={userInfo?.buyer_password}
                    onChange={handlePassword}
                    error={
                      error && userInfo?.buyer_password === '' ? true : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // helperText={
                    // error &&
                    // userInfo?.buyer_password === '' &&
                    // 'This field is required'
                    // }
                  />
                  <div style={{ color: `${color}` }}> {errorMessage} </div>
                </div>
                <div className="qna-container">
                  <label htmlFor="confirmPassword" className="question">
                    {t('loginForms.confirmPass')}*
                  </label>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPass ? 'text' : 'password'}
                    className="textField w-100"
                    placeholder={t('loginForms.confirmPass')}
                    value={userInfo?.confirmPassword}
                    onChange={(event) => handleFormChange(event)}
                    error={
                      error && userInfo?.confirmPassword === '' ? true : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPass}
                            edge="end"
                          >
                            {showConfirmPass ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // helperText={
                    // error &&
                    // userInfo?.confirmPassword === '' &&
                    // 'This field is required'
                    // }
                  />
                </div> */}
                </div>

                <div className="agreeLink">
                  <FormControlLabel
                    label=""
                    style={{
                      margin: '0',
                    }}
                    control={
                      <Checkbox
                        checked={checkedStatus == true ? true : false}
                        onChange={handleChange1}
                        disabled={disableFields}
                        sx={{
                          color: '#f7991f',
                          '&.Mui-checked': {
                            color: '#f7991f',
                          },
                        }}
                      />
                    }
                  />
                  <h4>
                    {t('loginForms.agree')}{' '}
                    <Link
                      // onClick={handleTerms}
                      to="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('loginForms.term')}
                    </Link>{' '}
                    &{' '}
                    <Link
                      to="/privacyPolicy"
                      // onClick={handlePolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('loginForms.privacy')}
                    </Link>
                  </h4>
                </div>

                {disableFields === true ? (
                  <div className="qna-container">
                    <label htmlFor="otp" className="question">
                      {t('loginForms.validateOtp')}
                    </label>
                    <OTPInput
                      onChange={handleChange}
                      value={OTP}
                      inputStyle="inputStyle"
                      numInputs={5}
                      inputType="number"
                      renderInput={(props) => (
                        <input {...props} className="inputStyle" max={1} />
                      )}
                      separator={<span></span>}
                    />
                  </div>
                ) : null}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {disableFields === true ? (
                    <button
                      className="validateButton"
                      type="button"
                      onClick={handleSignupOtp}
                    >
                      {t('loginForms.validateOtp')}
                    </button>
                  ) : (
                    <button
                      className="loginBtn"
                      //  onClick={handleBuyerSignup}
                      onClick={handleSendOtp}
                    >
                      {t('loginForms.signUp')}
                    </button>
                  )}
                  <div
                    className="signUpLink"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <h4>
                      {t('loginForms.existing')} {t('loginForms.buyer')}?
                      <span onClick={handleLoginOpen}>
                        {t('loginForms.signHere')}
                      </span>
                    </h4>
                  </div>

                  {disableFields === true ? (
                    <div className="resendLink">
                      {isActive === false ? (
                        <button
                          className="resend"
                          type="button"
                          // onClick={handleResendBuyerOtp}
                          onClick={handleResendSignupOtp}
                          disabled={isActive}
                        >
                          Resend OTP
                          <i className="ri-arrow-right-s-fill"></i>
                        </button>
                      ) : (
                        <div className={`timer ${isActive ? 'counting' : ''}`}>
                          {isActive ? `Resend OTP in ${timer}s` : ''}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </LoadingOverlay>
      </Dialog>
    </>
  );
}

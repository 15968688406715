import qs from 'qs';
import axios from 'hooks/axios';
import { useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import MyOrder from '../MyOrders/MyOrder';
import SellerLayout from 'layouts/SellerLayout';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { t } from 'i18next';
import SalesOrdersMobile from 'Components/MobileTables/SalesOrders/SalesOrders';
import { useWindowSize } from '@uidotdev/usehooks';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const ReturnOrders = () => {
  const size = useWindowSize();
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const [salesOrders, setSalesOrders] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(true);
  const [fullData, setFullData] = useState([]);
  const [filters, setFilters] = useState({
    seller_id: user?.seller_id,
    page: 1,
    per_page: 10,
    search: '',
  });

  useEffect(() => {
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/returnorders`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSalesOrders(res?.data?.result);
        setFullData(res?.data);
        setFullPageLoader(false);
      });
  }, []);

  // handleLoad more
  const handleLoadMore = () => {
    setFilters({ ...filters, page: filters?.page + 1 });
  };

  useDidMountEffect(() => {
    setFullPageLoader(true);
    const productsD = qs.stringify({
      seller_id: filters?.seller_id,
      per_page: filters?.per_page,
      page: filters?.page,
      search: filters?.search,
    });

    axios
      .post(`${API_URL}/returnorders`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        const oldArray = salesOrders;
        const moreItems = res?.data?.result;
        const newArray = [...oldArray, ...moreItems];
        setSalesOrders(newArray);
        setFullData(res?.data);
        setFullPageLoader(false);
      });
  }, [filters?.page]);

  return (
    <SellerLayout>
      <section className="salesOrderPage">
        <Card sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h6" component="div" className="p-3">
            {t('sellerDashboard.returnedOrderList')}
          </Typography>
        </Card>
        <div className="my-6">
          {size.width <= 769 ? (
            <SalesOrdersMobile
              salesOrders={salesOrders}
              loading={fullPageLoader}
              fullData={fullData}
              filters={filters}
              setFilters={setFilters}
              handleLoadMore={handleLoadMore}
              page="return"
            />
          ) : (
            <MyOrder
              salesOrders={salesOrders}
              loading={fullPageLoader}
              fullData={fullData}
              filters={filters}
              setFilters={setFilters}
              handleLoadMore={handleLoadMore}
              page="return"
            />
          )}
        </div>
      </section>
    </SellerLayout>
  );
};

export default ReturnOrders;

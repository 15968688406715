import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
    padding: '8px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    minWidth: '160px',
    padding: '8px 16px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableInfoRows = ({ heading, info }) => {
  return (
    <StyledTableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <StyledTableCell component="th" scope="row">
        {heading}
      </StyledTableCell>
      <StyledTableCell align="right">{info}</StyledTableCell>
    </StyledTableRow>
  );
};

const SalesOrdersTable = ({ order, index, page }) => {
  const [expand, setExpand] = useState(false);
  const userType = localStorage.getItem('currentUser');

  const history = useHistory();

  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const order_date = new Date(order?.order_date);

  const handlePush = () => {
    {
      userType == 'buyer'
        ? history.push(`/orderDetails/${order?.order_id}`)
        : page == 'cancel'
        ? history.push(`/cancelOrderDetails/${order?.order_id}`)
        : page == 'return'
        ? history.push(`/returnedOrderDetails/${order?.order_id}`)
        : history.push(`/sellerOrderDetails/${order?.order_id}`);
    }
  };

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };
  return (
    <div className="mobileTableRowDisplay">
      <div className="briefBox">
        <div className="headingNameContainer" onClick={handlePush}>
          {userType == 'seller' && (
            <div className="titleName">
              {index + 1}. {order?.buyer_name} - ₹{order?.amount}
            </div>
          )}
          <div className="subName hoveredDataNavlink">
            {t('sellerDashboard.id')}: {order?.order_id}
          </div>
          {userType == 'buyer' && (
            <div className="subName">
              {t('sellerDashboard.orderedOn')}:{' '}
              {`${padL(order_date.getDate())}-${padL(
                order_date.getMonth() + 1
              )}-${order_date.getFullYear()} ${padL(
                order_date.getHours()
              )}:${padL(order_date.getMinutes())}:${padL(
                order_date.getSeconds()
              )}`}
            </div>
          )}
        </div>

        <div className="buttonContainer">
          <IconButton variant="contained" size="medium" onClick={handleExpand}>
            {expand === false ? (
              <AddCircleIcon
                style={{
                  width: '1.1rem',
                  height: '1.1rem',
                }}
              />
            ) : (
              <RemoveCircleIcon
                style={{
                  width: '1.1rem',
                  height: '1.1rem',
                }}
              />
            )}
          </IconButton>
        </div>
      </div>
      {expand && (
        <div className="infoBox">
          <TableContainer
            sx={{ overflowX: 'hidden' }}
            className="myPolicyOuterTable"
          >
            <Table aria-label="customized table" stickyHeader>
              <TableBody>
                <TableInfoRows
                  heading={t('sellerDashboard.id')}
                  info={order?.order_id}
                />
                <TableInfoRows
                  heading={t('sellerDashboard.amount')}
                  info={`₹ ${order?.amount}`}
                />
                {userType == 'seller' && (
                  <TableInfoRows
                    heading={t('sellerDashboard.buyerName')}
                    info={order?.buyer_name}
                  />
                )}
                <TableInfoRows
                  heading={t('sellerDashboard.date')}
                  info={`${padL(order_date.getDate())}-${padL(
                    order_date.getMonth() + 1
                  )}-${order_date.getFullYear()} ${padL(
                    order_date.getHours()
                  )}:${padL(order_date.getMinutes())}:${padL(
                    order_date.getSeconds()
                  )}`}
                />
                {userType == 'seller' && (
                  <TableInfoRows
                    heading={t('sellerDashboard.payment')}
                    info={order?.payment_gateway_id}
                  />
                )}
                <TableInfoRows
                  heading={t('sellerDashboard.items')}
                  info={order?.order_items?.length}
                />
                <TableInfoRows
                  heading={t('sellerDashboard.status')}
                  info={order?.order_status_label}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default SalesOrdersTable;

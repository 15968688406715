import './productDetail.scss';

const ProductImg = ({ img }) => {
  return (
    <div className="productImgContainer">
      <div className="productImg">
        <img src={img} alt="product image" />
      </div>
    </div>
  );
};

export default ProductImg;

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7991f',
    color: theme.palette.common.white,
    minWidth: '160px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '160px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ShippingInfo = ({ modalOpen, handleModalClose, cart, shipping_info }) => {
  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const exp_delivery_date = new Date(shipping_info?.[0]?.exp_delivery_date);

  return (
    <Dialog
      open={modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      fullWidth="md"
      maxWidth="md"
      style={{
        borderRadius: '20px',
      }}
    >
      <div className="modal-body p-0">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleModalClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <div className="loginContainer">
          <div className="modal-header">
            <div className="loginTitle" style={{ textAlign: 'left' }}>
              {/* Shipping Details of {cart?.product_name} from {cart?.seller_name}
              {cart?.seller_last_name} */}
              {t('shippingModal.details')} #{cart?.sub_transaction_id}{' '}
              {cart?.product_name}
            </div>
          </div>

          {/* <hr /> */}

          <div className="shippingInfoContainer">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer
                sx={{ maxHeight: '60vh' }}
                className="myPolicyOuterTable"
              >
                <Table aria-label="customized table" stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        {t('shippingModal.number')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {t('shippingModal.name')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {t('shippingModal.date')}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {shipping_info?.length == 0 ? (
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            colSpan={10}
                            style={{
                              textAlign: 'center',
                              fontSize: '1.25rem',
                              padding: '2rem 0',
                            }}
                          >
                            {t('noData')}
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        <>
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {shipping_info?.[0]?.shipping_no}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {shipping_info?.[0]?.shipping_name}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {`${padL(exp_delivery_date.getDate())}-${padL(
                                exp_delivery_date.getMonth() + 1
                              )}-${exp_delivery_date.getFullYear()}`}
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ShippingInfo;

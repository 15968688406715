import ProductCard from '../../ProductCard/ProductCard';
import './productionSection.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import CardSkeleton from '../../ProductCard/CardSkeleton';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const ProductSection = () => {
  const [products, setProducts] = useState([]);
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  var settings = {
    dots: false,
    infinite: products?.length > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: products?.length > 3,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const productsD = qs.stringify({
      // language: i18n.language,
    });

    axios
      .post(`${API_URL}/categorys`, productsD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setProducts(res?.data?.result);
        setLoading(false);
      });
  }, []);

  return (
    <section className="productSection">
      <div className="productSection__title">{t('products')}</div>
      <div className="productContainer">
        {loading == true ? (
          <Slider {...settings}>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </Slider>
        ) : (
          <Slider {...settings}>
            {products?.map((product) => (
              <ProductCard
                key={product?.category_id}
                img={product?.cat_image_thumb}
                title={product?.category_name}
                desc={product?.cat_desc}
                product_id={product?.category_id}
              />
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default ProductSection;

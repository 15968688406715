import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { IoIosArrowDown } from 'react-icons/io';
import { NavLink, useLocation } from 'react-router-dom';
import qs from 'qs';
import axios from 'hooks/axios';
import { toastMessageWarning } from 'utils/toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SubMenu = ({ data }) => {
  const { pathname } = useLocation();
  let path = pathname.replace('/', '');
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [sellerInfo, setSellerInfo] = useState([]);
  const currentUser = localStorage.getItem('userInfo');
  const user = JSON.parse(currentUser);
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const authToken = JSON.parse(token);
  const history = useHistory();

  useEffect(() => {
    const userD = qs.stringify({
      seller_id: user?.seller_id,
    });

    axios
      .post(`${API_URL}/getsellerinfo`, userD, {
        headers: {
          Authorization: `${authToken}`,
        },
      })
      .then((res) => {
        setSellerInfo(res?.data?.result?.[0]);
      });
  }, []);

  useEffect(() => {
    setSubMenuOpen(data?.menus.some((menu) => menu.link === path));
  }, []);

  const handleSubMenu = () => {
    if (data?.menus.some((menu) => menu.link === path) === true) {
      setSubMenuOpen(true);
    } else {
      setSubMenuOpen(!subMenuOpen);
    }
  };

  const handleClick = (link) => {
    if (
      sellerInfo?.upi_bank === '' ||
      sellerInfo?.upi_bank === null ||
      sellerInfo?.upi_bank === undefined
    ) {
      setSubMenuOpen(false);
      history.push('/sellerHome');
      toastMessageWarning({
        message: `Please fill your bank info in Manage Profile, to perform following actions !`,
      });
    } else {
      history.push(`/${link}`);
    }
  };

  return (
    <div className="border-b border-slate-300 pb-1">
      <div
        // className={`link ${data?.menus?.includes(path) && 'active'}`}
        className={`link ${
          data?.menus.some((menu) => menu.link === path) && 'active'
        }`}
        // className={`link`}
        onClick={handleSubMenu}
      >
        <data.icon size={23} className="min-w-max" />
        <p className="flex-1  linkHeader capitalize">{data.name}</p>
        <IoIosArrowDown
          className={` ${subMenuOpen && 'rotate-180'} duration-200 `}
        />
      </div>
      <motion.ul
        animate={
          subMenuOpen
            ? {
                height: 'fit-content',
              }
            : {
                height: 0,
              }
        }
        className="flex h-0 flex-col pl-14 text-[0.8rem] font-normal overflow-hidden"
      >
        {data.menus?.map((menu, index) => (
          <li key={index}>
            {/* className="hover:text-blue-600 hover:font-medium" */}
            {/* <NavLink
              to={`/${menu?.link}`}
              className="link !bg-transparent capitalize"
              onClick={handleClick}
            >
              {menu?.name}
            </NavLink> */}
            <div
              className={
                menu.link === path
                  ? 'link !bg-transparent capitalize active'
                  : 'link !bg-transparent capitalize'
              }
              onClick={() => handleClick(menu?.link)}
            >
              {menu?.name}
            </div>
          </li>
        ))}
      </motion.ul>
    </div>
  );
};

export default SubMenu;
